import { Injectable } from '@angular/core';
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
} from '@angular/fire/firestore';
import { Listing, User } from 'src/types';
import { AuthService } from './auth.service';
import { CartService } from './cart.service';
import { ListingManagerService } from './listing-manager.service';
import { MessengerService } from './messenger.service';
import { ModalHelperService } from './modal-helper.service';
import { PayService } from './pay.service';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  editingCard: Listing;
  viewingUser: User;

  constructor(
    public authService: AuthService,
    public cartService: CartService,
    public firestore: Firestore,
    public payService: PayService,
    public modalHelper: ModalHelperService,
    public messengerService: MessengerService,
    public listingManager: ListingManagerService
  ) {}

  async adminOptions(owner, card, modalHelper) {
    this.cartService.isAdmin = true;

    const buttons: any = [
      {
        text: 'Message Uploader',
        icon: 'chatbubble-outline',
        handler: () => {
          this.cartService.openMessenger(owner, card);
        },
      },
      {
        text: card.isStaffPick
          ? 'Unselect as Staff Pick'
          : 'Select as Staff Pick',
        icon: card.isStaffPick ? 'star' : 'star-outline',
        handler: () => {
          this.listingManager
            .toggleStaffPick(owner.uid, card.isStaffPick, card.id)
            .then(() => (card.isStaffPick = !card.isStaffPick));
        },
      },
      {
        text: 'Change Listing Status',
        icon: 'alert-circle-outline',
        handler: () => {
          this.markListing(card, modalHelper);
        },
      },
    ];

    if (card.status === 'shipped') {
      buttons.push({
        text: 'Leave Review',
        icon: 'clipboard-outline',
        handler: () => {
          this.cartService.openReviewer(card);
        },
      });
    }

    buttons.push(
      {
        text: 'Edit Listing',
        icon: 'create-outline',
        handler: () => {
          this.listingManager.updateListing(card, modalHelper, true);
        },
      },
      {
        text: 'Delete Listing',
        icon: 'trash-outline',
        role: 'destructive',
        handler: () => {
          this.deleteListing(card.id);
        },
      }
    );

    buttons.push({
      text: 'Cancel',
      icon: 'close',
      role: 'cancel',
      handler: () => {},
    });

    this.handleBtns(buttons, modalHelper);
  }

  async handleBtns(buttons, modalHelper) {
    if (modalHelper.isMobile) {
      const selectedBtn = await modalHelper.createActionSheet({
        buttons,
      });
      buttons[selectedBtn].handler();

      return;
    }

    setTimeout(async () => {
      const a = await modalHelper.createActionSheet({
        buttons,
      });
      buttons[a].handler();
    }, 500);
  }

  async adminOptionsForUser(user, modalHelper) {
    this.cartService.isAdmin = true;

    this.viewingUser = user;

    const buttons = [
      {
        text: 'Send Verification Text',
        icon: 'chatbubble-outline',
        handler: () => {
          this.messengerService.sendText(user.phoneNumber).then((d: any) => {
            this.modalHelper.createAlert({
              title: 'Verify User',
              message: 'Code ' + d.verificationNumber + ' sent to user',
              buttonTitle: 'Archive',
              type: 'confirm',
              handler: () => deleteDoc(d.twilioDoc),
            });
          });
        },
      },
      {
        text: 'View Orders',
        icon: 'file-tray-full-outline',
        handler: async () => {
          const orders = (
            await getDocs(
              query(
                collection(this.firestore, 'Users', String(user.uid), 'Orders'),
                orderBy('status')
              )
            )
          ).docs.map((order) => order.data());

          this.authService.openOrders(orders);
        },
      },
      {
        text: 'Review Editor',
        icon: 'megaphone-outline',
        handler: () => {
          this.modalHelper.triggerReviewEditor(user);
        },
      },
      {
        text: 'Alter Affiliate',
        icon: 'people-outline',
        handler: () => {
          this.alterAffilate(user);
        },
      },
      {
        text: 'View Spendings',
        icon: 'cash-outline',
        handler: () => {
          this.viewSpendings(user);
        },
      },
      {
        text: 'More Account Settings',
        icon: 'ellipsis-horizontal-outline',
        handler: () => this.moreAccountModification(user, modalHelper),
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      },
    ];

    this.handleBtns(buttons, modalHelper);
  }

  async moreAccountModification(user, modalHelper) {
    const buttons = [
      {
        text: !user.isSupport
          ? 'Make Support Account'
          : 'Revoke Support Account',
        icon: 'help-buoy-outline',
        handler: () => {
          this.authService.makeSupportAccount(user);
        },
      },
      {
        text: !user.isStarSeller ? 'Make Star Seller' : 'Unmake Star Seller',
        icon: 'star-outline',
        handler: () => {
          this.authService.makeAccountStarSeller(user);
        },
      },
      {
        text: !user.isReported ? 'Report Account' : 'Unreport Account',
        icon: 'flag-outline',
        handler: () => {
          this.authService.reportAccount(user);
        },
      },
      {
        text: !user.isUnderMonitoring ? 'Monitor Account' : 'Unmonitor Account',
        icon: 'eye-outline',
        handler: () => {
          this.authService.monitoringAccount(user);
        },
      },
      {
        text: 'Ban User',
        icon: 'ban-outline',
        handler: () => {
          if (
            confirm('Are you sure you want to ban this accounts phone number? ')
          ) {
            this.authService.ban(user.uid);
          }
        },
      },
      {
        text: 'Delete Users Account',
        icon: 'trash-outline',
        handler: () => {
          if (confirm('Are you sure you want to delete this accounts data? ')) {
            this.authService.destroy(user.uid, true);
          }
        },
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      },
    ];

    this.handleBtns(buttons, modalHelper);
  }

  deleteListing(listingID) {
    if (
      confirm(
        'Mark this listing as "Removed", hiding it from the marketplace ?? '
      )
    ) {
      this.listingManager.updateListingStatus(listingID, {
        status: 'removed',
        removedDate: Date.now(),
      });
    }
  }

  async markListing(card, modalHelper) {
    const buttons = [
      {
        text: 'Sold',
        handler: async () =>
          modalHelper.createAlert({
            title: 'Mark listing as sold?',
            type: 'confirm',
            handler: () =>
              this.updateListingStatus(card, {
                status: 'sold',
                soldDate: Date.now(),
              }),
          }),
      },
      {
        text: 'Pending Sold',
        handler: async () =>
          modalHelper.createAlert({
            title: 'Mark listing as pending sale?',
            type: 'confirm',
            handler: () =>
              this.updateListingStatus(card, {
                status: 'c_pending',
                pendingDate: Date.now(),
              }),
          }),
      },
      {
        text: 'Pending',
        handler: () =>
          modalHelper.createAlert({
            title: 'Mark listing as pending sale?',
            type: 'confirm',
            handler: () =>
              this.updateListingStatus(card, {
                status: 'pending',
              }),
          }),
      },
      {
        text: 'Shipped',
        handler: () =>
          modalHelper.createAlert({
            title: 'Mark listing as shipped (awaiting review)?',
            type: 'confirm',
            handler: () =>
              this.updateListingStatus(card, {
                status: 'shipped',
              }),
          }),
      },
      {
        text: 'For Sale',
        handler: () =>
          modalHelper.createAlert({
            title: 'Mark listing as selling?',
            type: 'confirm',
            handler: () =>
              this.updateListingStatus(card, {
                status: 'selling',
                pendingDate: null,
                soldDate: null,
              }),
          }),
      },
      {
        text: 'Delete',
        role: 'destructive',
        handler: () =>
          modalHelper.createAlert({
            title: 'Delete listing?',
            type: 'confirm',
            handler: () =>
              this.updateListingStatus(card, {
                status: 'removed',
                removedDate: Date.now(),
              }),
          }),
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
      },
    ];

    this.handleBtns(buttons, modalHelper);
  }

  async updateListingStatus(card, statusObj) {
    this.listingManager.updateListingStatus(card.id, statusObj);
  }

  async removeReview(reviews, i) {
    const userID = reviews[i].user.uid;

    if (confirm('Archive this review?')) {
      await setDoc(
        doc(this.firestore, 'Users', userID, 'Reviews', reviews[i].id),
        {
          archived: true,
        },
        { merge: true }
      ).then(() =>
        this.recalculateRating(
          reviews.filter((index) => index !== i),
          userID
        )
      );
    }
  }

  async editReview(review) {
    this.cartService.isAdmin = true;

    const card = (
      await getDoc(doc(this.firestore, 'Listings', String(review.listing.id)))
    ).data();

    this.modalHelper.triggerReviewer(card, review);
  }

  async recalculateRating(reviews, userID) {
    const allRatings = [];

    reviews.forEach((review) => {
      if (!review.archived) {
        allRatings.push(review.stars);
      }
    });

    const sum = allRatings.reduce((a, b) => a + b, 0);
    const avg = sum / allRatings.length || 0;

    await setDoc(
      doc(this.firestore, 'Users', userID),
      {
        rating: avg,
      },
      {
        merge: true,
      }
    ).then(() => this.modalHelper.dismissModal());
  }

  async archiveOrder(order) {
    if (this.viewingUser) {
      this.modalHelper.createAlert({
        title: 'Archive Order',
        message: 'Are you sure you want to archive this order?',
        type: 'confirm',
        buttonTitle: 'Archive',
        handler: () => {
          // Add archive flag to order here
          setDoc(
            doc(
              this.firestore,
              'Users',
              this.viewingUser.uid,
              'Orders',
              order.id
            ),
            {
              archived: true,
            },
            {
              merge: true,
            }
          ).then(() =>
            this.modalHelper.createAlert({
              title: 'Order Archived',
              message:
                'Order archived! Close and reopen order page to see changes.',
            })
          );
        },
      });
    } else {
      this.modalHelper.createAlert({
        title: 'Error',
        message: 'User not found. must archive from admin panel.',
      });
    }
  }

  async alterAffilate(user) {
    this.modalHelper.createAlert({
      title: 'Users Affiliate (format: @username)',
      message:
        'This will apply to past orders as well. Ensure no major affiliate funds are unredeemed before changing.',
      inputPlaceholder: '@username',
      buttonTitle: 'Update',
      type: 'prompt',
      handler: async (newAff) => {
        await setDoc(
          doc(this.firestore, 'Users', user.uid),
          { affiliate: newAff },
          {
            merge: true,
          }
        );

        this.modalHelper.createAlert({
          title: 'Affiliate Updated',
          message: `Affiliate updated to ${newAff}`,
        });
      },
    });
  }

  async viewSpendings(account) {
    this.modalHelper.createLoader('Loading spendings...');

    const acc: any = await this.payService.getCustomerTransactions(
      account.customerAccountID
    );

    let totalSpend = 0;

    await this.modalHelper.dismissLoader();

    if (!acc.data) {
      return this.modalHelper.createAlert({
        title: 'Account Value',
        message: `Total spend: $0.00`,
      });
    }

    acc.data.forEach((a) => {
      totalSpend += a.amount;
    });

    const refinedValue = Number(+totalSpend / 100);

    this.modalHelper.createAlert({
      title: 'Account Value',
      message: `Total spend: $${refinedValue.toFixed(2)}`,
    });
  }
}
