import { Component, EnvironmentInjector, NgZone } from '@angular/core';
import { AnimationController, Platform } from '@ionic/angular';

import { AuthService } from './services/auth.service';
import { StatusBar, Style } from '@capacitor/status-bar';

import { App, URLOpenListenerEvent } from '@capacitor/app';

import { Router } from '@angular/router';
import { PayService } from './services/pay.service';
import { ModalHelperService } from './services/modal-helper.service';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

import { register } from 'swiper/element/bundle';
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  hasInternet = true;

  appMode = true;

  constructor(
    public platform: Platform,
    public authService: AuthService,
    public payService: PayService,
    public router: Router,
    private zone: NgZone,
    public environmentInjector: EnvironmentInjector,
    public modalHelper: ModalHelperService,
    public animationCtrl: AnimationController
  ) {
    this.initializeDeepRouter();
    this.buildNinja();
  }

  // This function checks the platform and network status,
  // and performs the necessary actions based on the results.
  buildNinja() {
    // If the platform is ready
    this.platform.ready().then(async () => {
      // Clean the asthetics
      this.cleanAsthetics();

      if (this.modalHelper.isIOS) {
        this.setup3DTouch();
      }

      this.hasInternet = await this.authService.checkForInternet();
      if (this.hasInternet) {
        this.internetVerified();
      }

      this.authService.watchForKillSwitch();
    });
  }

  internetVerified() {
    this.authService.establish().then(() => {
      this.payService.establish();
    });
  }

  async startBackgroundAnim() {
    if (await this.modalHelper.deviceCanHandleIt()) {
      // Device has more than 7GB of memory used, do not run code
      return;
    }

    // Device has 7GB or less of memory used, run code
    const body = document.querySelector('body');
    this.animationCtrl
      .create()
      .addElement(body)
      .duration(50000)
      .iterations(Infinity)
      .direction('alternate')
      .keyframes([
        { offset: 0, backgroundPosition: '0 0' },
        { offset: 1, backgroundPosition: '100% 20%' },
      ])
      .play();
  }

  // This function sets the style and color of the status bar and navigation bar,
  // and performs other necessary actions to clean the asthetics.
  async cleanAsthetics() {
    // If the current platform is a native platform and not a web platform
    if (!this.modalHelper.isMobile) {
      this.appMode = false;
      window.addEventListener('resize', () => {
        this.updateAppMode();
      });
    } else {
      this.appMode = true;
    }

    // Start the background animation
    this.startBackgroundAnim();

    // Clear the console and log a welcome message
    //console.clear();
    console.log(
      '%cWelcome to Cardboard Ninja! ~ by William Fischer',
      'color: #bba599'
    );
  }

  updateAppMode() {
    this.appMode = window.innerWidth < 1340;
  }

  // This function initializes the deep router,
  // which allows the app to handle deep links.
  initializeDeepRouter() {
    // Add a listener for app URL open events
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      // Run the code inside this function in the Angular zone
      this.zone.run(() => {
        // Get the slug from the URL
        const slug = event.url
          .split('.ninja')
          .pop()
          .split('.co')
          .pop()
          .split('.com')
          .pop()
          .split('.org')
          .pop();

        // If a slug was found
        if (slug) {
          // Navigate to the URL associated with the slug
          this.router.navigateByUrl(slug);
        }
      });
    });
  }

  disableBarcodeScanner() {
    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();
    document.body.classList.remove('qrscanner');

    if (this.modalHelper.isMobile) {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
  }

  setup3DTouch() {
    // document.addEventListener(
    //   'deviceready',
    //   function () {
    //     this.threeDeeTouch.isAvailable().then((isAvailable) => {
    //       if (isAvailable) {
    //         this.threeDeeTouch
    //           .watchForceTouches()
    //           .subscribe((data: ThreeDeeTouchForceTouch) => {
    //             console.log('Force touch %' + data.force);
    //             console.log('Force touch timestamp: ' + data.timestamp);
    //             console.log('Force touch x: ' + data.x);
    //             console.log('Force touch y: ' + data.y);
    //           });
    //         let actions: ThreeDeeTouchQuickAction[] = [
    //           {
    //             type: 'checkin',
    //             title: 'Check in',
    //             subtitle: 'Quickly check in',
    //             iconType: 'Compose',
    //           },
    //           {
    //             type: 'share',
    //             title: 'Share',
    //             subtitle: 'Share like you care',
    //             iconType: 'Share',
    //           },
    //           {
    //             type: 'search',
    //             title: 'Search',
    //             iconType: 'Search',
    //           },
    //           {
    //             title: 'Show favorites',
    //             iconTemplate: 'HeartTemplate',
    //           },
    //         ];
    //         this.threeDeeTouch.configureQuickActions(actions);
    //         this.threeDeeTouch.onHomeIconPressed().subscribe((payload) => {
    //           // returns an object that is the button you presed
    //           console.log('Pressed the ${payload.title} button');
    //           console.log(payload.type);
    //         });
    //       }
    //     });
    //   },
    //   false
    // );
  }
}
