import { Component, OnInit } from '@angular/core';
import {
  collection,
  query,
  limit,
  Firestore,
  getDocs,
} from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { ModalHelperService } from '../services/modal-helper.service';
import { PayService } from '../services/pay.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.page.html',
  styleUrls: ['./admin.page.scss'],
})
export class AdminPage implements OnInit {
  userSub: Subscription;

  showListings: boolean;

  constructor(
    public firestore: Firestore,
    public payService: PayService,
    public modalHelper: ModalHelperService
  ) {}

  ngOnInit() {}

  showType(type) {
    this.getDataForType(type).then((data) =>
      this.modalHelper.triggerGridController(data, type)
    );
  }

  async getDataForType(type) {
    let allOrders = [];
    let listings = [];
    let querySnapshot;
    if (type === 'Orders') {
      querySnapshot = await getDocs(
        query(collection(this.firestore, 'Users'), limit(500))
      );
      for (const listing of querySnapshot.docs) {
        const orders = await getDocs(
          query(
            collection(this.firestore, 'Users', listing.data().uid, 'Orders'),
            limit(500)
          )
        );
        allOrders = allOrders.concat(orders.docs.map((order) => order.data()));
      }
      return allOrders;
    } else {
      querySnapshot = await getDocs(
        query(collection(this.firestore, type), limit(500))
      );
      listings = querySnapshot.docs.map((listing) => listing.data());
      return listings;
    }
  }

  async showOtherActions() {
    const buttons = [
      {
        text: 'Clear Stripe Connected Accounts',
        handler: () => this.payService.clearConnectedAccounts(),
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      },
    ];

    const selectedBtn = await this.modalHelper.createActionSheet({
      buttons,
    });
    buttons[selectedBtn].handler();
  }
}
