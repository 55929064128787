import { Injectable } from '@angular/core';
import {
  collection,
  doc,
  Firestore,
  getDocs,
  limit,
  query,
  setDoc,
  where,
  writeBatch,
} from '@angular/fire/firestore';
import { AuthService } from './auth.service';
import { ListingManagerService } from './listing-manager.service';
import { PushService } from './push.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  gridView: boolean;
  cardView: boolean;
  selectedTab = 1;

  recentActivities = [];
  awaitingActivitiesLength = 0;

  constructor(
    public authService: AuthService,
    public firestore: Firestore,
    public pushService: PushService,
    public listingService: ListingManagerService
  ) {}

  async pullUserDataFromUsername(username) {
    return (
      await getDocs(
        query(
          collection(this.firestore, 'Users'),
          where('username', '==', username),
          limit(1)
        )
      )
    ).docs.map((listing) => listing.data());
  }

  async toggleFollow(user, me) {
    try {
      const batch = writeBatch(this.firestore);
      const following = me.following || [];
      const followers = user.followers || [];

      if (following.includes(user.uid)) {
        // Remove the user from the current user's following list
        following.splice(following.indexOf(user.uid), 1);
      } else {
        // Add the user to the current user's following list
        following.push(user.uid);
      }

      if (followers.includes(me.uid)) {
        // Remove the current user from the user's followers list
        followers.splice(followers.indexOf(me.uid), 1);
      } else {
        // Add the current user to the user's followers list
        followers.push(me.uid);

        this.pushService.send(
          user.fcmToken,
          'New Follower',
          `@${me.username} Followed You!`,
          '/mail?page=activity',
          user.uid,
          {
            type: 'followed',
            from: me.uid,
          }
        );
      }

      // Update the current user's following list in Firestore
      batch.update(doc(this.firestore, 'Users', String(me.uid)), {
        following,
      });
      // Update the user's followers list in Firestore
      batch.update(doc(this.firestore, 'Users', String(user.uid)), {
        followers,
      });

      // Save the updates to Firestore
      await batch.commit();
      // Push the follow to the seller's activity feed
      // Return the updated following and followers lists
      return { following, followers };
    } catch (error) {
      console.error(error);
    }
  }

  removeUnknownFollower(follower) {
    const followers = this.authService.user.followers || [];
    followers.splice(followers.indexOf(follower), 1);

    setDoc(
      doc(this.firestore, 'Users', this.authService.user.uid),
      {
        followers,
      },
      { merge: true }
    );

    this.authService.user.followers = followers;
    return followers;
  }

  async pullRecentActivites(userID) {
    // Get the recent activity for the current user
    const recentActivity = await this.getRecentActivity(userID);
    console.log('Pulled Activities', recentActivity);

    // If the recent activity is not an array, convert it to an array
    this.recentActivities = recentActivity
      ? Array.isArray(recentActivity)
        ? recentActivity
        : [recentActivity]
      : [];

    // Filter the recent activities to only include those that have not been seen and have a type
    this.awaitingActivitiesLength = this.recentActivities
      ? this.recentActivities.filter((c) => c && !c.hasSeen && c.type).length
      : 0;
  }

  // Get the current user's recent activity.
  async getRecentActivity(userID) {
    try {
      // Query the "Activity" subcollection of the current user's document
      // in the "Users" collection.
      const docs = (
        await getDocs(
          collection(this.firestore, 'Users', String(userID), 'Activity')
        )
      ).docs.map((listing) => listing.data());

      // Return the array of activity documents.
      return docs ? docs : [];
    } catch (error) {
      // Handle any errors that occur in the async code
      console.error(error);
    }
  }
}
