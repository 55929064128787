import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import { PhotoViewer, capShowOptions } from '@capacitor-community/photoviewer';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { CartService } from '../services/cart.service';
import { ListingManagerService } from '../services/listing-manager.service';

// import SwiperCore, { Swiper } from 'swiper';
// import Navigation from 'swiper';
// SwiperCore.use([Navigation]);

import { ModalHelperService } from '../services/modal-helper.service';
import { Meta, Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { AdminService } from '../services/admin.service';
import { MessengerService } from '../services/messenger.service';

import { Listing, User } from '../../types/index';
import { TcgControllerService } from '../services/tcg-controller.service';
import { CardEffectsService } from '../services/card-effects.service';
import { Device } from '@capacitor/device';
import { ListingApiService } from '../services/listing-api.service';
import { ProfileService } from '../services/profile.service';
import { PushService } from '../services/push.service';
import { Functions, httpsCallable } from '@angular/fire/functions';

// import { register } from 'swiper/element';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.page.html',
  styleUrls: ['./listing.page.scss'],
})
export class ListingPage implements OnInit {
  // @ViewChild('swiperRef', { static: false }) swiperRef;

  @Input() mySubject: BehaviorSubject<{
    card: Listing;
    owner: User;
  }>;

  card: any = [];

  cardID: string;
  selectedTagCard: string;

  formattedDate: Date;

  isMine: boolean;
  isInCart: boolean;
  inModal: boolean;

  showDeliverMessage: boolean;
  showReviewMessage: boolean;
  triedOnce: boolean;

  loading = true;

  pullLimit: number;
  pullLimitCap = 12;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public listingService: ListingManagerService,
    public authService: AuthService,
    public modalHelper: ModalHelperService,
    public location: Location,
    public messengerService: MessengerService,
    public listingManagerService: ListingManagerService,
    public adminService: AdminService,
    public cartService: CartService,
    private metaService: Meta,
    public pushService: PushService,
    public profileService: ProfileService,
    public listingApi: ListingApiService,
    public tcgService: TcgControllerService,
    public cardEffectsService: CardEffectsService,
    public fns: Functions,
    public titleService: Title
  ) {
    this.prepareListing();
  }

  ngOnInit() {}

  prepareListing() {
    return this.authService.gotAuth
      ? this.establish()
      : this.authService.waitForAuth().then(() => this.establish());
  }

  async establish() {
    if (!this.authService.gotAuth) {
      await this.authService.waitForAuth();
    }

    this.inModal = this.mySubject !== undefined;

    // register();
    // if (this.swiperRef.nativeElement) {
    //   new Swiper(this.swiperRef.nativeElement);
    // }

    this.pullWholeListingFromFunction();
  }

  pullWholeListingFromFunction() {
    Device.getInfo().then(async (info) => {
      const memLimit = Math.floor(info.memUsed / 1048576 / 4);
      const limit = memLimit < this.pullLimitCap ? memLimit : this.pullLimitCap;

      if (this.listingManagerService.cardHold) {
        this.card = this.listingManagerService.cardHold;
      }

      if (this.listingManagerService.ownerHold) {
        this.card.owner = this.listingManagerService.ownerHold;
      }

      // Pull ID for listing being viewed.
      // Either from modal subject or route.
      const id =
        this.mySubject &&
        this.mySubject.value.card &&
        this.mySubject.value.card.id
          ? this.mySubject.value.card.id
          : this.router.url.split('/')[this.router.url.split('/').length - 1];

      if (!id) {
        return await this.router.navigate(['/home']);
      }

      const includedOwner =
        this.listingManagerService.ownerHold &&
        this.listingManagerService.ownerHold.uid
          ? this.listingManagerService.ownerHold
          : undefined;

      console.log({
        id,
        limit,
        includedCard: this.listingManagerService.cardHold,
        includedOwner,
      });

      httpsCallable(
        this.fns,
        'listing-pull'
      )({
        id,
        limit,
        includedCard: this.listingManagerService.cardHold,
        includedOwner,
      })
        .then((res: any) => this.buildListingPage(res.data, limit))
        .catch((e) => console.error(e));
    });
  }

  async buildListingPage(listingData, limit) {
    console.log(listingData);

    this.card = await this.cleanCard(listingData);

    this.loading = false;

    // Pull TCG-related cards if none and if user is logged in (has tcgToken).
    if (
      this.authService.user &&
      (!this.card.relatedTCGCards || this.card.relatedTCGCards.length === 0)
    ) {
      this.card.relatedTCGCards = await this.listingApi.buildTCGRelatedProducts(
        this.modalHelper,
        this.card
      );
    }

    this.prepareWebElements();

    setTimeout(() => {
      this.cardEffectsService.getImageColour(this.card);
    }, 1000);
  }

  async cleanCard(card) {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.formattedDate = new Date(card.postDate['_seconds']);

    this.cartService.checkIfInCart(card.id);

    card.liked =
      this.authService.user && this.authService.user.liked
        ? this.authService.user.liked.includes(card.id)
        : false;

    // Check if the user is logged in and the user id exists
    if (this.authService.user && this.authService.user.uid) {
      // Check if the user is the owner of this listing
      this.isMine = card.uploader === this.authService.user.uid;
    }

    return card;
  }

  prepareWebElements() {
    if (this.modalHelper.isMobile) {
      return;
    }

    const title =
      '@' +
      this.card.uploaderUsername +
      // eslint-disable-next-line @typescript-eslint/quotes
      "'s " +
      this.card.name +
      ' on Cardboard Ninja';

    this.metaService.updateTag({
      name: 'title',
      content: title,
    });
    this.metaService.updateTag({
      name: 'image',
      content: this.card.tcgImage || this.card.images[0],
    });
    this.metaService.updateTag({
      property: 'og:title',
      content: title,
    });
    this.metaService.updateTag({
      property: 'og:image',
      content: this.card.tcgImage || this.card.images[0],
    });
    this.titleService.setTitle(title);
  }

  isNotEmptyDesc(desc: string): boolean {
    // Use regex to remove `<br />` tags
    const strippedDesc = desc.replace(/<br\s*\/?>/gi, '');

    // Check if the resulting string is not empty
    return strippedDesc.length !== 0;
  }

  // ON CLICK FUNCTIONS!

  async savingComingSoon() {
    this.modalHelper.createAlert({
      title: '😰 Bookmarking is coming soon...',
    });
  }

  async commentingComingSoon() {
    this.modalHelper.createAlert({
      title: '😰 Commenting is coming soon...',
    });
  }

  async openLikes() {
    this.modalHelper.triggerLikes(this.card.id);
  }

  async openTags(tag) {
    this.modalHelper.triggerTags(tag);
  }

  async likeListing() {
    // Return early if the user is not authenticated
    if (!this.authService.user) {
      this.authService.notAuthedPopup();
      return;
    }

    // Set the "liked" property to true
    this.card.liked = true;

    // Increment the like count
    this.card.likeCount++;

    // Trigger a haptic impact
    await this.modalHelper.buzz();

    // Stamp the user liking the listing to the database
    const newLiked = await this.listingService.toggleListingLikes(
      this.card,
      this.authService.user.uid
    );

    this.authService.user.liked = newLiked;
    // this.profileService.executeBuild(this.authService.user);

    // Push the like to the seller's activity feed
    if (this.card.uploader !== this.authService.user.uid) {
      this.pushService.send(
        this.card.owner.fcmToken,
        'New Like',
        '@' + this.authService.user.username + ' Liked ' + this.card.name,
        '/listing/' + this.card.id,
        this.card.owner.uid,
        {
          type: 'liked',
          from: this.authService.user.uid,
          listing: this.card.id,
        }
      );
    }
  }

  async unlikeListing() {
    // Trigger a haptic impact
    await this.modalHelper.buzz();

    // Decrement the like count
    this.card.likeCount--;

    // Set the "liked" property to false
    this.card.liked = false;

    // Unlike the listing
    const newLiked = await this.listingService.toggleListingLikes(
      this.card,
      this.authService.user.uid
    );

    this.authService.user.liked = newLiked;
    // this.profileService.executeBuild(this.authService.user);
  }

  async openPhoto(url, title) {
    PhotoViewer.show({
      images: [{ url, title }],
      mode: 'one',
      options: {
        title: true,
        backgroundcolor: 'ivory',
      },
    } as capShowOptions).catch((e) => alert(e));
  }

  async openListing(card) {
    this.modalHelper.triggerListingPage(card);
  }

  async openListingFromProduct(productID) {
    this.openListing(await this.listingApi.pullTCGProduct(productID));
  }

  openMessenger(owner, card) {
    if (this.authService.user) {
      this.cartService.openMessenger(owner, card);
    } else {
      this.authService.notAuthedPopup();
    }
  }

  async toInfinity(type) {
    this.modalHelper.triggerInfinity(type);
  }

  async listMarketCard(card) {
    this.listingManagerService.filledListingCard = null;
    this.listingManagerService.uploadingListingCard = await card;

    this.modalHelper.triggerListingForm();
  }

  async openMarketCard(card: Listing) {
    const buttons = [
      {
        text: 'Open in TCGPlayer',
        handler: () => this.modalHelper.triggerIAB(card.tcgUrl),
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      },
    ];

    if (this.authService.user && this.authService.user.sellerVerified) {
      buttons.splice(1, 0, {
        text: 'List this product',
        handler: () => this.listMarketCard(card),
      });
    }

    const selectedBtn = await this.modalHelper.createActionSheet({
      buttons,
    });
    buttons[selectedBtn].handler();
  }

  async openInTCGPlayer(url) {
    this.modalHelper.triggerIAB(url);
  }

  async openInEbay(name) {
    this.modalHelper.triggerIAB(
      'https://www.ebay.com/sch/i.html?&_nkw=' + encodeURIComponent(name)
    );
  }

  async openInGoogle(name) {
    this.modalHelper.triggerIAB(
      'https://www.google.com/search?q=' +
        encodeURIComponent(name) +
        '&tbm=shop'
    );
  }

  async openInTrollAndToad(name) {
    this.modalHelper.triggerIAB(
      'https://www.trollandtoad.com/category.php?selected-cat=7061&search-words=' +
        encodeURIComponent(name)
    );
  }

  async shareListing(title: string, id: string) {
    await this.modalHelper.share({
      title,
      text: 'Check out ' + title + ' on Cardboard Ninja',
      url: 'http://cbnja.co/home/listing/' + id,
      dialogTitle: 'Share this listing',
    });
  }

  isValidDate(date: any): boolean {
    return date instanceof Date && !isNaN(date.getTime());
  }
}
