import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { ListingManagerService } from 'src/app/services/listing-manager.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';

@Component({
  selector: 'app-type-slide',
  templateUrl: './type-slide.component.html',
  styleUrls: ['./type-slide.component.scss'],
})
export class TypeSlideComponent implements OnInit {
  types = [];
  ogTypes = [];
  noGames = false;
  loading = true;

  constructor(
    public router: Router,
    public modalHelper: ModalHelperService,
    public filterService: FilterService,
    public listingManagerService: ListingManagerService
  ) {}

  ngOnInit() {
    this.ogTypes = this.listingManagerService.games;
    this.types = this.ogTypes;

    this.filterService.gameFilterSub
      .pipe(debounceTime(100))
      .subscribe(() => this.filterTypes());

    setTimeout(() => (this.loading = false), 3000);
  }

  filterTypes() {
    const games = this.filterService.showingGames || [];

    this.noGames = !games.length;

    this.types = this.ogTypes.filter((banner) =>
      games.some((game) => game === banner.name)
    );
  }

  triggerInfinity(type) {
    this.router.navigate([], {
      queryParams: {
        infinity: type,
      },
      queryParamsHandling: 'merge',
    });
  }
}
