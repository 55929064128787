// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple {
  font-size: 16px;
  text-align: center;
  color: #ab8774;
  text-shadow: 1px 1px rgba(255, 255, 255, 0.4);
  margin-top: 20%;
  margin-top: 20vh;
  font-weight: bold;
}

.seller_button {
  width: -webkit-fill-available;
  background: #fff;
  color: #d2b094;
  font-weight: 800;
  justify-content: space-between;
  display: inline-flex;
  padding: 20px;
  margin: 20px;
  margin-bottom: 0px;
  border-radius: 6px;
  box-shadow: 2px 7px 10px rgba(242, 202, 170, 0.2509803922);
}

.active_seller_actions {
  background: #e8985d;
  color: #fff2e6;
  border: 2px solid rgba(255, 242, 229, 0.7294117647);
}
.active_seller_actions span {
  display: contents;
}

.card_searcher {
  padding-top: 10px;
}

.no_padding {
  padding-top: 50px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/mail/mail.page.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,kBAAA;EACA,cAAA;EACA,6CAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,6BAAA;EACA,gBAAA;EACA,cAAA;EACA,gBAAA;EACA,8BAAA;EACA,oBAAA;EACA,aAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,0DAAA;AACJ;;AAEA;EACI,mBAAA;EACA,cAAA;EACA,mDAAA;AACJ;AACI;EACI,iBAAA;AACR;;AAGA;EACI,iBAAA;AAAJ;;AAGA;EACI,4BAAA;AAAJ","sourcesContent":[".simple {\n    font-size: 16px;\n    text-align: center;\n    color: #ab8774;\n    text-shadow: 1px 1px rgb(255 255 255 / 40%);\n    margin-top: 20%;\n    margin-top: 20vh;\n    font-weight: bold;\n}\n\n.seller_button {\n    width: -webkit-fill-available;\n    background: #fff;\n    color: #d2b094;\n    font-weight: 800;\n    justify-content: space-between;\n    display: inline-flex;\n    padding: 20px;\n    margin: 20px;\n    margin-bottom: 0px;\n    border-radius: 6px;\n    box-shadow: 2px 7px 10px #f2caaa40;\n}\n\n.active_seller_actions {\n    background: #e8985d;\n    color: #fff2e6;\n    border: 2px solid #fff2e5ba;\n\n    span {\n        display: contents;\n    }\n}\n\n.card_searcher {\n    padding-top: 10px;\n}\n\n.no_padding {\n    padding-top: 50px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
