import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ListingManagerService } from '../services/listing-manager.service';
import { ModalHelperService } from '../services/modal-helper.service';
import { TcgControllerService } from '../services/tcg-controller.service';

@Component({
  selector: 'app-add-listing',
  templateUrl: './add-listing.page.html',
  styleUrls: ['./add-listing.page.scss'],
})
export class AddListingPage implements OnInit {
  // @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  set: string;
  loading = true;

  user: any;

  constructor(
    public tcgController: TcgControllerService,
    public router: Router,
    public listingManagerService: ListingManagerService,
    public authService: AuthService,
    public modalHelper: ModalHelperService
  ) {}

  ngOnInit() {
    this.pullUser();
  }

  async pullUser() {
    if (!this.authService.gotAuth) {
      this.authService.waitForAuth().then(() => this.establish());
    } else {
      this.establish();
    }
  }

  async establish() {
    if (!this.authService.user) {
      return setTimeout(() => this.establish(), 1000);
    }

    if (this.authService.user && this.authService.user.sellerVerified) {
      // Establish the connection to the TCG controller
      this.tcgController.establish(this.modalHelper);
    } else {
      // Open the seller form
      this.modalHelper.triggerSellerRegister();
    }

    // Set the loading state to false
    this.loading = false;
  }

  async searchCategory(category) {
    this.set = category;
    this.listingManagerService.uploadingGame = category;
    this.modalHelper.triggerSearchCatergory(category);
  }

  async uploadCustomListing() {
    this.listingManagerService.filledListingCard = null;

    this.modalHelper.triggerListingForm();
  }
}
