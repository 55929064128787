import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ListingManagerService } from 'src/app/services/listing-manager.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { User, Listing } from 'src/types';

@Component({
  selector: 'app-msg-header',
  templateUrl: './msg-header.component.html',
  styleUrls: ['./msg-header.component.scss'],
})
export class MsgHeaderComponent implements OnInit {
  @Input() owner: User;
  @Input() card: Listing;

  constructor(
    public authService: AuthService,
    public listingManagerService: ListingManagerService,
    public modalHelper: ModalHelperService
  ) {}

  ngOnInit() {}

  async showMessageOptions() {
    const buttons = [
      {
        text: 'Help',
        icon: 'help-buoy-outline',
        handler: () => this.modalHelper.triggerIAB('https://m.me/cbnja'),
      },
      {
        text: 'Report',
        icon: 'alert-circle-outline',
        handler: () =>
          this.listingManagerService.reportListing(this.card, this.authService.user.username, this.modalHelper),
      },
      {
        text: 'Block',
        icon: 'close-circle-outline',
        role: 'destructive',
        handler: () => this.authService.toggleBlockingUser(this.owner, false),
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      },
    ];

    const selectedBtn = await this.modalHelper.createActionSheet({
      buttons,
    });
    buttons[selectedBtn].handler();
  }
}
