// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wysiwyg-font-size-medium {
  font-size: small;
}

p {
  line-height: 16px;
}

strong {
  line-height: 32px;
}

.article-body {
  font-size: 22px;
  padding: 40px;
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/legal/legal.page.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,eAAA;EACA,aAAA;EACA,iBAAA;AACJ","sourcesContent":[".wysiwyg-font-size-medium {\n    font-size: small;\n}\n\np {\n    line-height: 16px;\n}\n\nstrong {\n    line-height: 32px;\n}\n\n.article-body {\n    font-size: 22px;\n    padding: 40px;\n    padding-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
