// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blank_txt {
  font-size: 14px;
  width: 100%;
  color: #c4a897;
  margin-left: 25px;
  margin-top: 0px;
  font-weight: 500;
}

.center_spinner {
  margin-top: 0px;
}

.loading_box_wrap {
  display: inline-flex;
  margin-left: 10px;
  margin-bottom: 20px;
  grid-gap: 10px;
}
.loading_box_wrap .loading_box {
  min-width: 180px;
  min-height: 280px;
  border-radius: 6px;
  background: #fafafa;
  opacity: 0.6;
  animation-name: fillChange;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
@keyframes fillChange {
  0% {
    background: #fafafa;
  }
  50% {
    background: #ededed;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/home-section/home-section.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,WAAA;EACA,cAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAiBA;EACI,oBAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;AAdJ;AAgBI;EACI,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EAEA,YAAA;EACA,0BAAA;EACA,sBAAA;EACA,mCAAA;EACA,sCAAA;AAfR;AAkBI;EACI;IACI,mBAAA;EAhBV;EAkBM;IACI,mBAAA;EAhBV;AACF","sourcesContent":[".blank_txt {\n    font-size: 14px;\n    width: 100%;\n    color: #c4a897;\n    margin-left: 25px;\n    margin-top: 0px;\n    font-weight: 500;\n}\n\n.center_spinner {\n    margin-top: 0px;\n}\n\n// .center_blocks {\n//     display: flex;\n//     justify-content: center;\n//     align-items: center;\n//     margin-left: 10px;\n//     margin-bottom: 20px;\n//     grid-gap: 10px;\n\n//     .loading_block {\n//         min-height: 200px;\n//         min-width: 150px;\n//         background: rgb(255 247 240);\n//     }\n// }\n\n.loading_box_wrap {\n    display: inline-flex;\n    margin-left: 10px;\n    margin-bottom: 20px;\n    grid-gap: 10px;\n\n    .loading_box {\n        min-width: 180px;\n        min-height: 280px;\n        border-radius: 6px;\n        background: #fafafa;\n\n        opacity: 0.6;\n        animation-name: fillChange;\n        animation-duration: 2s;\n        animation-iteration-count: infinite;\n        animation-timing-function: ease-in-out;\n    }\n\n    @keyframes fillChange {\n        0% {\n            background: #fafafa;\n        }\n        50% {\n            background: #ededed;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
