/* eslint-disable @typescript-eslint/dot-notation */
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ListingManagerService } from 'src/app/services/listing-manager.service';

import { Listing } from 'src/types';

@Component({
  selector: 'app-card-unit',
  templateUrl: './card-unit.component.html',
  styleUrls: ['./card-unit.component.scss'],
})
export class CardUnitComponent implements OnInit {
  @Input() card: Listing;

  newDate = new Date();

  isProfile: boolean;

  constructor(
    private datePipe: DatePipe,
    public router: Router,
    public authService: AuthService,
    public listingService: ListingManagerService
  ) {}

  ngOnInit() {
    if (this.card.postDate?.seconds) {
      this.isProfile = this.router.url.includes('profile');
    }
  }

  manageDate(date: Timestamp): string {
    if (!date) {
      return '';
    }
    const seconds = date['_seconds'] ?? date.seconds;
    return this.datePipe.transform(new Date(seconds * 1000), 'MMM dd, yyyy');
  }
}
