// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.minimal_grid {
  display: flex;
  grid-gap: 8px;
  row-gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 2vh;
  margin-bottom: 5vh;
  padding-left: 2vh;
  padding-right: 2vh;
}
.minimal_grid .card {
  margin: 0;
}
.minimal_grid .card_gridmode_wrap {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.minimal_grid .block_space {
  height: 10vh;
  width: 100%;
}

.outer_loading_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 10px;
}
.outer_loading_wrap .loading_wrap {
  width: 45%;
  height: 210px;
  margin: 0;
}
.outer_loading_wrap .loading_wrap .loading_block {
  height: 210px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/profile-card-view/profile-card-view.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,aAAA;EACA,eAAA;EACA,uBAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EAEA,iBAAA;EACA,kBAAA;AAAJ;AAEI;EACI,SAAA;AAAR;AAGI;EACI,UAAA;EACA,iBAAA;EACA,kBAAA;AADR;AAII;EACI,YAAA;EACA,WAAA;AAFR;;AAOA;EACI,aAAA;EACA,eAAA;EACA,uBAAA;EACA,cAAA;AAJJ;AAMI;EACI,UAAA;EACA,aAAA;EACA,SAAA;AAJR;AAMQ;EACI,aAAA;AAJZ","sourcesContent":[".minimal_grid {\n    display: flex;\n    grid-gap: 8px;\n    row-gap: 12px;\n    flex-wrap: wrap;\n    justify-content: center;\n    width: 100%;\n    margin-top: 2vh;\n    margin-bottom: 5vh;\n\n    padding-left: 2vh;\n    padding-right: 2vh;\n\n    .card {\n        margin: 0;\n    }\n\n    .card_gridmode_wrap {\n        width: 90%;\n        margin-left: auto;\n        margin-right: auto;\n    }\n\n    .block_space {\n        height: 10vh;\n        width: 100%;\n    }\n}\n\n\n.outer_loading_wrap {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: center;\n    grid-gap: 10px;\n\n    .loading_wrap {\n        width: 45%;\n        height: 210px;\n        margin: 0;\n\n        .loading_block {\n            height: 210px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
