// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mid_buttons {
  margin-top: 20%;
}
.mid_buttons ion-button {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  left: 0;
  right: 0;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/admin/admin.page.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AACI;EACI,UAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,OAAA;EACA,QAAA;EACA,mBAAA;AACR","sourcesContent":[".mid_buttons {\n    margin-top: 20%;\n\n    ion-button {\n        width: 70%;\n        margin-left: auto;\n        margin-right: auto;\n        display: block;\n        left: 0;\n        right: 0;\n        margin-bottom: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
