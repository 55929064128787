/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef } from 'ag-grid-community';
import { BehaviorSubject } from 'rxjs';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { Listing, User } from 'src/types';

@Component({
  selector: 'app-grid-controller',
  templateUrl: './grid-controller.component.html',
  styleUrls: ['./grid-controller.component.scss'],
})
export class GridControllerComponent implements OnInit {
  @Input() mySubject: BehaviorSubject<[]>;

  usersData = [];

  columnDefs: ColDef[] = [];
  defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    suppressMovable: true,
  };

  // eslint-disable-next-line @typescript-eslint/member-ordering
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  constructor(
    public firestore: Firestore,
    public modalHelper: ModalHelperService
  ) {}

  ngOnInit() {}

  onGridReady(): void {
    if (this.mySubject.value['type'] === 'Users') {
      this.columnDefs = [
        { field: 'username', cellRenderer: (params) => '@' + params.value },
        { field: 'name' },
        { field: 'phoneNumber', headerName: 'ph num' },
        { field: 'email' },
        { field: 'sellerVerified', headerName: 'Seller?' },
        { field: 'dob' },
        { field: 'stripeAccountID' },
        { field: 'stripePersonID' },
        { field: 'customerAccountID' },
        { field: 'affiliate' },
        { field: 'isReported', headerName: 'reported?' },
        { field: 'isUnderMonitoring', headerName: 'under monitoring?' },
      ];
    } else if (this.mySubject.value['type'] === 'Listings') {
      this.columnDefs = [
        { field: 'name' },
        { field: 'status' },
        {
          field: 'postDate',
          headerName: 'date listed',
          cellRenderer: (params) =>
            params.value ? String(new Date(params.value.seconds * 1000)) : '',
        },
        { field: 'uploaderUsername', headerName: 'user' },
        { field: 'uploader', hide: true },
        { field: 'id', hide: true },
        { field: 'definedInitialPrice', headerName: 'price' },
      ];
    } else if (this.mySubject.value['type'] === 'Orders') {
      this.columnDefs = [
        { field: 'id' },
        { field: 'status' },
        { field: 'shippedFrom', headerName: 'from' },
        { field: 'shippedTo', headerName: 'to' },
        {
          field: 'createdDate',
          headerName: 'date created',
          cellRenderer: (params) =>
            params.value ? String(new Date(params.value.seconds * 1000)) : '',
        },
        { field: 'totalCost', headerName: 'total cost' },
        { field: 'cost', headerName: 'order cost' },
        { field: 'shippingCost' },
        { field: 'feeAmount' },
      ];
    } else if (this.mySubject.value['type'] === 'Reportings') {
      this.columnDefs = [
        { field: 'listingName' },
        { field: 'reportedByUsername' },
        { field: 'moreInfo' },
        { field: 'id', hide: true },
        { field: 'uploader', hide: true },
        { field: 'sellerUsername' },
        { field: 'status' },
        { field: 'dateListed' },
        { field: 'dateReported' },
        { field: 'price' },
      ];
    } else if (this.mySubject.value['type'] === 'Banners') {
      this.columnDefs = [
        { field: 'image' },
        { field: 'link' },
        { field: 'name' },
        { field: 'status' },
      ];
    }

    this.usersData = this.mySubject.value['data'];
  }

  onCellClicked(e: CellClickedEvent): void {
    if (
      e.data.trackingDetails &&
      e.column.getColDef().headerName === 'Tracking Code'
    ) {
      this.modalHelper.triggerIAB(
        'https://auspost.com.au/mypost/track/#/details/' +
          e.data.trackingDetails.trackingCode
      );
    } else {
      if (e.data.username) {
        this.openProfile(e.data);
      } else if (e.data.link) {
        this.modalHelper.createAlert({
          title: 'Banners',
          message: 'Cannot edit banners.. yet',
        });
      } else if (e.data.name || e.data.reportedByUsername) {
        this.openListing(e.data);
      }
    }
  }

  async openProfile(owner: User) {
    this.modalHelper.triggerUserProfile(owner, 'shop');
  }

  async openListing(card: Listing) {
    this.modalHelper.triggerListingPage(
      (await getDoc(doc(this.firestore, 'Listings', String(card.id)))).data()
    );
  }

  clearSelection(): void {
    this.agGrid.api.deselectAll();
  }

  csvExport() {
    this.agGrid.api.exportDataAsCsv();
  }
}
