/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  runTransaction,
  setDoc,
  where,
} from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { AuthService } from './auth.service';
import { ModalHelperService } from './modal-helper.service';
import { ProfileService } from './profile.service';
import { PushService } from './push.service';

import { Listing, User } from '../../types/index';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root',
})
export class ListingManagerService {
  uploadingListingCard: any;
  filledListingCard: any;
  uploadingGame;
  isUpdating = false;

  withinSet: string;

  games = [
    {
      name: 'Magic: The Gathering',
      tcgNum: 1,
      image: 'assets/categories/magic.jpeg',
      cssClass: 'magic',
      tcgName: 'Magic: The Gathering',
    },
    {
      name: 'Pokémon',
      tcgNum: 3,
      image: 'assets/categories/pokemon.jpeg',
      cssClass: 'pokemon',
      tcgName: 'Pokemon',
    },
    {
      name: 'YuGiOh',
      tcgNum: 2,
      image: 'assets/categories/yugioh.webp',
      cssClass: 'yuGiOh',
      tcgName: 'YuGiOh',
    },
    {
      name: 'Flesh & Blood',
      tcgNum: 62,
      image: 'assets/categories/fab.jpeg',
      cssClass: 'fab',
      tcgName: 'Flesh and Blood TCG',
    },
    {
      name: 'Dragon Ball',
      tcgNum: 23,
      image: 'assets/categories/dbz.jpeg',
      cssClass: 'dbz',
      tcgName: 'Dragon Ball Super CCG',
    },
    {
      name: 'Final Fantasy',
      tcgNum: 24,
      image: 'assets/categories/ff.jpeg',
      cssClass: 'ff',
      tcgName: 'Final Fantasy TCG',
    },
  ];

  cardHold: Listing;
  ownerHold: User;

  followSub: Subscription;

  constructor(
    public authService: AuthService,
    public modalHelper: ModalHelperService,
    public pushService: PushService,
    public profileService: ProfileService,
    public fns: Functions,
    public firestore: Firestore
  ) {}

  async toggleListingLikes(listing, userID) {
    // Create references to the listing and user documents using the doc function
    const listingDoc = doc(this.firestore, 'Listings', String(listing.id));
    const userDoc = doc(this.firestore, 'Users', String(userID));

    // Retrieve the data from the listing and user documents using the getDoc function
    const [listingData, userData] = await Promise.all([
      (await getDoc(listingDoc)).data() as Listing,
      (await getDoc(userDoc)).data() as User,
    ]);

    const likedFolks = [...(listingData.likedFolks || [])];
    const userId = userID;

    if (likedFolks.includes(userId)) {
      likedFolks.splice(likedFolks.indexOf(userId), 1);
    } else if (listing.liked) {
      likedFolks.push(userId);
    }

    const updatedListingData = {
      likeCount: Math.max(
        (listingData.likeCount || 0) + (listing.liked ? 1 : -1),
        0
      ),
      likedFolks,
    };

    const updatedUserData = {
      liked: [
        ...((userData.liked as []) || []).filter((id) => id !== listing.id),
        listing.liked ? listing.id : null,
      ].filter(Boolean),
    };

    // Use the runTransaction() method to run the operations inside a transaction
    await runTransaction(this.firestore, async (transaction) => {
      // Update the data in the listing and user documents
      transaction.update(listingDoc, updatedListingData);
      transaction.update(userDoc, updatedUserData);
    });

    return updatedUserData.liked;
  }

  async pullFollowListings(tlimit, followers: string[]) {
    if (!followers) {
      return [];
    }

    const queries = followers.map((follower) =>
      getDocs(
        query(
          collection(this.firestore, 'Listings'),
          where('uploader', '==', follower),
          where('status', '==', 'selling'),
          orderBy('postDate', 'desc'),
          limit(tlimit)
        )
      ).then((snapshot) => {
        const listings = [];
        snapshot.forEach((listingData) => {
          listings.push(listingData.data());
        });
        return listings;
      })
    );
    const results = (await Promise.all(queries)).reduce(
      (a, b) => a.concat(b),
      []
    );
    results.sort(() => (Math.random() > 0.5 ? 1 : -1));

    return results;
  }

  async updateListing(card, modal, isUpdating) {
    card.otherListingsFromOwner = [];
    card.otherListingsFromRandomTag = [];
    card.relatedTCGCards = [];
    card.relatedPromo = [];
    card.owner = [];

    this.filledListingCard = card;
    this.isUpdating = isUpdating;

    modal.triggerListingForm();
  }

  async updateListingStatus(cardID, statusObj) {
    try {
      // Use the async/await syntax to update the document
      return await setDoc(doc(this.firestore, 'Listings', cardID), statusObj, {
        merge: true,
      });
    } catch (e) {
      console.log(e);
    }
  }

  async toggleStaffPick(ownerID, isPicked, cardID) {
    if (
      confirm(
        isPicked
          ? 'Unmark this listing as a staff pick??'
          : 'Mark this listing as a staff pick??'
      )
    ) {
      if (isPicked) {
        await this.sendUserStaffPickNotification(ownerID, cardID);
      }
      await setDoc(
        doc(this.firestore, 'Listings', cardID),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        { isStaffPick: !isPicked },
        {
          merge: true,
        }
      );
    }
  }

  async sendUserStaffPickNotification(ownerID, cardID) {
    const fcmToken = await getDoc(doc(this.firestore, 'Users', ownerID)).then(
      (d) => d.data().fcmToken
    );

    this.pushService.send(
      fcmToken,
      '@' + this.authService.user.username,
      'Recieved your package & left a review!',
      '/profile',
      ownerID,
      {
        type: 'purchase',
        from: this.authService.user.uid,
        listing: cardID,
      }
    );
  }

  async getPricingDataDirectly(productID) {
    return new Promise(async (resolve) => {
      resolve(
        (
          (await httpsCallable(
            this.fns,
            'pricingHistory-getPaymentHistory'
          )({
            productID,
          })) as any
        ).data.result
      );
    });
  }

  convertCard(game: string): string {
    if (game === 'Other') {
      return 'other';
    }

    if (this.games && game !== 'Loading...') {
      for (const element of this.games) {
        if (element.name === game) {
          return element.cssClass;
        }
      }
    }

    // If game is not found in this.games, return an empty string
    return '';
  }

  async reportListing(listing, reportedByUsername, modalHelper) {
    modalHelper.createLegacyAlert({
      header: 'Report ' + listing.name + '?',
      message:
        'Are you sure you want to report this as an illegitimate listing? ',
      inputs: [
        {
          name: 'moreInfo',
          type: 'textarea',
          placeholder: 'Additional information for report (optional)',
          id: 'moreInfo',
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Submit',
          role: 'submit',
          handler: async (data: { moreInfo: string }) => {
            const reportID = (Math.random() + 1).toString(36).substring(2);

            await setDoc(
              doc(this.firestore, 'Reportings', reportID),
              {
                listingName: listing.name,
                reportedByUsername,
                moreInfo: data.moreInfo || '',
                sellerUsername: listing.uploaderUsername,
                uploader: listing.uploader,
                status: listing.status,
                dateListed: listing.postDate,
                dateReported: new Date(),
                price: listing.definedInitialPrice,
                id: listing.id,
              },
              {
                merge: true,
              }
            );

            modalHelper.createAlert({
              title: 'Listing Reported!',
              message: 'Thank you! a human will look at it ASAP.',
            });
          },
        },
      ],
    });
  }

  async pullMostRecentSet(categoryId) {
    return (
      await getDocs(
        query(
          collection(this.firestore, 'Cards'),
          where('categoryId', '==', categoryId),
          orderBy('modifiedOn', 'desc'),
          limit(3)
        )
      )
    ).docs.map((d) => d.data());
  }

  async pullGroupsProducts(groupID, groupName, tcgController, modalHelper) {
    // Retrieve the existing group products from Firebase using the getDocs() function
    const existingGroupProducts = await getDocs(
      collection(this.firestore, 'Cards', String(groupID), 'Products')
    );

    const totalItemSize = await tcgController.getGroupProductsSize(groupID);

    // Check if the group has existing products
    if (
      existingGroupProducts.size &&
      existingGroupProducts.size.toString() >= totalItemSize
    ) {
      // If the group has existing products, log a message and update the cards and cardHold properties with the existing products
      tcgController.cards = existingGroupProducts.docs
        .map((card) => card.data())
        .filter((d) => !d.name.includes('Code') && d.name)
        .sort((a, b) => {
          // sort by publishedOn
          if (a.publishedOn < b.publishedOn) {
            return -1;
          }
          if (a.publishedOn > b.publishedOn) {
            return 1;
          }
          return 0;
        });

      tcgController.cardHold = tcgController.cards;
      // Update the withinSet property with the group name
      this.withinSet = groupName;
    } else {
      // If the group does not have existing products, retrieve the group products from tcgplayer and
      // update the cards and cardHold properties
      tcgController
        .getGroupProducts(groupID, 100, 0, modalHelper)
        .then((sets: any) => {
          if (sets) {
            // Save the group products to Firebase
            this.saveSet(groupID, sets, tcgController);
            // Update the withinSet property with the group name if the group has products, or null if the group does not have products
            this.withinSet = sets.length ? groupName : null;
            // Check if the group has more than 100 products
            if (sets.length === 100) {
              // If the group has more than 100 products, call the trickleDownBigBoys() function
              this.trickleDownBigBoys(groupID, tcgController);
            }
          }
        });
    }
  }

  trickleDownBigBoys(groupID, tcgController) {
    // Call the getNextBatch() function to retrieve the first batch of products
    this.getNextBatch(groupID, 100, 100, tcgController);
  }

  getNextBatch(groupID, offset, currentBatch, tcgController) {
    // Retrieve the next batch of group products from tcgplayer
    tcgController
      .getGroupProducts(groupID, 100, offset, this.modalHelper)
      .then((sets: any) => {
        console.log(`Pulled batch ${currentBatch}`);
        // Save the batch of products to Firebase
        this.saveSet(groupID, sets, tcgController);
        // Check if the batch has more than 100 products
        if (sets.length === 100) {
          // If the batch has more than 100 products, increment the offset and
          // currentBatch values and call the getNextBatch() function again to retrieve the next batch of products
          this.getNextBatch(
            groupID,
            offset + 100,
            currentBatch + 1,
            tcgController
          );
        }
      });
  }

  async saveSet(groupID: string, sets: any[], tcgController) {
    // Filter out sets with names that include "Code" or empty names
    // const cleanSets = sets.filter((d) => !d.name.includes('Code') && d.name);

    // Concatenate the clean sets with the existing cards array,
    // then sort the resulting array by productId
    tcgController.cards = [...tcgController.cards, ...sets]
      .sort((a: any, b: any) => a.productId - b.productId)
      .filter((d) => !d.name.includes('Code') && d.name);
    tcgController.cardHold = tcgController.cards;

    // Save the clean sets data for the given group ID
    await this.saveSetData(groupID, sets);
  }

  async saveSetData(groupID: string, sets: any[]): Promise<void> {
    // Iterate over each card in the clean sets array

    for (const card of sets) {
      // If the card has a name and it does not include "Code"
      // if (
      //   card &&
      //   card.name &&
      //   card.productId &&
      //   card.imageUrl &&
      //   !card.name.includes('Code')
      // ) {
      // Select the desired properties from the card object
      const dataToSave = {
        name: card.name,
        productId: card.productId,
        imageUrl: card.imageUrl,
      };

      // Save the card data to the Firestore database
      await setDoc(
        // Construct the database path using the given group ID
        // and the card's productId
        doc(
          this.firestore,
          'Cards',
          String(groupID),
          'Products',
          String(card.productId)
        ),
        dataToSave,
        {
          merge: true,
        }
      );
      //}
    }
  }
}
