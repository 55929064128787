import { MapsAPILoader } from '@ng-maps/core';
import { Component, NgZone, OnInit } from '@angular/core';
import { Firestore, doc, setDoc } from '@angular/fire/firestore';
import { AuthService } from '../services/auth.service';
import { ModalHelperService } from '../services/modal-helper.service';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.page.html',
  styleUrls: ['./shipping.page.scss'],
})
export class ShippingPage implements OnInit {
  updated: boolean;

  constructor(
    public zone: NgZone,
    public mapsAPILoader: MapsAPILoader,
    public modalHelper: ModalHelperService,
    public authService: AuthService,
    public firestore: Firestore
  ) {}

  ngOnInit() {}

  ionViewDidEnter() {
    this.findAddress();
  }

  async ionViewDidLeave() {
    if (!this.updated) {
      this.modalHelper.createToast({
        message: 'Address Not Updated',
        duration: 1000,
        position: 'top',
      });
    }
  }

  closeShipping() {
    this.modalHelper.dismissModal();
  }

  findAddress() {
    this.mapsAPILoader.load().then(async () => {
      const shipper = await document.querySelector('#shipper input');
      const autocomplete = new google.maps.places.Autocomplete(
        shipper as HTMLInputElement,
        {
          componentRestrictions: { country: 'aus' },
        }
      );
      autocomplete.addListener('place_changed', () => {
        this.zone.run(async () => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          console.log(place);
          if (place.formatted_address) {
            this.authService.user.formattedAddress = place.formatted_address;
            this.authService.user.vaugeAddress =
              place.address_components[4].short_name +
              ', ' +
              place.address_components[5].short_name; // CITY + STATE
            this.authService.user.placeID = place.place_id;

            await setDoc(
              doc(this.firestore, 'Users', this.authService.user.uid),
              {
                formattedAddress: this.authService.user.formattedAddress,
                vaugeAddress: this.authService.user.vaugeAddress,
                placeID: this.authService.user.placeID,
              },
              {
                merge: true,
              }
            );

            this.updated = true;

            this.modalHelper.createToast({
              message: 'Address Updated!',
              duration: 2000,
              position: 'bottom',
            });
          }
        });
      });
    });
  }

  hideKeyboard() {
    this.modalHelper.hideKeyboard();
  }
}
