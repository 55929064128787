import { Component, OnInit } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { ModalHelperService } from 'src/app/services/modal-helper.service';

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.css'],
})
export class NewsFeedComponent implements OnInit {
  news = [];

  loading = true;

  constructor(
    public modalHelper: ModalHelperService,
    public fns: Functions,
    public firestore: Firestore
  ) {}

  ngOnInit() {
    this.getNewsFromFeeds();
  }

  async getNewsFromFeeds() {
    this.news = (await httpsCallable(this.fns, 'news-fetchNews')()).data as any;
    this.loading = false;
  }
}
