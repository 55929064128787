/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { IonContent, Platform } from '@ionic/angular';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { CartService } from '../services/cart.service';
import { ProfileService } from '../services/profile.service';
import { PushService } from '../services/push.service';

import { User } from 'src/types';
import { GesturesService } from '../services/gestures.service';
import { TcgControllerService } from '../services/tcg-controller.service';
import { ModalHelperService } from '../services/modal-helper.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit, OnDestroy {
  @ViewChild('profilePic') profilePicChild: ElementRef;
  @ViewChild('profileCardWrap', { read: ElementRef })
  profileCardWrap: ElementRef;
  @ViewChild(IonContent, { static: false }) private content: IonContent;

  user: User;

  isPressing: boolean;
  establishedExternalServices: boolean;

  lastLeftTab = 1;

  pulledProfile: boolean;
  loading = true;
  cardLoading = true;

  profileRefreshSub: Subscription;
  pageScrollSubscription: Subscription;

  constructor(
    public authService: AuthService,
    public router: Router,

    public tcgController: TcgControllerService,
    public gestureService: GesturesService,
    public userService: UserService,
    public cartService: CartService,
    public pushService: PushService,
    public modalHelper: ModalHelperService,
    public profileService: ProfileService
  ) {}

  ngOnInit() {
    if (!this.authService.gotAuth) {
      this.authService
        .waitForAuth()
        .then(() => this.establish().then(() => this.subToRefreshes()));
    } else {
      this.establish().then(() => this.subToRefreshes());
    }
  }

  ngOnDestroy() {
    this.profileRefreshSub && this.profileRefreshSub.unsubscribe();
    this.pageScrollSubscription && this.pageScrollSubscription.unsubscribe();
  }

  ionViewDidEnter() {
    this.setTab(this.lastLeftTab);
  }

  subToRefreshes() {
    this.profileRefreshSub = this.authService.refreshProfileSub.subscribe(
      async (d) => {
        if (d) {
          this.loading = false;
          this.cardLoading = true;

          if (!this.establishedExternalServices && this.authService.user) {
            this.establishExternalServices();
          }

          await this.profileService.buildUserProfile(this.authService, d);

          this.cardLoading = false;
        }
      }
    );

    if (this.authService.gotAuth && !this.authService.user) {
      this.loading = false;
      this.cardLoading = false;
    }
  }

  async establishExternalServices() {
    this.establishedExternalServices = true;

    await this.pushService.establish(this.modalHelper, this.authService.user);

    // Establish the tcgController if the user is verified and the platform is cordova
    if (this.modalHelper.isMobile && this.authService.user.sellerVerified) {
      await this.tcgController.establish(this.modalHelper);
    }
  }

  async establish() {
    if (this.authService.user) {
      // Set the selected tab
      this.setTab(this.userService.selectedTab);
    }

    // Set up the swipe gesture and hide the loading indicator
    await this.setupSwipeGesture();

    // watch for tab scrolls
    this.watchForTabScroll();
  }

  // GESUTRE METHODS
  async setupSwipeGesture() {
    // Check if profile card wrap element is defined
    if (!this.profileCardWrap) {
      // If not defined, set up function to check for it using requestAnimationFrame
      const checkForProfileCardWrap = () => {
        // Check if profile card wrap element is now defined
        if (this.profileCardWrap) {
          // Create swipe gesture with specified options
          this.gestureService.runGestures(
            this.profileCardWrap.nativeElement,
            4
          );
        } else {
          // If not defined, request animation frame again to check for it later
          requestAnimationFrame(checkForProfileCardWrap);
        }
      };

      // Request animation frame to start checking for profile card wrap element
      requestAnimationFrame(checkForProfileCardWrap);
    } else {
      // Create swipe gesture with specified options
      this.gestureService.runGestures(this.profileCardWrap.nativeElement, 4);
    }
  }

  async setTab(tab) {
    // Vibrate the device to provide haptic feedback
    await this.modalHelper.buzz();

    // Update the selected tab and the last left tab
    this.userService.selectedTab = tab;
    this.lastLeftTab = this.userService.selectedTab;
  }

  async doRefresh(event) {
    this.authService.refreshProfile.next(this.authService.user);
    setTimeout(() => {
      event.target.complete();
    }, 1000);
  }

  watchForTabScroll() {
    this.pageScrollSubscription = this.authService.scrollPageSub.subscribe(
      (d) => {
        d && this.content.scrollToTop(400);
      }
    );
  }
}
