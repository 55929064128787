import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ListingManagerService } from 'src/app/services/listing-manager.service';
import { TCGCardData } from 'src/types';

@Component({
  selector: 'app-tcg-data-card-unit',
  templateUrl: './tcg-data-card-unit.component.html',
  styleUrls: ['./tcg-data-card-unit.component.scss'],
})
export class TcgDataCardUnitComponent implements OnInit {
  @Input() card: TCGCardData;
  @Input() game: '';

  gameColor: string;

  constructor(
    public authService: AuthService,
    public listingService: ListingManagerService
  ) {}

  ngOnInit() {
    this.gameColor = this.listingService.convertCard(this.game);
  }
}
