import { EventEmitter, Injectable, Input, NgZone, Output } from '@angular/core';
import { GestureController, GestureDetail, Platform } from '@ionic/angular';
import { UserService } from './user.service';
import { Device } from '@capacitor/device';
import { ModalHelperService } from './modal-helper.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class GesturesService {
  @Output() tap = new EventEmitter();
  @Output() press = new EventEmitter();
  @Input() delay = 300;
  action: any; //not stacking actions

  isPressing: boolean;

  private longPressActive = false;
  private positions = {
    start: {
      x: undefined as number,
      y: undefined as number,
    },
    current: {
      x: undefined as number,
      y: undefined as number,
    },
  };

  constructor(
    public authService: AuthService,

    private gestureCtrl: GestureController,
    public userService: UserService,
    public modalHelper: ModalHelperService,
    private zone: NgZone
  ) {}

  async runGestures(el, maxTab) {
    if (await this.modalHelper.deviceCanHandleIt()) {
      // Device has more than 7GB of memory used, do not run code
      return;
    }
    // Device has 7GB or less of memory used, run code

    this.gestureCtrl
      .create({
        el, // Element to apply gesture to
        gestureName: 'swipe', // Name of gesture
        direction: 'x', // Direction of gesture (x-axis)
        threshold: 31, // Minimum distance required for gesture to be recognized
        onMove: (ev) =>
          this.handleMoving(ev, maxTab) as unknown as boolean | void, // Callback function for when gesture is in progress
      })
      .enable(true); // Enable gesture
  }

  // Gestures used in profile and user profile
  async handleMoving(ev, maxTab) {
    // exit early if user is already pressing
    if (this.isPressing) {
      return;
    }

    // get the current tab
    const tab = this.userService.selectedTab;

    // handle tab change based on swipe direction
    if (ev.deltaX > 0 && tab !== 1) {
      // decrease tab index if not already at the first tab
      this.userService.selectedTab = tab - 1;
    } else if (ev.deltaX < 0 && tab < maxTab) {
      // increase tab index if not already at the last tab
      this.userService.selectedTab = tab + 1;
    } else if (ev.deltaX < 0 && tab === maxTab) {
      // go to the maximum tab if swiping from the third tab
      this.userService.selectedTab = maxTab;
      // Create swipe gesture with specified options
    } else {
      this.handleTabMoving(ev, 'profile');
    }

    this.goToTab(this.userService.selectedTab);

    // prevent further tab changes for a short time
    this.isPressing = true;
    setTimeout(() => {
      this.isPressing = false;
    }, 300);
  }

  // Create a swipe gesture that control the bottom tabs
  async runBtmTabGestures(el, page) {
    if (await this.modalHelper.deviceCanHandleIt()) {
      // Device has more than 7GB of memory used, do not run code
      return;
    }
    // Device has 7GB or less of memory used, run code

    // Create a swipe gesture using the gesture controller
    this.gestureCtrl
      .create({
        el, // Element to apply gesture to
        gestureName: 'swipe', // Name of gesture
        direction: 'x', // Direction of gesture (x-axis)
        threshold: 31, // Minimum distance required for gesture to be recognized
        blurOnStart: true, // Blur the element when gesture starts
        onMove: (ev: GestureDetail) => {
          // Callback function for when gesture is in progress
          this.handleTabMoving(ev, page);
        },
        canStart: (ev: GestureDetail) => this.isElementNotSectionOrSwiper(ev),
        notCaptured: (ev: GestureDetail) => {
          // Callback function for when gesture is not captured
          //console.log('not captured', ev);
        },
      })
      .enable(true); // Enable gesture
  }

  isElementNotSectionOrSwiper = (ev: GestureDetail) => {
    // Initialize an empty array to store the touched elements
    let touchedElements: any[] = [];

    // // Check the platform type to determine how to get the touched elements
    // if (this.modalHelper.isMobile) {
    //   // If the platform is cordova, get the class names of the touched elements
    //   touchedElements = (ev.event as TouchEvent)
    //     .composedPath()
    //     .map((pth: any) => ({ localName: pth.localName }));
    // } else {
    //   // If the platform is not cordova, get the path of the touched elements
    //   touchedElements = (ev.event as any).path;
    // }

    touchedElements = (ev.event as TouchEvent)
      .composedPath()
      .map((pth: any) => ({
        localName: pth.localName || '',
        className: pth.className || '',
      }));

    // Define a function to check if an element is not a section or swiper
    const isNotSectionOrSwiper = (c: any) =>
      c.className.indexOf('section') === 0 ||
      c.localName.indexOf('swiper-slide') === 0;

    if (!touchedElements) {
      return true;
    }

    // Filter the touched elements to only include those that are not sections or swipers
    const filteredElements = touchedElements.filter(isNotSectionOrSwiper);

    // Return true if the filtered list of elements is empty (indicating that the touched element is not a section or swiper),
    // otherwise return false
    return filteredElements.length === 0;
  };

  // Handle tab moving based on swipe direction and current page
  handleTabMoving(ev, page) {
    // return early if the scroll elements are defined
    // if (this.filterElementPath(ev).length) {
    //   return;
    // }

    if (!this.authService.user && page === 'mail') {
      page = 'profile';
    }

    // handle tab moving based on the current page
    switch (page) {
      case 'home':
        // go to the search tab if swiping from the home tab
        if (ev.deltaX < 0) {
          this.goToTab('search');
        }
        break;

      case 'search':
        // go to the home tab if swiping from the search tab
        if (ev.deltaX > 0) {
          this.goToTab('home');
        }
        // go to the mail tab if swiping from the search tab
        if (ev.deltaX < 0) {
          this.goToTab('mail');
        }
        break;

      case 'mail':
        // go to the search tab if swiping from the mail tab
        if (ev.deltaX > 0) {
          this.goToTab('search');
        }
        // go to the profile tab if swiping from the mail tab
        if (ev.deltaX < 0) {
          this.goToTab('profile');
        }
        break;

      case 'profile':
        // go to the mail tab if swiping from the profile tab
        if (ev.deltaX > 0) {
          this.goToTab('mail');
        }
        break;

      default:
        // do nothing
        break;
    }
  }

  async goToTab(tab) {
    // Wait for tab element to be selected
    return await new Promise((resolve, reject) => {
      const tabSelector = `[data-tab="${tab}"]`;
      const tabElement = document.querySelector(tabSelector) as HTMLElement;

      if (tabElement) {
        this.modalHelper.buzz();
        tabElement.click();
        resolve(tabElement);
      } else {
        reject(
          new Error(`Unable to find element with selector: ${tabSelector}`)
        );
      }
    });
  }

  loadLongPressOnElement() {
    document.querySelectorAll('.msg_box').forEach((el) => {
      const gesture = this.gestureCtrl.create({
        el,
        threshold: 0,
        gestureName: 'long-press',
        onStart: (ev) => {
          this.longPressActive = true;
          this.longPressAction(el.textContent);

          this.positions = {
            start: { x: ev.startX, y: ev.startY },
            current: { x: ev.currentX, y: ev.currentY },
          };
        },
        onMove: (ev) => {
          this.positions.current = { x: ev.currentX, y: ev.currentY };
        },
        onEnd: (ev) => {
          this.longPressActive = false;
        },
      });
      gesture.enable(true);
    });
  }

  longPressAction(message) {
    if (this.action) {
      clearInterval(this.action);
    }
    this.action = setTimeout(() => {
      this.zone.run(() => {
        // Check distance
        const xDistance = Math.abs(
          this.positions.start.x - this.positions.current.x
        );
        const yDistance = Math.abs(
          this.positions.start.y - this.positions.current.y
        );
        if (xDistance > 15 || yDistance > 15) {
          // User dragged finger
          return;
        }

        if (this.longPressActive === true) {
          this.longPressActive = false;
          this.pressMessage(message);
          this.press.emit();
        }
      });
    }, this.delay);
  }

  // Handle a press on a message
  async pressMessage(msg) {
    // Vibrate the device to provide haptic feedback
    await this.modalHelper.buzz();

    // Show the action sheet with the "Copy" and "Cancel" options
    const buttons = [
      {
        text: 'Copy',
        handler: () => this.copyText(msg),
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      },
    ];

    const selectedBtn = await this.modalHelper.createActionSheet({
      buttons,
    });
    buttons[selectedBtn].handler();
  }
  // Copy the given text to the clipboard
  async copyText(txt) {
    await this.modalHelper.dismissLoader();
    this.modalHelper.writeToClipboard(txt);
  }

  // clickUp() {
  //   const cardElements = document.querySelectorAll('.pull_down_card');

  //   if (!cardElements || !cardElements.length) {
  //     //try again in 2 seconds
  //     setTimeout(() => this.clickUp(), 1000);
  //   }

  //   if (!this.modalHelper.deviceCanHandleIt()) {
  //     cardElements.forEach((el) => {
  //       (
  //         el.getElementsByClassName('card_content')[0] as HTMLElement
  //       ).style.marginTop = '0px';
  //     });
  //     return;
  //   }

  //   cardElements.forEach((el) => {
  //     const gesture = this.gestureCtrl.create({
  //       el,
  //       threshold: 0,
  //       direction: 'y',
  //       gestureName: 'click-up',
  //       onMove: (ev) => {
  //         let upValue = ev.deltaY - 52;

  //         // Base on time aswell?? ~  || ev.currentTime - ev.startTime < 55
  //         if (upValue >= -52) {
  //           this.addContentNoScroll(
  //             document.querySelector('ion-content').classList
  //           );
  //         }

  //         if (upValue > -2) {
  //           upValue = -2;
  //         }

  //         if (upValue < -52) {
  //           upValue = -52;
  //         }

  //         (
  //           el.getElementsByClassName('card_content')[0] as HTMLElement
  //         ).style.marginTop = `${upValue}px`;

  //         this.removeContentNoScroll(
  //           document.querySelector('ion-content').classList
  //         );
  //       },
  //     });
  //     gesture.enable(true);
  //   });
  // }

  // addContentNoScroll(classList) {
  //   if (!classList.contains('no-scroll')) {
  //     classList.add('no-scroll');
  //   }
  // }

  // removeContentNoScroll(classList) {
  //   setTimeout(() => {
  //     if (classList.contains('no-scroll')) {
  //       classList.remove('no-scroll');
  //     }
  //   }, 500);
  // }

  // allowFreeScroll(el) {
  //   const messageBoxes = document.querySelectorAll('.msg_box');
  //   console.log(messageBoxes);

  //   this.gestureCtrl
  //     .create({
  //       el,
  //       gestureName: 'free-scroll',
  //       direction: 'y',
  //       threshold: 31,
  //       onMove: () => {
  //         messageBoxes.forEach((msgBox) => {
  //           msgBox.classList.add('free-scroll');
  //         });
  //       },
  //       onEnd: () => {
  //         messageBoxes.forEach((msgBox) => {
  //           msgBox.classList.remove('free-scroll');
  //         });
  //       },
  //     })
  //     .enable(true);
  // }
}
