import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { ListingManagerService } from 'src/app/services/listing-manager.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';

@Component({
  selector: 'app-review-cards',
  templateUrl: './review-cards.component.html',
  styleUrls: ['./review-cards.component.scss'],
})
export class ReviewCardsComponent {
  @Input() username = '';
  @Input() reviews = [];
  @Input() isAdmin = false;

  constructor(
    public router: Router,
    public adminService: AdminService,
    public listingManager: ListingManagerService,
    public modalHelper: ModalHelperService
  ) {}

  openListing(card) {
    this.modalHelper.triggerListingPage(card);
  }

  async openProfile(owner) {
    this.modalHelper.triggerUserProfile(owner, 'shop');
  }
}
