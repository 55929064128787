// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  --background: #fef9f2;
}

.top_artist_toolbar .profile_bbtn {
  text-align: left;
}
.top_artist_toolbar ion-avatar {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.top_artist_toolbar h3 {
  font-size: 16px;
  margin: 0;
  color: black;
}
.top_artist_toolbar h4 {
  font-size: 10px;
  width: 100%;
  max-width: 50vw;
  overflow: hidden;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/app/components/listing-header/listing-header.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA;AACJ;;AAGI;EACI,gBAAA;AAAR;AAGI;EACI,WAAA;EACA,YAAA;EACA,kBAAA;AADR;AAII;EACI,eAAA;EACA,SAAA;EACA,YAAA;AAFR;AAKI;EACI,eAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,uBAAA;AAHR","sourcesContent":["ion-toolbar {\n    --background: #fef9f2;\n}\n\n.top_artist_toolbar {\n    .profile_bbtn {\n        text-align: left;\n    }\n\n    ion-avatar {\n        width: 30px;\n        height: 30px;\n        margin-right: 10px;\n    }\n\n    h3 {\n        font-size: 16px;\n        margin: 0;\n        color: black;\n    }\n\n    h4 {\n        font-size: 10px;\n        width: 100%;\n        max-width: 50vw;\n        overflow: hidden;\n        text-overflow: ellipsis;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
