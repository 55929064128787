// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bagBadge {
  width: 12px;
  height: 12px;
  background: rgb(206, 108, 108);
  color: #fff;
  font-size: 8px;
  line-height: 12px;
  border-radius: 50%;
  position: absolute;
  margin-left: -18px;
  margin-top: -15px;
  z-index: 100;
}

.lil_title_txt {
  font-size: 10px;
  opacity: 0.4;
}`, "",{"version":3,"sources":["webpack://./src/app/components/bag-indicator/bag-indicator.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,8BAAA;EACA,WAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;AACJ;;AAEA;EACI,eAAA;EACA,YAAA;AACJ","sourcesContent":[".bagBadge {\n    width: 12px;\n    height: 12px;\n    background: rgb(206, 108, 108);\n    color: #fff;\n    font-size: 8px;\n    line-height: 12px;\n    border-radius: 50%;\n    position: absolute;\n    margin-left: -18px;\n    margin-top: -15px;\n    z-index: 100;\n}\n\n.lil_title_txt {\n    font-size: 10px;\n    opacity: 0.4;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
