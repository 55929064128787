import { Component, Input, OnInit } from '@angular/core';
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  Firestore,
  doc,
  getDoc,
  getDocs,
  DocumentData,
  Query,
} from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Listing, User } from 'src/types';
import { AuthService } from '../services/auth.service';
import { FilterService } from '../services/filter.service';
import { ListingManagerService } from '../services/listing-manager.service';
import { ModalHelperService } from '../services/modal-helper.service';
import { TcgControllerService } from '../services/tcg-controller.service';

@Component({
  selector: 'app-infinity',
  templateUrl: './infinity.page.html',
  styleUrls: ['./infinity.page.scss'],
})
export class InfinityPage implements OnInit {
  @Input() mySubject: BehaviorSubject<string>;
  infiniteListings: Listing[] = [];
  followListings: Listing[];
  definedType: string;
  promo: string;
  recentSetName: string;

  loaded: boolean;
  isTCGSingleDisplay: boolean;

  constructor(
    public firestore: Firestore,
    public router: Router,
    public route: ActivatedRoute,
    public filterService: FilterService,
    public modalHelper: ModalHelperService,
    public tcgController: TcgControllerService,
    public authService: AuthService,
    public listingManagerService: ListingManagerService
  ) {}

  ngOnInit() {
    this.organiseInfinity();
  }

  organiseInfinity() {
    this.loaded = false;
    this.definedType = null;
    this.infiniteListings = [];

    if (!this.definedType || this.definedType !== this.mySubject.value) {
      this.definedType = this.mySubject.value;

      console.log(this.definedType);

      if (this.definedType === 'daily') {
        this.loadBanaza();
      } else if (this.definedType === 'staff') {
        this.loadStaffPicks();
      } else if (this.definedType === 'new') {
        this.loadNewPicks();
      } else if (this.definedType === 'liked') {
        this.loadPopularPicks();
      } else if (this.definedType === 'viewed') {
        this.loadViewedPicks();
      } else if (this.definedType === 'for you') {
        this.loadForYouPicks();
      } else if (this.definedType === 'one dollar') {
        this.loadDollarPicks();
      } else if (this.definedType.includes('promo_')) {
        this.promo = this.definedType.replace('promo_', '');
        this.loadPromoCards();
      } else if (this.definedType.includes('recentSet')) {
        this.loadRecentSet(this.definedType.replace('recentSet-', ''));
      } else if (
        this.listingManagerService.games.filter(
          (game) => game.name === this.definedType
        ).length > 0
      ) {
        this.isTCGSingleDisplay = true;
        this.loadType(this.definedType);
      }
    }
  }

  ionViewDidLeave() {
    this.router.navigate([], {
      queryParams: {
        infinity: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  loadBanaza() {
    this.pullListingsForQuery(
      query(
        collection(this.firestore, 'Listings'),
        where('status', '==', 'selling'),
        orderBy('postDate'),
        limit(100)
      )
    );
  }

  loadStaffPicks() {
    this.pullListingsForQuery(
      query(
        collection(this.firestore, 'Listings'),
        where('isStaffPick', '==', true),
        orderBy('postDate'),
        limit(100)
      )
    );
  }

  loadNewPicks() {
    this.pullListingsForQuery(
      query(
        collection(this.firestore, 'Listings'),
        where('status', '==', 'selling'),
        orderBy('postDate', 'desc'),
        limit(100)
      )
    );
  }

  loadPopularPicks() {
    this.pullListingsForQuery(
      query(
        collection(this.firestore, 'Listings'),
        where('status', '==', 'selling'),
        orderBy('likeCount', 'desc'),
        limit(100)
      )
    );
  }

  loadViewedPicks() {
    this.pullListingsForQuery(
      query(
        collection(this.firestore, 'Listings'),
        where('status', '==', 'selling'),
        orderBy('viewCount', 'desc'),
        limit(100)
      )
    );
  }

  // Define a function to load the For You Picks listings
  async loadForYouPicks() {
    // Call the pullFollowListings function to get the listings
    const listings = await this.listingManagerService.pullFollowListings(
      100,
      this.authService.user.following || 0
    );

    // Set the followListings property to the retrieved listings
    this.infiniteListings = listings as any;
    this.loaded = true;
  }

  loadDollarPicks() {
    this.pullListingsForQuery(
      query(
        collection(this.firestore, 'Listings'),
        where('status', '==', 'selling'),
        where('definedInitialPrice', '<=', 1.0),
        orderBy('definedInitialPrice'),
        orderBy('postDate', 'desc'),
        limit(100)
      )
    );
  }

  loadType(type) {
    this.pullListingsForQuery(
      query(
        collection(this.firestore, 'Listings'),
        where('status', '==', 'selling'),
        where('game', '==', type),
        orderBy('postDate'),
        limit(100)
      )
    );

    this.router.navigate([], {
      queryParams: {
        infinity: type,
      },
      queryParamsHandling: 'merge',
    });
  }

  loadPromoCards() {
    this.pullListingsForQuery(
      query(
        collection(this.firestore, 'Listings'),
        where('tags', 'array-contains', String(this.promo)),
        where('status', '==', 'selling'),
        orderBy('postDate'),
        limit(100)
      )
    );
  }

  async pullListingsForQuery(queryToRun: Query<DocumentData>) {
    // Get all listings that match the specified query
    const listings = (await getDocs(queryToRun)).docs.map((order) =>
      order.data()
    );

    // Get all the listing owners
    const owners = await Promise.all(
      listings.map(async (listing) => await this.getOwner(listing))
    );

    // Add the owner to each listing and add the listing to the infiniteListings array
    listings.forEach((listing, index) => {
      if (this.filterService.showingGames.includes(listing.game)) {
        listing.owner = owners[index];
        this.infiniteListings.push(listing as Listing);
      }
    });
    this.loaded = true;
  }

  getOwner(listing) {
    return new Promise(async (resolve) => {
      const listingDoc = doc(this.firestore, 'Users', listing.uploader);
      resolve((await getDoc(listingDoc)).data());
    });
  }

  async openListing(card: Listing) {
    this.modalHelper.triggerListing(card, card.owner);
  }

  searchCategory() {
    if (this.authService.user) {
      if (this.authService.user.sellerVerified) {
        this.openCategoryFilter();
      } else {
        this.router.navigateByUrl('/add-listing');
      }
    } else {
      this.authService.notAuthedPopup();
    }
  }

  async openCategoryFilter() {
    this.modalHelper.triggerSearchCatergory(this.definedType);
  }

  loadRecentSet(set) {
    this.loaded = false;

    this.listingManagerService.games.forEach(async (game: any) => {
      if (game.innerSet.groupId == set) {
        this.recentSetName = game.tcgName;

        await this.listingManagerService.pullGroupsProducts(
          game.innerSet.groupId,
          game.tcgName,
          this.tcgController,
          this.modalHelper
        );

        this.infiniteListings = this.tcgController.cards;

        this.loaded = true;
      }
    });
  }
}
