// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stars {
  text-align: left;
  font-size: 8px;
  display: inline;
}

.faded_banner {
  background: #e99696;
  color: #fff;
  text-align: center;
  padding: 4px;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  margin-top: -7px;
  position: relative;
  letter-spacing: 4px;
}

.faded img {
  opacity: 0.6;
}`, "",{"version":3,"sources":["webpack://./src/app/components/card-unit/card-unit.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,cAAA;EACA,eAAA;AACJ;;AAEA;EACI,mBAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,+BAAA;EACA,8BAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":[".stars {\n    text-align: left;\n    font-size: 8px;\n    display: inline;\n}\n\n.faded_banner {\n    background: #e99696;\n    color: #fff;\n    text-align: center;\n    padding: 4px;\n    font-size: 10px;\n    font-weight: bold;\n    text-transform: uppercase;\n    border-bottom-right-radius: 4px;\n    border-bottom-left-radius: 4px;\n    margin-top: -7px;\n    position: relative;\n    letter-spacing: 4px;\n}\n\n.faded img {\n    opacity: 0.6;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
