import { Component, OnInit } from '@angular/core';
import { FilterService } from '../services/filter.service';
import { ListingManagerService } from '../services/listing-manager.service';
import { ModalHelperService } from '../services/modal-helper.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.page.html',
  styleUrls: ['./welcome.page.scss'],
})
export class WelcomePage implements OnInit {
  constructor(
    public listingManager: ListingManagerService,
    public filterService: FilterService,
    public modalHelper: ModalHelperService
  ) {}

  ngOnInit() {}

  async ionViewWillLeave() {
    this.modalHelper.dismissModal();
    await this.modalHelper.setPreference('seenTutorial', 'true');
  }

  async toggleGame(game) {
    if (!this.anyGamesSelected()) {
      this.filterService.showingGames = [];
    }

    await this.filterService.filterByGame(
      !game.selected ? true : false,
      game.name
    );

    game.selected = !game.selected;

    if (game.selected) {
      this.modalHelper.buzz();
    }
  }

  anyGamesSelected() {
    return this.listingManager.games.some((game: any) => game.selected);
  }

  filterGames() {
    this.modalHelper.dismissModal();
  }
}
