// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top_card {
  margin-top: 15%;
}

.custom_card {
  border-radius: 3px;
  background: #d2b094;
  color: #fff2e6;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  margin-bottom: 20px;
}
.custom_card h1 {
  font-size: 15px;
}
.custom_card h3 {
  font-size: 16px;
  text-align: center;
}
.custom_card p {
  font-size: 12px;
}
.custom_card .opt_btns {
  margin-top: 30px;
}
.custom_card button {
  background: none;
  color: #fff3e4;
  border: 1px solid #fff3e4;
  padding: 10px;
  box-sizing: border-box;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 5px;
  border-radius: 3px;
}
.custom_card .url_strip {
  background: #fff3e4;
  color: #a1836a;
  font-family: monospace;
  font-weight: bold;
  font-size: 22px;
  border-radius: 4px;
  margin: 20px 0;
  padding: 10px;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
}
.custom_card .url_strip ion-icon {
  margin-top: 3px;
}

.reverse_card {
  color: #d2b094;
  background: #fff2e6;
  border: 1px solid #d2b094;
}`, "",{"version":3,"sources":["webpack://./src/app/affiliates/affiliates.page.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AAEA;EACI,kBAAA;EACA,mBAAA;EACA,cAAA;EACA,UAAA;EACA,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;AACJ;AACI;EACI,eAAA;AACR;AAEI;EACI,eAAA;EACA,kBAAA;AAAR;AAGI;EACI,eAAA;AADR;AAII;EACI,gBAAA;AAFR;AAKI;EACI,gBAAA;EACA,cAAA;EACA,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,UAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;AAHR;AAMI;EACI,mBAAA;EACA,cAAA;EAEA,sBAAA;EACA,iBAAA;EACA,eAAA;EAEA,kBAAA;EACA,cAAA;EACA,aAAA;EAEA,WAAA;EACA,oBAAA;EACA,8BAAA;AAPR;AASQ;EACI,eAAA;AAPZ;;AAYA;EACI,cAAA;EACA,mBAAA;EACA,yBAAA;AATJ","sourcesContent":[".top_card {\n    margin-top: 15%;\n}\n\n.custom_card {\n    border-radius: 3px;\n    background: #d2b094;\n    color: #fff2e6;\n    width: 80%;\n    margin-left: auto;\n    margin-right: auto;\n    padding: 20px;\n    margin-bottom: 20px;\n\n    h1 {\n        font-size: 15px;\n    }\n\n    h3 {\n        font-size: 16px;\n        text-align: center;\n    }\n\n    p {\n        font-size: 12px;\n    }\n\n    .opt_btns {\n        margin-top: 30px;\n    }\n\n    button {\n        background: none;\n        color: #fff3e4;\n        border: 1px solid #fff3e4;\n        padding: 10px;\n        box-sizing: border-box;\n        width: 80%;\n        margin-left: auto;\n        margin-right: auto;\n        display: block;\n        margin-bottom: 5px;\n        border-radius: 3px;\n    }\n\n    .url_strip {\n        background: #fff3e4;\n        color: #a1836a;\n\n        font-family: monospace;\n        font-weight: bold;\n        font-size: 22px;\n\n        border-radius: 4px;\n        margin: 20px 0;\n        padding: 10px;\n\n        width: 100%;\n        display: inline-flex;\n        justify-content: space-between;\n\n        ion-icon {\n            margin-top: 3px;\n        }\n    }\n}\n\n.reverse_card {\n    color: #d2b094;\n    background: #fff2e6;\n    border: 1px solid #d2b094;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
