import { Component, Input, OnInit } from '@angular/core';
import { doc, Firestore, setDoc } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { ListingManagerService } from 'src/app/services/listing-manager.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { Listing, User } from 'src/types';

@Component({
  selector: 'app-listing-header',
  templateUrl: './listing-header.component.html',
  styleUrls: ['./listing-header.component.scss'],
})
export class ListingHeaderComponent implements OnInit {
  @Input() owner: User;
  @Input() listing: Listing;
  @Input() inModal: boolean;

  constructor(
    public cartService: CartService,
    public authService: AuthService,
    public adminService: AdminService,
    public modalHelper: ModalHelperService,
    public listingManagerService: ListingManagerService,
    public router: Router,
    public firestore: Firestore
  ) {}

  ngOnInit() {}

  async shareListing() {
    await this.modalHelper.share({
      title: this.listing.name,
      text: 'Check out ' + this.listing.name + ' on Cardboard Ninja',
      url: 'http://cbnja.co/home/listing/' + this.listing.id,
      dialogTitle: 'Share this listing',
    });
  }

  openListingFully(card) {
    this.modalHelper
      .dismissModal()
      .then(() => this.modalHelper.triggerListingPage(card))
      .catch(() => {
        this.router.navigate([
          this.router.url.split('?')[0] + '/listing/' + card.id,
        ]);
      });
  }

  async triggerListingMore() {
    const buttons: any = [
      {
        text: 'Share this listing',
        icon: 'share-outline',
        handler: () => this.shareListing(),
      },
      {
        text: 'Get help from support',
        icon: 'help-buoy-outline',
        handler: () => this.modalHelper.triggerIAB('https://m.me/cbnja'),
      },
    ];

    if (this.authService.user.uid === this.owner.uid) {
      buttons.push({
        text: 'Edit this listing',
        icon: 'create-outline',
        handler: () =>
          this.listingManagerService.updateListing(
            this.listing,
            this.modalHelper,
            true
          ),
      });
    }

    if (this.authService.user.isAdmin) {
      buttons.push({
        text: 'Admin options',
        icon: 'lock-open-outline',
        handler: () =>
          this.adminService.adminOptions(
            this.owner,
            this.listing,
            this.modalHelper
          ),
      });
    }

    buttons.push({
      text: 'Report this listing',
      role: 'destructive',
      icon: 'flag-outline',
      handler: () =>
        this.listingManagerService.reportListing(
          this.listing,
          this.authService.user.username,
          this.modalHelper
        ),
    });

    buttons.push({
      text: 'Cancel',
      icon: 'close',
      role: 'cancel',
      handler: () => {},
    });

    const selectedBtn = await this.modalHelper.createActionSheet({
      buttons,
    });

    buttons[selectedBtn].handler();
  }
}
