import { Injectable } from '@angular/core';
import { doc, Firestore, setDoc } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ListingManagerService } from './listing-manager.service';

@Injectable({
  providedIn: 'root',
})
export class FilterService {
  uid: string;

  showingGames: any = [];

  refreshGameFilter: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  gameFilterSub: Observable<boolean> = this.refreshGameFilter.asObservable();

  constructor(
    public firestore: Firestore,
    public authService: AuthService,
    public listingManagerService: ListingManagerService
  ) {}

  async pullSavedShowingGames(user) {
    if (user) {
      this.uid = user.uid;
    }

    if (user && user.showingGamesFilter && user.showingGamesFilter.length) {
      this.showingGames = user.showingGamesFilter;
      //this.refreshGameFilter.next(true);
    } else {
      this.showingGames = this.listingManagerService.games.map(
        (game) => game.name
      );
    }

    this.refreshGameFilter.next(true);
  }

  async filterByGame(isChecked: boolean, game: string) {
    if (isChecked) {
      this.showingGames.push(game);
    } else {
      const gameIndex = this.showingGames.indexOf(game);
      if (gameIndex > -1) {
        this.showingGames.splice(gameIndex, 1);
      }
    }

    if (this.uid) {
      await setDoc(
        doc(this.firestore, 'Users', String(this.uid)),
        {
          showingGamesFilter: this.showingGames,
        },
        { merge: true }
      );
    }

    this.refreshGameFilter.next(true);
  }
}
