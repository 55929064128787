import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements } from '@stripe-elements/stripe-elements/loader';
import { defineCustomElements as jeepPhotoviewer } from 'jeep-photoviewer/loader';
import { defineCustomElements as pwaElements } from '@ionic/pwa-elements/loader';

import 'hammerjs'; // HAMMER TIME

// if (environment.production) {
//   enableProdMode();
// }

// const bootstrap = () => {
//   platformBrowserDynamic()
//     .bootstrapModule(AppModule)
//     .catch((err) => console.log(err));
// };

// defineCustomElements(window);

// if (document.readyState === 'complete') {
//   bootstrap();
// } else {
//   document.addEventListener('DOMContentLoaded', bootstrap);
// }

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));

pwaElements(window);

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
jeepPhotoviewer(window);
