import { Component, OnInit } from '@angular/core';
import { ModalHelperService } from '../services/modal-helper.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.page.html',
  styleUrls: ['./about.page.scss'],
})
export class AboutPage implements OnInit {
  constructor(public modalHelper: ModalHelperService) {}

  ngOnInit() {}
}
