import { Injectable } from '@angular/core';
import { doc, Firestore } from '@angular/fire/firestore';
import { Device } from '@capacitor/device';
import { AccelListenerEvent, Motion } from '@capacitor/motion';
import { setDoc } from '@firebase/firestore';

import ColorThief from 'colorthief/dist/color-thief.mjs';
import { ModalHelperService } from './modal-helper.service';

@Injectable({
  providedIn: 'root',
})
export class CardEffectsService {
  constructor(
    public modalHelper: ModalHelperService,
    public firestore: Firestore
  ) {}

  // async addCardMotion() {
  //   const motionCardEl = document.getElementById('motion_card');
  //   const swipeSlideImgs = document.querySelectorAll('.swipe_slide img');

  //   // We'll use a low-pass filter to smooth out the acceleration data
  //   const ALPHA = 0.8;
  //   let previousAcceleration = { x: 0, y: 0, z: 0 };

  //   // We'll also use a dead-zone filter to ignore small changes in acceleration
  //   const DEAD_ZONE = 0.1;

  //   // We'll add a delay before applying the acceleration data to the `rotate3d` property
  //   const DELAY = 100; // 100ms
  //   let timeout: any = null;

  //   // Check that the elements exist before adding event listeners
  //   if (motionCardEl && swipeSlideImgs.length > 0) {
  //     motionCardEl.addEventListener('click', async () => {
  //       // Request permission to use the device's motion sensor
  //       await (DeviceMotionEvent as any).requestPermission();
  //       Motion.addListener('accel', (event: DeviceMotionEvent) => {
  //         // The DeviceMotionEvent provides acceleration data along the x, y, and z axes
  //         const { x, y, z } = event.acceleration;

  //         // Apply a low-pass filter to smooth out the acceleration data
  //         const filteredAcceleration = {
  //           x: x * ALPHA + previousAcceleration.x * (1 - ALPHA),
  //           y: y * ALPHA + previousAcceleration.y * (1 - ALPHA),
  //           z: z * ALPHA + previousAcceleration.z * (1 - ALPHA),
  //         };
  //         previousAcceleration = filteredAcceleration;

  //         // Apply a dead-zone filter to ignore small changes in acceleration
  //         const deadZoneAcceleration = {
  //           x:
  //             Math.abs(filteredAcceleration.x) > DEAD_ZONE
  //               ? filteredAcceleration.x
  //               : 0,
  //           y:
  //             Math.abs(filteredAcceleration.y) > DEAD_ZONE
  //               ? filteredAcceleration.y
  //               : 0,
  //           z:
  //             Math.abs(filteredAcceleration.z) > DEAD_ZONE
  //               ? filteredAcceleration.z
  //               : 0,
  //         };

  //         // Add a delay before applying the acceleration data to the `rotate3d` property
  //         clearTimeout(timeout);
  //         timeout = setTimeout(() => {
  //           // We can use the filtered and dead-zone acceleration data to apply a
  //           // smoother 3D rotation to an element using the CSS `rotate3d` property
  //           const card = swipeSlideImgs[0] as HTMLImageElement;
  //           card.style.transform = `rotate3d(${deadZoneAcceleration.x}, ${deadZoneAcceleration.y}, ${deadZoneAcceleration.z}, 30deg)`;
  //         }, DELAY);
  //       });
  //     });
  //   }
  // }

  // removeCardMotion() {
  //   // Remove all listeners
  //   Motion.removeAllListeners();
  // }

  async getImageColour(listing) {
    // Check is listing has colours already
    if (listing.colours) {
      return Array.from(document.querySelectorAll('.swipe_slide img')).forEach(
        (img: HTMLImageElement, index) =>
          (img.parentElement.style.backgroundColor = `rgba(${listing.colours[index]}, 0.6)`)
      );
    }

    if (await this.modalHelper.deviceCanHandleIt()) {
      return;
    }

    // Okay, now we can pull the listings colours for the first time.
    const images = Array.from(document.querySelectorAll('.swipe_slide img'));

    const colorThief = new ColorThief();
    const proxy =
      'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=';

    const prominentColors = await Promise.all(
      images
        .map((img: HTMLImageElement) =>
          img.getAttribute('src').replace('data:image/png;base64,', '')
        )
        .map((imageURL) => {
          const img = new Image();
          img.crossOrigin = 'Anonymous';

          return new Promise((resolve) => {
            img.addEventListener('load', async () => {
              const color = await colorThief.getColor(img);
              img.removeEventListener('load', () => {});
              resolve(color);
            });
            img.src = proxy + encodeURIComponent(imageURL);
          });
        })
    );

    this.saveColours(prominentColors, listing.id);

    images.forEach(
      (img: HTMLImageElement, index) =>
        (img.parentElement.style.backgroundColor = `rgba(${(
          prominentColors[index] as any
        ).join(',')}, 0.6)`)
    );
  }

  async saveColours(colours: any, id) {
    //Format colours into single array of rgb values
    colours = colours.map((colour) => colour.join(','));

    //Save colours to firestore
    await setDoc(
      doc(this.firestore, 'Listings', id),
      {
        colours,
      },
      { merge: true }
    );
  }
}
