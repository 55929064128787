/* eslint-disable @typescript-eslint/naming-convention */
// eslint-disable-next-line @typescript-eslint/naming-convention
import { Injectable } from '@angular/core';
import { doc, Firestore, writeBatch } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Platform } from '@ionic/angular';
import { ListingManagerService } from './listing-manager.service';
import { ModalHelperService } from './modal-helper.service';
import { PayService } from './pay.service';
import { PushService } from './push.service';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  trackingCode: string;

  constructor(
    public firestore: Firestore,
    private fns: Functions,
    public pushService: PushService,
    public payService: PayService,
    public modalHelper: ModalHelperService,
    public listingService: ListingManagerService
  ) {}

  // Submit the given tracking code and order information
  async submitTrack(trackingCode, order, me) {
    this.trackingCode = trackingCode;

    // Call the track() function with the given tracking code and order information
    const dRes = (await this.track(trackingCode, order)) as any;

    console.log(dRes);

    // If the response data contains a delivery status, call the trackingSuccess() function
    if (dRes.data && dRes.data.data) {
      this.trackingSuccess(
        dRes.data.data[0].delivery_status,
        order,
        me.paymentIntentID,
        {
          code: this.trackingCode,
          courier: dRes.data.data[0].courier_code,
          dateShipped: new Date(),
          shippedToAddress: order.details[0].formattedAddress,
          shippedToUser: me.username,
        },
        me
      );
    } else {
      // Otherwise, dismiss the loading indicator and call the trackingError() function
      await this.modalHelper.dismissLoader();
      this.trackingError(dRes.data.message);
    }
  }

  // Track the given package with the given tracking code
  async track(code, order?) {
    await this.modalHelper.createLoader({
      message: 'Confirming Tracking...',
    });

    // Call the "tracking-trackPackage" function with the given tracking code and customer information
    const trackLabel = httpsCallable(this.fns, 'tracking-trackPackage');
    const result = await trackLabel({
      trackingNumber: code,
      customer_email: order.email,
      customer_name: order.name,
    });

    // Return the result of the function call
    return result;
  }

  // Handle a successful tracking submission
  async trackingSuccess(status, customer, paymentIntentID, trackingObject, me) {
    // If the package status is "pending", try again in 3 seconds
    if (status && status === 'pending') {
      setTimeout(() => {
        this.submitTrack(customer, customer, me);
      }, 3000);
      return;
    }

    // If the package status is "transit" or "pickup", make the payment transferable
    if (!status || status === 'transit' || status === 'pickup') {
      if (customer.cards[0].isCollectedFunds) {
        const amount = Math.floor(customer.totalPrice * 100);
        await this.payService.makeCollectedFundPaymentTransferable(
          amount,
          customer.details[0].stripeAccountID
        );
      } else {
        await this.payService.makePaymentTransferable(paymentIntentID);
      }

      // Save the delivery status and return
      await this.saveDeliveryStatus(customer, trackingObject, me);

      return;
    }

    // If the package has any other status, show an error message
    await this.modalHelper.dismissLoader();

    this.modalHelper.createAlert({
      title: 'Tracking Failed',
      message:
        'It seems the package has a status of "' +
        status +
        '". It requires a status of "in transit". You might have to wait for your ' +
        'postal service to proccess the package... If it is already delivered, please contact ' +
        'us so we can verify that.',
    });
  }

  async saveDeliveryStatus(customer, trackingObject, seller) {
    // Update the cards and order status, and the seller's balance
    this.updateOrderAndCardStatus(customer, trackingObject);

    // Dismiss the loading indicator and show a success message
    await this.modalHelper.dismissLoader();

    await this.modalHelper.createAlert({
      title: 'Thank you for participating in this lil community <3',
      message:
        'Shipment confirmed. Customer notified. The funds will be delegated after they confirm to have received your item. You may cash out in the profile section at any time. ',
    });

    this.pushService.send(
      customer.owner.fcmToken,
      'Order Shipped!',
      `An order you placed with @${seller.username} has been shipped!`,
      '/mail?page=orders',
      customer.owner.uid,
      {
        type: 'shipped',
        from: seller.uid,
      }
    );
    return;
  }

  updateOrderAndCardStatus(order, trackingObject) {
    if (this.trackingCode) {
      // Create a new batch write
      const batch = writeBatch(this.firestore);

      // Log the update to the console
      console.log('Updating Order', order);

      // Get the reference to the order document
      const orderRef = doc(
        this.firestore,
        'Users',
        String(order.shippedFrom),
        'Orders',
        String(order.id)
      );

      // Add the update to the batch
      batch.set(
        orderRef,
        {
          status: 'shipped',
          shippedDate: new Date(),
          pendingDate: null,
          trackingCode: this.trackingCode,
        },
        {
          merge: true,
        }
      );

      // Update each card in the order
      order.cards.forEach((card) => {
        // Get the reference to the card document
        const cardRef = doc(this.firestore, 'Listings', String(card));

        // Add the update to the batch
        batch.set(
          cardRef,
          {
            status: 'shipped',
            shippedDate: new Date(),
            pendingDate: null,
            trackingDetails: trackingObject,
          },
          {
            merge: true,
          }
        );
      });

      // Commit the batch write
      batch.commit();
    } else {
      // Handle missing tracking code
      this.trackingError('Missing tracking code!');
    }
  }

  async trackingError(e) {
    this.modalHelper.dismissLoader();

    this.modalHelper.createAlert({
      title: 'Tracking Failed',
      message: e,
    });
  }

  updateSellerBalance(customer) {
    // Initialize the total price of the cards
    let total = 0;

    // Calculate the total price of the cards
    customer.cards.forEach(async (card) => {
      total += card.definedInitialPrice;
    });

    return total;
  }
}
