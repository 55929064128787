/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { PayService } from '../services/pay.service';

import { Camera, CameraResultType } from '@capacitor/camera';
import { doc, Firestore, setDoc } from '@angular/fire/firestore';
import { MessengerService } from '../services/messenger.service';
import { ModalHelperService } from '../services/modal-helper.service';
declare global {
  interface Window {
    FB: any;
  }
}

@Component({
  selector: 'app-funds',
  templateUrl: './funds.page.html',
  styleUrls: ['./funds.page.scss'],
})
export class FundsPage implements OnInit {
  bankAccount = {
    account_holder_name: '',
    routing_number: '',
    account_number: '',
  };

  payoutData = [];
  idFailed: string;
  accountValue: string;
  pendingValue: string;
  last4: number;

  isUploading: boolean;
  hasSetBank: boolean;
  veryifyingIDDocument: boolean;
  hasSetIDDocument: boolean;
  payoutsAvailable: boolean;

  isLoadingFunds = true;

  constructor(
    public payService: PayService,
    public authService: AuthService,
    public firestore: Firestore,
    public modalHelper: ModalHelperService,
    public messengerService: MessengerService
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.checkBalanceAndAccount();
  }

  ionViewDidEnter() {
    setTimeout(() => {
      const chatbox = document.getElementById('fb-customer-chat');
      chatbox.setAttribute('page_id', '104790965646005');
      chatbox.setAttribute('attribution', 'biz_inbox');

      window.FB.fbAsyncInit = () => {
        window.FB.init({
          xfbml: true,
          version: 'v14.0',
        });
      };

      ((d, s, id) => {
        let js;
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        // eslint-disable-next-line prefer-const
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_GB/sdk/xfbml.customerchat.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }, 1000);
  }

  async checkBalanceAndAccount() {
    if (this.authService.user.stripeAccountID) {
      this.payService
        .getBalance(this.authService.user.stripeAccountID)
        .then((balanceData: any) => {
          this.accountValue = Number(
            +balanceData.available[0].amount / 100
          ).toFixed(2);

          this.pendingValue = Number(
            +balanceData.pending[0].amount / 100
          ).toFixed(2);

          this.payService
            .getAccount(this.authService.user.stripeAccountID)
            .then((accountData: any) => {
              if (
                accountData.external_accounts.data[0] &&
                accountData.external_accounts.data[0].object === 'bank_account'
              ) {
                this.hasSetBank = true;
                this.last4 = accountData.external_accounts.data[0].last4;

                if (accountData.external_accounts.data[0].account) {
                  this.organisePayouts(
                    accountData.external_accounts.data[0].account
                  );
                }
              }
              console.log(accountData);

              if (accountData.requirements.currently_due.length > 0) {
                this.veryifyingIDDocument = false;
                this.idFailed = accountData.requirements.errors[0]
                  ? accountData.requirements.errors[0].reason
                  : '';
              } else {
                if (accountData.requirements.pending_verification.length) {
                  this.veryifyingIDDocument = true;
                } else {
                  this.veryifyingIDDocument = false;
                  this.hasSetIDDocument = true;
                }
              }

              this.payoutsAvailable = accountData.payouts_enabled;
              this.isLoadingFunds = false;
            });
        });
    }
  }

  organisePayouts(account) {
    this.payService.getPayouts(account).then((payoutsData: any) => {
      this.payoutData = [];
      payoutsData.data.forEach((payout) => {
        payout.feeAmount = (payout.amount / 100 / 10) * 0.3;
        payout.totalRecPayment = Number(
          payout.amount / 100 - payout.feeAmount
        ).toFixed(2);
        payout.arrival_date =
          payout.arrival_date && payout.arrival_date !== 'Invalid Date'
            ? new Date(payout.arrival_date * 1000)
            : null;
        payout.created = new Date(payout.created * 1000);

        this.payoutData.push(payout);
      });
    });
  }

  connectBankAccount() {
    if (
      this.bankAccount.account_holder_name &&
      this.bankAccount.account_number &&
      this.bankAccount.routing_number
    ) {
      this.payService
        .getBankToken(this.bankAccount)
        .then((token: any) => {
          this.payService
            .connectBankToAccount(this.authService.user.stripeAccountID, token)
            .then(() => {
              this.hasSetBank = true;

              this.modalHelper.createAlert({
                title: 'Bank Account Connected',
                message: 'Bank Account Connected!',
              });
            })
            .catch((d) => this.bankError(d));
        })
        .catch((d) => this.bankError(d));
    } else {
      this.showFundsError('Something is missing');
    }
  }

  disconnectBankAccount() {
    this.hasSetBank = false;
    this.last4 = null;
  }

  async bankError(array) {
    this.modalHelper.createAlert({
      title: 'Error Connecting Bank Account',
      message: JSON.stringify(array),
    });
  }

  async doRefresh(event) {
    this.isLoadingFunds = true;

    await this.checkBalanceAndAccount();

    event.target.complete();
  }

  // async uploadFrontIDPhoto() {
  //   await Camera.getPhoto({
  //     quality: 90,
  //     allowEditing: true,
  //     resultType: CameraResultType.Base64,
  //     promptLabelHeader: 'Upload Front ID Photo',
  //   }).then(async (image) => {
  //     await this.modalHelper.createLoader({
  //       message: 'Uploading Front ID Photo...',
  //     });

  //     const imageToSend = image.base64String;

  //     fetch(`data:image/jpeg;base64,${imageToSend}`).then(
  //       async (base64Response) => {
  //         const blob = await base64Response.blob();
  //         const theForm: FormData = new FormData();

  //         theForm.append('purpose', 'identity_document');
  //         theForm.append(
  //           'file',
  //           blob,
  //           this.authService.user.uid + '-front-identity-photo'
  //         );

  //         this.payService
  //           .uploadIdentityPhoto(theForm)
  //           .then((res: any) => {
  //             this.modalHelper.dismissLoader().then(async () => {
  //               setDoc(
  //                 doc(this.firestore, 'Users', this.authService.user.uid),
  //                 {
  //                   frontIdentitiyID: res.id,
  //                 },
  //                 {
  //                   merge: true,
  //                 }
  //               );
  //               this.authService.user.frontIdentitiyID = res.id;
  //             });
  //           })
  //           .catch((err) => {
  //             this.modalHelper.dismissLoader().then(() => {
  //               this.showFundsError('Error uploading Document');
  //               console.log(err);
  //             });
  //           });
  //       }
  //     );
  //   });
  // }

  // async uploadBackIDPhoto() {
  //   await Camera.getPhoto({
  //     quality: 90,
  //     allowEditing: true,
  //     resultType: CameraResultType.Base64,
  //     promptLabelHeader: 'Upload Back ID Photo',
  //   }).then(async (image) => {
  //     await this.modalHelper.createLoader({
  //       message: 'Uploading Back ID Photo...',
  //     });

  //     const imageToSend = image.base64String;

  //     fetch(`data:image/jpeg;base64,${imageToSend}`).then(
  //       async (base64Response) => {
  //         const blob = await base64Response.blob();
  //         const theForm: FormData = new FormData();

  //         theForm.append('purpose', 'identity_document');
  //         theForm.append(
  //           'file',
  //           blob,
  //           this.authService.user.uid + '-back-identity-photo'
  //         );

  //         this.payService
  //           .uploadIdentityPhoto(theForm)
  //           .then(async (res: any) => {
  //             await this.modalHelper.dismissLoader();

  //             await setDoc(
  //               doc(this.firestore, 'Users', this.authService.user.uid),
  //               {
  //                 backIdentitiyID: res.id,
  //               },
  //               {
  //                 merge: true,
  //               }
  //             );
  //             this.authService.user.backIdentitiyID = res.id;
  //           })
  //           .catch((err) =>
  //             this.modalHelper
  //               .dismissLoader()
  //               .then(() => this.showFundsError('Error uploading Document'))
  //           );
  //       }
  //     );
  //   });
  // }

  // commitUpload() {
  //   this.payService
  //     .connectIdentityDocument(
  //       this.authService.user.stripeAccountID,
  //       this.authService.user.stripePersonID,
  //       this.authService.user.frontIdentitiyID,
  //       this.authService.user.backIdentitiyID
  //     )
  //     .then(async (rese: any) => {
  //       if (rese.data.raw) {
  //         this.bankError(rese.data.raw);
  //       } else {
  //         await this.modalHelper.createAlert({
  //           title: 'Identity Document Submitted!',
  //           message: rese.data.verification.status,
  //         });

  //         await setDoc(
  //           doc(this.firestore, 'Users', this.authService.user.uid),
  //           {
  //             identityDocument: rese.data.verification.status,
  //           },
  //           {
  //             merge: true,
  //           }
  //         );

  //         this.authService.user.identityDocument =
  //           rese.data.verification.status;
  //         this.veryifyingIDDocument = true;
  //       }
  //     })
  //     .catch((e) => this.showFundsError(e));
  // }

  makeTransfer(accountValue) {
    if (accountValue > 0.5) {
      this.payService
        .makeTransfer(accountValue, this.authService.user.stripeAccountID)
        .then(async (rese: any) => {
          if (rese.raw.message) {
            this.showFundsError(JSON.stringify(rese));
            this.showFundsError(rese.raw.message);
          } else {
            this.showFundsError(
              'Payout in transit! You should see the funds in your account within 24 hours!'
            );
          }
        })
        .catch((e) => this.showFundsError(e));
    } else {
      this.showFundsError(
        'You must have at least $0.50 in your account to make a transfer!'
      );
      return;
    }
  }

  showFundsError(error) {
    this.modalHelper.createAlert({
      title:
        'Something went wrong! Please try again later. If the problem persists, please contact support.',
      message: error,
    });
  }

  makeIdentitySheet() {
    this.payService.createIdentitySheet(this.authService.user.stripeAccountID);
  }
}
