import { Injectable } from '@angular/core';
import { ModalHelperService } from './modal-helper.service';
import {
  doc,
  Firestore,
  getDoc,
  setDoc,
  updateDoc,
} from '@angular/fire/firestore';

import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class CartService {
  established: boolean;

  bag: any = [];

  inCart: boolean;
  hasSetInCart: boolean;
  isAdmin: boolean;
  triedOnce: boolean;
  bagHasBeenOpened: boolean;

  totalPrice = 0;
  trueCardLength = 0;
  cardsLength = 0;

  cartLoading = true;

  currentCardID: string;

  constructor(
    public modalHelper: ModalHelperService,
    public authService: AuthService,
    public firestore: Firestore
  ) {}

  async establish() {
    if (!this.authService.gotAuth) {
      this.authService.waitForAuth().then(() => this.constructCart());
    } else {
      this.constructCart();
    }
  }

  constructCart() {
    if (!this.authService.gotAuth) {
      if (!this.triedOnce) {
        this.triedOnce = true;
        setTimeout(() => this.constructCart(), 600);
      } else {
        console.log('Something went wrong. Please try again.');
      }

      return;
    }

    this.established = true;

    // If the user has a "cart" property in their Firebase document, pull the cards for the cart
    if (this.authService.user && this.authService.user.cart) {
      this.pullCardsForCart();
    }
  }

  async pullCardsForCart(card?) {
    try {
      // Get the cards for the user's cart
      const cards = await this.pullDataForCards(this.authService.user.cart);

      if (card) {
        // Find card in cards and update its price.
        const index = cards.findIndex((c) => c.id === card.id);
        cards[index] = card;
      }

      // Build the bag from the cards and their associated owners
      const bag = await this.buildBag(
        [...new Set(cards.map((card) => card.uploader))],
        cards
      );

      // Get the total price of the bag
      this.bag = await this.getBagPriceTotal(bag);

      // Check if the user has any items in their cart
      this.checkIfInCart();
    } catch (err) {
      // Handle any errors that may occur during the process
      console.error(err);
    }
  }
  async pullDataForCards(cart) {
    try {
      const cards = [];

      // Get the data for each card in the user's cart
      for (const cardID of cart) {
        // Get the card's Firebase document
        const document = await getDoc(doc(this.firestore, 'Listings', cardID));
        const cardData = document.data();

        if (!cardData) {
          this.removeSingleItemFromBag(cardID);
          continue;
        }

        if (cardData.status === 'selling') {
          // If the card is still for sale, add it to the array of cards
          cards.push(cardData);
        } else {
          // If the card is no longer for sale, remove it from the user's cart
          this.removeSingleItemFromBag(cardData);
        }
      }

      return cards;
    } catch (err) {
      // Handle any errors that may occur during the retrieval process
      throw err;
    }
  }

  async buildBag(uniqueUploaders, cards) {
    try {
      this.cardsLength = 0;

      // Retrieve the owner data for each unique uploader in parallel
      const owners = await Promise.all(
        uniqueUploaders.map(async (uploader) => {
          // Get the owner's Firebase document
          const document = await getDoc(
            doc(this.firestore, 'Users', String(uploader))
          );
          const ownerData = document.data();

          // Filter the cards array to only include cards owned by the current owner
          ownerData.cards = cards.filter((card) => card.uploader === uploader);

          this.cardsLength += ownerData.cards.length;

          return ownerData;
        })
      );

      // Build the bag from the owner data
      const bag = [...owners];

      return bag;
    } catch (err) {
      // Handle any errors that may occur during the bag building process
      throw err;
    }
  }

  async getBagPriceTotal(bag) {
    this.totalPrice = 0;

    try {
      // Create a new array of updated bag data
      const updatedBag = await Promise.all(
        bag.map(async (oneBag) => {
          let newPrice = 0;

          // Calculate the total price and shipping price for the current bag
          oneBag.cards.forEach((oneCard) => {
            newPrice += Number(oneCard.definedInitialPrice) || 0;
          });

          // Update the bag data with the calculated prices and other information
          oneBag.shippingType = 'standard';
          oneBag.priceWithOutShipping = Number(newPrice).toFixed(2);
          oneBag.shippingPrice = this.calculateShippingPrice(oneBag.cards);

          oneBag.time = Date.now();
          oneBag.totalPrice =
            +oneBag.priceWithOutShipping + +oneBag.shippingPrice;

          this.totalPrice += oneBag.totalPrice;

          return oneBag;
        })
      );

      return updatedBag;
    } catch (err) {
      // Handle any errors that may occur during the bag price calculation process
      throw err;
    }
  }

  calculateShippingPrice(cards) {
    // Calculate the shipping price based on the number of cards in the bag.
    // Batches of 4 cards with the shipping size 'single' will be shipped together for $3.20.
    // Each additional card will be charged $0.80.
    // Cards with any shipping size besides 'single' will be charged their shipping price individually.

    const singleCards = cards.filter((card) => card.shippingSize === 'single');
    const multiCards = cards.filter((card) => card.shippingSize !== 'single');

    const singleCardsLength = singleCards.length;

    const singleCardsPrice =
      Math.ceil(singleCardsLength / 4) * 3.2 +
      (singleCardsLength % 4 === 0 ? 0 : ((singleCardsLength % 4) - 1) * 0.8);

    const multiCardsPrice = multiCards.reduce(
      (acc, card) => acc + Number(card.shippingPrice),
      0
    );

    return (singleCardsPrice + multiCardsPrice).toFixed(2);
  }

  checkIfInCart(cardID?) {
    this.currentCardID = cardID || this.currentCardID;

    // Check if the current card ID exists in the bag array
    const cardFound = this.bag.some((oneBag) =>
      oneBag.cards.some((card) => card.id === this.currentCardID)
    );

    this.hasSetInCart = true;
    // Set the inCart property based on whether a matching card ID was found
    this.inCart = cardFound ? true : false;
  }

  async removeSingleItemFromBag(card) {
    this.authService.user.cart = this.authService.user.cart.filter(
      (cartItem) => cartItem !== card.id
    );
    await setDoc(
      doc(this.firestore, 'Users', this.authService.user.uid),
      {
        cart: this.authService.user.cart,
      },
      { merge: true }
    );
    this.pullCardsForCart();
  }

  removeOwnerFromBag(owner) {
    owner.cards.forEach((card) => {
      this.authService.user.cart = this.authService.user.cart.filter(
        (cartItem) => cartItem !== card.id
      );
    });
    setDoc(
      doc(this.firestore, 'Users', this.authService.user.uid),
      {
        cart: this.authService.user.cart,
      },
      { merge: true }
    );
    this.pullCardsForCart();
  }

  clearWholeBag() {
    this.authService.user.cart = [];
    setDoc(
      doc(this.firestore, 'Users', this.authService.user.uid),
      {
        cart: this.authService.user.cart,
      },
      { merge: true }
    );
    this.pullCardsForCart();
  }

  async crossCheckData() {
    return new Promise(async (resolve) => {
      this.bag.forEach((bagItem, bagKey) => {
        bagItem.cards.forEach(async (card, cardKey) => {
          const cardData = (
            await getDoc(doc(this.firestore, 'Listings', card.id))
          ).data();

          return !cardData || cardData.status !== 'selling'
            ? this.bag[bagKey].cards.splice(cardKey, 1)
            : (this.bag[bagKey].cards[cardKey] = cardData);
        });
      });
      resolve('done');
    });
  }

  async addCardToBag(card) {
    try {
      this.inCart = true;

      // Add haptic feedback to indicate the card has been added to the bag
      await this.modalHelper.buzz();

      // Ensure that the user's cart exists and is an array
      if (!Array.isArray(this.authService.user.cart)) {
        this.authService.user.cart = [];
      }

      if (this.authService.user.cart.includes(card.id)) {
        // Remove card from cart if already there
        this.authService.user.cart = this.authService.user.cart.filter(
          (cartItem) => cartItem !== card.id
        );
      }

      this.authService.user.cart.push(card.id);
      card.inCart = true; // Update the inCart flag

      // Update the user's cart in the Firestore database
      const userRef = doc(this.firestore, 'Users', this.authService.user.uid);
      await setDoc(
        userRef,
        { cart: this.authService.user.cart },
        { merge: true }
      );

      // Pull the updated list of cards in the cart for display
      await this.pullCardsForCart(card);
      return '+ Card added to cart'; // Return confirmation message
    } catch (error) {
      console.error('Error adding card to cart:', error);
      return 'Failed to add card to cart';
    }
  }

  getCardLengthTotal() {
    // Set the initial total card count to 0
    this.trueCardLength = 0;

    // Check if the bag exists and contains at least one element
    if (this.bag && this.bag[0]) {
      // Loop through each element in the bag and add the number of cards in each element to the total count
      this.bag.forEach((oneBag) => {
        this.trueCardLength += oneBag.cards.length;
      });
    }
  }

  async openBag() {
    if (!this.bagHasBeenOpened) {
      this.bagHasBeenOpened = true;
      this.modalHelper.triggerBag();

      setTimeout(() => {
        this.bagHasBeenOpened = false;
      }, 1000);
    }
  }

  async openMessenger(owner, card) {
    if (
      owner &&
      owner.blockList &&
      owner.blockList.includes(this.authService.user.uid)
    ) {
      return this.modalHelper.createAlert({
        title: 'Blocked',
        message: 'This user has blocked you.',
      });
    }

    this.modalHelper.triggerMessenger(owner, card);
  }

  async openReviewer(card) {
    this.modalHelper.triggerReviewer(card);
  }

  async openProfile(owner, type) {
    this.modalHelper.triggerUserProfile(owner, type);
  }
}
