/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  collection,
  doc,
  DocumentData,
  Firestore,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  Unsubscribe,
  where,
} from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, IonSearchbar } from '@ionic/angular';
import { debounceTime, fromEvent, Observable, Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ModalHelperService } from '../services/modal-helper.service';
import { UserService } from '../services/user.service';
import { MessengerService } from '../services/messenger.service';
import { GesturesService } from '../services/gestures.service';
import { Keyboard } from '@capacitor/keyboard';

interface Message {
  archived: boolean;
  message: string;
  context: string;
  time: number;
  to: string;
  from: string;
}

interface Card {
  name: string;
}

interface User {
  username: string;
}

interface CleanedMessage extends Message {
  card: Card;
  user: User;
  otherUser: User | null;
}

@Component({
  selector: 'app-mail',
  templateUrl: './mail.page.html',
  styleUrls: ['./mail.page.scss'],
})
export class MailPage implements OnInit, OnDestroy {
  @ViewChild('mailWrap', { read: ElementRef })
  mailWrap: ElementRef;

  @ViewChild('mailSearchInput', { static: true }) mailSearchInput: IonSearchbar;
  @ViewChild(IonContent, { static: false }) private content: IonContent;

  messaging: Observable<DocumentData[]>;

  orders: any = [];

  messagingMeSub: Unsubscribe;
  messagingMeInnerSub: Unsubscribe;
  msgSub: Unsubscribe;
  ordersSnapshot: Unsubscribe;

  messagingMe: CleanedMessage[] = [];
  messagingMeHold: CleanedMessage[] = [];

  loading = true;
  loadingMessages = true;
  triedOnce = false;

  sellingOrderCount = 0;

  recentActivitySub: Subscription;
  refreshMailSubscription: Subscription;
  pageScrollSubscription: Subscription;
  inputChangeSubscription: Subscription;

  constructor(
    public authService: AuthService,
    public firestore: Firestore,
    public router: Router,
    public route: ActivatedRoute,
    public gestureService: GesturesService,
    public modalHelper: ModalHelperService,
    public userService: UserService,
    public messengerService: MessengerService
  ) {}

  ngOnInit(): void {
    this.waitForAuth();
  }

  ngOnDestroy(): void {
    this.refreshMailSubscription?.unsubscribe();
    this.pageScrollSubscription?.unsubscribe();
    this.inputChangeSubscription?.unsubscribe();
    this.unsubscribeMessagingSubscriptions();
  }

  // ionViewDidEnter() {
  //   if (this.authService.user) {
  //     this.msgSub = onSnapshot(
  //       doc(this.firestore, 'Messages', this.authService.user.uid),
  //       (usrDoc) => {
  //         const messagedUsers = usrDoc.data()?.messagedUsers as string[];
  //         messagedUsers && this.getPeopleMessagingMe(messagedUsers);
  //       }
  //     );
  //   }
  // }

  private async waitForAuth(): Promise<void> {
    if (!this.authService.gotAuth) {
      await this.authService.waitForAuth();
    }
    this.establish();
  }

  private async establish(): Promise<void> {
    if (!this.authService.user || !this.authService.user.uid) {
      if (!this.triedOnce) {
        this.triedOnce = true;
        setTimeout(() => this.establish(), 600);
      }
      return;
    }

    this.loading = true;
    this.loadingMessages = true;

    await this.getMessages();
    await this.constructUserOrders();

    await this.autoOpenPages();
    await this.subToRefreshes();
    await this.setupSwipeGesture();

    this.userService.pullRecentActivites(this.authService.user.uid);

    this.watchForTabScroll();
  }

  private async getMessages(): Promise<void> {
    try {
      this.unsubscribeMessagingSubscriptions();

      const messagesDoc = doc(
        this.firestore,
        'Messages',
        this.authService.user.uid
      );
      this.msgSub = onSnapshot(messagesDoc, (usrDoc) => {
        const messagedUsers = usrDoc.data()?.messagedUsers as string[];
        messagedUsers && this.getPeopleMessagingMe(messagedUsers);
      });
    } catch (error) {
      this.loadingMessages = false;
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  private sortMessages(): void {
    this.messagingMe = this.messagingMe
      ? this.messagingMe.sort((a, b) => b.time - a.time)
      : [];

    // Remove duplicates as a failsafe
    this.messagingMe = this.messagingMe.filter(
      (thing, index, self) =>
        index === self.findIndex((t) => t.context === thing.context)
    );

    this.messagingMeHold = this.messagingMe;
    this.loadingMessages = false;
  }

  private async autoOpenPages(): Promise<void> {
    const queryParams = this.route.snapshot.queryParamMap;
    const to = queryParams.get('t');
    const from = queryParams.get('f');
    const id = queryParams.get('i');

    if (to && from && id) {
      await this.getMessage(to, from, id);
    } else {
      const page = queryParams.get('page');
      if (page === 'activity') {
        this.modalHelper.triggerRecentActivity(
          this.userService.recentActivities
        );
      } else if (page === 'funds') {
        this.modalHelper.triggerFunds();
      } else if (page === 'actions') {
        this.authService.openOrders(this.orders);
      }
    }
  }

  private async getMessage(
    to: string,
    from: string,
    id: string
  ): Promise<void> {
    if (!to || !from || !id) {
      return;
    }

    try {
      const messagesFromMeDoc = collection(
        this.firestore,
        'Messages',
        to,
        from
      );
      const messagesFromMeData = await getDocs(messagesFromMeDoc);
      const fromMeData = messagesFromMeData.docs.map((fromData) =>
        fromData.data()
      );

      if (fromMeData.length > 0) {
        this.messengerService.openMessenger(to, from, id);
      } else {
        const messagesForMeDoc = collection(
          this.firestore,
          'Messages',
          from,
          to
        );
        const messagesForMeData = await getDocs(messagesForMeDoc);
        const forMeData = messagesForMeData.docs.map((formMeData) =>
          formMeData.data()
        );

        if (forMeData.length > 0) {
          this.messengerService.openMessenger(to, from, id);
        }
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private async getPeopleMessagingMe(people: string[]): Promise<void> {
    try {
      this.unsubscribeMessagingSubscriptions();

      const uid = this.authService.user.uid;

      this.messagingMe = [];
      this.messagingMeHold = [];

      for (const person of people) {
        const messagingDoc = collection(
          this.firestore,
          'Messages',
          person,
          uid
        );
        const messagingDoc2 = collection(
          this.firestore,
          'Messages',
          uid,
          person
        );

        const messagingMeSub = onSnapshot(
          query(messagingDoc, where('archived', '==', false), limit(200)),
          async (docData) => {
            const messagesFromMe = docData.docs.map(
              (msg) => msg.data() as Message
            );

            if (messagesFromMe.length > 0) {
              await this.contextSortMessages(messagesFromMe);
            }

            this.watchForInputChange();
          }
        );

        const messagingMeInnerSub = onSnapshot(
          query(messagingDoc2, orderBy('time', 'desc'), limit(200)),
          async (docData2) => {
            console.log('* Pulling Messages for user...');
            const messagesForMe = docData2.docs.map(
              (msg) => msg.data() as Message
            );

            if (messagesForMe.length > 0) {
              await this.contextSortMessages(messagesForMe);
            }
          }
        );

        this.messagingMeSub = messagingMeSub;
        this.messagingMeInnerSub = messagingMeInnerSub;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private async contextSortMessages(docs: Message[]): Promise<void> {
    const updatedMessages: CleanedMessage[] = [];

    for (const msg of docs) {
      const cleanMSG = await this.cleanMessage(msg);
      if (cleanMSG) {
        updatedMessages.push(cleanMSG);
      }
    }

    this.messagingMe = [...this.messagingMeHold, ...updatedMessages];
    console.log('** Updated Messages: ', this.messagingMe);
    this.sortMessages();
  }

  private async cleanMessage(message: Message): Promise<CleanedMessage | null> {
    try {
      if (message.archived || !message.message) {
        return null;
      }

      const cardDoc = await getDoc(
        doc(this.firestore, 'Listings', String(message.context))
      );
      const card = cardDoc.data() as Card;

      const userDoc = await getDoc(
        doc(this.firestore, 'Users', String(message.to))
      );
      const user = userDoc.data() as User;

      let otherUser: User | null = null;

      if (message.from === this.authService.user.uid) {
        const otherUserDoc = await getDoc(
          doc(this.firestore, 'Users', String(message.to))
        );
        otherUser = otherUserDoc.data() as User;
      } else if (message.to === this.authService.user.uid) {
        const otherUserDoc = await getDoc(
          doc(this.firestore, 'Users', String(message.from))
        );
        otherUser = otherUserDoc.data() as User;
      }

      if (!user || !otherUser) {
        return null;
      }

      return {
        ...message,
        card,
        user,
        otherUser,
      };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  doRefresh(event): void {
    this.establish();
    setTimeout(() => {
      event.target.complete();
    }, 1000);
  }

  private async subToRefreshes(): Promise<void> {
    this.refreshMailSubscription =
      this.messengerService.refreshMailSub.subscribe((d) => {
        if (d) {
          this.getMessages();
        }
      });
  }

  private async constructUserOrders(): Promise<void> {
    try {
      this.unsubscribeOrdersSnapshot();

      this.ordersSnapshot = onSnapshot(
        query(
          collection(
            this.firestore,
            'Users',
            String(this.authService.user.uid),
            'Orders'
          ),
          orderBy('createdDate', 'desc'),
          orderBy('shippedDate', 'desc')
        ),
        (docData) => {
          console.log('* Pulling Orders for user...');
          const orders = docData.docs.map((msg) => msg.data());

          console.log('Pulled Orders', orders);

          this.orders = orders;
          this.sellingOrderCount = orders.filter(
            (o) => o.status === 'pending'
          ).length;
        }
      );
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private async getUserProfile(id: string): Promise<any> {
    try {
      const userProfileDoc = doc(this.firestore, 'Users/' + id);
      const userProfile = await getDoc(userProfileDoc);
      return userProfile.data();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private async organizeArray(
    profiles: any[]
  ): Promise<Map<string, { cards: any[]; details: any[] }>> {
    try {
      const organized = new Map<string, { cards: any[]; details: any[] }>();
      for (const profile of profiles) {
        if (profile.ownedBy) {
          const details = await this.getUserProfile(profile.ownedBy);
          if (!organized.has(profile.ownedBy)) {
            organized.set(profile.ownedBy, {
              cards: [],
              details: [],
            });
          }
          organized.get(profile.ownedBy).cards.push(profile);
          organized.get(profile.ownedBy).details.push(details);
        }
      }

      return organized;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  private async pluckMyMessagingUsers(to: string, from: string): Promise<void> {
    try {
      const messagesDoc = doc(this.firestore, 'Messages', to);
      const messagesData = (await getDoc(messagesDoc)).data();
      const messagedUsers = messagesData?.messagedUsers;
      const updatedMessagedUsers = messagedUsers?.filter(
        (user) => user !== from
      );

      await setDoc(
        messagesDoc,
        {
          messagedUsers: updatedMessagedUsers,
        },
        {
          merge: true,
        }
      );
    } catch (error) {
      console.error(error);
    }
  }

  private watchForTabScroll(): void {
    this.pageScrollSubscription = this.authService.scrollPageSub.subscribe(
      (d) => {
        d && this.content.scrollToTop(400);
      }
    );
  }

  private async setupSwipeGesture(): Promise<void> {
    if (this.mailWrap) {
      this.gestureService.runBtmTabGestures(
        this.mailWrap.nativeElement,
        'mail'
      );
    } else {
      const checkForElement = () => {
        if (this.mailWrap) {
          this.gestureService.runBtmTabGestures(
            this.mailWrap.nativeElement,
            'mail'
          );
        } else {
          requestAnimationFrame(checkForElement);
        }
      };
      requestAnimationFrame(checkForElement);
    }
  }

  private async watchForInputChange(): Promise<void> {
    if (this.mailSearchInput) {
      const searchInput$ = fromEvent(
        await this.mailSearchInput.getInputElement(),
        'keyup'
      );

      const debouncedSearchInput$ = searchInput$.pipe(debounceTime(500));

      this.inputChangeSubscription = debouncedSearchInput$.subscribe((e) =>
        this.searchMail(e)
      );
    }
  }

  private searchMail(e): void {
    const term = e.target.value;

    this.messagingMe =
      term && term.length
        ? this.messagingMeHold.filter(
            (msg) =>
              (msg.card &&
                msg.card.name.toLowerCase().includes(term.toLowerCase())) ||
              (msg.otherUser
                ? msg.otherUser &&
                  msg.otherUser.username
                    .toLowerCase()
                    .includes(term.toLowerCase())
                : msg.user &&
                  msg.user.username.toLowerCase().includes(term.toLowerCase()))
          )
        : this.messagingMeHold;
  }

  clearSearch(): void {
    this.messagingMe = this.messagingMeHold;
    this.mailSearchInput.value = '';
  }

  hideKeyboard(): void {
    this.modalHelper.hideKeyboard();
  }

  private unsubscribeMessagingSubscriptions(): void {
    this.msgSub?.();
    this.messagingMeSub?.();
    this.messagingMeInnerSub?.();
  }

  private unsubscribeOrdersSnapshot(): void {
    this.ordersSnapshot?.();
  }
}
