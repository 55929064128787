import {
  Component,
  OnInit,
  NgZone,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

import { MapsAPILoader } from '@ng-maps/core';
import { Keyboard } from '@capacitor/keyboard';
import {
  collection,
  query,
  where,
  limit,
  Firestore,
  getDocs,
  DocumentReference,
  deleteDoc,
} from '@angular/fire/firestore';
import { PayService } from 'src/app/services/pay.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { User } from 'src/types';
import { ModalHelperService } from 'src/app/services/modal-helper.service';

import intlTelInput from 'intl-tel-input';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  @ViewChild('search') searchElementRef: ElementRef;
  @ViewChild('password', { static: false }) passwordInput: any;
  @ViewChild('confPassword', { static: false }) confPasswordInput: any;

  @ViewChild('username', { static: false }) usernameInput: any;
  @ViewChild('name', { static: false }) nameInput: any;
  @ViewChild('number', { static: false }) numberInput: any;
  @ViewChild('bio', { static: false }) bioInput: any;

  proposedUser: User;

  loginMode: boolean;
  signupMode: boolean;
  phoneMode: boolean;

  signingUp: boolean;

  secondSignupMode: boolean;
  keyBoardHere: boolean;
  oAuthMode: boolean;
  usernameValid: boolean;
  onDevice: boolean;
  onIOS: boolean;

  showPassword: boolean;
  showConfPassword: boolean;

  isLogginIn: boolean;

  affiliate: string;
  authCredential: string;

  currentPhoneNumber: number;
  storedVerificationNumber: number;
  inputtedVerificationNumber: string;

  thirtySecondRun = 30;
  txtCount = 0;

  storedDoc: DocumentReference<unknown>;

  user: any = {
    email: '',
    password: '',
    confirmPassword: '',
    username: '',
    name: '',
    bio: '',
    address: '',
    vaugeAddress: '',
    placeID: '',
    isRemoved: false,
  };

  iti: any;

  constructor(
    public authService: AuthService,
    public payService: PayService,
    public zone: NgZone,
    public firestore: Firestore,
    public messengerService: MessengerService,
    public mapsAPILoader: MapsAPILoader,
    public modalHelper: ModalHelperService
  ) {
    this.onDevice = this.modalHelper.isMobile;
    if (this.onDevice) {
      Keyboard.addListener(
        'keyboardWillShow',
        () => (this.keyBoardHere = true)
      );
      Keyboard.addListener(
        'keyboardWillHide',
        () => (this.keyBoardHere = false)
      );
    }

    if (this.modalHelper.isIOS) {
      this.onIOS = true;
    }
  }

  ngOnInit() {}

  async secondSignup() {
    if (this.user.email && this.user.password && this.user.confirmPassword) {
      if (
        this.user.password.length <= 5 ||
        this.user.password !== this.user.confirmPassword
      ) {
        this.modalHelper.createAlert({
          title: 'Something is wrong with your passwords.',
          message:
            'They must match & be atleast 6 characters. Please try again',
        });
      } else {
        // Show loading indicator
        await this.modalHelper.createLoader({
          message:
            'Just a few more steps to go before you can start using our platform. ' +
            'Hang tight while we collect the necessary information to get you set up.',
        });

        await this.modalHelper.setPreference('midLoggingIn', 'true');

        this.authService.signup(this.user).then(async () => {
          this.signingUp = true;
          this.secondSignupMode = true;

          setTimeout(async () => {
            this.findAddress();
            this.setupPhone();

            this.modalHelper.dismissLoader();
          }, 1000);
        });
      }
    } else {
      this.authService.triggerErrors();
    }
  }

  // getFlagIcon() {
  //   if (!this.phoneInput || !this.phoneInput.el) {
  //     return 'flag-icon flag-icon-us';
  //   }

  //   const countryCode = this.phoneInput.el?.value?.toLowerCase();
  //   return `flag-icon flag-icon-${countryCode}`;
  // }

  signupTrigger() {
    this.signupMode = true;
    this.loginMode = false;
    this.secondSignupMode = false;
  }

  async checkIfCanVerify() {
    this.authService.triggerErrors();

    if (this.user.username) {
      if (
        this.user.formattedAddress &&
        this.user.vaugeAddress &&
        this.user.placeID
      ) {
        this.verifyNumber();
      } else {
        this.modalHelper.createAlert({
          title: 'Invalid Address',
          message:
            'Something is wrong about the address you inputted. Please try again.',
        });
      }
    } else {
      this.modalHelper.createAlert({
        title: 'Invalid Username',
        message: 'Your username is invalid or unavailable. Please try again.',
      });
    }
  }

  async verifyNumber() {
    if (!this.iti || !this.iti.isValidNumber() || !this.iti.getNumber()) {
      return this.modalHelper.createAlert({
        title: 'Invalid Number',
      });
    }

    // 1 = INTERNATIONAL NUMBER
    this.user.phoneNumber = this.iti.getNumber(1);

    const sameNumber = await getDocs(
      query(
        collection(this.firestore, 'Users'),
        where('phoneNumber', '==', this.user.phoneNumber),
        where('isRemoved', '==', false)
      )
    );

    if (!sameNumber.size) {
      if (this.user.phoneNumber) {
        this.signupMode = false;
        this.triggerPhoneMode(this.user.phoneNumber);
      } else {
        this.authError('auth/invalid-phone-number');
      }
    } else {
      this.modalHelper.createAlert({
        title: 'Phone Number Already Exists',
        message: 'This phone number already has an account!',
        type: 'confirm',
        buttonTitle: 'Login',
        handler: () => {
          this.loginMode = true;
          this.signupMode = false;
        },
      });
    }
  }

  async login() {
    if (this.user.email && this.user.password) {
      await this.modalHelper.setPreference('midLoggingIn', 'true');

      await this.modalHelper.createLoader({
        message: 'Trying to log you in...',
      });

      this.authService.login(this.user).then(async () => {
        this.isLogginIn = true;
        this.proposedUser = (
          await getDocs(
            query(
              collection(this.firestore, 'Users'),
              where('email', '==', this.user.email),
              limit(1)
            )
          )
        ).docs.map((listing) => listing.data())[0] as User;

        await this.modalHelper.dismissLoader();

        if (this.proposedUser && this.proposedUser.uid) {
          this.secondSignupMode = true;
          this.loginMode = false;

          this.triggerPhoneMode(this.proposedUser.phoneNumber);
        } else {
          this.modalHelper.createAlert({
            title: 'User not found',
            message: 'We could not find a user with that email.',
          });
        }
      });
    } else {
      this.authService.triggerErrors();
    }
  }

  async backFromPhoneMode() {
    this.phoneMode = false;
    this.currentPhoneNumber = 0;

    await this.modalHelper.setPreference('midLoggingIn', 'false');

    if (this.authService.holdUser) {
      // Auth Login/Signup
      this.loginMode = false;
      this.secondSignupMode = false;
    } else {
      if (this.isLogginIn) {
        // Email login
        this.loginMode = true;
        this.secondSignupMode = false;
      } else {
        // Email Signup
        this.signupMode = true;
        this.secondSignupMode = true;
      }
    }
  }

  findAddress() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          componentRestrictions: { country: 'aus' },
        }
      );
      autocomplete.addListener('place_changed', () => {
        this.zone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (
            place.address_components[4].short_name &&
            place.address_components[5].short_name
          ) {
            this.user.formattedAddress = place.formatted_address;
            this.user.vaugeAddress =
              place.address_components[4].short_name +
              ', ' +
              place.address_components[5].short_name; // CITY + STATE
            this.user.placeID = place.place_id;
          } else {
            this.modalHelper.createAlert({
              title: 'Invalid Address',
              message:
                'Something is wrong about the address you inputted. Please try again.',
            });
          }
        });
      });
    });
  }

  setupPhone() {
    const input = document.querySelector('#phone-input');
    console.log(input);
    this.iti = intlTelInput(input, {
      initialCountry: 'au',
      preferredCountries: ['au', 'nz', 'us', 'gb'],
      separateDialCode: true,
      autoPlaceholder: 'polite',
      utilsScript: 'assets/utils.js',
    });
  }

  async forgotPassword() {
    if (!this.user.email) {
      this.modalHelper.createAlert({
        title: 'Can not send link',
        message: 'Type in your email first & try again',
      });
    }
    this.authService
      .forgotPassword(this.user.email)
      .then(
        async () =>
          this.modalHelper.createAlert({
            title: 'Everybody makes mistakes.',
            message:
              'We sent u an email with a link to make a new password bozo',
          }),
        async (rejectionReason) =>
          this.modalHelper.createAlert({
            title: 'Error sending password reset email',
            message: rejectionReason,
          })
      )
      .catch(async (e) =>
        this.modalHelper.createAlert({
          title: 'An error occurred while attempting to reset your password',
          message: e,
        })
      );
  }

  // connectWithFacebook() {
  //   this.authService
  //     .facebookConnect(this.isLogginIn)
  //     .then((d) => this.loadSecondStepForOAuth(d))
  //     .catch((e) => this.authError(e));
  // }

  async connectWithGoogle() {
    const res = await this.authService.loginWithGoogle();
    this.handleAuthResponse(res);
  }

  async connectWithFacebook() {
    const res = await this.authService.loginWithFacebook();
    this.handleAuthResponse(res);
  }

  async connectWithApple() {
    const res = await this.authService.loginWithApple();
    this.handleAuthResponse(res);
  }

  handleAuthResponse(res) {
    if (res === 'login') {
      this.authService.fulfillUser(this.user);
    } else if (res === 'phoneAuth') {
      this.loginMode = false;
      this.secondSignupMode = true;

      if (!this.phoneMode) {
        this.triggerPhoneMode(this.authService.holdUserData.phoneNumber);
      }
    } else if (res === 'signup') {
      this.signingUp = true;
      this.signUserUp();
    }
  }

  triggerPhoneMode(phNum) {
    this.phoneMode = true;
    this.currentPhoneNumber = phNum;

    console.log(phNum);

    this.messengerService
      .sendText(phNum)
      .then((d: any) => {
        this.storedVerificationNumber = d.verificationNumber;
        this.storedDoc = d.twilioDoc;

        const runner = setInterval(() => {
          if (this.thirtySecondRun === 0 || this.authService.user) {
            clearInterval(runner);
          } else {
            this.thirtySecondRun--;
          }
        }, 1000);
      })
      .catch((e) => this.authError(e));
  }

  async submitPhoneForm() {
    // Check if the stored verification number matches the inputted verification number
    if (
      Number(this.storedVerificationNumber) ===
      Number(this.inputtedVerificationNumber.replace(' ', ''))
    ) {
      // Delete Twilio Verification Doc
      await deleteDoc(this.storedDoc);

      // If signing up, save the user details
      if (this.signingUp) {
        await this.authService.saveUserDetails(this.user);
      }

      // Mark the auth process as complete
      this.authService.fulfillUser(this.proposedUser || this.user);
    } else {
      this.authError(
        'The SMS code you entered is incorrect. Please try again.'
      );
    }
  }

  setInputtedNumber(e) {
    let term = e.target.value;
    this.txtCount = term.replace(' ', '').length;

    if (this.txtCount >= 6) {
      term = term.slice(0, 3) + ' ' + term.slice(3, 6);
    }

    this.inputtedVerificationNumber = term;
  }

  signUserUp() {
    this.user.email = this.authService.holdUser.email;
    this.user.uid = this.authService.holdUser.uid;
    this.user.profilePic = this.authService.holdUser.photoURL || '';

    if (this.user && this.user.uid) {
      this.signupMode = true;
      this.secondSignupMode = true;

      setTimeout(async () => {
        this.findAddress();
        this.setupPhone();

        this.modalHelper.dismissLoader();
      }, 1000);
    }
  }

  async authError(error) {
    await error;
    if (error && error !== 'No Access Token') {
      this.modalHelper.createAlert({
        title: 'Error Signing Up',
        message: error,
      });
    }
  }

  usernameParser(event) {
    event.target.value = String(event.target.value)
      .toLowerCase()
      .replace(/\s/g, '');
    const regExp = new RegExp('^[A-Za-z0-9? ]+$');

    if (!regExp.test(event.target.value)) {
      event.target.value = event.target.value.slice(0, -1);
    }
  }

  async lookUpUsername(e) {
    const usersWithTheSameUsername = (
      await getDocs(
        query(
          collection(this.firestore, 'Users'),
          where('username', '==', e.target.value),
          limit(1)
        )
      )
    ).docs.map((listing) => listing.data());

    this.usernameValid = !usersWithTheSameUsername.length;

    if (usersWithTheSameUsername.length) {
      await this.modalHelper.createAlert({
        title: 'This username is already taken.',
        message: 'Please try again',
      });

      this.user.username = '';
    }
  }

  setFocus(element) {
    element.setFocus();
  }
  setInnerFocus(element) {
    element.el.nativeElement.getElementsByTagName('input')[0].focus();
  }

  async noSMSActions() {
    const buttons = [
      {
        text: 'Resend SMS',
        icon: 'refresh',
        handler: async () => this.reSendUserSMS(),
      },
      {
        text: 'Contact Support',
        icon: 'call',
        handler: () => this.messengerService.getHelp(),
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      },
    ];

    const selectedBtn = await this.modalHelper.createActionSheet({
      buttons,
    });
    buttons[selectedBtn].handler();
  }

  async reSendUserSMS() {
    const textRequest =
      Number(await this.modalHelper.getPreference('sms_requests')) || 0;

    if (textRequest >= 5) {
      this.modalHelper.createAlert({
        title: 'Too Many Attempts',
        message: 'Please contact support for help.',
      });
      return;
    }

    await this.modalHelper.setPreference(
      'sms_requests',
      String(textRequest + 1)
    );

    await this.messengerService
      .sendText(this.authService.holdUserData.phoneNumber)
      .then((d: any) => {
        this.storedVerificationNumber = d.verificationNumber;
        this.storedDoc = d.twilioDoc;
        this.thirtySecondRun = 30;
      });

    this.modalHelper.createAlert({
      title: 'SMS Resent',
    });
  }

  obsecurePhoneNumber(num) {
    return num.slice(0, 3) + ' *** *** ' + num.slice(12, 15);
  }
}
