// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.middle_sec {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 20%;
}
.middle_sec h3 {
  font-size: 16px;
}
.middle_sec p {
  margin-top: 5%;
  font-size: 12px;
}

.closeShippingIco {
  font-size: 18px;
  margin-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/app/shipping/shipping.page.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;AACJ;AAAI;EACI,eAAA;AAER;AAAI;EACI,cAAA;EACA,eAAA;AAER;;AAEA;EACI,eAAA;EACA,eAAA;AACJ","sourcesContent":[".middle_sec {\n    width: 80%;\n    margin-left: auto;\n    margin-right: auto;\n    text-align: center;\n    margin-top: 20%;\n    h3 {\n        font-size: 16px;\n    }\n    p {\n        margin-top: 5%;\n        font-size: 12px;\n    }\n}\n\n.closeShippingIco {\n    font-size: 18px;\n    margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
