// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card_searcher {
  padding-top: 10px;
}

.no_padding {
  padding-top: 50px !important;
}

.noResFound {
  padding: 80px;
  text-align: center;
  font-size: 20px;
  color: #f3caa9;
  font-weight: bold;
  text-transform: uppercase;
}

.section {
  padding-bottom: 10px;
}

.promo_tag_label {
  cursor: pointer;
}

.card {
  max-width: 45%;
}

ion-select {
  --placeholder-color: #ac8875;
  --placeholder-opacity: 1;
  width: 100%;
  justify-content: center;
  font-size: 10px;
  max-width: 55px;
  font-weight: bold;
}

ion-select::part(text) {
  color: #a68f71;
}

ion-select::part(icon) {
  color: #ac8875;
  height: 12px;
  width: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/search/search.page.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;AACJ;;AAEA;EACI,4BAAA;AACJ;;AAEA;EACI,aAAA;EAEA,kBAAA;EACA,eAAA;EACA,cAAA;EACA,iBAAA;EACA,yBAAA;AAAJ;;AAGA;EACI,oBAAA;AAAJ;;AAGA;EACI,eAAA;AAAJ;;AAGA;EACI,cAAA;AAAJ;;AAGA;EACI,4BAAA;EACA,wBAAA;EACA,WAAA;EACA,uBAAA;EACA,eAAA;EACA,eAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,cAAA;AAAJ;;AAGA;EACI,cAAA;EACA,YAAA;EACA,WAAA;AAAJ","sourcesContent":[".card_searcher {\n    padding-top: 10px;\n}\n\n.no_padding {\n    padding-top: 50px !important;\n}\n\n.noResFound {\n    padding: 80px;\n\n    text-align: center;\n    font-size: 20px;\n    color: #f3caa9;\n    font-weight: bold;\n    text-transform: uppercase;\n}\n\n.section {\n    padding-bottom: 10px;\n}\n\n.promo_tag_label {\n    cursor: pointer;\n}\n\n.card {\n    max-width: 45%;\n}\n\nion-select {\n    --placeholder-color: #ac8875;\n    --placeholder-opacity: 1;\n    width: 100%;\n    justify-content: center;\n    font-size: 10px;\n    max-width: 55px;\n    font-weight: bold;\n}\n\nion-select::part(text) {\n    color: #a68f71;\n}\n\nion-select::part(icon) {\n    color: #ac8875;\n    height: 12px;\n    width: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
