import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { FilterService } from 'src/app/services/filter.service';
import { ListingManagerService } from 'src/app/services/listing-manager.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { TcgControllerService } from 'src/app/services/tcg-controller.service';

@Component({
  selector: 'app-set-slide',
  templateUrl: './set-slide.component.html',
  styleUrls: ['./set-slide.component.scss'],
})
export class SetSlideComponent implements OnInit {
  loading = true;

  recentSets = [];

  constructor(
    public router: Router,
    public authService: AuthService,
    public filterService: FilterService,
    public modalHelper: ModalHelperService,
    public tcgController: TcgControllerService,
    public listingManagerService: ListingManagerService
  ) {}

  ngOnInit() {
    if (!this.authService.gotAuth) {
      this.authService.waitForAuth().then(() => this.pullSets());
    } else {
      this.pullSets();
    }
  }

  async pullSets() {
    this.loading = true;

    await this.tcgController.establish(this.modalHelper);

    this.recentSets = [];

    this.listingManagerService.games.forEach(async (game: any) => {
      game.innerSet = (
        await this.listingManagerService.pullMostRecentSet(game.tcgNum)
      ).sort((a, b) => b.modifiedOn - a.modifiedOn)[1];

      this.recentSets.push(game);
    });

    this.loading = false;
  }
}
