import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

import { Platform } from '@ionic/angular';

import {
  doc,
  Firestore,
  getDoc,
  setDoc,
  Timestamp,
} from '@angular/fire/firestore';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from '@angular/fire/storage';

import { AuthService } from '../services/auth.service';
import { ListingManagerService } from '../services/listing-manager.service';
import { TcgControllerService } from '../services/tcg-controller.service';

// import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
// import { Filesystem } from '@capacitor/filesystem';

import { decode } from 'base64-arraybuffer';
// import { PhotoViewer, capShowOptions } from '@capacitor-community/photoviewer';

import { ModalHelperService } from '../services/modal-helper.service';

// import { register } from 'swiper/element/bundle';

import { Listing } from 'src/types';
import { ProfileService } from '../services/profile.service';
import { ListingFormService } from './listing-form-service.service';

@Component({
  selector: 'app-listing-form',
  templateUrl: './listing-form.page.html',
  styleUrls: ['./listing-form.page.scss'],
})
export class ListingFormPage implements OnInit {
  card: any = [];
  tempImages: any = [];
  selectedCardPrices = [];

  marketPrice: number;
  recommendedMarketPrice: number;
  convertedMarketPrice: number;

  loader: HTMLIonLoadingElement;

  showCustomSubType: boolean;

  conditionTag = 'Lightly Played';
  saleTypeTag = 'Direct Sale';
  subTypeTag: string;
  setURL: string;
  setCleanName: string;

  uploadedLength = 0;
  listingType = 'selling';

  loading = true;
  isEditing = false;

  public items: Array<any>;

  constructor(
    public tcgController: TcgControllerService,
    public router: Router,
    public firestore: Firestore,
    public authService: AuthService,
    public listingManagerService: ListingManagerService,
    public modalHelper: ModalHelperService,
    public profileService: ProfileService,
    public lfs: ListingFormService,
    public sanitizer: DomSanitizer,
    public platform: Platform
  ) {}

  ngOnInit() {
    if (!this.authService.gotAuth) {
      this.authService.waitForAuth().then(() => this.establish());
    } else {
      this.establish();
    }
  }

  async ionViewDidLeave() {
    if (this.lfs.uploadingListing.name && !this.lfs.uploadingListing.tcgUrl) {
      await this.modalHelper.setPreference(
        'listing_form_hold',
        JSON.stringify(this.lfs.uploadingListing)
      );
    } else {
      this.listingManagerService.uploadingListingCard = [];
      this.listingManagerService.filledListingCard = [];
    }
  }

  async establish() {
    // Clear any existing user input data.
    await this.clearUserInputData();

    // Get the filled listing card from the listing manager service.
    const filledCard = this.listingManagerService.filledListingCard;

    // If a filled card is available...
    if (filledCard) {
      // Populate the card to be edited.
      await this.populateCardToEdit(filledCard);

      // Get the TCG subtypes for the card.
      this.getTCGSubtypes(filledCard.tcgProductID);
    } else {
      // Get the card that is currently being uploaded.
      const card = await this.listingManagerService.uploadingListingCard;

      // If a card is available...
      if (card) {
        // Set the card properties.
        this.card = card;
        this.lfs.isUpdating = false;

        // If the card has an image URL, add it to the user inputted card data.
        if (this.card.imageUrl) {
          this.lfs.uploadingListing.images.push(this.card.imageUrl);
        }

        // Set the TCG URL for the card in the user inputted card data.
        this.lfs.uploadingListing.tcgUrl = this.card.url || '';

        // If the card has a group, add it to the user inputted card data tags.
        if (this.card.group) {
          this.lfs.uploadingListing.tags.push(this.card.group.name);
        }

        // Get the TCG subtypes for the card.
        this.getTCGSubtypes(this.card.productId);

        // Check for a form hold in local storage.
        const formHold = JSON.parse(
          String(await this.modalHelper.getPreference('listing_form_hold'))
        );

        // If a form hold is available, use it to populate the user inputted card data.
        if (formHold) {
          this.lfs.uploadingListing = formHold;
        }

        this.isEditing = false;
      } else {
        // If no card is available, set the loading property to false.
        this.loading = false;
      }
    }
  }

  async getTCGSubtypes(productID) {
    // If no product ID is provided, return and set the loading property to false.
    if (!productID) {
      return (this.loading = false);
    }

    this.tcgController
      .getProductDetails(this.modalHelper, productID)
      .then((sets: any) => {
        console.log(sets);
        if (!sets[0]) {
          return;
        }
        this.setURL = sets[0].url;
        this.setCleanName = sets[0].name;
      });

    await this.tcgController.establish(this.modalHelper);

    console.log(productID);
    this.tcgController
      .getTCGPrices(productID, this.modalHelper)
      .then(async (data) => {
        console.log(data);

        if (!data.results) {
          return (this.loading = false);
        }

        // Filter the results to get only the cards with market prices.
        this.selectedCardPrices = await data.results.filter(
          (c) => c.marketPrice
        );

        // If there are any prices available, update the subtype.
        if (this.selectedCardPrices[0]) {
          this.updateSubType(null, this.selectedCardPrices[0].subTypeName);
        } else {
          await this.modalHelper.createToast({
            message: 'No pricing data found for this listing...',
            duration: 3000,
            position: 'bottom',
            color: 'danger',
          });

          this.updateSubType(null, 'other');
        }

        // Set the loading property to false.
        this.loading = false;
      })
      .catch(async (err) => {
        // If an error occurs, log it to the console and display a toast message.
        await this.modalHelper.createToast({
          message: 'Something went wrong in collecting this cards prices',
          position: 'bottom',
          duration: 2000,
        });

        console.log(err);
        this.loading = false;
      });
  }

  async populateCardToEdit(filledCard) {
    // Set the user inputted card data to the filled card.
    this.lfs.uploadingListing = filledCard;

    // Set the condition and sale type tags.
    this.conditionTag = this.card.condition;
    this.saleTypeTag = this.card.saleType;

    // If the card has a TCG product ID, set the card properties to the user inputted card data.
    if (this.lfs.uploadingListing.tcgProductID) {
      this.card = this.lfs.uploadingListing;
    }

    // If the card is showcasing, set the listing type to "showcasing".
    if (this.lfs.uploadingListing.isShowcasing) {
      this.listingType = 'showcasing';
    }

    // Set the filled card property if updating.
    this.lfs.isUpdating = this.listingManagerService.isUpdating;

    this.isEditing = true;
  }

  // async uploadPhoto() {
  //   if (this.modalHelper.isMobile) {
  //     const buttons = [
  //       {
  //         text: 'Take Picture',
  //         handler: () => {
  //           this.pickSingle();
  //         },
  //       },
  //       {
  //         text: 'Upload From Gallery',
  //         handler: () => {
  //           this.pickImages();
  //         },
  //       },
  //       {
  //         text: 'Cancel',
  //         icon: 'close',
  //         role: 'cancel',
  //         handler: () => {},
  //       },
  //     ];

  //     const selectedBtn = await this.modalHelper.createActionSheet({
  //       title: 'Select Image Source',
  //       buttons,
  //     });
  //     buttons[selectedBtn].handler();
  //   } else {
  //     this.pickSingle();
  //   }
  // }

  // async pickSingle() {
  //   await Camera.getPhoto({
  //     source: CameraSource.Camera,
  //     resultType: CameraResultType.Base64,
  //     presentationStyle: 'popover',
  //     allowEditing: false,
  //     quality: 20,
  //   })
  //     .then((image) => {
  //       this.lfs.uploadingListing.images.push(image.base64String);
  //     })
  //     .catch((e) => console.log(e));
  // }

  // async pickImages() {
  //   // Open the camera picker and set the presentation style and image quality.
  //   await Camera.pickImages({
  //     presentationStyle: 'popover',
  //     quality: 20,
  //   })
  //     .then(async (images) => {
  //       await this.modalHelper.createLoader({
  //         message: 'Compressing Images...',
  //       });

  //       // For each selected image...
  //       images.photos.forEach(async (photo) => {
  //         // Read the image file and add it to the user inputted card data.
  //         Filesystem.readFile({ path: photo.path }).then((res) => {
  //           this.lfs.uploadingListing.images.push(res.data);
  //         });
  //       });

  //       // Dismiss the loading indicator.
  //       this.modalHelper.dismissLoader();
  //     })
  //     .catch((e) => console.log(e));
  // }

  // This method updates the selected card subtype.
  // If the subtype is not "other", it updates the market price and recommended price
  // based on the selected subtype. If the subtype is "other", it shows the custom
  // subtype input field.
  updateSubType(e, defaultSubType) {
    // If an event object was passed, get the selected subtype from the event target's value.
    // Otherwise, use the default subtype passed as an argument.
    const subType = e ? e.target.value : defaultSubType;

    // If the selected subtype is not "other"...
    if (subType !== 'other') {
      // Set the flag to hide the custom subtype input field.
      this.showCustomSubType = false;

      // Loop through each selected card price.
      this.selectedCardPrices.forEach(async (card: any) => {
        // If the selected card's subtype matches the selected subtype...
        if (card.subTypeName === subType) {
          // Set the selected subtype on the user inputted card data.
          this.lfs.uploadingListing.subType = subType;
          // Get the market price for the selected subtype.
          this.marketPrice = Number(card.marketPrice || 0);

          // If a market price was found...
          if (this.marketPrice) {
            // Convert the market price from USD to AUD.
            const price = await this.convertUSDtoAUD(this.marketPrice);
            // Set the converted market price with two decimal places.
            this.convertedMarketPrice = Number(price.toFixed(2));

            // Calculate the recommended market price (10% higher than the converted market price).
            this.recommendedMarketPrice = Number(
              (this.convertedMarketPrice * 1.1).toFixed(2)
            );

            if (!this.isEditing) {
              // Set the recommended market price as the initial price for the user inputted card.
              this.lfs.uploadingListing.definedInitialPrice =
                this.recommendedMarketPrice;
            }
          }

          // Update the subtype tag for the selected card.
          this.ammendSubtypeTag(subType, true);
        }
      });
    } else {
      // If the selected subtype is "other", show the custom subtype input field.
      this.showCustomSubType = true;
    }
  }

  // This method converts the specified value from USD to AUD using the current
  // exchange rate from the "Currencies" document in the Firestore database.
  async convertUSDtoAUD(value) {
    // Get the "Currencies" document from the Firestore database.
    const listingDoc = doc(this.firestore, 'Admin', 'Currencies');
    // Get the data from the document.
    const d = (await getDoc(listingDoc)).data();
    // Calculate the converted value by multiplying the value by the USD to AUD exchange rate.
    const converted = value * d.USDtoAUD;

    // Return the converted value.
    return converted;
  }

  async addTagAlert() {
    this.modalHelper.createAlert({
      title: '+ New Tag',
      type: 'prompt',
      inputPlaceholder: 'Pack Fresh, Cute, Discount, etc.',
      handler: (tag) => this.lfs.uploadingListing.tags.push(tag),
    });
  }

  submitListing() {
    if (this.lfs.uploadingListing.isShowcasing) {
      this.executeListingSubmission();
      return;
    }

    this.modalHelper.createAlert({
      title: 'Submit Listing',
      message:
        'Are you sure you want to submit this listing? Doing so agrees to our terms of sales,' +
        ' which outlines that by submitting this listing you are legally required to have the item in your possession,' +
        ' to describe it as-is and to ship it to the buyer within 14 business days of purchase.' +
        ' You will not receive funds until a tracking code is submitted for this item post-purchase.' +
        ' If we verify the item was not shipped as described, or a few negative reviews pile up, you will be banned from the platform.',
      buttonTitle: 'Geez, Ok',
      type: 'confirm',
      handler: () => this.executeListingSubmission(),
    });
  }

  async executeListingSubmission() {
    const totalItemCost =
      this.lfs.uploadingListing.definedInitialPrice +
      this.lfs.uploadingListing.shippingPrice;

    const uploadLimit = await getDoc(
      doc(this.firestore, 'Admin', 'Controls')
    ).then((doc) => doc.data().uploadLimit);

    if (totalItemCost > uploadLimit) {
      return this.modalHelper.createAlert({
        title: 'Price Limit Exceeded',
        message:
          'Cardboard Ninja is still fairly new, so for safety purposes the total cost of the item and shipping cannot exceed $' +
          uploadLimit +
          '. We plan to remove this limit soon!',
        buttonTitle: 'Ok',
        type: 'alert',
      });
    }

    this.loader = await this.modalHelper.createLiveLoader({
      message: 'Uploading...',
    });

    await this.loader.present();

    this.lfs.uploadingListing.id = this.lfs.isUpdating
      ? this.lfs.uploadingListing.id
      : (Math.random() + 1).toString(36).substring(2);

    this.uploadImages();
  }

  async uploadImages() {
    this.uploadedLength = 0;
    this.tempImages = [];

    try {
      for (const img of this.lfs.uploadingListing.images) {
        if (
          img.includes('tcgplayer.com') ||
          img.includes('firebasestorage.googleapis.com')
        ) {
          this.uploadedLength++;
          this.tempImages.push(img);
        } else {
          const uploadedImage = await this.uploadIndividualPic(
            new Blob([new Uint8Array(decode(img))], {
              type: 'image/jpeg',
            })
          );
          this.tempImages.push(uploadedImage);
        }
      }

      this.uploadCard();
    } catch (error) {
      // Handle error
      console.error(error);
    }
  }

  async uploadIndividualPic(image) {
    return new Promise((resolve, reject) => {
      const filePath =
        'UserImages/' +
        this.authService.user.uid +
        '/Listings/' +
        this.lfs.uploadingListing.id +
        '/' +
        (Math.random() + 1).toString(36).substring(2);

      const storage = getStorage();
      const storageRef = ref(storage, filePath);

      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.loader.message =
            'Uploading... ' + Number(progress).toFixed(2) + '%';
        },
        (error) => {
          reject(error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log('Firebase storage image uploaded: ', downloadURL);
          this.uploadedLength++;
          resolve(downloadURL);
        }
      );
    });
  }

  async uploadCard() {
    // Check if card is showcasing
    const isShowcasing = this.lfs.uploadingListing.isShowcasing;

    // Set initial price and owner based on showcasing
    // this.lfs.uploadingListing.definedInitialPrice = isShowcasing ? 1 : 0;
    this.lfs.uploadingListing.ownedBy = isShowcasing
      ? this.lfs.uploadingListing.uploader
      : null;

    // Set status based on showcasing
    this.lfs.uploadingListing.status = isShowcasing ? 'sold' : 'selling';

    // Check if initial price is valid
    if (this.lfs.uploadingListing.definedInitialPrice < 0.1) {
      this.modalHelper.dismissLoader();

      this.uploadingCardError(
        'Upload Failed. Please check your initial price and try again. Must be above 10 cents.'
      );

      return;
    }

    // Organize inputted card data
    this.organiseInputtedCard();

    // Remove any empty fields
    Object.keys(this.lfs.uploadingListing).forEach((key) => {
      if (this.lfs.uploadingListing[key] === '') {
        delete this.lfs.uploadingListing[key];
      }
    });

    // Log card data
    console.log(
      'Uploading Card : ',
      this.lfs.uploadingListing,
      ' using: ',
      this.card
    );

    // Upload card to Firestore
    try {
      if (isShowcasing) {
        this.profileService.addOwnedCard(
          this.authService,
          this.lfs.uploadingListing.id,
          this.authService.user.uid,
          this.authService.user.ownedCards || []
        );
      } else {
        if (!this.lfs.isUpdating) {
          this.profileService.addListedCard(
            this.authService,
            this.lfs.uploadingListing.id,
            this.authService.user.uid,
            this.authService.user.listedCards || []
          );
        }
      }

      await setDoc(
        doc(this.firestore, 'Listings', String(this.lfs.uploadingListing.id)),
        this.lfs.uploadingListing,
        { merge: true }
      );

      await this.clearUserInputData();
      await this.modalHelper.dismissLoader();

      // Check if card is being uploaded for the first time or updated
      if (this.lfs.isUpdating) {
        this.openTradingCardUpdatedlert();
      } else {
        this.openTradingCardListingAlert();
      }
    } catch (e) {
      console.log(e);
      await this.modalHelper.createAlert({
        title: 'Upload Failed',
        message: e.error,
      });

      this.modalHelper.dismissLoader();
    }
  }

  organiseInputtedCard() {
    // Set default values for certain fields
    this.lfs.uploadingListing.uploader =
      this.lfs.uploadingListing.uploader || this.authService.user.uid;
    this.lfs.uploadingListing.uploaderUsername =
      this.lfs.uploadingListing.uploaderUsername ||
      this.authService.user.username;
    this.lfs.uploadingListing.game =
      this.listingManagerService.uploadingGame ||
      this.lfs.uploadingListing.game;
    this.lfs.uploadingListing.images = this.tempImages;
    this.lfs.uploadingListing.discountedPrice = null;

    this.lfs.uploadingListing.relatedTCGCards = [];

    // Set default values for certain fields based on card data from TCGPlayer
    this.lfs.uploadingListing.name =
      this.card.name || this.lfs.uploadingListing.name;
    this.lfs.uploadingListing.desc = this.lfs.uploadingListing.desc
      ? this.lfs.uploadingListing.desc.replace(/\r\n|\r|\n/g, '<br />')
      : '';
    if (this.card.productId || this.lfs.uploadingListing.tcgProductID) {
      this.lfs.uploadingListing.tcgMarketPrice =
        this.marketPrice ||
        this.lfs.uploadingListing.tcgMarketPrice ||
        this.lfs.uploadingListing.definedInitialPrice;
      this.lfs.uploadingListing.tcgProductID =
        this.card.productId || this.lfs.uploadingListing.tcgProductID;
      this.lfs.uploadingListing.tcgCategoryID =
        this.card.categoryId || this.lfs.uploadingListing.tcgCategoryID || '';
      this.lfs.uploadingListing.tcgGroupID =
        this.card.groupId || this.lfs.uploadingListing.tcgGroupID || '';
      this.lfs.uploadingListing.tcgExtendedData =
        this.card.extendedData ||
        this.lfs.uploadingListing.tcgExtendedData ||
        '';
    }

    this.lfs.uploadingListing.postDate =
      this.lfs.uploadingListing.postDate || Timestamp.now();

    // Filter tags
    this.lfs.uploadingListing.tags = this.lfs.uploadingListing.tags.filter(
      (t) => !t.selected
    );

    // Set default values for certain fields based on card group data from TCGPlayer
    if (this.card.group) {
      this.lfs.uploadingListing.groupName =
        this.card.group || this.card.group.name || '';
      this.lfs.uploadingListing.tcgGroupAbbrivation =
        this.lfs.uploadingListing.tcgGroupAbbrivation && this.card.group
          ? this.card.group.abbreviation
          : '';
    } else {
      this.lfs.uploadingListing.groupName =
        this.lfs.uploadingListing.groupName ||
        this.lfs.uploadingListing.set ||
        '';
    }

    // Set default initial price
    if (!this.lfs.uploadingListing.definedInitialPrice) {
      this.lfs.uploadingListing.definedInitialPrice = Number(
        ((this.marketPrice / 10) * 9).toFixed(2)
      );
    }
  }

  async openTradingCardListingAlert() {
    await this.modalHelper.createAlert({
      title: 'Listing Successful!',
      message:
        'Congratulations. You can view your cards for sale in the profile tab.',
      buttonTitle: 'Continue Listing',
    });

    await this.modalHelper.dismissModal();
  }

  async openTradingCardUpdatedlert() {
    await this.modalHelper.dismissModal();
    this.modalHelper.createAlert({
      title: 'Trading Card Updated!',
      message: 'Your trading card has been updated successfully.',
    });
  }

  killTag(i) {
    this.lfs.uploadingListing.tags.splice(i, 1);
  }

  async ammendConditonTag($event) {
    // store the new tag value
    const newTag = $event.target.value;

    // remove the old tag from the tags array
    const oldTagIndex = this.lfs.uploadingListing.tags.indexOf(
      this.conditionTag
    );
    if (oldTagIndex > -1) {
      this.lfs.uploadingListing.tags.splice(oldTagIndex, 1);
    }

    // update the conditionTag and add the new tag to the tags array
    this.conditionTag = newTag;
    if (!this.lfs.uploadingListing.tags.includes(newTag)) {
      this.lfs.uploadingListing.tags.push(newTag);
    }
  }

  async ammendSaletypeTag($event) {
    // store the new tag value
    const newTag = $event.target.value;

    // remove the old tag from the tags array
    const oldTagIndex = this.lfs.uploadingListing.tags.indexOf(
      this.saleTypeTag
    );
    if (oldTagIndex > -1) {
      this.lfs.uploadingListing.tags.splice(oldTagIndex, 1);
    }

    // update the saleTypeTag and add the new tag to the tags array
    this.saleTypeTag = newTag;
    if (!this.lfs.uploadingListing.tags.includes(newTag)) {
      this.lfs.uploadingListing.tags.push(newTag);
    }
  }

  async ammendSubtypeTag(tag, isText) {
    // store the new tag value
    const newTag = isText ? tag : tag.target.value;

    // remove the old tag from the tags array
    const oldTagIndex = this.lfs.uploadingListing.tags.indexOf(this.subTypeTag);
    if (oldTagIndex > -1) {
      this.lfs.uploadingListing.tags.splice(oldTagIndex, 1);
    }

    // update the subTypeTag and add the new tag to the tags array
    this.subTypeTag = newTag;

    // Push if tag is not already in the array
    if (!this.lfs.uploadingListing.tags.includes(newTag)) {
      this.lfs.uploadingListing.tags.push(newTag);
    }
  }

  unselectTag(selectingTag, i) {
    // check if the tag is selected or not
    if (selectingTag.selected) {
      // if it is selected, update the tag with its original value
      this.lfs.uploadingListing.tags[i] = selectingTag.tag;
    } else {
      // if it is not selected, create a new object with the tag and the selected flag
      const fakeObj = {
        tag: selectingTag,
        selected: true,
      };

      // update the tag with the new object
      this.lfs.uploadingListing.tags[i] = fakeObj;
    }
  }

  async clearAll() {
    this.modalHelper.createAlert({
      title: 'Wipe Form?',
      message: 'All images and data will be cleared.',
      type: 'confirm',
      buttonTitle: 'Clear',
      handler: async () => {
        await this.clearUserInputData();
        this.establish();
      },
    });
  }

  async clearUserInputData() {
    this.card = [];
    this.tempImages = [];
    this.lfs.clearListing();
    this.modalHelper.removePreference('listing_form_hold');
  }

  replaceDollars(event) {
    const pattern = /[0-9.,]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  sortBy(prop: string) {
    return this.lfs.uploadingListing.images.sort((a, b) => {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] === b[prop]) {
        return 0;
      } else {
        return -1;
      }
    });
  }

  // reOrderImages(oldIndex, newIndex) {
  //   // Get array of images
  //   const arr = this.lfs.uploadingListing.images;

  //   // Remove element at old index and insert at new index
  //   arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);

  //   // Update user inputted card data
  //   this.lfs.uploadingListing.images = arr;
  // }

  setListingType(e) {
    this.listingType = e.target.value;
    this.lfs.uploadingListing.isShowcasing = this.listingType === 'showcasing';
  }

  pinFormatter(value: number) {
    return `${value}`;
  }

  uploadingCardError(error) {
    this.modalHelper.createAlert({
      title: 'Something went wrong in uploader the user',
      message: error,
    });
  }

  async openCard(card) {
    this.modalHelper.triggerListing(card, card.owner);
  }
}
