import { Component, Input, OnInit } from '@angular/core';
import {
  setDoc,
  doc,
  Firestore,
  collection,
  getDocs,
} from '@angular/fire/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { MessengerService } from 'src/app/services/messenger.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { Message } from 'src/types';

@Component({
  selector: 'app-mail-card',
  templateUrl: './mail-card.component.html',
  styleUrls: ['./mail-card.component.scss'],
})
export class MailCardComponent implements OnInit {
  @Input() msg: Message;

  constructor(
    public authService: AuthService,
    public modalHelper: ModalHelperService,
    public messengerService: MessengerService,
    public firestore: Firestore
  ) {}

  ngOnInit() {}

  convertToDate(date) {
    return new Date(date * 1000);
  }

  archive(message) {
    this.modalHelper.createAlert({
      title: 'Archive',
      message: 'Are you sure you want to archive this conversation?',
      buttonTitle: 'Archive',
      type: 'confirm',
      handler: () => this.archiveMessages(message),
    });
  }

  async archiveMessages(message) {
    const messagePath =
      message.to === this.authService.user.uid
        ? `Messages/${message.to}/${message.from}`
        : `Messages/${message.from}/${message.to}`;

    try {
      const messages = (
        await getDocs(collection(this.firestore, messagePath))
      ).docs.map((doc) => doc.data());

      messages.forEach((msg) => {
        if (msg.context === message.context) {
          setDoc(
            doc(this.firestore, messagePath, msg.id),
            { archived: true },
            { merge: true }
          );
        }
      });

      this.messengerService.triggerMailRefresh();
    } catch (error) {
      console.error('Failed to archive message:', error);
    }
  }
}
