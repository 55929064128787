/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  collection,
  query,
  where,
  collectionData,
  Firestore,
  doc,
  getDoc,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Keyboard } from '@capacitor/keyboard';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ListingManagerService } from '../services/listing-manager.service';
import { ModalHelperService } from '../services/modal-helper.service';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.page.html',
  styleUrls: ['./tags.page.scss'],
})
export class TagsPage implements OnInit, OnDestroy {
  @Input() mySubject: BehaviorSubject<string>;

  tag: string;

  taggedCards: any = [];
  cleanTagged: any = [];

  relatedTagSub: Subscription;

  constructor(
    public firestore: Firestore,
    public router: Router,
    public modalHelper: ModalHelperService,
    public listingManager: ListingManagerService
  ) {}

  ngOnInit() {
    this.tag = this.mySubject.value;

    this.getRelatedTags();
  }

  ngOnDestroy() {
    this.relatedTagSub && this.relatedTagSub.unsubscribe();
  }

  getRelatedTags() {
    this.relatedTagSub && this.relatedTagSub.unsubscribe();

    const listingCollection = collection(this.firestore, 'Listings');
    const refQuery = query(
      listingCollection,
      where('tags', 'array-contains', this.tag),
      where('status', '==', 'selling')
    );

    const collData = collectionData(refQuery);

    this.relatedTagSub = collData.subscribe(
      (d) => {
        this.cleanTagged = d;
        this.getOwnersForTaggedCards(d);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  getOwnersForTaggedCards(cards) {
    const promises = cards.map(async (listing) => {
      const listingDoc = doc(this.firestore, 'Users', listing.uploader);
      listing.owner = (await getDoc(listingDoc)).data();
      return listing;
    });

    Promise.all(promises).then((updatedCards) => {
      this.taggedCards = updatedCards;
    });
  }

  openCard(card) {
    this.modalHelper.dismissModal();

    this.listingManager.cardHold = card;
    this.listingManager.ownerHold = card.owner;

    this.router.navigateByUrl('/home/listing/' + card.id);
  }

  searchTags(e) {
    const searchQ = e.target.value;
    if (searchQ) {
      this.taggedCards = this.cleanTagged.filter((c) =>
        c.name.toLowerCase().includes(searchQ.toLowerCase())
      );
    } else {
      this.taggedCards = this.cleanTagged;
    }
  }

  hideKeyboard() {
    this.modalHelper.hideKeyboard();
  }
}
