/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from '@angular/fire/firestore';
import {
  DocumentData,
  DocumentReference,
  Timestamp,
  writeBatch,
} from '@firebase/firestore';
import { IonContent, IonInfiniteScroll, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { ListingManagerService } from '../services/listing-manager.service';
import { ModalHelperService } from '../services/modal-helper.service';
import { TcgControllerService } from '../services/tcg-controller.service';

@Component({
  selector: 'app-search-category',
  templateUrl: './search-category.page.html',
  styleUrls: ['./search-category.page.scss'],
})
export class SearchCategoryPage implements OnInit {
  @Input() mySubject: BehaviorSubject<string>;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  @ViewChild('searchInput', { static: false }) searcher: {
    setFocus: () => void;
  };
  @ViewChild(IonContent, { static: false }) private content: IonContent;

  category: string;
  set: string;
  query: string;
  onTheFlyQuery: string;

  initalLoad: boolean;
  temporaryWithinSet: boolean;
  searchingTCG: boolean;
  showSetSearch: boolean;

  maxLengthBeforeShowHide = 200;

  quickSearchResults = [];
  sets = [];
  setsHold = [];

  constructor(
    public tcgController: TcgControllerService,
    public firestore: Firestore,
    public modalHelper: ModalHelperService,
    public listingManagerService: ListingManagerService
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.establish();
  }

  async establish(): Promise<void> {
    // Store the current value of the mySubject input
    const currentSubject = this.mySubject.value;
    // Check if the current category is the same as the current subject
    if (this.category !== currentSubject) {
      // Update the current category
      this.category = currentSubject;
      // Organize the current set
      this.organiseSet();
      // Check if the set data is already stored in local storage

      const setDocs = await getDocs(
        query(
          collection(this.firestore, 'Cards'),
          where('categoryId', '==', Number(this.set)),
          where('abbreviation', '!=', 'POP'),
          orderBy('abbreviation'),
          orderBy('publishedOn', 'desc')
        )
      );

      if (await this.modalHelper.getPreference('set-' + this.set)) {
        // Retrieve the set data from local storage
        const setColl = JSON.parse(
          String(await this.modalHelper.getPreference('set-' + this.set))
        );

        if (setColl.length !== setDocs.size) {
          this.retrieveSetFromFirebase(setDocs);
        } else {
          // Update the sets and setsHold properties with the data from local storage and sort by publishedOn
          this.sets = setColl.sort((a: any, b: any) => {
            if (a.publishedOn > b.publishedOn) {
              return -1;
            }
            if (a.publishedOn < b.publishedOn) {
              return 1;
            }
            return 0;
          });
          this.setsHold = this.sets;
        }
      } else {
        this.retrieveSetFromFirebase(setDocs);
      }
      // Clear the cards and selectedCardPrices properties
      this.tcgController.cards = [];
      this.tcgController.cardHold = [];
      this.tcgController.selectedCardPrices = [];
    }
    // Set focus on the searcher input after a delay of 700ms
    setTimeout(() => {
      if (this.searcher && this.searcher.setFocus) {
        this.searcher.setFocus();
      }
    }, 700);
  }

  retrieveSetFromFirebase(setDocs) {
    // Retrieve the set data from Firebase
    this.pullSetsFromFirebase(setDocs).then(async (sets: []) => {
      this.sets = sets.sort((a: any, b: any) => {
        if (a.publishedOn > b.publishedOn) {
          return -1;
        }
        if (a.publishedOn < b.publishedOn) {
          return 1;
        }
        return 0;
      });
      this.setsHold = this.sets;
      // Store the set data in local storage for future use
      await this.modalHelper.setPreference(
        'set-' + this.set,
        JSON.stringify(this.sets)
      );
    });
  }

  organiseSet() {
    this.listingManagerService.games.forEach((game) => {
      if (game.name === this.category) {
        this.set = String(game.tcgNum);
        this.pullSet(this.set);
      }
    });
  }

  autoSearchTCG(e: any): void {
    // Store the search query as a lowercase string
    const formattedQuery = String(e.target.value).toLowerCase();

    // Check if the query is empty or undefined
    if (!formattedQuery || !formattedQuery.length) {
      this.clearAutoTCG();
      return;
    }

    this.initalLoad = false;

    this.onTheFlyQuery = formattedQuery;

    // Check if the withinSet property is true
    // If withinSet is true, call the searchWithinSet() function with the query
    // Otherwise, call the searchAllProducts() function with the query
    this.listingManagerService.withinSet
      ? this.searchWithinSet(formattedQuery)
      : this.searchAllProducts(formattedQuery);
  }

  clearAutoTCG() {
    if (!this.listingManagerService.withinSet) {
      this.tcgController.offset = 0;
      this.tcgController.cardHold = [];
      this.tcgController.selectedCardPrices = [];
    }

    this.tcgController.cards = this.tcgController.cardHold;

    this.quickSearchResults = [];
    this.query = '';
  }

  searchAllProducts(value) {
    // Check if the search query is not empty
    if (value.length) {
      // Replace certain category names with their corresponding values
      let organisedCategory = '';

      //convert name to tcgName
      this.listingManagerService.games.forEach((game) => {
        if (game.name === this.category) {
          organisedCategory = game.tcgName;
        }
      });

      console.log(value);
      // Search for products using the tcgController and the organisedCategory and query
      this.tcgController.quickSearch(encodeURIComponent(value)).then(
        (data: any) =>
          // Filter the search results to only include products from the organisedCategory and exclude products with 'Code Card' in the name
          !this.searchingTCG &&
          (this.quickSearchResults = data.filter(
            (d) =>
              d['product-line-name'] === organisedCategory &&
              (!d['product-name'] || !d['product-name'].includes('Code Card'))
          ))
      );
    } else {
      this.clearAutoTCG();
    }
  }

  searchWithinSet(value) {
    if (this.temporaryWithinSet) {
      this.tcgController.cards = [];
      this.tcgController.cardHold = [];
      this.listingManagerService.withinSet = '';

      this.temporaryWithinSet = false;
    }

    // Filter the cardHold array using the search query
    this.tcgController.cards = this.tcgController.cardHold.filter(
      (card) =>
        (card.cleanName && card.cleanName.toLowerCase().includes(value)) ||
        (card.name && card.name.toLowerCase().includes(value))
    );
  }

  async pullSetsFromFirebase(setDocs) {
    // Create a new Promise that will be resolved with the sets data
    return new Promise(async (resolve) => {
      // Retrieve the sets data from Firebase using the getDocs() function and a query that filters by the
      // set property and orders by the groupId property
      const sets = setDocs.docs
        // Map the sets data to only include the data from each set
        .map((set) => set.data());
      // Resolve the Promise with the sets data
      resolve(sets);
    });
  }

  async pullGroupProducts(groupID, groupName) {
    this.initalLoad = true;

    await this.listingManagerService.pullGroupsProducts(
      groupID,
      groupName,
      this.tcgController,
      this.modalHelper
    );

    this.initalLoad = false;
    this.content.scrollToTop();
  }

  async pullSet(set: string): Promise<void> {
    const docRef = doc(this.firestore, 'Admin', 'TCG-Player-Dates');
    const docSnap = await getDoc(docRef);
    const lastPulledTimestamp = docSnap.data()[`lastUpdated-${set}`].toDate();
    const timeDiff = new Date().getTime() - lastPulledTimestamp.getTime();
    const daysSinceUpdated = timeDiff / (1000 * 3600 * 24);

    if (daysSinceUpdated > 1) {
      await this.actuallyPullSet(docRef, set);
    }
  }

  async actuallyPullSet(docRef: DocumentReference<DocumentData>, set: string) {
    const res = (await this.tcgController.listAllSets(
      Number(set),
      0,
      10
    )) as any;

    if (!res.results) {
      return;
    }

    await this.modalHelper.removePreference(`set-${set}`);

    const batch = writeBatch(this.firestore);
    for (const card of res.results as any) {
      const ref = doc(this.firestore, 'Cards', String(card.groupId));
      batch.set(ref, card, { merge: true });
    }
    await batch.commit();

    const now = new Timestamp(Date.now() / 1000, 0);

    await setDoc(docRef, { [`lastUpdated-${set}`]: now }, { merge: true });

    this.establish();
  }

  // async saveSet(groupID: string, sets: any[]) {
  //   // Filter out sets with names that include "Code" or empty names
  //   // const cleanSets = sets.filter((d) => !d.name.includes('Code') && d.name);

  //   // Concatenate the clean sets with the existing cards array,
  //   // then sort the resulting array by productId
  //   this.tcgController.cards = [...this.tcgController.cards, ...sets]
  //     .sort((a: any, b: any) => a.productId - b.productId)
  //     .filter((d) => !d.name.includes('Code') && d.name);
  //   this.tcgController.cardHold = this.tcgController.cards;

  //   // Save the clean sets data for the given group ID
  //   await this.saveSetData(groupID, sets);
  // }

  // async saveSetData(groupID: string, sets: any[]): Promise<void> {
  //   // Iterate over each card in the clean sets array

  //   for (const card of sets) {
  //     // If the card has a name and it does not include "Code"
  //     // if (
  //     //   card &&
  //     //   card.name &&
  //     //   card.productId &&
  //     //   card.imageUrl &&
  //     //   !card.name.includes('Code')
  //     // ) {
  //     // Select the desired properties from the card object
  //     const dataToSave = {
  //       name: card.name,
  //       productId: card.productId,
  //       imageUrl: card.imageUrl,
  //     };

  //     // Save the card data to the Firestore database
  //     await setDoc(
  //       // Construct the database path using the given group ID
  //       // and the card's productId
  //       doc(
  //         this.firestore,
  //         'Cards',
  //         String(groupID),
  //         'Products',
  //         String(card.productId)
  //       ),
  //       dataToSave,
  //       {
  //         merge: true,
  //       }
  //     );
  //     //}
  //   }
  // }

  // This function searches the sets array for sets with names
  // or abbreviations that match the given search term.
  searchSets(e: any) {
    // Get the search term from the event target's value
    const searchTerm = e.target.value.toLowerCase();

    // Filter the sets array to only include sets with names
    // or abbreviations that include the search term
    this.sets = this.setsHold.filter(
      (set) =>
        set.name.toLowerCase().includes(searchTerm) ||
        set.abbreviation.toLowerCase().includes(searchTerm)
    );
  }

  leaveSet() {
    this.tcgController.cards = [];
    this.tcgController.cardHold = [];
    this.listingManagerService.withinSet = '';
    this.query = '';

    this.sets = this.setsHold;
    this.content.scrollToTop();
  }

  selectCard(card) {
    this.quickSearchResults = [];

    if (card['product-name']) {
      this.query = card['product-name'];
      this.tcgController.getPrice(this.query, this.set, this.modalHelper);
    } else if (card['set-name']) {
      this.temporaryWithinSet = true;
      this.pullGroupProducts(
        this.pullGroupID(card['set-name']),
        card['set-name']
      );
    }
  }

  pullGroupID(setName) {
    return this.setsHold.filter((set) => set.name === setName)[0].groupId;
  }

  // This function searches the TCG database for cards
  // with names that match the given search query.
  async searchTCG(e: any) {
    if (this.modalHelper.isMobile) {
      this.modalHelper.hideKeyboard();
    }

    this.searchingTCG = true;

    // If the search is not limited to a specific set
    if (!this.listingManagerService.withinSet) {
      // If the search query is at least 2 characters long and a set is selected
      if (e.target[0].value.length >= 2 && this.set) {
        // Get the search query from the event target's value
        this.query = e.target[0].value;
        // Clear the cards and selected card prices arrays,
        // and the quick search results
        this.tcgController.offset = 0;
        this.tcgController.cards = [];
        this.tcgController.cardHold = [];
        this.tcgController.selectedCardPrices = [];

        // Search the TCG database for cards with names that match the query
        await this.tcgController.getPrice(
          this.query,
          this.set,
          this.modalHelper
        );
        this.quickSearchResults = [];

        // Wait a second before re-allowing quick search.
        setTimeout(() => (this.searchingTCG = false), 5000);
      }
    }
  }

  // This function loads additional data from the Firestore database
  // and updates the view when the user scrolls to the bottom of the page.
  async loadData(event: any) {
    try {
      await this.tcgController.getOffsetPlayerCards(this.modalHelper);
      event.target.complete();
    } catch (error) {
      console.log(alert(JSON.stringify(error)));
    }
  }

  hideKeyboard() {
    if (this.modalHelper.isMobile && !this.query.length) {
      this.modalHelper.hideKeyboard();
    }
  }

  async selectListing(card) {
    this.listingManagerService.filledListingCard = null;
    this.listingManagerService.uploadingListingCard = await card;

    this.modalHelper.triggerListingForm();
  }

  async openCustomListing() {
    this.modalHelper.triggerListingForm();
  }

  toggleSetSearcher() {
    this.showSetSearch = !this.showSetSearch;
  }

  shouldShowInfiniteScroll(): boolean {
    const { offset } = this.tcgController;
    return offset && offset % 20 === 0;
  }
}
