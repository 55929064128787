import { MapsAPILoader } from '@ng-maps/core';
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { setDoc, doc, Firestore } from '@angular/fire/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { PayService } from 'src/app/services/pay.service';
import { Keyboard } from '@capacitor/keyboard';
import { TcgControllerService } from 'src/app/services/tcg-controller.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';

@Component({
  selector: 'app-seller-register',
  templateUrl: './seller-register.component.html',
  styleUrls: ['./seller-register.component.scss'],
})
export class SellerRegisterComponent implements OnInit {
  @ViewChild('search') searchElementRef: ElementRef;
  @ViewChild('lastName', { static: false }) lastNameInput: any;
  @ViewChild('firstName', { static: false }) firstNameInput: any;

  formValue = {
    email: '',
    phoneNumber: {},
    legalFirstName: '',
    legalLastName: '',
    dob: Date.now(),
    stripeAccountID: '',
    stripePersonID: '',
    sellerVerified: true,
    address: {
      city: '',
      country: '',
      line1: '',
      line2: '',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      postal_code: '',
      state: '',
    },
  };
  form: FormGroup;

  verifiedInfo: {
    dob: string;
  };

  showForm: boolean;
  keyBoardHere: boolean;

  regStep = 1;

  constructor(
    public zone: NgZone,
    public mapsAPILoader: MapsAPILoader,
    public firestore: Firestore,

    public modalHelper: ModalHelperService,
    public payService: PayService,
    public authService: AuthService,

    public tcgController: TcgControllerService
  ) {}

  ngOnInit() {
    if (this.modalHelper.isMobile) {
      Keyboard.addListener(
        'keyboardWillShow',
        () => (this.keyBoardHere = true)
      );
      Keyboard.addListener(
        'keyboardWillHide',
        () => (this.keyBoardHere = false)
      );
    }
  }

  findAddress() {
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          componentRestrictions: { country: 'aus' },
        }
      );
      autocomplete.addListener('place_changed', () => {
        this.zone.run(() => {
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          place.address_components.forEach((comp) => {
            if (comp.types[0] === 'country') {
              this.formValue.address.country = comp.short_name;
            }
            if (comp.types[0] === 'postal_code') {
              this.formValue.address.postal_code = comp.long_name;
            }
            if (comp.types[0] === 'administrative_area_level_1') {
              this.formValue.address.state = comp.long_name;
            }
            if (comp.types[0] === 'administrative_area_level_2') {
              this.formValue.address.city = comp.short_name;
            }
          });

          this.formValue.address.line1 = place.name;
        });

        this.searchElementRef.nativeElement.focus();
      });
    });
  }

  showSellerForm() {
    this.showForm = true;

    setTimeout(() => this.setFocus(this.firstNameInput), 300);
  }

  loadNextStep() {
    if (this.formValue.legalFirstName && this.formValue.legalLastName) {
      this.regStep = 2;
      setTimeout(() => {
        this.findAddress();
      }, 1000);
    } else {
      this.authService.triggerErrors();
    }
  }

  goBack() {
    this.regStep = 1;
  }

  async verifyAccount() {
    if (this.formValue.address.country && this.formValue.dob) {
      const cleanDateObj = {
        day: String(this.formValue.dob).split('-')[2],
        month: String(this.formValue.dob).split('-')[1],
        year: String(this.formValue.dob).split('-')[0],
      };

      if (cleanDateObj.day && cleanDateObj.month && cleanDateObj.year) {
        await this.modalHelper.createLoader({
          message: 'Creating Seller Account...',
        });

        this.formValue.email = this.authService.user.email;
        this.formValue.phoneNumber = this.authService.user.phoneNumber;

        this.payService
          .createSellerAccount(this.formValue)
          .then(async (d: any) => {
            if (d.data) {
              this.formValue.stripePersonID = d.data.pers;
              this.formValue.stripeAccountID = d.data.acc;

              if (
                this.formValue.stripeAccountID &&
                this.formValue.stripePersonID
              ) {
                this.authService.user.legalFirstName =
                  this.formValue.legalFirstName;
                this.authService.user.legalLastName =
                  this.formValue.legalLastName;
                this.authService.user.dob = cleanDateObj;
                this.authService.user.address = this.formValue.address;
                this.authService.user.sellerVerified =
                  this.formValue.sellerVerified;

                this.authService.user.stripePersonID =
                  this.formValue.stripePersonID;
                this.authService.user.stripeAccountID =
                  this.formValue.stripeAccountID;

                this.formValue.phoneNumber = this.authService.user.phoneNumber;

                await setDoc(
                  doc(this.firestore, 'Users', this.authService.user.uid),
                  this.formValue,
                  {
                    merge: true,
                  }
                );

                await this.modalHelper.createAlert({
                  title: 'Seller account verified!',
                  message:
                    'Congratulations. You may now upload cards. We may ask for a photo of your ID & ' +
                    'your bank details if/when you have funds ready to directly collect from the platform.',
                });

                await this.modalHelper.dismissLoader();
                await this.modalHelper.dismissModal();

                await this.modalHelper.setPreference('tcg_access_token', null);
                this.authService.user.sellerVerified = true;

                this.tcgController.establish(this.modalHelper);
              } else {
                this.sellerIssue(d.data.raw.message);
              }
            } else {
              this.modalHelper.dismissLoader().then(() => {
                this.sellerIssue('Issue connecting details to Stripe :( ');
              });
            }
          })
          .catch((e) => {
            this.sellerIssue(JSON.stringify(e));
          });
      } else {
        this.sellerIssue(
          'Something went wrong with your address or date of birth! Please try again...'
        );
      }
    }
  }

  sellerIssue(issue) {
    this.modalHelper.dismissLoader().then(async () =>
      this.modalHelper.createAlert({
        title: 'Error Establishing Account',
        message: issue,
      })
    );
  }

  closeSellerRegister() {
    this.modalHelper.dismissModal();
  }

  setFocus(element) {
    element.setFocus();
  }
}
