import { Component, OnInit } from '@angular/core';
import { MessengerService } from '../services/messenger.service';
import { ModalHelperService } from '../services/modal-helper.service';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.page.html',
  styleUrls: ['./legal.page.scss'],
})
export class LegalPage implements OnInit {
  constructor(
    public messengerService: MessengerService,
    public modalHelper: ModalHelperService
  ) {}

  ngOnInit() {}
}
