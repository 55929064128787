import { Component, Input, OnInit } from '@angular/core';
import {
  collection,
  deleteDoc,
  doc,
  Firestore,
  getDoc,
  getDocs,
  query,
  writeBatch,
} from '@angular/fire/firestore';
import { updateDoc } from '@firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ModalHelperService } from '../services/modal-helper.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-recent-activity',
  templateUrl: './recent-activity.page.html',
  styleUrls: ['./recent-activity.page.scss'],
})
export class RecentActivityPage implements OnInit {
  @Input() mySubject: BehaviorSubject<[]>;
  activities: any = [];

  loading: boolean;

  constructor(
    public authService: AuthService,
    public userService: UserService,
    public modalHelper: ModalHelperService,
    public firestore: Firestore
  ) {}

  ngOnInit() {}

  async ionViewDidEnter() {
    this.pullActivities();
  }

  async pullActivities(): Promise<void> {
    // Set the loading state to true
    this.loading = true;

    // Sort the activities by date in descending order
    this.activities = this.mySubject
      ? this.mySubject.value.sort((a: any, b: any) =>
          a.date < b.date ? 1 : -1
        )
      : [];

    // Use a for-of loop to iterate over the array of activities
    for (const activity of this.activities) {
      try {
        // Get the details for the activity's sender or recipient
        const userSnapshot = await getDoc(
          doc(this.firestore, 'Users', String(activity.from))
        );
        activity.user = userSnapshot.data();

        // If the activity has a listing, get its details
        if (activity.listing) {
          const cardSnapshot = await getDoc(
            doc(this.firestore, 'Listings', String(activity.listing))
          );
          activity.card = cardSnapshot.data();
        }
      } catch (error) {
        // Catch and handle any errors
        console.error(error);
      }
    }

    // Set the loading state to false after all activities have been processed
    this.loading = false;
  }

  ionViewWillLeave() {
    this.setSeen();
  }

  closeActivity() {
    this.modalHelper.dismissModal();
  }

  clearSingleActivity(activityID, i) {
    deleteDoc(
      doc(
        this.firestore,
        'Users',
        String(this.authService.user.uid),
        'Activity',
        String(activityID)
      )
    );

    this.activities.splice(i, 1);
  }

  async clearAllActivities(): Promise<void> {
    // Use a batch write to delete all the activities in a single call to the Firestore database
    const batch = writeBatch(this.firestore);

    // Flag to track whether any errors occurred during the deletion process
    let errorOccurred = false;

    try {
      // Use for-await-of loop to iterate over the array of activities
      // Use the batch write to delete the activity from the Firestore database

      (
        await getDocs(
          query(
            collection(
              this.firestore,
              'Users',
              this.authService.user.uid,
              'Activity'
            )
          )
        )
      ).forEach(async (d) => batch.delete(d.ref));

      // Commit the batch write
      await batch.commit();

      // Clear the activities array
      this.activities = [];
    } catch (error) {
      // Set the error flag to true
      errorOccurred = true;
    }

    // If an error occurred, exit the loop early and perform the batch delete for the remaining activities
    if (errorOccurred) {
      return;
    }
  }

  async setSeen() {
    (
      await getDocs(
        query(
          collection(
            this.firestore,
            'Users',
            this.authService.user.uid,
            'Activity'
          )
        )
      )
    ).forEach(async (d) => {
      await updateDoc(d.ref, {
        hasSeen: true,
      });
    });

    this.activities.forEach((activity) => {
      activity.hasSeen = true;
    });

    this.userService.pullRecentActivites(this.authService.user.uid);
  }

  manageDate(date) {
    return new Date(date.seconds * 1000);
  }
}
