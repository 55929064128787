// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.review_title {
  color: #d2b094;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 3px;
}

.review_content {
  display: inline-flex;
  width: 100%;
  grid-gap: 15px;
  background: rgba(255, 243, 229, 0.6156862745);
}
.review_content img {
  min-width: 50px;
  max-width: 50px;
  object-fit: cover;
  margin-top: 5px;
  border-radius: 2px;
}

.stars {
  margin: 0;
  text-align: left;
}

h3 {
  text-align: center;
  font-size: 13px;
  margin-top: 10vh;
}

ion-button {
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px;
}

ion-avatar {
  width: 30px;
  height: 30px;
  margin: 10px;
}

ion-card ion-item:first-child {
  --background: #fbede2 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/review-cards/review-cards.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,oBAAA;EACA,WAAA;EACA,cAAA;EACA,6CAAA;AACJ;AACI;EACI,eAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;AACR;;AAGA;EACI,SAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,eAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,WAAA;EACA,YAAA;EACA,YAAA;AAAJ;;AAII;EACI,gCAAA;AADR","sourcesContent":[".review_title {\n    color: #d2b094;\n    font-size: 18px;\n    margin-top: 10px;\n    margin-bottom: 3px;\n}\n\n.review_content {\n    display: inline-flex;\n    width: 100%;\n    grid-gap: 15px;\n    background: #fff3e59d;\n\n    img {\n        min-width: 50px;\n        max-width: 50px;\n        object-fit: cover;\n        margin-top: 5px;\n        border-radius: 2px;\n    }\n}\n\n.stars {\n    margin: 0;\n    text-align: left;\n}\n\nh3 {\n    text-align: center;\n    font-size: 13px;\n    margin-top: 10vh;\n}\n\nion-button {\n    font-size: 12px;\n    padding-left: 10px;\n    padding-right: 10px;\n    border-radius: 4px;\n}\n\nion-avatar {\n    width: 30px;\n    height: 30px;\n    margin: 10px;\n}\n\nion-card {\n    ion-item:first-child {\n        --background: #fbede2 !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
