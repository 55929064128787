import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { ModalHelperService } from '../services/modal-helper.service';
import {
  Firestore,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from '@angular/fire/firestore';

@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.page.html',
  styleUrls: ['./receipts.page.scss'],
})
export class ReceiptsPage {
  loading = true;
  purchasedCards = [];

  constructor(
    public authService: AuthService,
    public firestore: Firestore,
    public modalHelper: ModalHelperService
  ) {
    this.buildReceipts();
  }

  // - Get all purchases made by a user
  // - Get all cards owned by a user
  // - Get all orders made by the uploader of each card
  // - Combine the cards and orders into a single array
  async buildReceipts() {
    try {
      const user = this.authService.user;
      const purchasedCards = await this.fetchPurchasedCards(user.ownedCards);

      const cleanPurchasedCards = purchasedCards.filter((card) => card);

      console.log(cleanPurchasedCards);

      const uploaderIdsSet = new Set(
        cleanPurchasedCards.map((card) => card.uploader)
      );

      const uploaderLookup = await this.fetchUploaderLookup(uploaderIdsSet);
      const orderDocs = await this.fetchOrderDocs(uploaderLookup);

      const orders = orderDocs.map((docs) =>
        docs.docs.map((orderDoc) => orderDoc.data())
      );

      const purchasedCardsWithDetails = this.combineOrdersWithCards(
        cleanPurchasedCards,
        orders,
        uploaderLookup
      );
      this.filterOwnedCards(
        purchasedCardsWithDetails,
        this.authService.user.uid
      );

      this.purchasedCards = purchasedCardsWithDetails;

      console.log(this.purchasedCards);

      this.loading = false;
    } catch (error) {
      console.error('Error pulling purchases:', error);
    }
  }

  async fetchPurchasedCards(ownedCardIds) {
    const purchasedCards = [];

    if (!ownedCardIds) return [];

    for (const cardId of ownedCardIds) {
      const cardData = (
        await getDoc(doc(this.firestore, 'Listings', String(cardId)))
      ).data();
      purchasedCards.push(cardData);
    }

    return purchasedCards;
  }

  async fetchUploaderLookup(uploaderIdsSet) {
    const uploaderDocs = await Promise.all(
      Array.from(uploaderIdsSet).map((uploaderId) =>
        getDoc(doc(this.firestore, 'Users', String(uploaderId)))
      )
    );

    const uploaderLookup = {};
    uploaderDocs.forEach((userDoc) => {
      uploaderLookup[userDoc.id] = userDoc.data();
    });

    return uploaderLookup;
  }

  async fetchOrderDocs(uploaderLookup) {
    const orderPromises = Object.keys(uploaderLookup).map((uploaderId) =>
      getDocs(
        query(
          collection(this.firestore, 'Users', uploaderId, 'Orders'),
          where('shippedTo', '==', this.authService.user.uid)
        )
      )
    );

    return await Promise.all(orderPromises);
  }

  combineOrdersWithCards(purchasedCards, orders, uploaderLookup) {
    return orders.flatMap((order) => {
      if (!order[0]) return [];
      const uploader = uploaderLookup[order[0].shippedFrom];

      return order.map((details) => ({
        details,
        uploader,
        cards: purchasedCards.filter((card) => details.cards.includes(card.id)),
      }));
    });
  }

  filterOwnedCards(purchasedCardsWithDetails, userId) {
    purchasedCardsWithDetails.forEach((order) => {
      order.cards = order.cards.filter((card) => card.ownedBy === userId);
    });
  }

  async doRefresh(event) {
    setTimeout(() => {
      event.target.complete();
    }, 1000);
  }

  manageDate(date) {
    return new Date(date.seconds * 1000);
  }

  price(amount) {
    return '$' + Number(amount || 0).toFixed(2);
  }

  generateTotalMinusGST(price) {
    // Return price minus 10%
    return this.price(price * 0.9);
  }

  generateGST(price) {
    // Return 10% of price
    return this.price(price * 0.1);
  }
}
