/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';

import {
  ApplePayEventsEnum,
  GooglePayEventsEnum,
  PaymentSheetEventsEnum,
  Stripe,
} from '@capacitor-community/stripe';

import { environment } from '../../environments/environment';
import {
  setDoc,
  doc,
  Firestore,
  updateDoc,
  getDoc,
  writeBatch,
} from '@angular/fire/firestore';
import { AuthService } from './auth.service';

import { PushService } from './push.service';
import { ModalHelperService } from './modal-helper.service';
import { Router } from '@angular/router';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { CartService } from './cart.service';
import { ProfileService } from './profile.service';

@Injectable({
  providedIn: 'root',
})
export class PayService {
  isApplePayAvailable = false;
  isGooglePayAvailable = false;

  bag: any;
  checkingOutOrder: any;

  uploadingOrder: boolean;
  paymentType: string;

  constructor(
    public authService: AuthService,
    public firestore: Firestore,
    private fns: Functions,
    public pushService: PushService,
    public router: Router,
    public modalHelper: ModalHelperService,
    public profileService: ProfileService,
    public cartService: CartService
  ) {}

  async establish() {
    Stripe.initialize({
      publishableKey: environment.stripeKey,
    })
      .then(() => {
        this.crazyStripeListeners();
      })
      .catch((e) => this.failedPaymentAlert(e, 'initializing'));
  }

  async crazyStripeListeners() {
    Stripe.isApplePayAvailable()
      .then(() => (this.isApplePayAvailable = true))
      .catch(() => (this.isGooglePayAvailable = false));
    Stripe.isGooglePayAvailable()
      .then(() => (this.isGooglePayAvailable = true))
      .catch(() => (this.isGooglePayAvailable = false));

    this.processListerners();
  }

  processListerners() {
    Stripe.addListener(PaymentSheetEventsEnum.Completed, () =>
      this.paymentComplete(false)
    );

    Stripe.addListener(
      ApplePayEventsEnum.Loaded,
      () => (this.isApplePayAvailable = true)
    );

    Stripe.addListener(
      ApplePayEventsEnum.FailedToLoad,
      () => (this.isApplePayAvailable = false)
    );

    Stripe.addListener(ApplePayEventsEnum.Completed, () =>
      this.paymentComplete(false)
    );

    Stripe.addListener(GooglePayEventsEnum.Loaded, () => {
      this.isGooglePayAvailable = true;
    });

    Stripe.addListener(
      GooglePayEventsEnum.FailedToLoad,
      () => (this.isGooglePayAvailable = false)
    );

    Stripe.addListener(GooglePayEventsEnum.Completed, () =>
      this.paymentComplete(false)
    );
  }

  async makePayment(order) {
    this.modalHelper.createLoader({
      message: 'Processing payment...',
    });

    this.checkingOutOrder = order;

    // Ensure card isnt locked
    httpsCallable(
      this.fns,
      'purchaseChecker-checkAndLock'
    )({
      cards: order.cards,
    })
      .then((res: any) => {
        this.modalHelper.dismissLoader();

        res.data
          ? this.buildPaymentSheet(order)
          : this.modalHelper.createAlert({
              title: 'Card is no longer available.',
              message:
                'The card you are trying to purchase is no longer available. Please try again. If it seems like you can\'t buy it, somebody may have beaten you to it :( ',
            });
      })
      .catch((e) => {
        this.modalHelper.dismissLoader();
        console.log(e);
      });
  }

  async buildPaymentSheet(order) {
    const buttons = [];

    buttons.push({
      text: 'Credit/Debit Pay',
      handler: () =>
        this.createPaymentSheet(order.stripeAccountID, order.totalPrice),
    });

    if (this.modalHelper.isIOS && this.isApplePayAvailable) {
      buttons.push({
        text: 'Apple Pay',
        handler: () =>
          this.createApplePay(
            order.stripeAccountID,
            order.totalPrice,
            order.username
          ),
      });
    }

    if (this.modalHelper.isAndroid && this.isGooglePayAvailable) {
      buttons.push({
        text: 'Google Pay',
        handler: () =>
          this.createGooglePay(
            order.stripeAccountID,
            order.totalPrice,
            order.username
          ),
      });
    }

    if (this.authService.user && this.authService.user.sellerVerified) {
      buttons.push({
        text: 'Collected Funds',
        handler: () =>
          this.payWithCollectedFunds(order.stripeAccountID, order.totalPrice),
      });
    }

    if (this.authService.user && this.authService.user.isAdmin) {
      buttons.push({
        text: 'Insta-Buy',
        handler: () => {
          this.paymentType = 'instaBuy';
          this.paymentComplete(false);
        },
      });
    }

    buttons.push({
      text: 'Cancel',
      icon: 'close',
      role: 'cancel',
      handler: async () => this.modalHelper.dismissLoader()
    });

    const selectedBtn = await this.modalHelper.createActionSheet({
      title:
        'Select payment method for the $' +
        order.totalPrice.toFixed(2) +
        ' order shipping to ' +
        order.formattedAddress,
      buttons,
    });

    await this.releaseLock(order.cards);

    buttons[selectedBtn].handler();
  }

  async releaseLock(cards) {
    const batch = writeBatch(this.firestore);

    cards.forEach((card) => {
      const cardDocRef = doc(this.firestore, 'Listings', String(card.id));
      batch.update(cardDocRef, { paymentLocked: false });
    });

    await batch.commit();
  }

  async createPaymentSheet(sellerID, paymentAmount) {
    this.modalHelper.createLoader({});

    this.preparePayment(paymentAmount.toFixed(2), sellerID)
      .then((data: any) => {
        this.modalHelper.dismissLoader();
        this.paymentType = 'Credit Card';

        Stripe.createPaymentSheet({
          paymentIntentClientSecret: data.paymentIntent,
          customerEphemeralKeySecret: data.ephemeralKey,
          customerId: data.customer,
          merchantDisplayName: 'Cardboard Ninja',
        })
          .then(() => {
            Stripe.presentPaymentSheet().catch((e) =>
              this.failedPaymentAlert(e, 'presenting')
            );
          })
          .catch((e) => this.failedPaymentAlert(e, 'creating'));
      })
      .catch((e) => this.failedPaymentAlert(e, 'preparing'));
  }

  async failedPaymentAlert(e, type: string) {

    await this.modalHelper.dismissLoader();

    this.modalHelper.createAlert({
      title:
        'Something went wrong in ' +
        type +
        ' your payment.. please try again shortly or contact us...',
      message: e,
    });
  }

  async createApplePay(sellerID, paymentAmount, username) {
    this.preparePayment(paymentAmount, sellerID)
      .then(async (data: any) => {
        this.modalHelper.dismissLoader();
        this.paymentType = 'Apple Pay';

        Stripe.createApplePay({
          paymentIntentClientSecret: data.paymentIntent,
          paymentSummaryItems: [
            {
              label: '@' + username,
              amount: Number(
                paymentAmount.toLocaleString('en-US', {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
                })
              ),
            },
          ],
          merchantIdentifier: 'merchant.williamfischer.cardboardninja',
          countryCode: 'AU',
          currency: 'AUD',
        })
          .then(() =>
            Stripe.presentApplePay().catch((e) =>
              this.failedPaymentAlert(e, 'creating')
            )
          )
          .catch((e) => this.failedPaymentAlert(e, 'creating'));
      })
      .catch((e) => this.failedPaymentAlert(e, 'preparing'));
  }

  async createGooglePay(sellerID, paymentAmount, username) {
    this.preparePayment(paymentAmount, sellerID)
      .then((data: any) => {
        this.modalHelper.dismissLoader();
        this.paymentType = 'Google Pay';

        Stripe.createGooglePay({
          paymentIntentClientSecret: data.paymentIntent,
          paymentSummaryItems: [
            {
              label: '@' + username,
              amount: paymentAmount,
            },
          ],
          merchantIdentifier: 'merchant.williamfischer.cardboardninja',
          countryCode: 'AU',
          currency: 'AUD',
        })
          .then(() =>
            Stripe.presentGooglePay().catch((e) =>
              this.failedPaymentAlert(e, 'preseting')
            )
          )
          .catch((e) => this.failedPaymentAlert(e, 'creating'));
      })
      .catch((e) => this.failedPaymentAlert(e, 'preparing'));
  }

  async preparePayment(paymentAmount, sellerID) {
    await this.modalHelper.createLoader({});

    return new Promise(async (resolve, reject) => {
      if (paymentAmount < 0.5) {
        await this.modalHelper.createAlert({
          title: 'Minimum $0.50',
          message:
            'You must spend at least $0.50 with this seller to purchase this card through Cardboard Ninja',
        });

        return this.modalHelper.dismissLoader();
      } else {
        this.manageCustomerAccount()
          .then(async (customerAccountID) => {
            const amount = (paymentAmount * 100).toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            });

            const feeAmount = Math.round(
              Number((paymentAmount / 10) * 0.3 * 100)
            );

            const newPayment = httpsCallable(this.fns, 'stripe-signPayment');

            await newPayment({
              currency: 'aud',
              stripeSellerID: sellerID,
              stripeCustomerID: customerAccountID,
              amount,
              feeAmount,
            })
              .then(async (e) => {
                const payment = e.data;

                if (!payment['raw']) {
                  await setDoc(
                    doc(
                      this.firestore,
                      'Users',
                      String(this.authService.user.uid)
                    ),
                    {
                      paymentIntentID: payment['paymentIntent'],
                    },
                    {
                      merge: true,
                    }
                  );

                  this.authService.user.paymentIntentID =
                    payment['paymentIntent'];

                  resolve(payment);
                } else {
                  reject(payment['raw'].message);
                }
              })
              .catch((e) => reject(e));
          })
          .catch((e) => {
            reject(e);
          });
      }
    });
  }

  manageCustomerAccount() {
    return new Promise(async (resolve, reject) => {
      if (this.authService.user && this.authService.user.uid) {
        const user = this.authService.user;

        const customerObject = {
          email: user.email,
          phone: user.phoneNumber.replace(/ /g, ''),
          name: user.name,
          address: user.address || '',
        };

        if (user.customerAccountID) {
          resolve(user.customerAccountID);
        } else {
          const newCustomer = httpsCallable(this.fns, 'stripe-createCustomer');
          newCustomer(customerObject)
            .then(async (customer: any): Promise<void> => {
              user.customerAccountID = customer.data.id;

              await setDoc(
                doc(this.firestore, 'Users', String(this.authService.user.uid)),
                {
                  customerAccountID: user.customerAccountID,
                },
                {
                  merge: true,
                }
              );

              this.authService.user.customerAccountID = user.customerAccountID;
              resolve(user.customerAccountID);
            })
            .catch((e) => reject(e));
        }
      } else {
        reject('Not Authed');
      }
    });
  }

  async createSellerAccount(usr) {
    return new Promise((resolve, reject) => {
      this.postSellerAccount(usr, '202.87.166.71')
        .then((d) => resolve(d))
        .catch((e) => reject(e));
    });
  }

  async postSellerAccount(user, ip_address) {
    return new Promise(async (resolve, reject) => {
      const cleanDateObj = {
        day: String(user.dob).split('-')[2],
        month: String(user.dob).split('-')[1],
        year: String(user.dob).split('-')[0],
      };

      const accountData = {
        email: user.email,
        firstName: user.legalFirstName,
        lastName: user.legalLastName,
        address: user.address,
        dob: cleanDateObj,
        phone: user.phoneNumber,
        ip: ip_address,
      };

      const newSellerAccount = httpsCallable(
        this.fns,
        'stripe-createSellerAccount'
      );
      newSellerAccount(accountData)
        .then((data: any) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  }

  async uploadIdentityPhoto(formData) {
    return new Promise(async (resolve, reject) => {
      // const httpOptions = {
      //   headers: new HttpHeaders({
      //     enctype: 'multipart/form-data;',
      //     Authorization: 'Bearer ' + environment.stripeKey,
      //   }),
      // };
      // await this.http
      //   .post('https://files.stripe.com/v1/files', formData, httpOptions)
      //   .toPromise(Promise)
      //   .then((data) => {
      //     resolve(data);
      //   })
      //   .catch((err) => {
      //     reject(err);
      //   });
    });
  }

  connectIdentityDocument(account, person, front_document, back_document) {
    console.log(account, document);
    return new Promise(async (resolve, reject) => {
      await this.modalHelper.createLoader({});

      const newIdentityDocument = httpsCallable(
        this.fns,
        'stripe-connectIdentityDocument'
      );
      newIdentityDocument({ account, person, front_document, back_document })
        .then(async (data: any): Promise<void> => {
          this.modalHelper.dismissLoader().then(async () => {
            resolve(data);
          });
        })
        .catch((error) => {
          this.modalHelper.dismissLoader().then(async () => {
            reject(error);
          });
        });
    });
  }

  makePaymentTransferable(intentID) {
    return new Promise(async (resolve, reject) => {
      const confirmPaymentTranser = httpsCallable(
        this.fns,
        'stripe-confirmPaymentTransacation'
      );
      confirmPaymentTranser({ intentID })
        .then(async (data: any): Promise<void> => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  makeCollectedFundPaymentTransferable(amount, destination) {
    return new Promise(async (resolve, reject) => {
      const confirmCollectedFundsPaymentTranser = httpsCallable(
        this.fns,
        'stripe-confirmCollectedFundTransacation'
      );
      confirmCollectedFundsPaymentTranser({ amount, destination })
        .then(async (data: any): Promise<void> => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async clearConnectedAccounts() {
    if (
      confirm(
        'Clear all Stripe accounts & customers that dont have money?? You really shouldnt be doing this... are you very sure? ' +
          'Even if an error procceds this.. check Stripe..'
      )
    ) {
      await this.modalHelper.createLoader({
        message: 'Clearing Seller Accounts...',
      });

      const clearConnectedAccounts = httpsCallable(
        this.fns,
        'stripe-clearConnectedAccounts'
      );
      clearConnectedAccounts()
        .then(async () => {
          this.modalHelper.dismissLoader().then(async () =>
            this.modalHelper.createAlert({
              title: 'Success',
              message: 'Cleared Stripe Accounts',
            })
          );
        })
        .catch((error) => {
          this.modalHelper.dismissLoader();
          this.failedPaymentAlert(error, 'clearing');
        });
    }
  }

  getBankToken(bankAccount) {
    return new Promise(async (resolve, reject) => {
      await this.modalHelper.createLoader({
        message: 'Registering Bank Account...',
      });

      const newBankAccount = httpsCallable(
        this.fns,
        'stripe-createBankAccount'
      );
      newBankAccount(bankAccount)
        .then(async (data: any) => {
          this.modalHelper.dismissLoader().then(async () => {
            resolve(data.data.id);
          });
        })
        .catch((error) => {
          this.modalHelper.dismissLoader().then(async () => {
            reject(error);
          });
        });
    });
  }

  makeTransfer(transferVal, accountID) {
    return new Promise(async (resolve, reject) => {
      await this.modalHelper.createLoader({
        message: 'Requesting Transfer...',
      });

      const convertedTransferVal = transferVal * 100;

      const makeTransfer = httpsCallable(this.fns, 'stripe-makeTransfer');
      makeTransfer({ amount: convertedTransferVal, account: accountID })
        .then(async (data: any) => {
          this.modalHelper.dismissLoader().then(async () => {
            resolve(data.data);
          });
        })
        .catch((error) => {
          this.modalHelper.dismissLoader().then(async () => {
            reject(error);
          });
        });
    });
  }

  getPayouts(acc) {
    return new Promise(async (resolve, reject) => {
      const getPayouts = httpsCallable(this.fns, 'stripe-getPayouts');
      getPayouts({ account: acc })
        .then((data: any) => {
          resolve(data.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  connectBankToAccount(account_num, token) {
    return new Promise(async (resolve, reject) => {
      await this.modalHelper.createLoader({
        message: 'Connecting Bank Account...',
      });

      const bankAccount = httpsCallable(this.fns, 'stripe-connectBankAccount');
      bankAccount({ account: account_num, external_account: token })
        .then(async (data: any) => {
          this.modalHelper.dismissLoader().then(async () => {
            resolve(data);
          });
        })
        .catch((error) => {
          this.modalHelper.dismissLoader().then(async () => {
            reject(error);
          });
        });
    });
  }

  getBalance(acc) {
    return new Promise((resolve, reject) => {
      const getUsrBalance = httpsCallable(this.fns, 'stripe-getBalance');
      getUsrBalance({ account: acc })
        .then((data: any) => {
          resolve(data.data);
        })
        .catch((error) => {
          this.modalHelper.dismissLoader();
          reject(error);
        });
    });
  }

  getAccount(acc) {
    return new Promise((resolve, reject) => {
      const getUsrAccount = httpsCallable(this.fns, 'stripe-getAccount');
      getUsrAccount({ account: acc })
        .then((data: any) => {
          this.modalHelper.dismissLoader();
          resolve(data.data);
        })
        .catch((error) => {
          this.modalHelper.dismissLoader();
          reject(error);
        });
    });
  }

  getCustomerTransactions(customerID) {
    return new Promise((resolve, reject) => {
      const getCustomerTransactions = httpsCallable(
        this.fns,
        'stripe-getCustomerTransactions'
      );
      getCustomerTransactions({ customer: customerID })
        .then((data: any) => resolve(data.data))
        .catch((error) => reject(error));
    });
  }

  moveAffiliatedFunds() {
    this.modalHelper.createAlert({
      title: 'Coming Soon',
      message: 'This feature is coming soon',
    });
  }

  async paymentComplete(isCollectedFunds) {
    await this.processCompletedOrder(isCollectedFunds);

    await this.cartService.pullCardsForCart();

    await this.modalHelper.createAlert({
      title: 'Payment Successful',
      message:
        'You will receive a notification when the user has shipped your package.',
      buttonTitle: 'Go Home',

      // buttons: [
      // {
      //   text: 'View Profile',
      //   id: 'cancel-button',
      //   handler: () => {
      //     if (this.authService.user) {
      //       this.modalHelper.dismissLoader();
      //       this.modalHelper.dismissModal();

      //       this.router.navigateByUrl('/profile');
      //     } else {
      //       this.authService.notAuthedPopup();
      //     }
      //   },
      // },
      // {
      //   text: 'Message Seller',
      //   id: 'confirm-button',
      //   handler: () => {
      //     if (this.authService.user) {
      //       this.cartService.openMessenger(
      //         this.checkingOutOrder,
      //         this.checkingOutOrder.cards[0]
      //       );
      //     } else {
      //       this.authService.notAuthedPopup();
      //     }
      //   },
      // },
      // {
      //   text: 'Go Home',
      //   id: 'confirm-button',
      //   handler: () => {
      //     this.modalHelper.dismissLoader();
      //     this.modalHelper.dismissModal();
      //     this.router.navigateByUrl('/');
      //   },
      // },
      // ],
    });

    this.modalHelper.dismissLoader();
    this.modalHelper.dismissModal();
    this.router.navigateByUrl('/');
  }

  async processCompletedOrder(isCollectedFunds: boolean) {
    try {
      await this.uploadOrder();

      const userCards = this.authService.user.cart;

      for (const card of this.checkingOutOrder.cards) {
        const index = userCards.findIndex(
          (cartCard) => cartCard.id === card.id
        );
        if (index !== -1) {
          userCards.splice(index, 1);
        }

        const listingData = {
          status: 'pending',
          pendingDate: Date.now(),
          ownedBy: this.authService.user.uid,
          ownedByUsername: this.authService.user.username,
          isCollectedFunds,
        };

        await setDoc(
          doc(this.firestore, 'Listings', String(card.id)),
          listingData,
          { merge: true }
        );

        this.profileService.addOwnedCard(
          this.authService,
          card.id,
          this.authService.user.uid,
          this.authService.user.ownedCards || []
        );

        await this.cartService.removeSingleItemFromBag(card);

        this.pushService.send(
          this.checkingOutOrder.fcmToken,
          '@' + this.authService.user.username,
          'Just purchased ' + card.name + ' 🎉🎉',
          '/profile',
          this.checkingOutOrder.uid,
          {
            type: 'purchase',
            from: this.authService.user.uid,
            listing: card.id,
          },
          null
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  async uploadOrder() {
    // Add a check to make sure this function is only called once
    if (this.uploadingOrder) {
      return;
    }

    this.uploadingOrder = true;

    try {
      const cardArr = [];

      if (this.checkingOutOrder.uid) {
        this.checkingOutOrder.cards.forEach((card) => cardArr.push(card.id));

        const orderID = (Math.random() + 1).toString(36).substring(2);
        const totalPrice = Number(this.checkingOutOrder.totalPrice.toFixed(2));
        const feeAmount = Number(((totalPrice / 100) * 0.3).toFixed(2));

        console.log('order', this.checkingOutOrder);

        await setDoc(
          doc(
            this.firestore,
            'Users',
            String(this.checkingOutOrder.uid),
            'Orders',
            String(orderID)
          ),
          {
            id: orderID,
            status: 'pending',
            createdDate: new Date(),
            shippedDate: 0,
            cards: cardArr,
            shippedTo: this.authService.user.uid,
            shippedFrom: this.checkingOutOrder.uid,
            cost: Number(this.checkingOutOrder.priceWithOutShipping),
            shippingPrice: Number(this.checkingOutOrder.shippingPrice),
            address: this.authService.user.address,
            buyerPhNum: this.checkingOutOrder.phoneNumber,
            paymentType: this.paymentType,
            totalPrice,
            feeAmount,
          },
          {
            merge: true,
          }
        );
      } else {
        this.failedPaymentAlert(
          'Cannot upload order! ID not found!',
          'uploading'
        );
      }
    } finally {
      this.uploadingOrder = false;
    }
  }

  async payWithCollectedFunds(accountID, price) {
    await this.modalHelper.createLoader({});

    this.getBalance(this.authService.user.stripeAccountID)
      .then((balanceData: any) => {
        const accountValue = Number(
          +balanceData.available[0].amount / 100
        ).toFixed(2);

        const pendingValue = Number(
          +balanceData.pending[0].amount / 100
        ).toFixed(2);

        this.modalHelper.dismissLoader().then(async () => {
          if (accountValue && accountValue >= price) {
            this.modalHelper.createAlert({
              title: 'Purchase Trading Cards?',
              message:
                'Use your funds to purchase this order for $' +
                price.toFixed(2) +
                '? After this you will have a balance of $' +
                (Number(accountValue) - price).toFixed(2),
              type: 'confirm',
              buttonTitle: 'Purchase',
              handler: () =>
                this.transferBetweenAccounts(
                  price,
                  this.authService.user.stripeAccountID,
                  accountID
                )
                  .then(() => {
                    this.paymentComplete(true);
                  })
                  .catch((e) => this.failedPaymentAlert(e, 'transferring')),
            });
          } else {
            this.modalHelper.createAlert({
              title: 'Insufficient Funds',
              message:
                'Currently you have $' +
                accountValue +
                ' of accessible funds from selling cards, leaving you $' +
                Number(price - Number(accountValue)).toFixed(2) +
                ' dollars short on this purchase 😔' +
                (pendingValue.length && pendingValue !== '0.00'
                  ? ' We do notice you have $' +
                    pendingValue +
                    ' pending tho, which will be useable soon! ' +
                    ' find out about that more on the funds page.'
                  : ''),
            });
          }
        });
      })
      .catch((e) => this.failedPaymentAlert(e, 'balancing'));
  }

  transferBetweenAccounts(amount, myID, toID) {
    return new Promise((resolve, reject) => {
      this.modalHelper.createLoader({
        message: 'Transferring funds...',
      });

      const chargeAccount = httpsCallable(this.fns, 'stripe-chargeAccount');

      amount = Math.floor(amount * 100);

      chargeAccount({
        amount,
        account: myID,
        // destination: toID,
      })
        .then(async (data: any) => {
          this.modalHelper.dismissLoader().then(() => {
            resolve(data.data);
          });
        })
        .catch((error) => {
          this.modalHelper.dismissLoader().then(() => {
            reject(error);
          });
        });
    });
  }

  async createIdentitySheet(sellerID: string) {
    if (!this.modalHelper.isMobile) {
      return this.modalHelper.createAlert({
        title: 'Error',
        message: 'This feature is only available on mobile',
      });
    }

    await this.modalHelper.createLoader({});

    const newIdentitySession = httpsCallable(
      this.fns,
      'stripe-createIdentitySession'
    );

    await newIdentitySession({
      stripeSellerID: sellerID,
    })
      .then(async (d: any) => {
        await Stripe.createIdentityVerificationSheet({
          ephemeralKeySecret: d.data.ephemeralKeySecret,
          verificationId: d.data.verficationSessionId,
        });
        await this.modalHelper.dismissLoader();
        const result = await Stripe.presentIdentityVerificationSheet();
        console.log(result);
      })
      .catch(async (e) => {
        await this.modalHelper.dismissLoader();
        await this.modalHelper.createAlert({
          title: 'Error',
          message: e.message,
        });
      });
  }
}
