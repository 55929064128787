/* eslint-disable quote-props */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import {
  setDoc,
  doc,
  Firestore,
  addDoc,
  collection,
} from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { User } from 'src/types';

@Injectable({
  providedIn: 'root',
})
export class PushService {
  user: User;

  constructor(
    public fns: Functions,
    public firestore: Firestore,

    public router: Router,
    public route: ActivatedRoute
  ) {}

  async establish(modalHelper, user) {
    this.user = user;
    // Check if the app is running on a Cordova platform
    if (modalHelper.isMobile) {
      // Add listeners for push notification events
      await this.addPushNotificationListeners(modalHelper);

      // Request push notification permissions
      this.requestPermissions(modalHelper);
    }
  }

  async requestPermissions(modalHelper) {
    PushNotifications.register().catch((e) =>
      this.showPushNotificationError(e, modalHelper)
    ); // Show any errors

    // PushNotifications.requestPermissions()
    //   .then((result) => {
    //     // If permission is granted, register for push notifications
    //     if (result.receive === 'granted') {
    //       PushNotifications.register().catch((e) =>
    //         this.showPushNotificationError(e, modalHelper)
    //       ); // Show any errors
    //     } else {
    //     }
    //   })
    //   .catch((e) => this.showPushNotificationError(e, modalHelper)); // Show any errors
  }

  async send(
    fcmToken,
    title,
    body,
    deep_link,
    activityUserDest,
    activity,
    card?
  ) {
    return new Promise(async (resolve, reject) => {
      if (activityUserDest) {
        activity.date = new Date();

        await addDoc(
          collection(
            this.firestore,
            'Users',
            String(activityUserDest),
            'Activity'
          ),
          activity
        );
      }

      if (fcmToken) {
        const sendMessage = httpsCallable(this.fns, 'notifier-sendMessage');
        sendMessage({
          card,
          title,
          body,
          deep_link,
          token: fcmToken,
        })
          .then(async (res: any) => {
            resolve(res.data.results);
          })
          .catch((e) => {
            reject(e);
          });
      }
    });
  }

  // Function to try to save a token
  tryToSaveToken(token) {
    // Check if the token, user, and user ID are all valid, and if the user's fcmToken is either not set or is different from the token being saved
    if (token && this.user && this.user.uid) {
      this.saveToken(token); // Save the token
    }
  }

  // Function to save a token
  async saveToken(token) {
    // Set the user's fcmToken to the provided token using the Firestore API
    await setDoc(
      doc(this.firestore, 'Users', String(this.user.uid)),
      {
        fcmToken: token,
      },
      {
        merge: true,
      }
    );
  }

  // Clears the user's FCM token in the Firestore database.
  // This is important because it ensures that the user does not
  // receive any unnecessary push notifications.
  async clearFCMToken(userID) {
    await setDoc(
      doc(this.firestore, 'Users', String(userID)),
      { fcmToken: null },
      { merge: true }
    );

    PushNotifications.removeAllDeliveredNotifications();
    PushNotifications.removeAllListeners();
  }

  // Displays an error alert to the user with the given error message.
  async showPushNotificationError(error, modalHelper) {
    modalHelper.createAlert({
      title: 'Error',
      message: error,
    });
  }

  async addPushNotificationListeners(modalHelper) {
    // Add a listener for the 'registration' event
    await PushNotifications.addListener(
      'registration',
      (token: Token) => this.tryToSaveToken(token.value) // Save the token
    );

    // Add a listener for the 'registrationError' event
    await PushNotifications.addListener('registrationError', (error: any) => {
      this.showPushNotificationError(
        'Error on registration: ' + JSON.stringify(error),
        modalHelper
      );
    });

    // Add a listener for the 'pushNotificationActionPerformed' event
    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification) => {
        const url = notification.notification.data['gcm.n.link']; // Get the URL from the notification data
        const body = notification.notification.body;

        // If the notification body is "Package Shipped 😩😩😩", show an alert
        if (body === 'Package Shipped 😩😩😩') {
          modalHelper.createAlert({
            title: 'Wohoo!',
            message:
              'Congrats! A user has received your package and left you a review 😩😩😩',
          });
        } else {
          // If the notification body is not "Package Shipped 😩😩😩", check if the URL exists and navigate to it if it does
          if (url) {
            this.router.navigateByUrl(url);
          }
        }
      }
    );

    // Add a listener for the 'pushNotificationReceived' event
    await PushNotifications.addListener(
      'pushNotificationReceived',
      async (notification: PushNotificationSchema) => {
        const item = JSON.parse(JSON.stringify(notification));

        setTimeout(
          () =>
            modalHelper.createToast({
              message: item.title + ' : ' + item.body,
              duration: 3000,
              cssClass: 'empty_toast',
              position: 'bottom',
            }),
          500
        );
      }
    );
  }
}
