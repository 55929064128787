// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.type_image {
  display: flex;
  grid-gap: 10px;
  box-sizing: border-box;
  padding: 5px 10px;
  overflow-y: hidden;
  overflow-x: scroll;
}
.type_image .type {
  min-width: 250px;
  height: 125px;
  background: #4e4e4e;
  text-align: center;
  color: #fff;
  background-size: cover;
  background-position: 100% 25%;
  border-radius: 4px;
}
.type_image .type label {
  font-weight: bold;
  text-shadow: 1px 1px black;
  line-height: 205px;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
}

.nothing_panel {
  text-align: center;
  padding: 20px;
}
.nothing_panel h1 {
  font-size: 16px;
  color: #f0b78c;
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/components/type-slide/type-slide.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,cAAA;EAEA,sBAAA;EACA,iBAAA;EAEA,kBAAA;EACA,kBAAA;AADJ;AAGI;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,sBAAA;EACA,6BAAA;EACA,kBAAA;AADR;AAGQ;EACI,iBAAA;EACA,0BAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EACA,yBAAA;AADZ;;AAMA;EACI,kBAAA;EACA,aAAA;AAHJ;AAKI;EACI,eAAA;EACA,cAAA;EACA,iBAAA;AAHR","sourcesContent":[".type_image {\n    display: flex;\n    grid-gap: 10px;\n\n    box-sizing: border-box;\n    padding: 5px 10px;\n\n    overflow-y: hidden;\n    overflow-x: scroll;\n\n    .type {\n        min-width: 250px;\n        height: 125px;\n        background: #4e4e4e;\n        text-align: center;\n        color: #fff;\n        background-size: cover;\n        background-position: 100% 25%;\n        border-radius: 4px;\n\n        label {\n            font-weight: bold;\n            text-shadow: 1px 1px black;\n            line-height: 205px;\n            color: white;\n            font-size: 16px;\n            text-transform: uppercase;\n        }\n    }\n}\n\n.nothing_panel {\n    text-align: center;\n    padding: 20px;\n\n    h1 {\n        font-size: 16px;\n        color: #f0b78c;\n        font-weight: bold;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
