import { Component, OnInit, ViewChild } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs';
import { FilterService } from 'src/app/services/filter.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss'],
})
export class BannersComponent implements OnInit {
  @ViewChild('swiperRef', { static: false }) swiperRef;

  loading = true;
  banners = [];

  constructor(
    public router: Router,
    public fns: Functions,
    public filterService: FilterService,
    public modalHelper: ModalHelperService
  ) {
    this.pullBanners();
  }

  ngOnInit() {}

  async pullBanners() {
    this.loading = true;

    const bannerVersion = (
      await httpsCallable(this.fns, 'home-pullBannerVersion')({})
    ).data as any;
    const localBannerVersion = Number(
      await this.modalHelper.getPreference('bannerVersion')
    );
    const localBanners = JSON.parse(
      String(await this.modalHelper.getPreference('banners'))
    );

    let tempBanners = localBannerVersion === bannerVersion ? localBanners : [];

    if (tempBanners.length === 0) {
      await this.modalHelper.setPreference('bannerVersion', bannerVersion);

      tempBanners = (
        await httpsCallable(this.fns, 'home-pullBanners')({ limit: 6 })
      ).data as any;
      await this.modalHelper.setPreference(
        'banners',
        JSON.stringify(tempBanners)
      );
    }

    this.filterService.gameFilterSub
      .pipe(debounceTime(100))
      .subscribe((d) => this.filterBanners(tempBanners));
  }

  filterBanners(tempBanners) {
    this.loading = false;

    const games = this.filterService.showingGames || [];
    this.banners = tempBanners.filter((banner) =>
      games.some((game) => game === banner.game)
    );

    setTimeout(() => {
      if (this.swiperRef) {
        const swiperElem = this.swiperRef.nativeElement.swiper;
        swiperElem.slideTo(Math.floor(Math.random() * this.banners.length));
        swiperElem.update();
      }
    });
  }

  openLink(link?) {
    return link ? this.router.navigateByUrl(link) : null;
  }
}
