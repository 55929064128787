/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/dot-notation */
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  collection,
  collectionData,
  doc,
  Firestore,
  getDoc,
  query,
  where,
} from '@angular/fire/firestore';
import { IonContent, Platform } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { PushService } from '../services/push.service';

import { Router } from '@angular/router';

import { Keyboard, KeyboardResize } from '@capacitor/keyboard';
import { ModalHelperService } from '../services/modal-helper.service';
import { CartService } from '../services/cart.service';
import { Listing, User } from 'src/types';
import { GesturesService } from '../services/gestures.service';

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.page.html',
  styleUrls: ['./messenger.page.scss'],
})
export class MessengerPage implements OnInit, OnDestroy {
  @Input() mySubject: BehaviorSubject<[]>;
  @ViewChild(IonContent, { read: IonContent, static: false })
  content: IonContent;

  @ViewChild('msgWrap', { read: ElementRef }) msgWrap: ElementRef;

  oMessageSubber: Subscription;
  messageSubber: Subscription;

  owner: User;
  card: Listing;
  messages = [];
  moreSub: any;
  firstMessageDate: Date;

  messagingUsers: any = [];
  savedToMe: boolean;
  loaded: boolean;

  messageLength: number;

  private mutationObserver: MutationObserver;

  constructor(
    public authService: AuthService,
    public firestore: Firestore,

    public router: Router,
    public cartService: CartService,
    public modalHelper: ModalHelperService,
    public gestureService: GesturesService,
    public pushService: PushService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.messageSubber && this.messageSubber.unsubscribe();
    this.oMessageSubber && this.oMessageSubber.unsubscribe();
    this.moreSub && this.moreSub.unsubscribe();
  }

  ionViewDidEnter() {
    this.establish().then(() => {
      if (this.modalHelper.isMobile && this.modalHelper.isIOS) {
        Keyboard.setResizeMode({ mode: KeyboardResize.Native }).then(() =>
          Keyboard.addListener('keyboardWillShow', () => this.scrollToBottom())
        );
      }

      this.gestureJazz();
    });
  }

  gestureJazz() {
    setTimeout(() => {
      this.mutationObserver = new MutationObserver(() => {
        this.scrollToBottom();
        //this.gestureService.allowFreeScroll(this.msgWrap.nativeElement);
      });
      this.mutationObserver.observe(this.msgWrap.nativeElement, {
        childList: true,
      });

      document.querySelectorAll('.openURL').forEach((el) => {
        el.addEventListener('click', (e: any) => {
          this.modalHelper.triggerIAB(e.target.innerText);
        });
      });

      setTimeout(() => {
        this.gestureService.loadLongPressOnElement();
      }, 1000);
    }, 1000);
  }

  ionViewDidLeave() {
    if (this.modalHelper.isMobile && this.modalHelper.isIOS) {
      Keyboard.setResizeMode({ mode: KeyboardResize.None }).then(() => {
        Keyboard.removeAllListeners();
      });
    }
  }

  async scrollToBottom() {
    setTimeout(() => {
      this.content.scrollToBottom(300);
    }, 500);
  }

  async establish() {
    return new Promise(async (resolve, reject) => {
      this.owner = this.mySubject.value['owner'];
      this.mySubject.value['card'] &&
        (this.card = this.mySubject.value['card']);

      if (!this.owner.username) {
        this.generateOwnerUsername(this.owner.uid || this.owner).then(
          (d: any) => {
            this.owner = d;
            this.pullMessages()
              .then((m) => resolve(m))
              .catch((m) => reject(m));
          }
        );
      } else {
        this.pullMessages()
          .then((m) => resolve(m))
          .catch((m) => reject(m));
      }
    });
  }

  generateOwnerUsername(id) {
    return new Promise(async (resolve) => {
      const listingDoc = doc(this.firestore, 'Users', String(id));
      const messagingUsers = (await getDoc(listingDoc)).data();
      resolve(messagingUsers);
    });
  }

  doRefresh(event) {
    this.pullMessages().then(() => {
      event.target.complete();
    });
  }

  async pullMessages() {
    // Unsubscribe from any existing subscriptions
    this.messageSubber && this.messageSubber.unsubscribe();
    this.oMessageSubber && this.oMessageSubber.unsubscribe();

    // Check if the current user is the owner of the messages
    if (this.owner) {
      // Check if the current user has sent any messages to the other user
      await this.checkIfIHaveSentMessageToUser();

      // Get the list of users who have messaged the current user
      const users = (await this.pullWhosMessaged()) as any;

      // Update the list of messaging users
      this.messagingUsers = users ? users.messagedUsers || [] : [];

      // Mark the messages as loaded
      this.loaded = true;

      // Return the list of messaging users
      return this.messagingUsers;
    }
  }

  async checkIfIHaveSentMessageToUser() {
    // Check if the card has a valid id
    if (this.card && this.card.id) {
      // Get the messages collection for the current user and the other user
      const messagesCollection = collection(
        this.firestore,
        'Messages',
        this.authService.user.uid,
        this.owner.uid
      );

      // Create a query to filter the messages by the card id
      const refQuery = query(
        messagesCollection,
        where('context', '==', this.card.id)
      );

      // Get the data from the query
      const coll = collectionData(refQuery);

      // Subscribe to the data and process the messages
      this.messageSubber = coll.subscribe((data) => {
        // If there are no messages, update the savedToMe flag and check if the other user has sent any messages to the current user
        if (!data || !data.length) {
          this.savedToMe = false;
          this.checkifUserHasSentMessageToMe();
        } else {
          // If there are messages, sort them by time and update the relevant properties
          this.messages = data.sort((a, b) =>
            a.time.seconds > b.time.seconds ? 1 : -1
          );
          this.savedToMe = true;
          this.messageLength = data.length;
          this.firstMessageDate = new Date(
            this.messages[0].time.seconds * 1000
          );
        }
      });
    } else {
      // If the card has no id, show an alert
      this.showMessengerAlert('Could not find trading card...');
    }
  }

  async checkifUserHasSentMessageToMe() {
    // Get the messages collection for the other user and the current user
    const messagesCollection = collection(
      this.firestore,
      'Messages',
      this.owner.uid,
      this.authService.user.uid
    );

    // Create a query to filter the messages by the card id
    const refQuery = query(
      messagesCollection,
      where('context', '==', this.card.id)
    );

    // Get the data from the query
    const coller = await collectionData(refQuery);

    // Subscribe to the data and process the messages
    this.oMessageSubber = coller.subscribe((data) => {
      // If there are messages, sort them by time and update the relevant properties
      if (data || data.length) {
        this.messages = data.sort((a, b) => (a.time > b.time ? 1 : -1));
        this.messageLength = data.length;

        // If there is at least one message, set the firstMessageDate property
        if (this.messages[0]) {
          this.firstMessageDate = new Date(
            this.messages[0].time.seconds * 1000
          );
        }
      }
    });
  }

  // Retrieve the list of messaging users for the current user
  async pullWhosMessaged() {
    // Retrieve the document containing the list of messaging users
    const messagingUsersDoc = await getDoc(
      doc(this.firestore, 'Messages', this.authService.user.uid)
    );

    // Return the data contained in the document
    return messagingUsersDoc.data();
  }

  // Show an alert with the given error message
  showMessengerAlert(error) {
    this.modalHelper.createAlert({
      title: 'Something went wrong! 😢',
      message: error,
    });
  }
}
