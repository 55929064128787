import { Component, Input } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { ListingManagerService } from 'src/app/services/listing-manager.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { Listing } from 'src/types';

@Component({
  selector: 'app-micro-grid',
  templateUrl: './micro-grid.component.html',
  styleUrls: ['./micro-grid.component.scss'],
})
export class MicroGridComponent {
  @Input() listings: Listing[] = [];

  loading = true;
  loaded = false;

  constructor(
    public firestore: Firestore,
    public listingManager: ListingManagerService,
    public modalHelper: ModalHelperService
  ) {}

  ngOnChanges() {
    if (this.listings.length > 0) {
      this.loading = false;
      this.loaded = true;
    }
  }

  async openListing(card: Listing) {
    this.modalHelper.triggerListingPage(card);
  }

  trackByListing(index: number, listing: Listing) {
    return listing.id;
  }
}
