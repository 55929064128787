// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.big_button {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  box-sizing: border-box;
  border: none;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
  height: 50px;
}

.lil_button {
  font-size: 12px;
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 5px;
  border: 1px solid #c19387;
  color: #c19387;
  background: none;
}

.filled {
  background: #c19387;
  color: #fff;
}

.block_space {
  height: 35vh;
}

.no_payout {
  opacity: 0.4;
  pointer-events: none;
}

.payedOutCard {
  color: #9bbc9d;
}

.balance_card {
  min-width: -webkit-fill-available;
  text-align: center;
}

ion-input {
  width: 90% !important;
}`, "",{"version":3,"sources":["webpack://./src/app/funds/funds.page.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,kBAAA;EACA,UAAA;EACA,sBAAA;EACA,YAAA;EACA,cAAA;EACA,gBAAA;EACA,mBAAA;EACA,YAAA;AACJ;;AAEA;EACI,eAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,mBAAA;EACA,eAAA;EACA,yBAAA;EACA,cAAA;EACA,gBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,WAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,YAAA;EACA,oBAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,iCAAA;EACA,kBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ","sourcesContent":[".big_button {\n    margin-left: auto;\n    margin-right: auto;\n    width: 80%;\n    box-sizing: border-box;\n    border: none;\n    display: block;\n    margin-top: 20px;\n    margin-bottom: 20px;\n    height: 50px;\n}\n\n.lil_button {\n    font-size: 12px;\n    padding: 10px;\n    border-radius: 4px;\n    width: 100%;\n    margin-bottom: 10px;\n    margin-top: 5px;\n    border: 1px solid #c19387;\n    color: #c19387;\n    background: none;\n}\n\n.filled {\n    background: #c19387;\n    color: #fff;\n}\n\n.block_space {\n    height: 35vh;\n}\n\n.no_payout {\n    opacity: 0.4;\n    pointer-events: none;\n}\n\n.payedOutCard {\n    color: #9bbc9d;\n}\n\n.balance_card {\n    min-width: -webkit-fill-available;\n    text-align: center;\n}\n\nion-input{\n    width: 90% !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
