// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activity_wrapper .emptyTxt {
  color: #cfb5a6;
  margin-top: 30%;
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 14px;
}
.activity_wrapper .ticket_wrapper {
  padding: 20px;
  padding-top: 5px;
  box-sizing: border-box;
}
.activity_wrapper .ticket_wrapper .ticket {
  background: rgba(244, 171, 115, 0.08);
  border: 2px dotted #dadada;
  border-left: 5px solid #dbb49f;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 3px;
  color: #c5a285;
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
}
.activity_wrapper .ticket_wrapper .ticket ion-icon {
  font-size: 21px;
  margin-top: 6%;
}
.activity_wrapper .ticket_wrapper .ticket .eye_icon {
  margin-right: 5%;
}
.activity_wrapper .ticket_wrapper .information {
  width: 85%;
}
.activity_wrapper .ticket_wrapper .card_grid {
  display: inline-flex;
}

.faded {
  opacity: 0.4;
}`, "",{"version":3,"sources":["webpack://./src/app/recent-activity/recent-activity.page.scss"],"names":[],"mappings":"AACI;EACI,cAAA;EACA,eAAA;EACA,UAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;AAAR;AAGI;EACI,aAAA;EACA,gBAAA;EACA,sBAAA;AADR;AAGQ;EACI,qCAAA;EACA,0BAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,cAAA;EAEA,WAAA;EACA,oBAAA;EACA,8BAAA;AAFZ;AAIY;EACI,eAAA;EACA,cAAA;AAFhB;AAKY;EACI,gBAAA;AAHhB;AAOQ;EACI,UAAA;AALZ;AAQQ;EACI,oBAAA;AANZ;;AAWA;EACI,YAAA;AARJ","sourcesContent":[".activity_wrapper {\n    .emptyTxt {\n        color: #cfb5a6;\n        margin-top: 30%;\n        width: 60%;\n        display: block;\n        margin-left: auto;\n        margin-right: auto;\n        text-align: center;\n        font-size: 14px;\n    }\n\n    .ticket_wrapper {\n        padding: 20px;\n        padding-top: 5px;\n        box-sizing: border-box;\n\n        .ticket {\n            background: rgb(244 171 115 / 8%);\n            border: 2px dotted #dadada;\n            border-left: 5px solid #dbb49f;\n            padding: 20px;\n            margin-bottom: 20px;\n            border-radius: 3px;\n            color: #c5a285;\n\n            width: 100%;\n            display: inline-flex;\n            justify-content: space-between;\n\n            ion-icon {\n                font-size: 21px;\n                margin-top: 6%;\n            }\n\n            .eye_icon {\n                margin-right: 5%;\n            }\n        }\n\n        .information {\n            width: 85%;\n        }\n\n        .card_grid {\n            display: inline-flex;\n        }\n    }\n}\n\n.faded {\n    opacity: 0.4;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
