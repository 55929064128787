import { Injectable } from '@angular/core';

import {
  ActionSheetController,
  AlertController,
  LoadingController,
  ModalController,
  Platform,
  PopoverController,
} from '@ionic/angular';
import { Browser, OpenOptions } from '@capacitor/browser';
import { BehaviorSubject } from 'rxjs';

import { Share } from '@capacitor/share';
import { Toast } from '@capacitor/toast';
import { Keyboard } from '@capacitor/keyboard';
import { Clipboard } from '@capacitor/clipboard';
import { Dialog } from '@capacitor/dialog';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';

import { AboutPage } from '../about/about.page';
import { AdminPage } from '../admin/admin.page';
import { AffiliatesPage } from '../affiliates/affiliates.page';
import { BagPage } from '../bag/bag.page';
import { GridControllerComponent } from '../components/grid-controller/grid-controller.component';
import { SellerRegisterComponent } from '../components/seller-register/seller-register.component';
import { ContactPage } from '../contact/contact.page';
import { FollowPage } from '../follow/follow.page';
import { FundsPage } from '../funds/funds.page';
import { HowtoPage } from '../howto/howto.page';
import { InfinityPage } from '../infinity/infinity.page';
import { LegalPage } from '../legal/legal.page';
import { ListingFormPage } from '../listing-form/listing-form.page';
import { ListingLikesPage } from '../listing-likes/listing-likes.page';
import { ListingPage } from '../listing/listing.page';
import { MessengerPage } from '../messenger/messenger.page';
import { OrdersPage } from '../orders/orders.page';
import { RecentActivityPage } from '../recent-activity/recent-activity.page';
import { ReviewEditorPage } from '../review-editor/review-editor.page';
import { SearchCategoryPage } from '../search-category/search-category.page';
import { ShippingPage } from '../shipping/shipping.page';
import { TagsPage } from '../tags/tags.page';
import { UserProfilePage } from '../user-profile/user-profile.page';
import { WriteReviewPage } from '../write-review/write-review.page';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { HowtoDeliveryPage } from '../howto-delivery/howto-delivery.page';
import { Device } from '@capacitor/device';
import { Router, UrlTree } from '@angular/router';
import { ListingManagerService } from './listing-manager.service';
import { WelcomePage } from '../welcome/welcome.page';

import { Preferences } from '@capacitor/preferences';
import { ReceiptsPage } from '../receipts/receipts.page';
import { OrderTrackerPage } from '../orders/order-tracker/order-tracker.page';

@Injectable({
  providedIn: 'root',
})
export class ModalHelperService {
  lastBuzz: number;
  deviceInfoUsed: number;

  isMobile: boolean;
  isAndroid: boolean;
  isIOS: boolean;

  constructor(
    public router: Router,
    public platform: Platform,
    public actionSheetController: ActionSheetController,
    public listingManager: ListingManagerService,
    private modalController: ModalController,
    public alertController: AlertController,
    private loadingController: LoadingController,
    private popoverController: PopoverController,
    public actionSheetContoller: ActionSheetController
  ) {
    this.isMobile = this.platform.is('cordova');
    this.isAndroid = this.platform.is('android');
    this.isIOS = this.platform.is('ios');
  }

  // Trigger a modal with the specified component and breakpoints
  async triggerModal(
    component,
    breakpoints,
    initialBreakpoint,
    componentProps?
  ) {
    const modal = await this.modalController.create({
      component,
      breakpoints,
      initialBreakpoint,
      componentProps,
    });

    return modal.present();
  }

  async triggerUserProfile(user, type) {
    const mySubject = new BehaviorSubject({ owner: user, type });
    this.triggerModal(UserProfilePage, [0, 0.5, 1], 0.5, { mySubject });
  }

  async triggerListing(card, owner?) {
    this.listingManager.cardHold = card;
    this.listingManager.ownerHold = owner;

    const mySubject = new BehaviorSubject({ card, owner });
    this.triggerModal(ListingPage, [0, 0.5, 1], 0.5, { mySubject });
  }

  async triggerListingPage(card) {
    this.buzz();

    this.listingManager.cardHold = card;
    this.listingManager.ownerHold = card.owner;

    const paramSplit = this.router.url.split('?');

    let newURL: string;
    if (paramSplit[0].includes('listing')) {
      newURL = paramSplit[0].replace(/listing\/\w+/, `listing/${card.id}`);
    } else {
      newURL = paramSplit[0] + `/listing/${card.id}`;
    }

    if (paramSplit[1]) {
      newURL = newURL + `?${paramSplit[1]}`;
    }

    this.router.navigateByUrl(newURL);
  }

  async triggerTags(tag) {
    const mySubject = new BehaviorSubject(tag);
    this.triggerModal(TagsPage, [0, 0.5, 1], 0.5, { mySubject });
  }

  async triggerFunds() {
    this.triggerModal(FundsPage, [0, 0.5, 1], 1);
  }

  async triggerRecentActivity(recentActivities) {
    const mySubject = new BehaviorSubject(recentActivities);
    this.triggerModal(RecentActivityPage, [0, 0.5, 1], 1, {
      mySubject,
    });
  }

  async triggerLikes(id) {
    console.log(id);
    const listingID = new BehaviorSubject(id);
    this.triggerModal(ListingLikesPage, [0, 0.5, 1], 1, { listingID });
  }

  async triggerSearchCatergory(category) {
    const mySubject = new BehaviorSubject(category);
    this.triggerModal(SearchCategoryPage, [0, 0.5, 1], 1, { mySubject });
  }

  async triggerSellerRegister() {
    this.triggerModal(SellerRegisterComponent, [0, 0.85, 1], 1);
  }

  async triggerGridController(data, type) {
    const mySubject = new BehaviorSubject({ data, type });
    this.triggerModal(GridControllerComponent, [0, 0.5, 1], 0.5, { mySubject });
  }

  async triggerFollow(type, user) {
    const mySubject = new BehaviorSubject({ type, user });
    this.triggerModal(FollowPage, [0, 0.5, 1], 0.5, { mySubject });
  }

  async triggerReviewer(card, review?) {
    const mySubject = new BehaviorSubject({ card, preReview: review || '' });
    this.triggerModal(WriteReviewPage, [0, 1], 1, {
      mySubject,
    });
  }

  async triggerMessenger(owner, card) {
    const mySubject = new BehaviorSubject({ owner, card });
    this.triggerModal(MessengerPage, [0, 0.5, 1], 1, { mySubject });
  }

  async triggerOrders(orders) {
    const mySubject = new BehaviorSubject(orders);
    this.triggerModal(OrdersPage, [0, 0.5, 1], 0.5, { mySubject });
  }

  async triggerOrderTracker(order) {
    const mySubject = new BehaviorSubject(order);
    this.triggerModal(OrderTrackerPage, [0, 0.5, 1], 0.5, { mySubject });
  }

  async triggerReviewEditor(user) {
    const mySubject = new BehaviorSubject(user);
    this.triggerModal(ReviewEditorPage, [0, 0.5, 1], 0.5, { mySubject });
  }

  async triggerInfinity(type) {
    const mySubject = new BehaviorSubject(type);
    this.triggerModal(InfinityPage, [0, 0.5, 1], 0.5, { mySubject });
  }

  async triggerBag() {
    this.triggerModal(BagPage, [0, 0.5, 1], 0.5);
  }

  async triggerAdmin() {
    this.triggerModal(AdminPage, [0, 0.5, 1], 0.5);
  }

  async triggerContact() {
    this.triggerModal(ContactPage, [0, 0.5, 1], 0.5);
  }

  async triggerLegal() {
    this.triggerModal(LegalPage, [0, 0.5, 1], 0.5);
  }

  async triggerAffiliates() {
    this.triggerModal(AffiliatesPage, [0, 0.5, 1], 0.5);
  }

  triggerReceipts() {
    this.triggerModal(ReceiptsPage, [0, 0.5, 1], 0.5);
  }

  async triggerAbout() {
    this.triggerModal(AboutPage, [0, 0.5, 1], 0.5);
  }

  async triggerHowTo() {
    this.triggerModal(HowtoPage, [0, 0.5, 1], 1);
  }

  async triggerHowToDelivery() {
    this.triggerModal(HowtoDeliveryPage, [0, 0.5, 1], 1);
  }

  async triggerListingForm() {
    this.triggerModal(ListingFormPage, [0, 0.5, 1], 1);
  }

  async triggerShipping() {
    this.triggerModal(ShippingPage, [0, 0.5, 1], 0.5);
  }

  async triggerWelcome() {
    this.triggerModal(WelcomePage, [0, 0.85, 1], 0.85);
  }

  // IAB
  async triggerIAB(url: string): Promise<void> {
    try {
      const options: OpenOptions = {
        url,
        presentationStyle: 'popover',
        toolbarColor: '#fff3e3',
      };
      await Browser.open(options);
    } catch (error) {
      console.error(error);
    }
  }

  // Share
  async share(opts) {
    if ((await Share.canShare()) && this.platform.is('cordova')) {
      Share.share(opts);
    } else {
      this.createAlert({
        header: 'Share Error',
        message: 'Unable to share at this time',
        type: 'confirm',
        buttons: ['Copy to Clipboard'],
        handler: async () => this.writeToClipboard(opts.url),
      });
    }
  }

  //Clipboard
  async writeToClipboard(content) {
    await Clipboard.write({
      // eslint-disable-next-line id-blacklist
      string: content,
    });

    await this.buzz();

    await this.createToast({
      message: 'Copied to Clipboard',
      duration: 2000,
    });
  }

  // Loader
  async createLiveLoader(opts) {
    return this.loadingController.create(opts);
  }
  async createLoader(opts) {
    const loading = await this.loadingController.create(opts);
    await loading.present();
  }
  async dismissLoader() {
    let topLoader = await this.loadingController.getTop();
    while (topLoader) {
      if (!(await topLoader.dismiss())) {
        break;
      }
      topLoader = await this.loadingController.getTop();
    }
  }

  // Toasts
  async createToast(opts) {
    // const toast = await this.toastController.create(opts);
    // await toast.present();

    await Toast.show({
      text: opts.message,
      position: opts.position || 'bottom',
      duration: opts.duration || 'short',
    });
  }
  async dismissToast() {
    // let topToast = await this.toastController.getTop();
    // while (topToast) {
    //   if (!(await topToast.dismiss())) {
    //     break;
    //   }
    //   topToast = await this.toastController.getTop();
    // }
  }

  // Alerts
  async createAlert(alertOpts) {
    if (!alertOpts.type || alertOpts.type === 'alert') {
      await Dialog.alert({
        title: alertOpts.title || '',
        message: alertOpts.message || '',
        buttonTitle: alertOpts.buttonTitle || 'OK',
      });
    } else if (alertOpts.type === 'confirm') {
      const { value } = await Dialog.confirm({
        title: alertOpts.title || '',
        message: alertOpts.message || '',
        okButtonTitle: alertOpts.buttonTitle || 'Confirm',
      });

      if (!value) {
        return null;
      }

      return alertOpts.handler();
    } else if (alertOpts.type === 'prompt') {
      const { value } = await Dialog.prompt({
        title: alertOpts.title || '',
        message: alertOpts.message || '',
        okButtonTitle: alertOpts.buttonTitle || 'Confirm',
        inputText: alertOpts.inputText || '',
        inputPlaceholder: alertOpts.inputPlaceholder || '',
      });
      if (!value) {
        return null;
      }
      alertOpts.handler(value);
    }
  }

  async createLegacyAlert(alertOpts) {
    const res = await this.alertController.create(alertOpts);
    return res.present();
  }

  // Popover
  async dismissPopover() {
    let topPopover = await this.popoverController.getTop();
    while (topPopover) {
      if (!(await topPopover.dismiss())) {
        break;
      }
      topPopover = await this.popoverController.getTop();
    }
  }

  async dismissModal() {
    await this.modalController.dismiss();
  }

  // Action Sheets
  async createActionSheet(sheetOptions) {
    if (!this.isIOS) {
      return await this.createLegacySheet(sheetOptions);
    }

    const newBtns = sheetOptions.buttons.map((button) => ({
      title: button.text,
      style:
        button.role === 'destructive'
          ? ActionSheetButtonStyle.Destructive
          : button.role === 'cancel'
          ? ActionSheetButtonStyle.Cancel
          : ActionSheetButtonStyle.Default,
      icon: button.icon,
    }));

    return await ActionSheet.showActions({
      title: sheetOptions.title,
      message: sheetOptions.message,
      options: newBtns,
    })
      .then((res) => res.index)
      .catch((e) => e);
  }

  async createLegacySheet(sheetOptions) {
    return (
      (await (
        await this.actionSheetController.create({
          header: sheetOptions.title,
          buttons: sheetOptions.buttons,
        })
      ).present()) as any
    ).data.index;
  }

  async dismissActionSheet() {
    await this.actionSheetController.dismiss();
  }

  // Function to trigger haptic feedback (vibration) on device
  async buzz(): Promise<void> {
    // Only allow haptic feedback to run once every half second at most to prevent overuse
    if (Date.now() - this.lastBuzz < 500) {
      return;
    }

    this.lastBuzz = Date.now(); // Update the timestamp of the last haptic feedback

    // Use Ionic's Haptics plugin to trigger a light impact haptic feedback
    await Haptics.impact({ style: ImpactStyle.Light });
  }

  async deviceCanHandleIt() {
    const minimumDeviceMemory = 15;

    if (this.deviceInfoUsed) {
      return this.deviceInfoUsed < minimumDeviceMemory;
    }

    const info = await Device.getInfo();
    this.deviceInfoUsed =
      info.memUsed > 10 ? Math.floor(info.memUsed / 1048576 / 4) : 100;

    return this.deviceInfoUsed < minimumDeviceMemory;
  }

  async pullLimitCap() {
    if (!this.isMobile) {
      return 10;
    }

    if (!this.deviceInfoUsed) {
      const info = await Device.getInfo();
      this.deviceInfoUsed = Math.floor(info.memUsed / 1048576 / 4);
    }

    return this.deviceInfoUsed;
  }

  async hideKeyboard() {
    if (this.isMobile) {
      Keyboard.hide();
    }
  }

  async setPreference(key: string, value: any) {
    return Preferences.set({
      key,
      value,
    });
  }

  getPreference(key: string) {
    return Preferences.get({ key })
      .then((res) => res.value)
      .catch((err) => {
        console.log(err);
      });
  }

  async removePreference(key: string) {
    return Preferences.remove({ key });
  }
}
