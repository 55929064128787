// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h3 {
  text-align: center;
  font-size: 13px;
  margin-top: 10vh;
}

.top_tabs {
  justify-content: space-evenly;
  overflow: scroll;
}
.top_tabs ion-button {
  margin: 0;
  font-size: 0.7em;
  padding: 0 8px;
  border-radius: 4px;
}

.bottom_brown {
  border-bottom: 1px solid #f2ddc7;
}

ion-avatar {
  width: 30px;
  height: 30px;
  margin: 10px;
}

.selected {
  background: #ecd7c2;
  --color: #fff;
}
.selected .micro_spin {
  color: #fff !important;
}

ion-toolbar {
  padding-left: 14px;
  padding-right: 14px;
}

.micro_spin {
  color: #ecd7c2 !important;
  width: 12px;
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/profile/profile.page.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,eAAA;EACA,gBAAA;AACJ;;AAEA;EACI,6BAAA;EACA,gBAAA;AACJ;AACI;EACI,SAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;AACR;;AAGA;EACI,gCAAA;AAAJ;;AAGA;EACI,WAAA;EACA,YAAA;EACA,YAAA;AAAJ;;AAGA;EACI,mBAAA;EACA,aAAA;AAAJ;AAEI;EACI,sBAAA;AAAR;;AAIA;EACI,kBAAA;EACA,mBAAA;AADJ;;AAIA;EACI,yBAAA;EACA,WAAA;EACA,iBAAA;AADJ","sourcesContent":["h3 {\n    text-align: center;\n    font-size: 13px;\n    margin-top: 10vh;\n}\n\n.top_tabs {\n    justify-content: space-evenly;\n    overflow: scroll;\n\n    ion-button {\n        margin: 0;\n        font-size: 0.7em;\n        padding: 0 8px;\n        border-radius: 4px;\n    }\n}\n\n.bottom_brown {\n    border-bottom: 1px solid #f2ddc7;\n}\n\nion-avatar {\n    width: 30px;\n    height: 30px;\n    margin: 10px;\n}\n\n.selected {\n    background: #ecd7c2;\n    --color: #fff;\n\n    .micro_spin {\n        color: #fff !important;\n    }\n}\n\nion-toolbar {\n    padding-left: 14px;\n    padding-right: 14px;\n}\n\n.micro_spin {\n    color: #ecd7c2 !important;\n    width: 12px;\n    margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
