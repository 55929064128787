import { Injectable } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { Listing } from 'src/types';

@Injectable({
  providedIn: 'root',
})
export class ListingFormService {
  isUpdating: boolean;

  listing: Listing = {
    name: '',
    id: '',
    desc: '',
    images: [],

    postDate: Timestamp.now(),
    pendingDate: Timestamp.now(),
    soldDate: Timestamp.now(),

    uploader: '',
    uploaderUsername: '',

    subType: '',
    set: '',
    game: 'Other',
    condition: 'Lightly Played',
    language: 'english',
    tags: [],

    definedInitialPrice: 0,
    shippingSize: 'single',
    shippingPrice: 3.2,

    isShowcasing: false,
    isReported: false,
    isStaffPick: false,
    viewCount: 0,

    hidePricingData: 'show',
    listingType: 'selling',
    status: 'selling',
    type: 'single',

    relatedTCGCards: [],
  };

  //userInputtedCardData
  uploadingListing: Listing = this.listing;

  constructor() {}

  clearListing() {
    this.uploadingListing = {
      name: '',
      id: '',
      desc: '',
      images: [],

      postDate: Timestamp.now(),
      pendingDate: Timestamp.now(),
      soldDate: Timestamp.now(),

      uploader: '',
      uploaderUsername: '',

      subType: '',
      set: '',
      game: 'Other',
      condition: 'Lightly Played',
      language: 'english',
      tags: [],

      definedInitialPrice: 0,
      shippingSize: 'single',
      shippingPrice: 3.2,

      isShowcasing: false,
      isReported: false,
      isStaffPick: false,
      viewCount: 0,

      hidePricingData: 'show',
      listingType: 'selling',
      status: 'selling',
      type: 'single',

      relatedTCGCards: [],
    };
  }
}
