/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input, OnInit } from '@angular/core';
import { Firestore, setDoc, doc, Timestamp } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { ListingManagerService } from 'src/app/services/listing-manager.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { ProfileService } from 'src/app/services/profile.service';
import { Listing, User } from 'src/types';

@Component({
  selector: 'app-listing-footer',
  templateUrl: './listing-footer.component.html',
  styleUrls: ['./listing-footer.component.scss'],
})
export class ListingFooterComponent implements OnInit {
  @Input() card: Listing;
  @Input() owner: User;
  @Input() isMine = false;

  customer: User;
  loading = true;

  constructor(
    public authService: AuthService,
    public cartService: CartService,
    public profileService: ProfileService,
    public modalHelper: ModalHelperService,
    public firestore: Firestore,
    public router: Router,
    public listingManagerService: ListingManagerService
  ) {}

  ngOnInit() {
    this.handleLoader();
  }

  handleLoader() {
    this.loading = true;

    const waitForCard = setInterval(() => {
      if (
        this.cartService.hasSetInCart &&
        this.card &&
        this.card.status &&
        this.owner
      ) {
        this.loading = false;
        clearInterval(waitForCard);
      }
    });
  }

  async editListing() {
    const buttons = [];

    buttons.push({
      text: 'Adjust Details',
      handler: () =>
        this.listingManagerService.updateListing(
          this.card,
          this.modalHelper,
          true
        ),
    });

    if (!this.card.isShowcasing) {
      buttons.push(
        {
          text: 'Apply Discount',
          handler: () => this.applyDiscount(),
        },
        {
          text: 'Mark As',
          handler: () => this.markListing(),
        },
        {
          text: 'Duplicate',
          handler: () =>
            this.listingManagerService.updateListing(
              this.card,
              this.modalHelper,
              false
            ),
        },
        {
          text: 'Delete',
          role: 'destructive',
          handler: () => this.deleteListing(),
        }
      );
    }

    buttons.push({
      text: 'Cancel',
      icon: 'close',
      role: 'cancel',
      handler: () => {},
    });

    const selectedBtn = await this.modalHelper.createActionSheet({
      buttons,
    });
    buttons[selectedBtn].handler();
  }

  deleteListing() {
    this.modalHelper.createAlert({
      title: 'Delete listing?',
      message:
        'The will remove the listing from the Cardboard Ninja platform entirely & forever.',
      type: 'confirm',
      buttonTitle: 'Delete',
      handler: async () => {
        await this.updateListingStatus({
          status: 'removed',
          removedDate: Date.now(),
        });

        this.authService.refreshProfile.next(this.authService.user);

        this.router.navigate(['/profile']);
      },
    });
  }

  async markListing() {
    const buttons = [
      {
        text: 'Sold',
        handler: () =>
          this.modalHelper.createAlert({
            title: 'Mark listing as sold?',
            message:
              'The will remove it from the market forever, deeming it no longer purchaseable',
            type: 'confirm',
            handler: () =>
              this.updateListingStatus({
                status: 'sold',
                soldDate: Date.now(),
              }),
          }),
      },
      {
        text: 'Pending Sold',
        handler: () =>
          this.modalHelper.createAlert({
            title: 'Mark listing as pending sale?',
            message: 'This will remove it from the market, but can be undone',
            type: 'confirm',
            handler: () =>
              this.updateListingStatus({
                status: 'c_pending',
                pendingDate: Date.now(),
              }),
          }),
      },
      {
        text: 'Delete',
        role: 'destructive',
        handler: () =>
          this.modalHelper.createAlert({
            title: 'Delete listing?',
            message:
              'The will remove the listing from the Cardboard Ninja platform entirely & forever.',
            type: 'confirm',
            handler: async () => {
              await this.updateListingStatus({
                status: 'removed',
                removedDate: Date.now(),
              });

              await this.modalHelper.buzz();

              this.authService.refreshProfile.next(this.authService.user);
            },
          }),
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      },
    ];

    const selectedBtn = await this.modalHelper.createActionSheet({
      buttons,
    });
    buttons[selectedBtn].handler();
  }

  async markPendingListing() {
    const buttons = [
      {
        text: 'Sold',
        handler: async () =>
          this.modalHelper.createAlert({
            title: 'Mark listing as sold?',
            message:
              'The will remove it from the market forever, deeming it no longer purchaseable',
            type: 'confirm',
            handler: () =>
              this.updateListingStatus({
                status: 'sold',
                soldDate: Date.now(),
              }),
          }),
      },
      {
        text: 'For Sale',
        handler: async () =>
          this.modalHelper.createAlert({
            title: 'Mark listing as selling? ',
            message:
              'This will place the listing on the market and allow other users to purchase it.',
            type: 'confirm',
            handler: () =>
              this.updateListingStatus({
                status: 'selling',
              }),
          }),
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      },
    ];

    const selectedBtn = await this.modalHelper.createActionSheet({
      buttons,
    });
    buttons[selectedBtn].handler();
  }

  async updateListingStatus(statusObj) {
    this.card.status = statusObj.status;
    if (statusObj.soldDate) {
      this.card.soldDate = statusObj.soldDate;
    }
    if (statusObj.pendingDate) {
      this.card.pendingDate = statusObj.pendingDate;
    }

    await this.listingManagerService.updateListingStatus(
      this.card.id,
      statusObj
    );

    this.authService.refreshProfile.next(this.authService.user);
  }

  async applyDiscount() {
    this.modalHelper.createAlert({
      title: 'Apply New Price',
      message:
        'Enter your new sale price below. All prices in AUD. The difference between the new price and the old price will appear as a mark-down',
      type: 'prompt',
      inputText: this.card.definedInitialPrice.toString(),
      buttonTitle: 'Apply',
      handler: (amount: any) => this.setDiscount(amount),
    });
  }

  async setDiscount(amount) {
    if (amount >= this.card.definedInitialPrice || amount < 0.1) {
      this.modalHelper.createAlert({
        title: 'Invalid Price',
        message:
          'New price must be above 10 cents and less than the original price',
      });
    } else {
      await setDoc(
        doc(this.firestore, 'Listings', this.card.id),
        {
          definedInitialPrice: amount,
          discountedPrice:
            this.card.discountedPrice || this.card.definedInitialPrice,
        },
        {
          merge: true,
        }
      );

      this.card.discountedPrice =
        this.card.discountedPrice || this.card.definedInitialPrice;
      this.card.definedInitialPrice = amount;

      this.modalHelper.createToast({
        message: 'Discount Applied!',
        position: 'bottom',
        duration: 1000,
      });
    }
  }

  addToBag(card) {
    return this.authService.user
      ? this.cartService.addCardToBag(card)
      : this.authService.notAuthedPopup();
  }

  manageDate(date: Timestamp): string {
    if (!date) {
      return '';
    }
    const seconds = date['_seconds'] ?? date.seconds;
    return new Date(seconds * 1000).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  }

  async openMessenger(owner: User) {
    if (this.authService.user) {
      this.cartService.openMessenger(owner, this.card);
    } else {
      this.authService.notAuthedPopup();
    }
  }

  async openTracker() {
    const order = await this.profileService.getOrder(
      this.card.uploader,
      this.card.id
    );

    if (order) {
      order.owner = this.owner;
      return this.modalHelper.triggerOrderTracker(order);
    } else {
      this.modalHelper.createToast({
        message: 'No order found!',
        position: 'bottom',
        duration: 1000,
      });
      return;
    }
  }
}
