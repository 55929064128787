// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status {
  text-align: center;
  font-size: 10px;
  width: 100%;
  display: block;
  color: #9e9e9e;
  font-weight: bold;
}
.status .c {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #9e9e9e;
  margin-left: 2px;
  margin-right: 2px;
}

.green {
  color: #a8cca8;
}
.green .c {
  background: #a8cca8;
}

.red {
  color: #d26c6c;
}
.red .c {
  background: #d26c6c;
}

.orange {
  color: #e0b07f;
}
.orange .c {
  background: #e0b07f;
}

.lightgrey {
  color: #a5a5a5;
}
.lightgrey .c {
  background: #a5a5a5;
}

.lightpurple {
  color: #8c7ae6;
}
.lightpurple .c {
  background: #8c7ae6;
}`, "",{"version":3,"sources":["webpack://./src/app/components/card-unit/status-indicator/status-indicator.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,eAAA;EACA,WAAA;EACA,cAAA;EACA,cAAA;EACA,iBAAA;AACJ;AACI;EACI,qBAAA;EACA,UAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;AACR;;AAGA;EACI,cAAA;AAAJ;AAEI;EACI,mBAAA;AAAR;;AAIA;EACI,cAAA;AADJ;AAGI;EACI,mBAAA;AADR;;AAKA;EACI,cAAA;AAFJ;AAII;EACI,mBAAA;AAFR;;AAMA;EACI,cAAA;AAHJ;AAKI;EACI,mBAAA;AAHR;;AAOA;EACI,cAAA;AAJJ;AAMI;EACI,mBAAA;AAJR","sourcesContent":[".status {\n    text-align: center;\n    font-size: 10px;\n    width: 100%;\n    display: block;\n    color: #9e9e9e;\n    font-weight: bold;\n\n    .c {\n        display: inline-block;\n        width: 6px;\n        height: 6px;\n        border-radius: 50%;\n        background: #9e9e9e;\n        margin-left: 2px;\n        margin-right: 2px;\n    }\n}\n\n.green {\n    color: #a8cca8;\n\n    .c {\n        background: #a8cca8;\n    }\n}\n\n.red {\n    color: #d26c6c;\n\n    .c {\n        background: #d26c6c;\n    }\n}\n\n.orange {\n    color: #e0b07f;\n\n    .c {\n        background: #e0b07f;\n    }\n}\n\n.lightgrey {\n    color: #a5a5a5;\n\n    .c {\n        background: #a5a5a5;\n    }\n}\n\n.lightpurple {\n    color: #8c7ae6;\n\n    .c {\n        background: #8c7ae6;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
