// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card-title {
  font-size: 12px;

  height: 40px;
  min-height: 40px;
  max-height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  i {
    color: #e8e8e8;
    font-size: 10px;
  }
}

.inline_articles {
  display: inline-flex;
}

.news_lnk {
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

ion-card-subtitle {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.center_spinner {
  margin-top: 10px;
  margin-bottom: 10px;
}

.center_spinner ion-spinner {
  width: 25px !important;
  height: 25px !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/news-feed/news-feed.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;;EAEf,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;EAChB,oBAAoB;EACpB,qBAAqB;EACrB,4BAA4B;EAC5B,gBAAgB;;EAEhB;IACE,cAAc;IACd,eAAe;EACjB;AACF;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":["ion-card-title {\n  font-size: 12px;\n\n  height: 40px;\n  min-height: 40px;\n  max-height: 40px;\n  display: -webkit-box;\n  -webkit-line-clamp: 3;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n\n  i {\n    color: #e8e8e8;\n    font-size: 10px;\n  }\n}\n\n.inline_articles {\n  display: inline-flex;\n}\n\n.news_lnk {\n  max-width: 160px;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\nion-card-subtitle {\n  max-width: 100%;\n  overflow: hidden;\n  white-space: nowrap;\n}\n\n.center_spinner {\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.center_spinner ion-spinner {\n  width: 25px !important;\n  height: 25px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
