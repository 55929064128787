// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid_wrap {
  width: 100vw;
  height: 30vh;
  margin-top: 10px;
  padding: 10px;
}
.grid_wrap button {
  padding: 14px;
  margin-bottom: 10px;
  background: #95847a;
  color: #fff3e4;
  border-radius: 4px;
  width: 30vw;
  margin-left: 1vw;
}`, "",{"version":3,"sources":["webpack://./src/app/components/grid-controller/grid-controller.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;AACR","sourcesContent":[".grid_wrap {\n    width: 100vw;\n    height: 30vh;\n    margin-top: 10px;\n    padding: 10px;\n\n    button {\n        padding: 14px;\n        margin-bottom: 10px;\n        background: #95847a;\n        color: #fff3e4;\n        border-radius: 4px;\n        width: 30vw;\n        margin-left: 1vw;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
