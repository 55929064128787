import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-formatter-component',
  template: `<img
    border="0"
    style="object-fit: contain; margin: 0"
    src="{{ params.value ? params.value : params.data.images[0] }}"
  />`,
})
export class ImageFormatterComponentComponent implements OnInit {
  params: any;

  constructor() {}

  ngOnInit() {}
  agInit(params: any) {
    this.params = params;
  }
}
