// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.minimal_grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.minimal_grid .card {
  margin: 0;
  width: 25%;
  min-width: 25%;
}
.minimal_grid .card img {
  width: 100%;
  object-fit: cover;
  object-position: top;
  margin: auto;
  border-radius: 0px !important;
  /* max-height: 130px; */
  min-width: auto;
  height: 145px;
  max-width: 100px;
  object-fit: cover;
  object-position: center;
  border-radius: 3px !important;
}
.minimal_grid .card_gridmode_wrap {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.minimal_grid .block_space {
  height: 10vh;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/profile-grid-view/profile-grid-view.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,WAAA;AACJ;AACI;EACI,SAAA;EACA,UAAA;EACA,cAAA;AACR;AACQ;EACI,WAAA;EACA,iBAAA;EACA,oBAAA;EACA,YAAA;EACA,6BAAA;EACA,uBAAA;EACA,eAAA;EACA,aAAA;EACA,gBAAA;EACA,iBAAA;EACA,uBAAA;EACA,6BAAA;AACZ;AAGI;EACI,UAAA;EACA,iBAAA;EACA,kBAAA;AADR;AAII;EACI,YAAA;EACA,WAAA;AAFR","sourcesContent":[".minimal_grid {\n    display: flex;\n    flex-wrap: wrap;\n    width: 100%;\n\n    .card {\n        margin: 0;\n        width: 25%;\n        min-width: 25%;\n\n        img {\n            width: 100%;\n            object-fit: cover;\n            object-position: top;\n            margin: auto;\n            border-radius: 0px !important;\n            /* max-height: 130px; */\n            min-width: auto;\n            height: 145px;\n            max-width: 100px;\n            object-fit: cover;\n            object-position: center;\n            border-radius: 3px !important;\n        }\n    }\n\n    .card_gridmode_wrap {\n        width: 90%;\n        margin-left: auto;\n        margin-right: auto;\n    }\n\n    .block_space {\n        height: 10vh;\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
