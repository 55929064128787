// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inner_card_wrapper {
  display: flex;
  flex-direction: row;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: center;
  padding: 0;
  padding-top: 10%;
}
.inner_card_wrapper ion-spinner {
  margin-top: 20%;
}
.inner_card_wrapper .emptyTxt {
  color: #cfb5a6;
  margin-top: 30%;
  width: 60%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 14px;
}

.list_new {
  margin-top: 30px;
  opacity: 0.6;
  text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-color: #dfbdb0;
  text-decoration-style: dashed;
  color: #cfb5a6;
  min-width: 100%;
  display: block;
  text-align: center;
}

.height_space {
  height: 50px;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/infinity/infinity.page.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,wBAAA;EAAA,mBAAA;EACA,uBAAA;EACA,UAAA;EACA,gBAAA;AACJ;AACI;EACI,eAAA;AACR;AACI;EACI,cAAA;EACA,eAAA;EACA,UAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;AACR;;AAGA;EACI,gBAAA;EACA,YAAA;EACA,0BAAA;EACA,0BAAA;EACA,8BAAA;EACA,6BAAA;EACA,cAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,YAAA;EACA,WAAA;AAAJ","sourcesContent":[".inner_card_wrapper {\n    display: flex;\n    flex-direction: row;\n    height: fit-content;\n    justify-content: center;\n    padding: 0;\n    padding-top: 10%;\n\n    ion-spinner {\n        margin-top: 20%;\n    }\n    .emptyTxt {\n        color: #cfb5a6;\n        margin-top: 30%;\n        width: 60%;\n        display: block;\n        margin-left: auto;\n        margin-right: auto;\n        text-align: center;\n        font-size: 14px;\n    }\n}\n\n.list_new {\n    margin-top: 30px;\n    opacity: 0.6;\n    text-decoration: underline;\n    text-underline-offset: 3px;\n    text-decoration-color: #dfbdb0;\n    text-decoration-style: dashed;\n    color: #cfb5a6;\n    min-width: 100%;\n    display: block;\n    text-align: center;\n}\n\n.height_space {\n    height: 50px;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
