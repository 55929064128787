// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-textarea {
  max-width: 95%;
  min-width: 95%;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid rgba(223, 146, 80, 0.3607843137);
  margin-top: 10px;
  border-radius: 3px;
  padding: 15px;
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/app/write-review/write-review.page.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,cAAA;EACA,kBAAA;EACA,iBAAA;EACA,kDAAA;EACA,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;AACJ","sourcesContent":["ion-textarea {\n    max-width: 95%;\n    min-width: 95%;\n    margin-right: auto;\n    margin-left: auto;\n    border: 1px solid rgba(223, 146, 80, 0.3607843137);\n    margin-top: 10px;\n    border-radius: 3px;\n    padding: 15px;\n    box-sizing: border-box;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
