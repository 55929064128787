/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { ModalHelperService } from './modal-helper.service';

@Injectable({
  providedIn: 'root',
})
export class TcgControllerService {
  cards: any = [];
  cardHold: any = [];

  selectedCard: any;
  selectedCardPrices: any = [];

  cardsAtATime = 20;

  query: string;
  set: string;
  token: string;
  offset = 0;

  fetching: boolean;

  constructor(
    public fns: Functions,

    public firestore: Firestore,
    public modalHelper: ModalHelperService
  ) {
    this.cards = [];
    this.cardHold = [];
    this.fetching = false;
    this.offset = 0;
  }

  async establish(modalHelper: ModalHelperService) {
    if (!(await modalHelper.getPreference('tcg_access_token'))) {
      await modalHelper.createLoader({
        message: 'Authorising Device..',
      });

      this.authorizeTCGToken(modalHelper)
        .then((res: string) => (this.token = res))
        .catch((e) => {
          modalHelper
            .dismissLoader()
            .then(() => this.showTCGPlayerError(modalHelper, e));
        });
    } else {
      this.token = String(await modalHelper.getPreference('tcg_access_token'));
    }
  }

  async authorizeTCGToken(modalHelper) {
    return new Promise((resolve, reject) => {
      this.authorizeTCGPlayer(modalHelper)
        .then(async (output) => {
          const token = output.access_token;
          if (token) {
            await modalHelper.setPreference('tcg_access_token', token);
            modalHelper.dismissLoader();
            resolve(token);
          } else {
            await modalHelper.removePreference('tcg_access_token');
            this.showTCGPlayerError(
              modalHelper,
              'Error collecting TCG Player token. Please restart and try again .'
            );
            reject();
          }
        })
        .catch(async (e) => {
          modalHelper.dismissLoader();
          await modalHelper.removePreference('tcg_access_token');
          reject(e);
        });
    });
  }

  async authorizeTCGPlayer(modalHelper): Promise<any> {
    return new Promise((resolve, reject) => {
      const authorizeTCGPlayer = httpsCallable(
        this.fns,
        'tcgPlayer-authorizeTCGPlayer'
      );
      authorizeTCGPlayer()
        .then(async (res: any) => {
          if (res.data) {
            resolve(res.data);
          } else {
            await modalHelper.removePreference('tcg_access_token');
            this.showTCGPlayerError(modalHelper, 'No Res Data');
          }
        })
        .catch(async (e) => {
          await modalHelper.removePreference('tcg_access_token');
          reject(e);
        });
    });
  }

  async getPrice(query: string, set: string, modalHelper) {
    this.fetching = true;

    this.cards = [];
    this.cardHold = [];

    this.query = query ? query.toLowerCase() : '';
    this.set = set;

    this.token = String(await modalHelper.getPreference('tcg_access_token'));

    return await this.getOffsetPlayerCards(modalHelper);
  }

  async getOffsetPlayerCards(modalHelper) {
    try {
      const results = (await this.runQuery(
        this.token,
        this.set,
        {
          sort: 'Relevance',
          limit: this.cardsAtATime,
          offset: this.offset,
          filters: [{ name: 'ProductName', values: [this.query] }],
        },
        modalHelper
      )) as any;

      if (results) {
        const newCards = results.filter(
          (result: { id: any }) =>
            !this.cards.some((card) => card.id === result.id)
        );
        this.cards.push(...newCards);
      } else {
        this.fetching = false;
      }
    } catch (error) {
      console.error(error);
    }
  }

  async runQuery(token, set, body, modalHelper) {
    if (token) {
      try {
        const codes = await this.searchCategory(token, set, body);
        const processedCodes = JSON.stringify(codes).slice(1, -1);

        return await this.getAllCardsFromQuery(
          modalHelper,
          token,
          processedCodes
        );
      } catch (error) {
        console.error(error);
      }
    } else {
      this.establish(modalHelper);
    }
  }

  async searchCategory(token, set, body) {
    if (token) {
      const searchCategory = httpsCallable(
        this.fns,
        'tcgPlayer-searchCategory'
      );
      try {
        const res = await searchCategory({ token, set, body });
        const results = (res.data as { results: any[] }).results; // Type assertion
        return results;
      } catch (error) {
        console.error(error);
        throw error;
      }
    } else {
      throw new Error('No Token');
    }
  }

  async getSearchManifest(categoryID) {
    return new Promise((resolve, reject) => {
      const getSearchManifest = httpsCallable(
        this.fns,
        'tcgPlayer-getSearchManifest'
      );
      getSearchManifest({
        token: this.token,
        categoryID,
      })
        .then((res: any) => resolve(res.data))
        .catch((e) => {
          reject(e);
        });
    });
  }

  async getTCGPrices(productID, modalHelper): Promise<any> {
    return await new Promise(async (resolve, reject) => {
      console.log(
        this.token,
        't:',
        await modalHelper.getPreference('tcg_access_token')
      );
      const getTCGPrices = httpsCallable(this.fns, 'tcgPlayer-getTCGPrices');
      getTCGPrices({
        token:
          this.token || (await modalHelper.getPreference('tcg_access_token')),
        productID,
      })
        .then((res: any) => resolve(res.data))
        .catch((e) => {
          if (e.errors === 'Missing or invalid bearer token.') {
            this.establish(modalHelper);
          }

          reject(e);
        });
    });
  }

  async getAllCardsFromQuery(modalHelper, token, codes) {
    return new Promise((resolve) => {
      const getDetailsOfProducts = httpsCallable(
        this.fns,
        'tcgPlayer-getDetailsOfProducts'
      );
      getDetailsOfProducts({
        token,
        codes,
      })
        .then(async (res: any) =>
          resolve(await this.proccessCardDetails(modalHelper, res.data.results))
        )
        .catch((error) => this.showTCGPlayerError(modalHelper, error.message));
    });
  }

  async proccessCardDetails(modalHelper, cards) {
    const allGroups = [];
    //weird tcg way of saying nothing found.
    if (cards[0].name === 'TCGplayer Gift Card') {
      this.fetching = false;
    } else {
      const rawCards = await cards;
      this.offset += cards.length;

      await rawCards.forEach((card: any) => {
        if (
          !card.extendedData[0] ||
          card.extendedData[0].value === 'Code Card'
        ) {
          return;
        }

        allGroups.push(card.groupId);
        this.cards.push(card);
      });

      this.getCardGroups(modalHelper, allGroups);
    }
  }

  async getCardGroups(modalHelper, codes) {
    const getGroupsOfProducts = httpsCallable(
      this.fns,
      'tcgPlayer-getGroupsOfProducts'
    );
    getGroupsOfProducts({
      token: this.token,
      codes,
    })
      .then((res: any) => {
        res.data.results.forEach((group, i) => {
          this.cards[i].group = group;
        });
        this.fetching = false;
      })
      .catch((error) => this.showTCGPlayerError(modalHelper, error.message));
  }

  async quickSearch(query) {
    return new Promise((resolve, reject) => {
      const quickSearch = httpsCallable(this.fns, 'tcgPlayer-quickSearch');
      quickSearch({
        token: this.token,
        query,
      })
        .then((res: any) =>
          resolve(
            res.data.sets
              ? res.data.sets.concat(res.data.products)
              : res.data.products
          )
        )
        .catch((error) => reject(error.message));
    });
  }
  async listAllSets(categoryId, offset, limit) {
    return new Promise((resolve, reject) => {
      const listAllSets = httpsCallable(this.fns, 'tcgPlayer-listAllSets');
      listAllSets({
        token: this.token,
        categoryId,
        offset,
        limit,
      })
        .then((res: any) => resolve(res.data))
        .catch((error) => reject(error.message));
    });
  }

  async getGroupDetails(groupID) {
    console.log(this.token);
    return new Promise((resolve, reject) => {
      const getGroupMedia = httpsCallable(this.fns, 'tcgPlayer-getGroupMedia');
      getGroupMedia({
        token: this.token,
        groupID,
      })
        .then((res: any) => resolve(res.data))
        .catch((error) => reject(error.message));
    });
  }

  async getCategoryDetails(categoryID) {
    console.log(this.token);
    return new Promise((resolve, reject) => {
      const getGroupMedia = httpsCallable(this.fns, 'tcgPlayer-getCategoryMedia');
      getGroupMedia({
        token: this.token,
        categoryID,
      })
        .then((res: any) => resolve(res.data))
        .catch((error) => reject(error.message));
    });
  }




  async getGroupProducts(groupID, limit, offset, modalHelper) {
    this.fetching = true;

    const res = (await httpsCallable(
      this.fns,
      'tcgPlayer-getGroupProducts'
    )({
      token: this.token,
      groupID,
      limit,
      offset,
    })) as any;

    this.fetching = false;

    if (
      res.data.errors &&
      res.data.errors[0] &&
      res.data.errors[0].includes('bearer token')
    ) {
      await modalHelper.createAlert({
        title: 'Session Expired',
        message:
          'Authenticating token... if this keeps happening please restart.',
      });

      await modalHelper.removePreference('tcg_access_token');

      this.establish(modalHelper);
      return;
    } else if (!res.data.results || res.data.results.length <= 0) {
      await this.noCardsYet(modalHelper);
      return;
    } else {
      return res.data.results;
    }
  }

  async getGroupProductsSize(groupID) {
    return new Promise((resolve) => {
      const getGroupProducts = httpsCallable(
        this.fns,
        'tcgPlayer-getGroupProducts'
      );
      getGroupProducts({
        token: this.token,
        groupID,
        limit: 1,
        offset: 0,
      }).then(async (res: any) => resolve(res.data.totalItems));
    });
  }

  async noCardsYet(modalHelper) {
    modalHelper.createAlert({
      title: 'No Cards Yet',
      message: 'Perhaps this set has not been released?',
    });
  }

  async pullTCGRelatedCards(modalHelper, productID) {
    if (await modalHelper.getPreference('tcg_access_token')) {
      return new Promise(async (resolve, reject) => {
        console.log('pulling related cards');
        const getSimilarProducts = httpsCallable(
          this.fns,
          'tcgPlayer-getSimilarProducts'
        );
        getSimilarProducts({
          token: await modalHelper.getPreference('tcg_access_token'),
          productID,
        })
          .then(async (res: any) => resolve(res.data.results))
          .catch((error) => reject(error.message));
      });
    }
  }

  async getProductDetails(modalHelper, codes) {
    if (await modalHelper.getPreference('tcg_access_token')) {
      return new Promise(async (resolve, reject) => {
        const getDetailsOfProducts = httpsCallable(
          this.fns,
          'tcgPlayer-getDetailsOfProducts'
        );
        getDetailsOfProducts({
          token: String(await modalHelper.getPreference('tcg_access_token')),
          codes,
        })
          .then((res: any) => resolve(res.data.results))
          .catch((error) => reject(error.message));
      });
    }
  }

  showTCGPlayerError(modalHelper, error) {
    modalHelper.createAlert({
      title: 'TCGPlayer Error',
      message: error,
    });
  }

  async pullPSA(query: string) {
    return new Promise((resolve, reject) => {
      const scrapeSearchItems = httpsCallable(
        this.fns,
        'psa-scrapeSearchItems'
      );
      scrapeSearchItems()
        .then((res: any) => resolve(res))
        .catch((error) => reject(error.message));
    });
  }
}
