/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { DatePipe } from '@angular/common';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { doc, Firestore, getDoc, setDoc } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Chart, registerables } from 'chart.js';
import { ListingManagerService } from 'src/app/services/listing-manager.service';

@Component({
  selector: 'app-pricing-charts',
  templateUrl: './pricing-charts.component.html',
  styleUrls: ['./pricing-charts.component.scss'],
})
export class PricingChartsComponent implements OnInit {
  @Input() card: any;

  @ViewChild('lineCanvas') private lineCanvas: ElementRef;
  @ViewChild('barCanvas') private barCanvas: ElementRef;

  chartLoaded: boolean;
  showCharts: boolean;

  lineChart: any;
  barChart: any;

  selectedChartOption = 4;
  priceDataLength: number;

  fullPoints = [];

  constructor(
    public router: Router,
    public firestore: Firestore,
    public listingManagerService: ListingManagerService
  ) {}

  ngOnInit() {
    this.updatePricingDataSet();
  }

  updatePricingDataSet() {
    this.showCharts = true;
    this.chartLoaded = false;

    if (this.card.tcgProductID) {
      this.checkIfChartDataExists();
    }
  }

  splitDataIntoChunk(size) {
    // Store the selected size in a member variable
    this.selectedChartOption = size;

    // Show the charts and set the chartLoaded flag to false
    this.showCharts = true;
    this.chartLoaded = false;

    // Unregister any previously registered charts
    Chart.unregister(...registerables);

    // Destroy the line and bar charts
    this.lineChart && this.lineChart.destroy();
    this.barChart && this.barChart.destroy();

    // Determine the number of months to retrieve based on the selected size
    let numMonths;
    switch (size) {
      case 1:
        numMonths = 1;
        break;
      case 2:
        numMonths = 3;
        break;
      case 3:
        numMonths = 6;
        break;
      case 4:
        numMonths = 12;
        break;
    }

    // Retrieve the points for the specified number of months
    const points = this.getMonthsWorthOfPoints(this.fullPoints, numMonths);

    // Construct the charts using the selected points
    this.constructCharts(points);
  }

  getMonthsWorthOfPoints(points, months) {
    // Create a Date object representing one month ago
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - months);

    if (!points) {
      console.error('No points :(');
      return;
    }

    // Return an array of points with dates within the last month
    return points.filter((point) => {
      // Convert the point's date string to a Date object
      const pointDate = new Date(point.date);

      // Check if the point's date is after one month ago
      return pointDate > oneMonthAgo;
    });
  }

  constructCharts(data) {
    this.chartLoaded = true;

    const waitForLineChart = setInterval(() => {
      if (
        this.lineCanvas &&
        this.lineCanvas.nativeElement &&
        this.barCanvas &&
        this.barCanvas.nativeElement
      ) {
        clearInterval(waitForLineChart);
        this.orangiseChartData(data);
      }
    });
  }

  async checkIfChartDataExists() {
    const pricingData = this.card.pricingData;

    if (!pricingData || !pricingData.points) {
      this.getFreshDataFromAPI();
      return;
    }

    // Check if the pricing data exists and is within the last 30 days
    const isDataValid = pricingData
      ? this.isWithin30Days(pricingData.datePulled)
      : false;

    // Return the chart data if it is valid, otherwise fetch fresh data from the API
    if (isDataValid) {
      // Retrieve the points for the specified number of months
      this.fullPoints = pricingData.points;
      return this.splitDataIntoChunk(1);
    } else {
      return this.getFreshDataFromAPI();
    }
  }

  isWithin30Days(date) {
    // Calculate the difference between the given date and the current date in seconds
    const dateInSeconds = date['_seconds'] * 1000;

    const currentDateInSeconds = Date.now();
    const timeDifferenceInSeconds = Math.abs(
      dateInSeconds - currentDateInSeconds
    );

    // Check if the time difference is less than 30 days
    const secondsInOneDay = 86400;
    const timeDifferenceInDays = timeDifferenceInSeconds / secondsInOneDay;
    return timeDifferenceInDays < 30;
  }

  async getFreshDataFromAPI() {
    // Retrieve pricing data for the card
    const pricingData = await this.listingManagerService.getPricingDataDirectly(
      this.card.tcgProductID
    );

    // Save the data to Firebase
    this.saveData(pricingData);

    // Retrieve the points for the specified number of months

    this.fullPoints = pricingData as [];
    this.splitDataIntoChunk(1);
  }

  async getConversionRate() {
    const listingDoc = doc(this.firestore, 'Admin', 'Currencies');
    return (await getDoc(listingDoc)).data();
  }

  async orangiseChartData(data) {
    const marketDates = [];
    const salesDates = [];
    const marketPoints = [];
    const salesPoints = [];

    const datePipe = new DatePipe('en-US');

    if (data && data.length) {
      const convertionRate = await this.getConversionRate();

      data.forEach((point) => {
        point.variants.forEach((pointVar) => {
          if (pointVar.variant === this.card.subType) {
            // Use the JavaScript Date object's native method to convert the date string to a Date object
            const marketDate = new Date(point.date);
            marketDates.push(datePipe.transform(marketDate, 'dd/MM'));

            // Calculate the market price and use the Number object's toFixed method to round it to 2 decimal places
            const marketPrice = Number(
              pointVar.marketPrice * convertionRate.USDtoAUD
            ).toFixed(2);
            marketPoints.push(marketPrice);

            // Only add sales data if there is a valid average sales price
            if (Number(pointVar.averageSalesPrice)) {
              const salesDate = new Date(point.date);
              salesDates.push(datePipe.transform(salesDate, 'dd/MM'));

              // Use the Number object's toFixed method to round the average sales price to 2 decimal places
              const averageSalesPrice = Number(
                pointVar.averageSalesPrice
              ).toFixed(2);
              salesPoints.push(averageSalesPrice);
            }
          }
        });
      });
    }

    // Set the priceDataLength variable to the length of the marketPoints array
    this.priceDataLength = marketPoints.length;

    // Hide the charts if there is no data to display
    if (!this.priceDataLength) {
      this.showCharts = false;
    }

    // Pass the market data to the lineChartMethod for display
    this.lineChartMethod(marketDates, marketPoints);

    // Pass the sales data to the barChartMethod for display
    this.barChartMethod(salesDates, salesPoints);

    // Set the fullPoints variable to the input data if it is not already set
    if (!this.fullPoints || !this.fullPoints.length) {
      this.fullPoints = data;
    }
  }

  async saveData(data) {
    const points = data;

    // Save the document with the converted data
    await setDoc(
      doc(this.firestore, 'Pricing', String(this.card.tcgProductID)),
      {
        tcgProductID: this.card.tcgProductID,
        tcgGroupID: this.card.tcgGroupID || null,
        name: this.card.name,
        game: this.card.game,
        datePulled: new Date(),
        points,
      },
      {
        merge: true,
      }
    );
  }

  async lineChartMethod(dates, points) {
    Chart.register(...registerables);

    this.lineChart = new Chart(this.lineCanvas.nativeElement, {
      type: 'line',
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            ticks: {
              color: '#bba599',
              font: {
                size: 8,
              },
            },
          },
          x: {
            ticks: {
              color: '#bba599',
              font: {
                size: 8,
              },
            },
          },
        },
      },
      data: {
        labels: dates.reverse(),
        datasets: [
          {
            label: 'Market Price (AUD)',
            fill: false,
            backgroundColor: '#fff',
            borderColor: '#e5d1c6',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: '#e5d1c6',
            pointBackgroundColor: '#e5d1c6',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: '#e5d1c6',
            pointHoverBorderColor: '#e5d1c6',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 50,
            data: points.reverse(),
            spanGaps: false,
          },
        ],
      },
    });
  }

  async barChartMethod(dates, points) {
    Chart.register(...registerables);

    this.barChart = new Chart(this.barCanvas.nativeElement, {
      type: 'bar',
      options: {
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            ticks: {
              color: '#bba599',
              font: {
                size: 8,
              },
            },
          },
          x: {
            ticks: {
              color: '#bba599',
              font: {
                size: 8,
              },
            },
          },
        },
      },
      data: {
        labels: dates.reverse(),
        datasets: [
          {
            label: 'Average Sales Price (AUD)',
            data: points.reverse(),
            backgroundColor: [
              'rgba(245, 212, 186, 0.4)',
              'rgba(226, 195, 179, 0.4)',
              'rgba(248, 222, 210, 0.4)',
              'rgba(176, 140, 122, 0.4)',
            ],
          },
        ],
      },
    });
  }

  hideCharts() {
    this.selectedChartOption = 0;
    this.showCharts = false;
  }
}
