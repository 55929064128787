import { Component, OnInit } from '@angular/core';

import { MessengerService } from '../services/messenger.service';
import { ModalHelperService } from '../services/modal-helper.service';

@Component({
  selector: 'app-howto',
  templateUrl: './howto.page.html',
  styleUrls: ['./howto.page.scss'],
})
export class HowtoPage implements OnInit {
  display = 0;

  constructor(
    public modalHelper: ModalHelperService,
    public messengerService: MessengerService
  ) {}

  ngOnInit() {}
}
