/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { Order } from 'src/types';

@Component({
  selector: 'app-order-tracker',
  templateUrl: './order-tracker.page.html',
  styleUrls: ['./order-tracker.page.scss'],
})
export class OrderTrackerPage implements OnInit {
  @Input() mySubject: BehaviorSubject<[]>;

  order: Order;
  loading = true;

  constructor(public modalHelper: ModalHelperService) {}

  ionViewDidEnter() {
    this.order = this.mySubject.value as any;
    this.loading = false;
    console.log(this.order);
  }

  ngOnInit() {}

  manageDate(date) {
    return new Date(date['seconds'] * 1000);
  }
}
