/* eslint-disable @typescript-eslint/quotes */
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ProfileService } from 'src/app/services/profile.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { PushService } from 'src/app/services/push.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-profile-popover',
  templateUrl: './profile-popover.component.html',
  styleUrls: ['./profile-popover.component.scss'],
})
export class ProfilePopoverComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public profileService: ProfileService,
    public modalHelper: ModalHelperService,

    public pushService: PushService
  ) {}

  ngOnInit() {}

  async openAdmin() {
    await this.modalHelper.dismissPopover();
    await this.modalHelper.buzz();

    this.modalHelper.triggerAdmin();
  }

  async openAccount() {
    await this.modalHelper.dismissPopover();
    await this.modalHelper.buzz();

    const buttons = [];

    if (this.authService.user.sellerVerified) {
      buttons.push({
        text: 'My Affiliates',
        icon: 'people-outline',
        handler: () => this.modalHelper.triggerAffiliates(),
      });
    }

    buttons.push(
      {
        text: 'My Receipts',
        cssClass: 'secondary',
        icon: 'receipt-outline',
        handler: () => this.modalHelper.triggerReceipts(),
      },
      {
        text: 'Logout',
        cssClass: 'secondary',
        icon: 'log-out-outline',
        handler: async () => this.authService.signout(this.pushService),
      },
      {
        text: 'Delete Account',
        cssClass: 'secondary',
        role: 'destructive',
        icon: 'trash-outline',
        handler: () => this.profileService.deleteAccount(this.modalHelper),
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      }
    );


    const selectedBtn = await this.modalHelper.createActionSheet({
      buttons,
    });

    await this.modalHelper.dismissPopover();
    await this.modalHelper.buzz();

    buttons[selectedBtn].handler();
  }

  // async openContact() {
  //   await this.modalHelper.dismissPopover();
  //   await this.modalHelper.buzz();

  //   this.modalHelper.triggerContact();
  // }

  // async openLegal() {
  //   await this.modalHelper.dismissPopover();
  //   await this.modalHelper.buzz();

  //   this.modalHelper.triggerLegal();
  // }
  async openAbout() {
    await this.modalHelper.dismissPopover();
    await this.modalHelper.buzz();

    const buttons = [
      {
        text: 'Legal',
        icon: 'document-text-outline',
        handler: () => {
          this.modalHelper.triggerLegal();
        },
      },
      {
        text: 'About',
        icon: 'information-circle-outline',
        handler: () => {
          this.modalHelper.triggerAbout();
        },
      },
      {
        text: 'Contact Us',
        icon: 'mail-outline',
        handler: () => {
          this.modalHelper.triggerContact();
        },
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      },
    ];

    const selectedBtn = await this.modalHelper.createActionSheet({
      buttons,
    });
    buttons[selectedBtn].handler();
  }
}
