import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-bag-indicator',
  templateUrl: './bag-indicator.component.html',
  styleUrls: ['./bag-indicator.component.scss'],
})
export class BagIndicatorComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public cartService: CartService,
    public router: Router
  ) {}

  ngOnInit() {
    if (!this.cartService.established) {
      this.cartService.establish();
    }
  }

  openLogin() {
    this.router.navigateByUrl('/profile');
  }

  roundPrice(price: number) {
    return Math.floor(price);
  }
}
