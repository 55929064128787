import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';

@Component({
  selector: 'app-profile-card-view',
  templateUrl: './profile-card-view.component.html',
  styleUrls: ['./profile-card-view.component.scss'],
})
export class ProfileCardViewComponent implements OnInit {
  @Input() cards = [];
  @Input() inModal: boolean;
  @Input() loading: boolean;

  constructor(
    public router: Router,
    public authService: AuthService,
    public modalHelper: ModalHelperService
  ) {}

  ngOnInit() {}

  openListing(card) {
    if (this.inModal) {
      this.modalHelper.triggerListing(card, card.owner);
    } else {
      this.modalHelper.triggerListingPage(card);
    }
  }
}
