import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { Listing } from 'src/types';

@Component({
  selector: 'app-profile-grid-view',
  templateUrl: './profile-grid-view.component.html',
  styleUrls: ['./profile-grid-view.component.scss'],
})
export class ProfileGridViewComponent implements OnInit {
  @Input() cards: Listing[] = [];
  @Input() inModal: boolean;

  constructor(public router: Router, public modalHelper: ModalHelperService) {}

  ngOnInit() {}

  openListing(card) {
    if (this.inModal) {
      this.modalHelper.triggerListing(card, card.owner);
    } else {
      this.modalHelper.triggerListingPage(card);
    }
  }
}
