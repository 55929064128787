// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-avatar {
  width: 30px;
  height: 30px;
  margin-right: 14px;
  margin-top: 6px;
}

ion-label {
  display: block;
}

.stars {
  display: inline-flex;
  margin-top: -3px;
  grid-gap: 2px;
}
.stars ion-icon {
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/user-card/user-card.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,oBAAA;EACA,gBAAA;EACA,aAAA;AACJ;AACI;EACI,eAAA;AACR","sourcesContent":["ion-avatar {\n    width: 30px;\n    height: 30px;\n    margin-right: 14px;\n    margin-top: 6px;\n}\n\nion-label{\n    display: block;\n}\n\n.stars {\n    display: inline-flex;\n    margin-top: -3px;\n    grid-gap: 2px;\n\n    ion-icon {\n        font-size: 10px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
