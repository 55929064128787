import { Component, OnInit } from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';
import { ListingManagerService } from 'src/app/services/listing-manager.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-profile-filter',
  templateUrl: './profile-filter.component.html',
  styleUrls: ['./profile-filter.component.scss'],
})
export class ProfileFilterComponent implements OnInit {
  descending: boolean;

  sortingBy = 'status';
  sortingGame = 'All Games';
  sortingStatus = 'all';

  constructor(
    public modalHelper: ModalHelperService,
    public listingService: ListingManagerService,
    public profileService: ProfileService
  ) {}

  ngOnInit() {}

  hideKeyboard() {
    this.modalHelper.hideKeyboard();
  }

  async triggerSortByAction() {
    const buttons = [
      {
        text: 'Status',
        handler: () => this.sortBy('Status', 'status'),
      },
      {
        text: 'Original Price',
        handler: () => this.sortBy('Price Listed', 'definedInitialPrice'),
      },
      {
        text: 'Date Listed',
        handler: () => this.sortBy('Date Listed', 'postDate'),
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      },
    ];

    const selectedBtn = await this.modalHelper.createActionSheet({
      buttons,
    });
    buttons[selectedBtn].handler();
  }

  async triggerByGameAction() {
    const buttons = [];

    buttons.push({
      text: 'All Games',
      handler: () => this.sortGame('All Games'),
    });

    this.listingService.games.forEach((game) => {
      buttons.push({
        text: game.name,
        handler: () => this.sortGame(game.name),
      });
    });

    buttons.push({
      text: 'Cancel',
      icon: 'close',
      role: 'cancel',
      handler: () => {},
    });

    const selectedBtn = await this.modalHelper.createActionSheet({
      buttons,
    });
    buttons[selectedBtn].handler();
  }

  async triggerByStatusAction() {
    const buttons = [
      {
        text: 'All Statuses',
        handler: () => this.sortStatus('all'),
      },
      {
        text: 'Selling',
        handler: () => this.sortStatus('selling'),
      },
      {
        text: 'Shipped',
        handler: () => this.sortStatus('shipped'),
      },
      {
        text: 'Pending',
        handler: () => this.sortStatus('pending'),
      },
      {
        text: 'Sold',
        handler: () => this.sortStatus('sold'),
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      },
    ];

    const selectedBtn = await this.modalHelper.createActionSheet({
      buttons,
    });
    buttons[selectedBtn].handler();
  }

  sortBy(name: string, type: string) {
    if (this.sortingBy !== name) {
      if (this.sortingBy.includes(name)) {
        this.descending = !this.descending;
      }
      this.sortingBy = this.descending ? name + ' (Desc)' : name + ' (Asc)';
      this.profileService.applyFilters(
        type,
        this.descending,
        this.sortingGame,
        this.sortingStatus
      );
    }
  }

  sortStatus(status: string) {
    if (this.sortingStatus !== status) {
      this.sortingStatus = status;
      this.profileService.applyFilters(
        this.sortingBy,
        this.descending,
        this.sortingGame,
        this.sortingStatus
      );
    }
  }

  sortGame(game: string) {
    if (this.sortingGame !== game) {
      this.sortingGame = game;
      this.profileService.applyFilters(
        this.sortingBy,
        this.descending,
        this.sortingGame,
        this.sortingStatus
      );
    }
  }
}
