import { Injectable } from '@angular/core';
import {
  Firestore,
  getDocs,
  collection,
  where,
  query,
  limit,
  doc,
  setDoc,
} from '@angular/fire/firestore';
import { TcgControllerService } from './tcg-controller.service';

@Injectable({
  providedIn: 'root',
})
export class ListingApiService {
  constructor(
    public firestore: Firestore,
    public tcgService: TcgControllerService
  ) {}

  async pullTCGProduct(id) {
    return (
      await getDocs(
        query(
          collection(this.firestore, 'Listings'),
          where('tcgProductID', '==', id),
          limit(1)
        )
      )
    ).docs[0].data();
  }

  async buildTCGRelatedProducts(modalHelper, card) {
    if (!card.tcgProductID) {
      return [];
    }

    const relatedCards = await this.tcgService.pullTCGRelatedCards(
      modalHelper,
      card.tcgProductID
    ) as any;

    if (!relatedCards) {
      return [];
    }

    const productCodes = relatedCards.map((c) => c.purchasedStoreProductId);
    if (!productCodes.length) {
      return [];
    }

    const productDetails = await this.tcgService.getProductDetails(
      modalHelper,
      productCodes
    ) as any;

    const tcgRelatedProducts = await Promise.all(
      relatedCards.flatMap(async (boughtCard) => {
        const subCard = productDetails.find(
          (s) =>
            s.productId === boughtCard.purchasedStoreProductId &&
            !s.name.includes('Code Card')
        );
        if (!subCard) {
          return null;
        }

        subCard.boughtData = boughtCard;
        this.setRelatedCard(card.id, subCard);

        if (!subCard.productID) {
          return subCard;
        }

        const listingsSnapshot = await getDocs(
          query(
            collection(this.firestore, 'Listings'),
            where('tcgProductID', '==', String(subCard.productID)),
            where('status', '==', 'selling'),
            limit(1)
          )
        );
        subCard.marketCount = listingsSnapshot.size;

        return subCard;
      })
    );

    return tcgRelatedProducts.filter((product) => product && product.name);
  }

  async setRelatedCard(id, card) {
    return await setDoc(
      doc(
        this.firestore,
        'Listings',
        String(id),
        'TCGRelatedCards',
        String(card.productId)
      ),
      card
    );
  }

  async isPromo(tags) {
    const promos = (
      await getDocs(
        collection(this.firestore, 'Admin', 'Promos', 'Home Sections')
      )
    ).docs.map((p) => p.data());

    for (const promo of promos) {
      if (tags.includes(promo.tag)) {
        return promo.badge;
      }
    }

    return null;
  }
}
