/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Injectable } from '@angular/core';
import {
  Firestore,
  setDoc,
  doc,
  collection,
  getDocs,
  query,
  where,
  getDoc,
} from '@angular/fire/firestore';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from '@angular/fire/storage';
import { Camera, CameraResultType } from '@capacitor/camera';
import { decode } from 'base64-arraybuffer';
import { Review, Listing } from 'src/types';
import { AuthService } from './auth.service';
import { ListingApiService } from './listing-api.service';
import { UserService } from './user.service';
import { Functions, httpsCallable } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  ownedSub: any;
  soldSub: any;
  reviewSub: any;

  userReviews: Review[] = [];

  userCards: Listing[] = [];
  soldCards: Listing[] = [];
  ownedCards: Listing[] = [];
  likedCards: Listing[] = [];

  soldCardsHold: Listing[] = [];
  ownedCardsHold: Listing[] = [];
  likedCardsHold: Listing[] = [];

  loader: HTMLIonLoadingElement;

  // sellingLength = 0;
  // ownedLength = 0;

  searchMode: boolean;
  isSearching: boolean;
  hasBuiltProfile: boolean;

  // userProfile: any;

  hasRun: boolean;

  authService: any;

  listedCardCache = new Map<string, Listing>();
  ownedCardCache = new Map<string, Listing>();

  constructor(
    public userService: UserService,
    public fns: Functions,
    public firestore: Firestore,
    public listingApi: ListingApiService
  ) {}

  async buildUserProfile(authService, user) {
    this.authService = authService;

    if (!this.authService.gotAuth) {
      await this.authService.waitForAuth();
      return await this.executeBuild(user);
    } else {
      return await this.executeBuild(user);
    }
  }

  async executeBuild(user) {
    this.soldCards = [];
    this.ownedCards = [];
    this.likedCards = [];
    this.userReviews = [];

    return await httpsCallable(
      this.fns,
      'user-pull'
    )({
      id: user.uid,
    })
      .then((res: any) => {
        this.soldCards = res.data.listings;
        this.soldCardsHold = this.soldCards;
        this.ownedCards = res.data.ownedListings;
        this.ownedCardsHold = this.ownedCards;
        this.likedCards = res.data.likedListings;
        this.likedCardsHold = this.likedCards;
        this.userReviews = res.data.reviews;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  addListedCard(
    authService,
    cardID: string,
    userID: string,
    listedCards: string[]
  ) {
    authService.user.listedCards = [...listedCards, cardID];

    setDoc(
      doc(this.firestore, 'Users', userID),
      {
        listedCards: [...listedCards, cardID],
      },
      { merge: true }
    );

    authService.refreshProfile.next(authService.user);
  }

  addOwnedCard(
    authService,
    cardID: string,
    userID: string,
    ownedCards: string[]
  ) {
    authService.user.ownedCards = [...ownedCards, cardID];

    setDoc(
      doc(this.firestore, 'Users', userID),
      {
        ownedCards: authService.user.ownedCards,
      },
      { merge: true }
    );

    authService.refreshProfile.next(authService.user);
  }

  // Return the cards to display in the profile view based on the value of 'selectedTab'
  getProfileCards(selectedTab) {
    if (selectedTab > 0 && selectedTab < 4) {
      switch (selectedTab) {
        case 1:
          return this.ownedCards;
        case 2:
          return this.soldCards;
        case 3:
          return this.likedCards;
        default:
          return [];
      }
    } else {
      return [];
    }
  }

  async updateProfileOptions(modalHelper) {
    await modalHelper.dismissPopover();

    const buttons = [
      {
        text: 'Update Bio',
        icon: 'accessibility-outline',
        handler: () => {
          this.changeBio(modalHelper);
        },
      },
      {
        text: 'Update Profile Picture',
        icon: 'image-outline',
        handler: () => {
          Camera.getPhoto({
            allowEditing: false,
            promptLabelHeader: 'Upload Profile Picture',
            quality: 20,
            resultType: CameraResultType.Base64,
          })
            .then((image) => {
              const blob = new Blob(
                [new Uint8Array(decode(image.base64String))],
                {
                  type: `image/${image.format}`,
                }
              );

              this.uploadProfilePhoto(blob, modalHelper);
            })
            .catch((e) => console.error(e));
        },
      },
      {
        text: 'Update Display Name',
        icon: 'text-outline',
        handler: () => {
          this.changeDisplayName(modalHelper);
        },
      },
      {
        text: 'Update External URL',
        icon: 'link-outline',
        handler: () => {
          this.changeUrl(modalHelper);
        },
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      },
    ];

    const selectedBtn = await modalHelper.createActionSheet({
      buttons,
    });
    buttons[selectedBtn].handler();
  }

  async uploadProfilePhoto(imageToUpload, modalHelper) {
    await this.presentImageLoading(modalHelper);
    const filePath =
      'UserImages/' +
      this.authService.user.uid +
      '/' +
      (Math.random() + 1).toString(36).substring(2);

    const storage = getStorage();
    const storageRef = ref(storage, filePath);

    try {
      if (this.authService.user.profilePic) {
        const oldRef = ref(storage, this.authService.user.profilePic);
        await deleteObject(oldRef);
      }
    } catch (err) {
      console.log('No old profile picture found.');
    }

    const uploadTask = uploadBytesResumable(storageRef, imageToUpload);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        this.loader.message =
          'Uploading Images... ' + Number(progress).toFixed(2) + '%';
      },
      (error: any) => {
        modalHelper.dismissLoader().then(() => {
          modalHelper.createAlert({
            title: 'An error occurred whilst upload your image',
            message: error.message,
          });
        });
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          if (downloadURL) {
            await setDoc(
              doc(this.firestore, 'Users', this.authService.user.uid),
              {
                profilePic: downloadURL,
              },
              {
                merge: true,
              }
            );

            await modalHelper.dismissLoader();

            this.authService.user.profilePic = downloadURL;
            this.authService.refreshProfile.next(this.authService.user);

            await modalHelper.createToast({
              title: 'Profile photo updated!',
              duration: 1000,
              position: 'bottom',
            });
          }
        });
      }
    );
  }

  async deleteAccount(modalHelper) {
    modalHelper.createAlert({
      title: 'Delete Account?',
      message:
        // eslint-disable-next-line @typescript-eslint/quotes
        "This will delete all account data and can't be undone. It is against " +
        ' terms of service to delete an account with outstanding orders to ship.',
      type: 'confirm',
      handler: async () => this.authService.destroy(null, false),
    });
  }

  async presentImageLoading(modalHelper) {
    this.loader = await modalHelper.createLiveLoader({
      message: 'Uploading... 0%',
    });

    this.loader.present();
  }

  changeDisplayName(modalHelper) {
    modalHelper.createAlert({
      title: 'New Display Name',
      inputText: this.authService.user.name,
      inputPlaceholder: 'New Display Name',
      buttonTitle: 'Update',
      type: 'prompt',
      handler: async (newName) => {
        await setDoc(
          doc(this.firestore, 'Users', this.authService.user.uid),
          { name: newName },
          {
            merge: true,
          }
        );

        this.authService.user.name = newName;
        this.authService.refreshProfile.next(this.authService.user);
      },
    });
  }
  async changeBio(modalHelper) {
    const MAX_CHARACTERS = 300;

    await modalHelper.createLegacyAlert({
      header: `New Bio (max ${MAX_CHARACTERS} chars.)`,
      message: ' ',
      inputs: [
        {
          name: 'bio',
          type: 'textarea',
          placeholder: 'New Bio',
          id: 'bio',
          value: this.authService.user.bio.replace(/<br\s*[\/]?>/gi, '\n'),
          attributes: {
            maxlength: MAX_CHARACTERS.toString(),
          },
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {},
        },
        {
          text: 'Update',
          role: 'submit',
          handler: async (data: { bio: string }) => {
            if (data.bio.length > MAX_CHARACTERS) {
              modalHelper.createAlert({
                title: 'Bio too long',
                message: `Your bio is too long. Please keep it under ${MAX_CHARACTERS} characters.`,
                type: 'alert',
              });
              return;
            }

            const cleanBio = data.bio.replace(/\r\n|\r|\n/g, '<br />');

            await setDoc(
              doc(this.firestore, 'Users', this.authService.user.uid),
              { bio: cleanBio },
              {
                merge: true,
              }
            );

            this.authService.user.bio = cleanBio;
            this.authService.refreshProfile.next(this.authService.user);
          },
        },
      ],
    });

    const textareaElement = document.querySelector('textarea');
    const characterCountElement = document.createElement('ion-text');
    characterCountElement.textContent = `${
      MAX_CHARACTERS - this.authService.user.bio.length
    } characters remaining`;
    characterCountElement.style.fontSize = '12px';
    characterCountElement.style.color = 'grey';
    characterCountElement.style.textAlign = 'right';
    characterCountElement.style.marginTop = '8px';
    textareaElement.parentNode.insertBefore(
      characterCountElement,
      textareaElement.nextSibling
    );

    textareaElement.addEventListener('input', () => {
      const remainingCharacters = MAX_CHARACTERS - textareaElement.value.length;
      characterCountElement.textContent = `${remainingCharacters} characters remaining`;
    });
  }

  async changeUrl(modalHelper) {
    await modalHelper.createAlert({
      title: 'New URL',
      type: 'prompt',
      buttonTitle: 'Update',
      inputText: this.authService.user.externalUrl,
      inputPlaceholder: 'New URL',
      handler: async (url) => {
        const cleanURL = this.sanitizeUrl(url);
        try {
          const updatedUser = await this.updateUserExternalUrl(
            this.authService.user.uid,
            cleanURL
          );
          this.authService.user.externalUrl = cleanURL;
          this.authService.refreshProfile.next(updatedUser);
        } catch (error) {
          console.error('Failed to update user external URL:', error);
          // Handle error and provide feedback to the user
        }
      },
    });
  }

  async updateUserExternalUrl(uid, url) {
    const userRef = doc(this.firestore, 'Users', uid);
    const updateData = { externalUrl: url };
    const updateOptions = { merge: true };

    await setDoc(userRef, updateData, updateOptions);

    return {
      ...this.authService.user,
      externalUrl: url,
    };
  }

  sanitizeUrl(url) {
    const protocolRemoved = url.replace(/^(https?:\/\/)?/i, '');
    const wwwRemoved = protocolRemoved.replace(/^www\./i, '');
    const trailingSlashRemoved = wwwRemoved.replace(/\/$/, '');

    return trailingSlashRemoved;
  }

  searchProfile(searchQuery) {
    const q = searchQuery.toLowerCase();
    this.isSearching = q.length !== 0;

    const queryMatch = (card) =>
      card.name.toLowerCase().includes(q) ||
      card.game.replace('é', 'e').toLowerCase().includes(q); // Special thing for pokemon :(

    this.soldCards = this.soldCardsHold.filter((card) => queryMatch(card));
    this.ownedCards = this.ownedCardsHold.filter((card) => queryMatch(card));
    this.likedCards = this.likedCardsHold.filter((card) => queryMatch(card));
  }

  isValidHttpUrl(url: string) {
    let isurl;

    try {
      isurl = new URL(url);
    } catch (_) {
      return false;
    }

    return isurl.protocol === 'http:' || isurl.protocol === 'https:';
  }

  applyFilters(by: string, isDecending: boolean, game: string, status: string) {
    // Create a filter function that returns true if the card's game
    // and status properties match the specified values
    const filterCards = (card: Listing) => {
      let gameMatch = true;
      let statusMatch = true;
      if (game !== 'All Games') {
        gameMatch = card.game === game;
      }
      if (status !== 'all') {
        if (status === 'pending') {
          statusMatch = ['pending', 'c_pending'].includes(card.status);
        } else {
          statusMatch = card.status === status;
        }
      }
      return gameMatch && statusMatch;
    };

    // Use the filter function to update the soldCards, ownedCards, and likedCards properties
    this.soldCards = this.soldCardsHold.filter(filterCards);
    this.ownedCards = this.ownedCardsHold.filter(filterCards);
    this.likedCards = this.likedCardsHold.filter(filterCards);

    // Sort the soldCards, ownedCards, and likedCards properties using the specified property
    this.soldCards.sort((a: Listing, b: Listing) => {
      if (a[by] < b[by]) {
        return -1;
      }
      if (a[by] > b[by]) {
        return 1;
      }
      return 0;
    });
    this.ownedCards.sort((a: Listing, b: Listing) => {
      if (a[by] < b[by]) {
        return -1;
      }
      if (a[by] > b[by]) {
        return 1;
      }
      return 0;
    });
    this.likedCards.sort((a: Listing, b: Listing) => {
      if (a[by] < b[by]) {
        return -1;
      }
      if (a[by] > b[by]) {
        return 1;
      }
      return 0;
    });

    // Reverse the arrays if the isDecending flag is true
    if (isDecending) {
      this.soldCards.reverse();
      this.ownedCards.reverse();
      this.likedCards.reverse();
    }
  }

  disableSearchMode() {
    this.searchMode = false;

    this.soldCards = this.soldCardsHold;
    this.ownedCards = this.ownedCardsHold;
    this.likedCards = this.likedCardsHold;
  }

  async getOrder(owner: string, id: string) {
    const matchingOrder = (
      await getDocs(collection(this.firestore, 'Users', owner, 'Orders'))
    ).docs
      .map((orderSnapshot) => orderSnapshot.data())
      .find((order) => order.cards.includes(id));

    return matchingOrder || null;
  }
}
