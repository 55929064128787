// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  --border-width: 0 !important;
  padding: 0 !important;
}

.no_messages {
  text-align: center;
  width: 50%;
  color: #c6c4c4;
  margin-top: 8vh;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.date_stamp {
  text-align: center;
  color: #7d7d7d;
  width: 100%;
  font-size: 12px;
  display: block;
  margin-bottom: 2vh;
}

ion-footer {
  background: #fef3e4;
}

.msg_wrap {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/messenger/messenger.page.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,qBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,UAAA;EACA,cAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,kBAAA;EACA,cAAA;EACA,WAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":["ion-toolbar {\n    --border-width: 0 !important;\n    padding: 0 !important;\n}\n\n.no_messages {\n    text-align: center;\n    width: 50%;\n    color: #c6c4c4;\n    margin-top: 8vh;\n    display: block;\n    margin-right: auto;\n    margin-left: auto;\n}\n\n.date_stamp {\n    text-align: center;\n    color: #7d7d7d;\n    width: 100%;\n    font-size: 12px;\n    display: block;\n    margin-bottom: 2vh;\n}\n\nion-footer {\n    background: #fef3e4;\n}\n\n.msg_wrap {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
