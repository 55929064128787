import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ColDef } from 'ag-grid-community';
import { ListingManagerService } from 'src/app/services/listing-manager.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { ProfileService } from 'src/app/services/profile.service';
import { ImageFormatterComponentComponent } from '../image-formatter-component/image-formatter-component.component';

@Component({
  selector: 'app-profile-aggrid-view',
  templateUrl: './profile-aggrid-view.component.html',
  styleUrls: ['./profile-aggrid-view.component.scss'],
})
export class ProfileAggridViewComponent implements OnInit {
  @Input() tab: number;

  domLayout = 'autoHeight';
  noRowsTemplate = `<span>no rows to show</span>`;

  columnDefs: ColDef[] = [
    {
      headerName: '',
      field: 'tcgImage',
      cellClass: 'imgCell',
      filter: false,
      sortable: false,
      pinned: true,
      width: 80,
      cellRenderer: ImageFormatterComponentComponent,
    },
    {
      field: 'status',
      width: 90,
      cellRenderer: (params) =>
        params.value === 'c_pending' ? 'pending' : params.value,
    },
    { field: 'name', maxWidth: 220 },
    {
      field: 'definedInitialPrice',
      headerName: 'Price',
      width: 100,
      filter: 'agNumberColumnFilter',
      cellRenderer: (params) => '$' + params.value,
    },
    {
      field: 'postDate',
      headerName: 'Listed',
      width: 130,
      filter: 'agDateColumnFilter',
      cellRenderer: (p) =>
        new Date(p.value.seconds * 1000).toLocaleString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          hour: 'numeric',
        }),
      // valueFormatter: (p) =>
      //   p.value.second ? String(new Date(p.value.second * 1000)) : p.value,
      // filterValueGetter: (p) =>
      //   p.data.value.second
      //     ? String(new Date(p.data.value.second * 1000))
      //     : p.data.value
      //     ? String(new Date(p.data.value.second * 1000))
      //     : p.data.value,
    },
    { field: 'game', maxWidth: 150 },
  ];

  defaultColDef: ColDef = {
    sortable: true,
    resizable: true,
    autoHeight: true,
    wrapText: true,
    filter: true,
    floatingFilter: true,
    suppressMovable: true,
  };

  constructor(
    public profileService: ProfileService,
    public listingManager: ListingManagerService,
    public modalHelper: ModalHelperService,
    public router: Router
  ) {}

  ngOnInit() {}

  openListing(card) {
    this.modalHelper.triggerListingPage(card);
  }
}
