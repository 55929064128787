import { Component, Input, OnInit } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { debounceTime, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { FilterService } from 'src/app/services/filter.service';
import { Listing } from 'src/types';

import { ModalHelperService } from '../../services/modal-helper.service';

@Component({
  selector: 'app-big-home-section',
  templateUrl: './big-home-section.component.html',
  styleUrls: ['./big-home-section.component.scss'],
})
export class BigHomeSectionComponent implements OnInit {
  @Input() title = '';
  @Input() shortHand = '';

  listings: Listing[] = [];

  filterGameSubscription: Subscription;

  loading: boolean;

  constructor(
    public fns: Functions,
    public modalHelper: ModalHelperService,
    public authService: AuthService,
    public filterService: FilterService
  ) {}

  ngOnInit() {
    this.filterService.gameFilterSub
      .pipe(debounceTime(100))
      .subscribe(() => this.pullListings());
  }

  async pullListings() {
    try {
      this.loading = true;
      this.listings = [];

      const followingPromise = this.authService.user
        ? Promise.resolve(this.authService.user.following || [])
        : Promise.resolve([]);

      this.listings =
        this.filterService.showingGames &&
        this.filterService.showingGames.length
          ? ((
              await httpsCallable(
                this.fns,
                'home-pullListings'
              )({
                games: this.filterService.showingGames,
                following: await followingPromise,
                type: this.shortHand,
                limit: 60,
              })
            ).data as Listing[])
          : [];

      this.loading = false;
    } catch (error) {
      console.error('Failed to pull listings:', error);
    }
  }
}
