// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stars {
  text-align: left;
  font-size: 8px;
  display: inline;
}

.green {
  color: green;
}`, "",{"version":3,"sources":["webpack://./src/app/components/tcg-data-card-unit/tcg-data-card-unit.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,cAAA;EACA,eAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":[".stars {\n    text-align: left;\n    font-size: 8px;\n    display: inline;\n}\n\n.green{\n    color: green;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
