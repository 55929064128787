/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input, OnInit } from '@angular/core';
import {
  collection,
  Firestore,
  doc,
  getDoc,
  getDocs,
  query,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { TrackingService } from '../services/tracking.service';

import {
  BarcodeScanner,
  SupportedFormat,
} from '@capacitor-community/barcode-scanner';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ListingManagerService } from '../services/listing-manager.service';
import { ModalHelperService } from '../services/modal-helper.service';
import { AdminService } from '../services/admin.service';
import { Order } from 'src/types';
import { MessengerService } from '../services/messenger.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.page.html',
  styleUrls: ['./orders.page.scss'],
})
export class OrdersPage implements OnInit {
  @Input() mySubject: BehaviorSubject<[]>;
  orders: Order[] = [];
  loading = true;

  user: any;

  constructor(
    public firestore: Firestore,
    public router: Router,

    public modalHelper: ModalHelperService,
    public trackingService: TrackingService,
    public authService: AuthService,
    public adminService: AdminService,
    public messengerService: MessengerService,
    public listingManager: ListingManagerService
  ) {}

  ngOnInit() {}

  ionViewDidEnter() {
    this.establish();
  }

  establish() {
    if (this.mySubject && this.mySubject.value) {
      this.processOrders(this.mySubject.value as []);
    } else {
      this.pullUsersOrders();
    }

    // Set loading to false after all orders have been processed
    this.loading = false;
  }

  manageDate(date) {
    return new Date(date.seconds * 1000);
  }

  async pullUsersOrders() {
    this.orders = [];

    // Use await and for-of loop to iterate over the array of orders
    const orders = (
      await getDocs(
        query(
          collection(
            this.firestore,
            'Users',
            String(this.authService.user.uid),
            'Orders'
          )
        )
      )
    ).docs.map((order) => order.data());

    this.processOrders(orders);
  }

  async processOrders(orders) {
    const pendingOrders: Order[] = []; // array to hold pending orders
    const completedOrders: Order[] = []; // array to hold completed orders

    // Iterate over orders and classify them as pending or completed
    for (const order of orders as any) {
      const data = order;
      if (data.status === 'pending') {
        pendingOrders.push(data);
      } else {
        completedOrders.push(data);
      }
    }

    orders = pendingOrders.concat(completedOrders);

    for (const order of orders) {
      // Initialize cardData property as an empty array
      order.cardData = [];

      // Use await to get the data for the owner of the order
      order.owner = (
        await getDoc(doc(this.firestore, 'Users', String(order.shippedTo)))
      ).data();

      // Use toFixed() method to round the trueTotalPrice to 2 decimal places
      order.trueTotalPrice = (order.totalPrice - order.feeAmount).toFixed(2);

      // Use for-of loop to iterate over the array of cards and push their data to cardData
      for (const card of order.cards) {
        order.cardData.push(
          (await getDoc(doc(this.firestore, 'Listings', String(card)))).data()
        );
      }

      // Push the processed order to the orders array
      if (!order.archived) {
        this.orders.push(order);
      }
    }
  }

  async openListing(card) {
    await this.modalHelper.dismissModal();
    this.modalHelper.triggerListingPage(card);
  }

  async giveTrackingDetails(order) {
    const buttons = [
      {
        text: 'Enter Tracking Code Manually',
        handler: () => this.manuallyEnterOrder(order),
      },
      {
        text: 'Scan Tracking Code',
        handler: async () => this.scanQRCode(order),
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      },
    ];

    const selectedBtn = await this.modalHelper.createActionSheet({
      title: 'How would you like to enter the tracking code?',
      buttons,
    });
    buttons[selectedBtn].handler();
  }

  async scanQRCode(order) {
    document.body.classList.add('qrscanner');

    const scannerBox = document.createElement('div');
    scannerBox.className += 'scanner-ui';
    scannerBox.id = 'scanner-box';
    scannerBox.innerHTML += `
    <div class="scanner-box animate__animated animate__backInUp">
      <span></span>
    </div>
    <div id="cancel-row" class="bottom-options animate__animated animate__slideInUp">
      <h3>Cancel Scan</h3>
    </div>`;
    document.body.parentNode.insertBefore(
      scannerBox,
      document.body.nextSibling
    );

    document
      .getElementById('cancel-row')
      .addEventListener('click', () => this.disableBarcodeScanner());

    if (this.modalHelper.isMobile) {
      StatusBar.setStyle({
        style: Style.Dark,
      });
    }

    const status = await BarcodeScanner.checkPermission({ force: true });

    if (status.denied) {
      const c = confirm(
        'If you want to grant permission for using your camera, enable it in the app settings.'
      );
      if (c) {
        BarcodeScanner.openAppSettings();
      }
    } else {
      BarcodeScanner.hideBackground();
      const result = await BarcodeScanner.startScan({
        targetedFormats: [SupportedFormat.QR_CODE, SupportedFormat.DATA_MATRIX],
      });

      if (result.hasContent) {
        this.disableBarcodeScanner();

        this.trackingService.submitTrack(
          result.content,
          order,
          this.authService.user
        );
      }
    }
  }

  disableBarcodeScanner() {
    document.body.classList.remove('qrscanner');

    BarcodeScanner.showBackground();
    BarcodeScanner.stopScan();

    document
      .getElementById('cancel-row')
      .removeEventListener('click', () => this.disableBarcodeScanner());

    document.getElementById('scanner-box').remove();

    if (this.modalHelper.isMobile) {
      StatusBar.setStyle({
        style: Style.Light,
      });
    }
  }

  manuallyEnterOrder(order) {
    // Create the first alert to prompt the user to enter a tracking number
    this.modalHelper.createAlert({
      title: 'Enter Tracking Number',
      message: 'Carefully enter your tracking number for this package',
      inputPlaceholder: '33VAW5276548',
      type: 'prompt',
      buttonTitle: 'Shipped',
      handler: (code) => this.confirmManualOrder(order, code),
    });
  }

  confirmManualOrder(order, code) {
    if (code.length >= 8 && code.length <= 40) {
      // If the tracking number is valid, create the second alert to confirm the tracking details
      this.modalHelper.createAlert({
        title: 'Confirm Tracking Details',
        message:
          'WARNING: Are you sure "' +
          code +
          '" is the correct tracking number? ' +
          'You can not change it later. It will be verified. If it is incorrect or not shipped you are liable to ' +
          'not receive your funds and/or be removed from our community. Please double-check that the entire order is fulfilled.',
        type: 'confirm',
        handler: async () => {
          if (code === environment.trackingCheatCode) {
            this.cheatTracking(order);
          } else {
            // Otherwise, submit the tracking number and update the orders
            await this.trackingService.submitTrack(
              code,
              order,
              this.authService.user
            );

            this.pullUsersOrders();
          }
        },
      });
    } else {
      alert(
        'This tracking number dosent seem legit... please contact us if it is.'
      );

      return false;
    }
  }

  async cheatTracking(order) {
    // If the tracking number is the cheat code, show a loading indicator and save the delivery status
    await this.modalHelper.createLoader({
      message: 'Confirming Tracking...',
    });

    this.trackingService.trackingCode = environment.trackingCheatCode;
    this.trackingService
      .saveDeliveryStatus(
        order,
        {
          code: environment.trackingCheatCode,
          courier: 'none',
          dateShipped: new Date(),
          shippedToAddress: '221 Fake Street',
          shippedToUser: this.authService.user.uid,
        },
        this.authService.user
      )
      .then(() => {
        this.pullUsersOrders();
      })
      .catch(async (e) => {
        await this.modalHelper.dismissLoader();
        this.modalHelper.createAlert({
          title:
            'There was an error confirming your tracking code. Please try again later.',
          message: JSON.stringify(e),
        });
      });
  }

  showAddress(address) {
    // this.modalHelper.writeToClipboard(address);
    this.modalHelper.createAlert({
      title: 'Shipping Address',
      message: address,
    });
  }

  closeOrders() {
    this.modalHelper.dismissModal();
  }

  trackProgress(order) {
    this.modalHelper.triggerOrderTracker(order);
  }
}
