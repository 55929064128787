// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filled {
  background: #ceae92;
  color: #fff3e4;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 12px;
}

.noUsrTest {
  text-align: center;
  width: 100%;
  margin-top: 20vh;
  color: #c7b09b;
}

ion-title {
  text-transform: capitalize;
}

.harvey {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  display: block;
  border-radius: 2px;
}`, "",{"version":3,"sources":["webpack://./src/app/follow/follow.page.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;AACJ;;AAEA;EACI,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,cAAA;AACJ;;AAEA;EACI,0BAAA;AACJ;;AAEA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,kBAAA;AACJ","sourcesContent":[".filled {\n    background: #ceae92;\n    color: #fff3e4;\n    padding-left: 10px;\n    padding-right: 10px;\n    font-size: 12px;\n}\n\n.noUsrTest {\n    text-align: center;\n    width: 100%;\n    margin-top: 20vh;\n    color: #c7b09b;\n}\n\nion-title {\n    text-transform: capitalize;\n}\n\n.harvey {\n    width: 100px;\n    height: 100px;\n    object-fit: contain;\n    margin-top: 30px;\n    margin-left: auto;\n    margin-right: auto;\n    display: block;\n    border-radius: 2px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
