/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input, OnInit } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CardEffectsService } from 'src/app/services/card-effects.service';
import { FilterService } from 'src/app/services/filter.service';
import { ListingManagerService } from 'src/app/services/listing-manager.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { homePromoSection, Listing } from 'src/types';

@Component({
  selector: 'app-home-section',
  templateUrl: './home-section.component.html',
  styleUrls: ['./home-section.component.scss'],
})
export class HomeSectionComponent implements OnInit {
  @Input() title = '';
  @Input() shortHand = '';
  @Input() large = false;
  @Input() promoSection: homePromoSection;
  //@Input() listings: Listing[] = [];
  listings: Listing[] = [];

  // filterGameSubscription: Subscription;

  loading = true;
  noData = false;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public fns: Functions,
    public authService: AuthService,
    public filterService: FilterService,
    public modalHelper: ModalHelperService,
    public cardEffectsService: CardEffectsService,
    public listingManager: ListingManagerService
  ) {}

  ngOnInit() {
    this.filterService.gameFilterSub
      .pipe(debounceTime(100))
      .subscribe((d) => d && this.pullListings());
  }

  async pullListings() {
    try {
      this.loading = true;
      this.listings = [];

      const following = (await this.authService.user?.following) || [];

      const games = this.filterService.showingGames || [];

      this.listings = games.length
        ? ((
            await httpsCallable(
              this.fns,
              'home-pullListings'
            )({
              games,
              following,
              type: this.shortHand,
              limit: 10,
            })
          ).data as Listing[])
        : [];

      this.noData = this.listings.length === 0;
      this.loading = false;
    } catch (error) {
      console.error('Failed to pull listings:', error);
    }
  }

  async toInfinity(type) {
    this.router.navigate([], {
      queryParams: {
        infinity: type,
      },
      queryParamsHandling: 'merge',
    });
  }

  manageDate(date) {
    return new Date(date['_seconds'] * 1000);
  }

  triggerTitle() {
    if (!this.title.includes('#')) {
      return;
    }

    this.modalHelper.share({
      title: this.title,
      text: 'Check out #' + this.title + ' on Cardboard Ninja',
      url: 'http://localhost:8100/home?infinity=promo_' + this.title,
      dialogTitle: 'Share this listing',
    });
  }

  trackByListing(index: number, listing: Listing) {
    return listing.id;
  }
}
