/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input, OnInit } from '@angular/core';
import {
  collection,
  doc,
  Firestore,
  getDocs,
  onSnapshot,
  query,
  where,
} from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { GesturesService } from 'src/app/services/gestures.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { ProfileService } from 'src/app/services/profile.service';
import { PushService } from 'src/app/services/push.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-profile-detail-chunk',
  templateUrl: './profile-detail-chunk.component.html',
  styleUrls: ['./profile-detail-chunk.component.scss'],
})
export class ProfileDetailChunkComponent implements OnInit {
  @Input() user: any;
  @Input() inModal: boolean;

  userSubscription: any;
  profileRefreshSub: Subscription;

  isFollowing: boolean;
  isUser: boolean;

  lastActive: any;

  soldLength = 0;
  sellingLength = 0;

  constructor(
    public gestureService: GesturesService,
    public userService: UserService,
    public firestore: Firestore,
    public authService: AuthService,
    public pushService: PushService,
    public modalHelper: ModalHelperService,
    public profileService: ProfileService
  ) {}

  ngOnInit() {
    const waitForUser = setInterval(() => {
      if (this.authService.gotAuth) {
        this.establish();
        clearInterval(waitForUser);
      }
    }, 300);
  }

  ionViewWillLeave() {
    this.userSubscription.unsubscribe();
    this.profileRefreshSub.unsubscribe();
  }

  hookIntoUser(uid) {
    this.userSubscription = onSnapshot(
      doc(this.firestore, 'Users', uid),
      (usrDoc) => {
        console.log('* Pulling data for user ' + uid + '...');
        this.user = usrDoc.data();
      }
    );
  }

  async establish() {
    if (
      !this.user ||
      !this.user['uid'] ||
      (this.authService.user && this.user['uid'] === this.authService.user.uid)
    ) {
      // Is the user
      this.isUser = true;

      this.profileRefreshSub = this.authService.refreshProfileSub.subscribe(
        async (d) => {
          if (d) {
            this.user = this.authService.user;
          }
        }
      );
    } else {
      // Is not the user
      this.isUser = false;

      this.hookIntoUser(this.user['uid'] || this.authService.user.uid);

      this.isFollowing =
        this.authService.user &&
        this.user['followers'] &&
        this.user['followers'].includes(this.authService.user.uid);
    }

    this.soldLength = (
      await getDocs(
        query(
          collection(this.firestore, 'Listings'),
          where('uploader', '==', String(this.user.uid)),
          where('status', '==', 'sold')
        )
      )
    ).size;

    this.sellingLength = (
      await getDocs(
        query(
          collection(this.firestore, 'Listings'),
          where('uploader', '==', String(this.user.uid)),
          where('status', '==', 'selling')
        )
      )
    ).size;
  }

  async openFollow(type: any) {
    this.modalHelper.triggerFollow(
      type,
      this.isUser ? this.authService.user : this.user
    );
  }

  async shareLink(url: string) {
    this.modalHelper.share({
      text: url,
      url,
    });
  }

  followUser() {
    if (this.authService.user) {
      this.isFollowing = true;
      this.userService
        .toggleFollow(this.user, this.authService.user)
        .then((res: any) => this.updateFollowers(res));
    } else {
      this.authService.notAuthedPopup();
    }
  }

  unFollowUser() {
    if (this.authService.user) {
      this.isFollowing = false;
      this.userService
        .toggleFollow(this.user, this.authService.user)
        .then((res: any) => this.updateFollowers(res));
    } else {
      this.authService.notAuthedPopup();
    }
  }

  async updateFollowers(res) {
    if (res) {
      await this.modalHelper.buzz();

      this.authService.user.followers = res.followers;
      this.authService.user.following = res.following;

      this.user['followers'] = res.followers;
    }
  }

  triggerSearch() {
    this.profileService.searchMode = !this.profileService.searchMode;

    setTimeout(
      () =>
        document
          .querySelectorAll('#profileFilterSearchInput input')
          .forEach((i) => (i as HTMLElement).focus()),
      700
    );
  }

  openCardView() {
    this.userService.cardView = true;

    //setTimeout(() => this.gestureService.clickUp(), 1000);
  }

  openEditProfile(isUser) {
    if (!isUser) {
      return;
    }

    this.profileService.updateProfileOptions(this.modalHelper);
  }
}
