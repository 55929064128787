/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  Firestore,
  setDoc,
  doc,
  collection,
  getDocs,
  getDoc,
  where,
  query,
} from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Listing, Review } from 'src/types';
import { AuthService } from '../services/auth.service';
import { CartService } from '../services/cart.service';
import { ListingManagerService } from '../services/listing-manager.service';
import { ModalHelperService } from '../services/modal-helper.service';
import { PushService } from '../services/push.service';

@Component({
  selector: 'app-write-review',
  templateUrl: './write-review.page.html',
  styleUrls: ['./write-review.page.scss'],
})
export class WriteReviewPage implements OnInit {
  @Input() mySubject: BehaviorSubject<any>;

  // @ViewChild('reviewInput', { static: false }) review: {
  //   value: '';
  // };
  // @ViewChild('reviewRange', { static: false }) reviewRange: {
  //   value: '';
  // };

  card: Listing;
  preReview: Review;
  reviewValue = 0;
  reviewString = '';

  stars = [
    {
      name: '1',
      clicked: false,
    },
    {
      name: '2',
      clicked: false,
    },
    {
      name: '3',
      clicked: false,
    },
    {
      name: '4',
      clicked: false,
    },
    {
      name: '5',
      clicked: false,
    },
  ];

  constructor(
    public firestore: Firestore,
    public authService: AuthService,
    public modalHelper: ModalHelperService,
    public pushService: PushService,
    public router: Router,

    public listingService: ListingManagerService,
    public cartService: CartService
  ) {}

  async ngOnInit() {
    await this.modalHelper.createLoader({});

    this.card = this.mySubject.value['card'];
    this.preReview = this.mySubject.value['preReview'];

    await this.modalHelper.dismissLoader();

    if (this.preReview) {
      this.reviewValue = Number(this.preReview.stars);
      this.reviewString = String(this.preReview.review);
    }
  }

  updateReview(e) {
    this.reviewValue = e.target.value;
  }
  updateReviewString(e) {
    this.reviewString = e.target.value;
  }

  writeReview() {
    this.modalHelper.dismissModal();

    this.modalHelper.createAlert({
      title: 'Item Received?',
      message:
        'By submitting this review you are confirming the item arrived and is in your possession. ' +
        'After submitting this review the listing will be marked as sold.',
      type: 'confirm',
      handler: async () => {
        this.setReviewData();
        this.notifySeller();

        const reviews = await this.getOwnerReviews();
        await this.setReviewTotal(reviews, this.reviewValue);

        this.cartService.isAdmin = false;
        await this.updateCardsStatus();

        this.modalHelper.createAlert({
          title: 'Review Written',
          message:
            'Thank you! This listing has been marked as sold and the seller has been notified.',
          type: 'confirm',
          handler: () => location.reload(),
        });
      },
    });
  }

  async setReviewData() {
    // Generate a random ID for the review
    const reviewId = (Math.random() + 1).toString(36).substring(2);

    // Save the review to Firestore using the generated ID
    await setDoc(
      doc(
        this.firestore,
        'Users',
        String(this.card.uploader),
        'Reviews',
        String(reviewId)
      ),
      {
        // The user ID of the person who wrote the review
        by: this.cartService.isAdmin ? 'Admin' : this.authService.user.uid,
        // The text of the review
        review: this.reviewString,
        // The number of stars given in the review (0-5)
        stars: this.reviewValue,
        // The ID of the listing that was reviewed
        listing: this.card.id,
        // The date and time when the review was written
        time: new Date(),
        // The ID of the review
        id: reviewId,
        // Whether the review has been archived
        archived: false,
      },
      // Set the merge option to true to ensure that any existing data is preserved
      {
        merge: true,
      }
    );
  }

  async getOwnerReviews() {
    // Get all the reviews for the listing owner
    const reviewsSnapshot = await getDocs(
      query(
        collection(this.firestore, 'Users', this.card.uploader, 'Reviews'),
        where('archived', '!=', true)
      )
    );

    // Return an array of the review data
    return reviewsSnapshot.docs.map((listing) => listing.data());
  }

  async updateCardsStatus() {
    await setDoc(
      doc(this.firestore, 'Listings', this.card.id),
      {
        status: 'sold',
        soldDate: Date.now(),
      },
      {
        merge: true,
      }
    );
  }

  async setReviewTotal(reviews, rating) {
    // Create an array to hold all the ratings
    const allRatings = [];

    // Add each rating to the array
    reviews.forEach((review: { stars: any }) => {
      allRatings.push(review.stars);
    });

    // Add the new rating to the array
    allRatings.push(rating);

    // Calculate the sum of all the ratings
    const sum = allRatings.reduce((a, b) => a + b, 0);

    // Calculate the average rating
    const avg = sum / allRatings.length || 0;

    // Save the average rating to Firestore
    await setDoc(
      doc(this.firestore, 'Users', this.card.uploader),
      {
        rating: avg,
      },
      // Set the merge option to true to ensure that any existing data is preserved
      {
        merge: true,
      }
    );

    // Clear the review input
    this.reviewValue = 0;
    this.reviewString = '';
  }

  async notifySeller() {
    const fcmToken = await getDoc(
      doc(this.firestore, 'Users', this.card.uploader)
    ).then((d) => d.data().fcmToken);

    return await this.pushService.send(
      fcmToken,
      '@' + this.authService.user.username,
      'Received your package & left a review!',
      '/profile',
      this.card.uploader,
      {
        type: 'purchase',
        from: this.authService.user.uid,
        listing: this.card.id,
      }
    );
  }
}
