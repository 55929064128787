/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/prefer-for-of */
import {
  Component,
  ElementRef,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { IonContent } from '@ionic/angular';
import { ModalHelperService } from '../services/modal-helper.service';
import { GesturesService } from '../services/gestures.service';
import { FilterService } from '../services/filter.service';
import { Subscription } from 'rxjs';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss'],
})
export class HomePage implements OnInit, OnDestroy {
  @ViewChild('homeWrap', { read: ElementRef }) homeWrap: ElementRef;
  @ViewChild(IonContent, { static: false }) private content: IonContent;

  triedOnce: boolean;

  promos: any;

  shadowY: string;

  loading = true;

  routeSubscription: Subscription;
  tabScrollSubscription: Subscription;

  constructor(
    public router: Router,
    public fns: Functions,
    public route: ActivatedRoute,
    public modalHelper: ModalHelperService,
    public filterService: FilterService,
    public userService: UserService,
    public gestureService: GesturesService,
    public authService: AuthService
  ) {
    this.establish();
  }

  async ngOnInit() {}

  ngOnDestroy() {
    this.routeSubscription && this.routeSubscription.unsubscribe();
    this.tabScrollSubscription && this.tabScrollSubscription.unsubscribe();
  }

  async establish() {
    await this.pullPromos();

    this.waitForAuth();
  }

  async waitForAuth() {
    if (!this.authService.gotAuth) {
      this.authService.waitForAuth().then(() => this.organiseHome());
    } else {
      this.organiseHome();
    }
  }

  async organiseHome() {
    if (!this.authService.gotAuth) {
      if (!this.triedOnce) {
        this.triedOnce = true;
        setTimeout(() => this.organiseHome(), 600);
      } else {
        console.log('Something went wrong. Please try again.');
      }

      return;
    }

    await this.filterService.pullSavedShowingGames(this.authService.user);

    this.manageRoutes();
    //this.setupSwipeGesture();
    this.watchForTabScroll();

    // Check if the user has seen the tutorial before
    if (!(await await this.modalHelper.getPreference('seenTutorial'))) {
      this.modalHelper.triggerWelcome();
    }

    this.loading = false;
  }

  async pullPromos() {
    this.promos = (
      await httpsCallable(this.fns, 'home-pullPromoData')({})
    ).data;
  }

  async manageRoutes() {
    // split the URL by '/' to get an array of strings
    const urlComponents = window.location.href.split('/');
    // get the last element of the array (which will be the string after the last '/' in the URL)
    const lastString = urlComponents.pop();

    // check if the first character of the last string is '@'
    if (lastString.charAt(0) === '@') {
      // get the user by querying the 'Users' collection for a document
      // with the 'username' field equal to the username (without the '@' prefix)
      const user = this.userService.pullUserDataFromUsername(
        lastString.replace('@', '')
      );

      // if the user exists, open their profile with the 'shop' tab
      if (user[0]) {
        this.openProfile(user[0], 'shop');
      } else {
        this.modalHelper.createAlert({
          title: 'User Not Found',
          message: 'The user you are trying to view does not exist.',
        });
      }
    }

    // subscribe to query parameters and check for the 'infinity' parameter
    this.routeSubscription = this.route.queryParams.subscribe((params) => {
      if (params.infinity) {
        // if the 'infinity' parameter exists, open the 'infinity' modal
        this.modalHelper.triggerInfinity(params.infinity);
      }
    });
  }

  async openProfile(owner, type) {
    this.modalHelper.triggerUserProfile(owner, type);
  }

  async doRefresh(event) {
    this.filterService.refreshGameFilter.next(true);

    setTimeout(() => {
      event.target.complete();
    }, 800);
  }

  watchForTabScroll() {
    this.tabScrollSubscription = this.authService.scrollPageSub.subscribe(
      (d) => {
        d && this.content.scrollToTop(400);
      }
    );
  }

  // GESUTRE METHODS
  async setupSwipeGesture() {
    // Check if profile card wrap element is defined
    if (!this.homeWrap) {
      // If not defined, set up function to check for it using requestAnimationFrame
      const checkForProfileCardWrap = () => {
        // Check if profile card wrap element is now defined
        if (this.homeWrap) {
          // Create swipe gesture with specified options
          this.gestureService.runBtmTabGestures(
            this.homeWrap.nativeElement,
            'home'
          );
        } else {
          // If not defined, request animation frame again to check for it later
          requestAnimationFrame(checkForProfileCardWrap);
        }
      };

      // Request animation frame to start checking for profile card wrap element
      requestAnimationFrame(checkForProfileCardWrap);
    } else {
      // Create swipe gesture with specified options
      this.gestureService.runBtmTabGestures(
        this.homeWrap.nativeElement,
        'home'
      );
    }
  }
}
