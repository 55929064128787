import { Component, Input, OnInit } from '@angular/core';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { Listing } from 'src/types';

@Component({
  selector: 'app-msg-context-row',
  templateUrl: './msg-context-row.component.html',
  styleUrls: ['./msg-context-row.component.scss'],
})
export class MsgContextRowComponent implements OnInit {
  @Input() card: Listing;
  @Input() owner: Listing;

  constructor(public modalHelper: ModalHelperService) {}

  ngOnInit() {}

  async openCard() {
    this.modalHelper.triggerListing(this.card, this.owner);
  }

  async openTags(tag: any) {
    this.modalHelper.triggerTags(tag);
  }
}
