import { Component, OnInit } from '@angular/core';
import { FilterService } from 'src/app/services/filter.service';
import { ListingManagerService } from 'src/app/services/listing-manager.service';

@Component({
  selector: 'app-game-filter-popover',
  templateUrl: './game-filter-popover.component.html',
  styleUrls: ['./game-filter-popover.component.scss'],
})
export class GameFilterPopoverComponent implements OnInit {
  constructor(
    public filterService: FilterService,
    public listingManager: ListingManagerService
  ) {}

  ngOnInit() {}

  checkFilter(name) {
    return this.filterService.showingGames.filter((c) => c === name).length > 0;
  }

  selectAll() {
    this.listingManager.games.forEach((game) => {
      this.filterService.showingGames.push(game.name);
    });

    this.filterService.refreshGameFilter.next(true);
  }

  unselectAll() {
    this.filterService.showingGames = [];
    this.filterService.refreshGameFilter.next(true);
  }
}
