import { Component, Input, OnInit } from '@angular/core';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { Listing, User } from 'src/types';
import { ModalHelperService } from '../services/modal-helper.service';

@Component({
  selector: 'app-listing-likes',
  templateUrl: './listing-likes.page.html',
  styleUrls: ['./listing-likes.page.scss'],
})
export class ListingLikesPage implements OnInit {
  @Input() listingID: BehaviorSubject<[]>;

  usersWhoHaveLiked: User[] = [];

  noLikes = false;
  loading = true;

  constructor(
    public firestore: Firestore,
    public modalHelper: ModalHelperService
  ) {}

  ngOnInit() {
    this.pullCard();
  }

  closeLikes() {
    this.modalHelper.dismissModal();
  }

  private async pullCard() {
    const listing = (
      await getDoc(
        doc(this.firestore, 'Listings', String(this.listingID.value))
      )
    ).data();

    if (!listing || !listing.likedFolks) {
      this.loading = false;
      return (this.noLikes = true);
    }

    this.pullAllUsers(listing.likedFolks);
  }

  private async pullAllUsers(likedFolks) {
    // Create an empty array to store the user details
    const users = [];

    // Iterate over the list of users
    for (const user of likedFolks) {
      // Get the document for the current user
      const listingDoc = doc(this.firestore, 'Users', user);

      // Get the data for the user's document and add it to the array
      const endOutput = (await getDoc(listingDoc)).data();
      if (endOutput) {
        users.push(endOutput);
      }
    }

    // Assign the array of user details to the component's userDetails property
    this.usersWhoHaveLiked = users;
    this.loading = false;
    this.noLikes = false;
  }
}
