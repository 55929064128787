/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Injectable } from '@angular/core';
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  signInWithCredential,
  OAuthProvider,
  GoogleAuthProvider,
  FacebookAuthProvider,
  UserInfo,
  deleteUser,
  reauthenticateWithCredential,
} from '@angular/fire/auth';
import {
  collection,
  doc,
  Firestore,
  getDoc,
  query,
  setDoc,
  where,
  Timestamp,
  getDocs,
  onSnapshot,
} from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';

import { FacebookLogin } from '@capacitor-community/facebook-login';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import {
  SignInWithApple,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';
import { initializeApp } from '@angular/fire/app';
import { environment } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/types';

import { ModalHelperService } from './modal-helper.service';
import { SplashScreen } from '@capacitor/splash-screen';

import { Device } from '@capacitor/device';
import { StatusBar, Style } from '@capacitor/status-bar';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';

import {
  ScreenOrientation,
  OrientationType,
} from '@capawesome/capacitor-screen-orientation';
import { Network } from '@capacitor/network';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  auth: any;

  holdUser: any;
  user: User;
  holdUserData: User;

  isDown: boolean;
  gotAuth: boolean;
  pingedOnce: boolean;
  signingUp: boolean;
  authProcessCompleteCalled: boolean;
  initalLogin = false;

  appleEmail: string;

  killWatch: any;

  refreshProfile: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  refreshProfileSub: Observable<User> = this.refreshProfile.asObservable();

  scrollPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  scrollPageSub: Observable<boolean> = this.scrollPage.asObservable();

  constructor(
    public modalHelper: ModalHelperService,
    public router: Router,

    public route: ActivatedRoute,
    public firestore: Firestore
  ) {
    this.preLoadNinja();
  }

  async preLoadNinja() {
    this.initAuthServicesForRegister();
  }

  initAuthServicesForRegister() {
    // Initialize Facebook Login
    FacebookLogin.initialize({ appId: '112205865951190' }).catch((e) =>
      console.log(e)
    );

    // Determine the correct Google Auth client ID based on the platform
    let googleAuthClientID = environment.webGoogleClientID;
    if (Capacitor.isNativePlatform()) {
      if (Capacitor.getPlatform() === 'ios') {
        googleAuthClientID = environment.iosGoogleClientID;
      } else {
        googleAuthClientID = environment.androidGoogleClientID;
      }
    }

    // Initialize Google Auth with the correct client ID
    GoogleAuth.initialize({
      clientId: googleAuthClientID,
      scopes: ['profile', 'email'],
      grantOfflineAccess: true,
    });
  }

  async establish() {
    try {
      // Initialize the Firebase app and get the authentication object
      this.auth = getAuth(initializeApp(environment.firebase));

      // Watch for changes in auth state
      await this.auth.onAuthStateChanged(this.onAuthStateChanged.bind(this));
    } catch (error) {
      console.error(error);
    }
  }

  async onAuthStateChanged(user: UserInfo) {
    if (
      !user ||
      (await this.modalHelper.getPreference('midLoggingIn')) === 'true'
    ) {
      this.noUser();
    } else {
      this.loadUser(user.uid);
    }

    this.modalHelper.dismissToast();
  }

  async waitForAuth() {
    while (!this.gotAuth) {
      await this.sleep(200);
    }

    return 'done';
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async loadUser(uid: string) {
    onSnapshot(doc(this.firestore, 'Users', uid), (u) => {
      const userData = u.data() as User;

      if (
        !userData ||
        !userData.uid ||
        !userData.email ||
        !userData.username ||
        !userData.phoneNumber
      ) {
        this.noUser();
        return;
      }

      if (userData.isBanned || userData.isRemoved) {
        this.authError(
          'You have been banned or removed. Please contact support.'
        );
        this.noUser();
        return;
      }

      this.user = userData;

      this.gotAuth = true;
      this.refreshProfile.next(this.user);

      if (!this.authProcessCompleteCalled) {
        this.authProcessComplete();
        this.authProcessCompleteCalled = true;
      }
    });

    onSnapshot(doc(this.firestore, 'Ratings', uid), (u) => {
      if(!this.user){
        return;
      }
      this.user.rating = u.data() ? u.data().rating : 0;
    });

    this.appReady();
  }

  async noUser() {
    this.gotAuth = true;

    this.appReady();
  }

  async appReady() {
    if (this.modalHelper.isMobile) {
      await SplashScreen.hide();

      setTimeout(() => {
        // Set the style and background color of the status bar
        StatusBar.setStyle({ style: Style.Light });
        StatusBar.setBackgroundColor({ color: '#fff2e5' });
        ScreenOrientation.lock({ type: OrientationType.PORTRAIT });

        // If the platform is Android
        if (this.modalHelper.isAndroid) {
          // Set the color of the navigation bar
          NavigationBar.setColor({
            color: '#fff2e5',
            darkButtons: false,
          });
        }
      }, 1000);
    }

    this.modalHelper.dismissLoader();
    this.modalHelper.dismissToast();
  }

  async login(userData) {
    return new Promise(async (resolve, reject) => {
      try {
        // Attempt to sign in with the provided email and password
        const user = await signInWithEmailAndPassword(
          getAuth(),
          userData.email,
          userData.password
        );
        resolve(user);
      } catch (err) {
        // If an error occurs, dismiss the loading message and display an error
        this.modalHelper.dismissLoader();
        this.authError(err.code);
        reject('No Auth');
      }
    });
  }

  async signup(user) {
    return new Promise(async (resolve, reject) => {
      // Attempt to create user with email and password
      try {
        const userCredential = await createUserWithEmailAndPassword(
          this.auth,
          user.email,
          user.password
        );
        user.email = userCredential.user.email;
        user.uid = userCredential.user.uid;

        resolve(user);
      } catch (error) {
        // Dismiss loading indicator and show error message
        await this.modalHelper.dismissLoader();

        if (error.code === 'auth/email-already-in-use') {
          resolve(this.removeAccountIfNoData(user));
        } else {
          this.authError(error.code);

          reject('No Auth');
        }
      }
    });
  }

  async removeAccountIfNoData(user) {
    console.log(this.auth.currentUser);

    const querySnapshot = await getDocs(
      query(
        collection(this.firestore, 'Users'),
        where('email', '==', user.email)
      )
    );

    if (querySnapshot.size === 0) {
      deleteUser(this.auth.currentUser)
        .then(() => {
          this.signup(user);
          return;
        })
        .catch(async (error) => {
          if (error.code === 'auth/requires-recent-login') {
            //Reauthenticate user!

            const userCredential = await reauthenticateWithCredential(
              this.auth.currentUser,
              this.auth.currentUser.providerData[0].providerId
            );
            user.email = userCredential.user.email;
            user.uid = userCredential.user.uid;

            return user;
          } else {
            this.authError(error.code);
          }
        });
    } else {
      this.authError('auth/email-already-in-use');
    }
  }

  async loginWithGoogle(): Promise<string> {
    // Display a loading indicator to the user
    this.triggerLoginAlert();

    try {
      // Attempt to login with Google
      const googleUser = await GoogleAuth.signIn();

      // Extract the ID token from the Google user object
      const idToken = googleUser.authentication.idToken;

      // Create a Google credential using the ID token
      const credential = GoogleAuthProvider.credential(idToken);

      // Authorize the credential with Firebase
      const cred = await this.authorizeCred(credential);

      return cred;
    } catch (error) {
      // If the login fails, dismiss the loading indicator
      this.modalHelper.dismissLoader();
    }
  }

  async loginWithFacebook(): Promise<string> {
    // Display a loading indicator to the user
    this.triggerLoginAlert();

    try {
      // Attempt to login with Facebook
      const facebookResponse = await FacebookLogin.login({
        permissions: ['email'],
      });

      // Extract the Facebook access token from the response object
      const accessToken = facebookResponse.accessToken.token;

      // Create a Facebook credential using the access token
      const credential = FacebookAuthProvider.credential(accessToken);

      // Authorize the credential with Firebase
      const cred = await this.authorizeCred(credential);

      return cred;
    } catch (error) {
      // If the login fails, dismiss the loading indicator
      this.modalHelper.dismissLoader();
    }
  }

  async loginWithApple(): Promise<string> {
    // Display a loading indicator to the user
    this.triggerLoginAlert();

    try {
      // Create a new OAuth provider for Apple
      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');

      // Configure options for signing in with Apple
      const options: SignInWithAppleOptions = {
        clientId: 'com.cardboardninja.apple-signin',
        redirectURI: '/',
        scopes: 'email',
      };

      // Attempt to sign in with Apple
      const appleResponse = await SignInWithApple.authorize(options);

      // Extract the Apple identity token from the response object
      const idToken = appleResponse.response.identityToken;

      // Create an Apple credential using the identity token
      const credential = provider.credential({ idToken });

      // Authorize the credential with Firebase
      const cred = await this.authorizeCred(credential);

      return cred;
    } catch (error) {
      // If the login fails, dismiss the loading indicator
      this.modalHelper.dismissLoader();
    }
  }

  async triggerLoginAlert() {
    await this.modalHelper.setPreference('midLoggingIn', 'true');

    await this.modalHelper.buzz();

    this.modalHelper.createLoader({
      message: 'Authorising Account..',
    });
  }

  async authorizeCred(credential) {
    this.holdUser = (await signInWithCredential(this.auth, credential)).user;

    this.holdUserData = (
      await getDoc(doc(this.firestore, 'Users', String(this.holdUser.uid)))
    ).data() as User;

    this.modalHelper.dismissLoader();

    if (this.holdUserData && this.holdUserData.name) {
      // const completed2FAWithinTheHour = this.holdUserData.last2FA
      //   ? new Date().getTime() - this.holdUserData.last2FA.toDate().getTime() <
      //     60 * 60 * 1000
      //   : 0;

      // return completed2FAWithinTheHour ? 'login' : 'phoneAuth';

      return 'phoneAuth';
    } else {
      return 'signup';
    }
  }

  async fulfillUser(user: User) {
    this.modalHelper.createLoader({});

    const userID = String(this.holdUser?.uid ?? user.uid);

    try {
      // Check that the user has a uid
      if (!userID) {
        throw new Error('User does not have a uid');
      }

      // Update the `last2FA` & `lastLogged` fields in the user's document in Firestore
      await setDoc(
        doc(this.firestore, 'Users', String(userID)),
        {
          last2FA: Timestamp.now(),
          lastLogged: Timestamp.now(),
        },
        { merge: true }
      );

      await this.loadUser(userID);

      if (!this.user || !this.user.sellerVerified) {
        const waiter = setInterval(async () => {
          if (this.gotAuth) {
            clearInterval(waiter);

            await this.router.navigateByUrl('/home');

            setTimeout(
              () =>
                this.modalHelper.createToast({
                  message:
                    'Welcome to Cardboard Ninja' + (this.user && this.user.name
                      ? ', ' + this.user.name + '!'
                      : '!'),
                  duration: 3000,
                }),
              1000
            );
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  async authProcessComplete() {
    await this.modalHelper.setPreference('midLoggingIn', 'false');
    this.modalHelper.dismissLoader();
  }

  async saveUserDetails(user) {
    // Return early if user object is not defined
    if (!user) {
      return false;
    }

    // Set default values for user properties
    user.confirmPassword = null;
    user.password = null;
    user.rating = 0;
    user.signedUpDate = new Date();
    user.isRemoved = false;
    user.roles = ['baseUser'];

    user.deviceUUID = (await Device.getId()).identifier || 'no_device_id';

    user.last2FA = new Date();

    // Replace line breaks with <br /> in user bio
    if (user.bio) {
      user.bio = user.bio.replace(/\r\n|\r|\n/g, '<br />');
    }

    // Set affiliate if it exists in the query params
    if (this.route.snapshot.queryParamMap.get('aff')) {
      user.affiliate = this.route.snapshot.queryParamMap.get('aff');
    }

    try {
      // Save user details to Firestore
      await setDoc(doc(this.firestore, 'Users', String(user.uid)), user, {
        merge: true,
      });

      // Return success
      return true;
    } catch (error) {
      // Return error if saving to Firestore fails
      return error;
    }
  }

  async signout(pushService) {
    // Return early if user or auth objects are not defined
    if (!this.user || !this.auth) {
      return false;
    }

    try {
      await this.modalHelper.createLoader({
        message: 'Signing you out..',
      });

      // Clear FCM token for user
      if (pushService && this.user.fcmToken && this.modalHelper.isMobile) {
        await pushService.clearFCMToken(this.user.uid);
      }

      // Sign out user
      await signOut(this.auth);
      this.user = null;
      this.auth = null;

      // Reload page
      location.reload();
    } catch (error) {
      // Dismiss loading spinner and show error message
      this.modalHelper.dismissLoader();
      this.authError(error);
    }
  }

  async destroy(uid, isAdmin) {
    try {
      await this.modalHelper.createLoader({
        message: 'Removing account..',
      });

      // Get all listings uploaded by the user
      const userListingData = (
        await getDocs(
          query(
            collection(this.firestore, 'Listings'),
            where('uploader', '==', uid || this.user.uid)
          )
        )
      ).docs.map((listing) => listing.data());

      // Set the status of all user listings to "removed"
      if (userListingData && userListingData.length) {
        userListingData.forEach(async (listing) => {
          await setDoc(
            doc(this.firestore, 'Listings', String(listing.id)),
            { status: 'removed' },
            { merge: true }
          );
        });
      }

      // Set the "isRemoved" flag on the user's Firebase document
      await setDoc(
        doc(this.firestore, 'Users', String(uid || this.user.uid)),
        { isRemoved: true },
        { merge: true }
      );

      if (!isAdmin) {
        // If the user is not an admin, sign them out
        this.signout(null);
      } else {
        // Otherwise, display an error message
        this.authError('User Removed');
      }
    } catch (err) {
      // Handle any errors that may have occurred during the removal process
      this.modalHelper.dismissLoader();
      this.authError(err);
    }
  }

  async ban(uid) {
    await this.modalHelper.createLoader({
      message: 'Banning account..',
    });

    await setDoc(
      doc(this.firestore, 'Users', String(uid || this.user.uid)),
      { isBanned: true },
      { merge: true }
    );

    this.authError('User Banned');
  }

  async makeSupportAccount(user) {
    if (
      confirm(
        'Are you sure you want to make (or unmake) this user an official support account?'
      )
    ) {
      user.isSupport = !user.isSupport;

      await setDoc(
        doc(this.firestore, 'Users', user.uid),
        {
          isSupport: user.isSupport,
        },
        {
          merge: true,
        }
      );

      this.modalHelper.createAlert({
        title:
          '@' +
          user.username +
          ' ' +
          (user.isSupport
            ? 'is an offical support account now'
            : 'is no longer an offical support account'),
      });
    }
  }

  async makeAccountStarSeller(user) {
    if (!user) {
      return false;
    }

    // Ask user for confirmation
    const confirmMessage =
      'Are you sure you want to ' +
      (user.isStarSeller ? 'make' : 'unmake') +
      ' this user a star seller, signifying endorsement from Cardboard Ninja?';

    if (!confirm(confirmMessage)) {
      return false;
    }

    // Update isReported property of user object
    user.isStarSeller = !user.isStarSeller;

    try {
      // Save updated isReported property to Firestore
      await setDoc(
        doc(this.firestore, 'Users', user.uid),
        {
          isStarSeller: user.isStarSeller,
        },
        {
          merge: true,
        }
      );

      this.modalHelper.createAlert({
        title:
          '@' +
          user.username +
          ' ' +
          (user.isStarSeller
            ? ' is a star seller!'
            : ' is no longer a star seller'),
      });

      // Return success
      return true;
    } catch (error) {
      // Return error if saving to Firestore fails
      return error;
    }
  }

  async reportAccount(user) {
    if (!user) {
      return false;
    }

    // Ask user for confirmation
    const confirmMessage =
      'Are you sure you want to ' +
      (user.isReported ? 'unreport' : 'report') +
      ' this user?';

    if (!confirm(confirmMessage)) {
      return false;
    }

    // Update isReported property of user object
    user.isReported = !user.isReported;

    try {
      // Save updated isReported property to Firestore
      await setDoc(
        doc(this.firestore, 'Users', user.uid),
        {
          isReported: user.isReported,
        },
        {
          merge: true,
        }
      );

      this.modalHelper.createAlert({
        title:
          '@' +
          user.username +
          ' ' +
          (user.isReported ? 'reported' : 'unreported'),
      });

      // Return success
      return true;
    } catch (error) {
      // Return error if saving to Firestore fails
      return error;
    }
  }

  triggerErrors() {
    const inputs = document.querySelectorAll('input');
    inputs &&
      inputs.forEach((input) => {
        !input.value || input.value === ''
          ? input.classList.add('invalid')
          : input.classList.remove('invalid');
      });
  }

  async monitoringAccount(user) {
    if (!user) {
      return false;
    }

    // Ask user for confirmation
    const confirmMessage =
      'Are you sure you want to ' +
      (!user.isUnderMonitoring ? 'monitor' : 'unmonitor') +
      ' this user?';
    if (!confirm(confirmMessage)) {
      return false;
    }

    // Update isReported property of user object
    user.isUnderMonitoring = !user.isUnderMonitoring;

    try {
      // Save updated isUnderMonitoring property to Firestore
      await setDoc(
        doc(this.firestore, 'Users', user.uid),
        {
          isUnderMonitoring: user.isUnderMonitoring,
        },
        {
          merge: true,
        }
      );

      this.modalHelper.createAlert({
        title:
          '@' +
          user.username +
          ' ' +
          (user.isUnderMonitoring
            ? 'is tagged as monitored'
            : 'is untagged as monitored'),
      });

      // Return success
      return true;
    } catch (error) {
      // Return error if saving to Firestore fails
      return error;
    }
  }

  async toggleBlockingUser(user, isBlocked) {
    if (!user) {
      return false;
    }

    const blockTxt = isBlocked ? 'unblock' : 'block';
    const currentBlockList = this.user.blockList || [];

    this.modalHelper.createAlert({
      title: blockTxt + ' @' + user.username + '?',
      message: 'Are you sure you want to ' + blockTxt + ' this user?',
      type: 'confirm',
      buttonTitle: blockTxt + ' user',
      handler: async () =>
        setDoc(
          doc(this.firestore, 'Users', this.user.uid),
          {
            blockList: isBlocked
              ? currentBlockList.filter((uid) => uid !== user.uid)
              : [...currentBlockList, user.uid],
          },
          {
            merge: true,
          }
        ).then(() => {
          this.modalHelper.createAlert({
            title: 'Success',
            message: '@' + user.username + ' ' + blockTxt + 'ed',
          });
        }),
    });
  }

  async authError(error) {
    this.modalHelper.createAlert({
      title: 'Authentication Error',
      message: error,
    });
  }

  async forgotPassword(email) {
    return sendPasswordResetEmail(this.auth, email);
  }

  notAuthedPopup() {
    this.modalHelper.createToast({
      message: 'You must be logged in to do that.',
      duration: 3000,
      position: 'bottom',
      color: 'danger',
      buttons: [
        {
          icon: 'log-in-outline',
          handler: () => {
            this.modalHelper.dismissLoader().then(() => {
              this.modalHelper.dismissLoader().then(() => {
                this.router.navigate(['/profile']);
              });
            });
          },
        },
      ],
    });
  }

  triggerTopScroll() {
    this.scrollPage.next(true);
  }

  async openOrders(orders?) {
    this.modalHelper.triggerOrders(orders);
  }

  async checkForInternet() {
    // If the current platform is a native platform
    if (Capacitor.isNativePlatform) {
      try {
        // Get the current network status
        const status = await Network.getStatus();

        // If the network is connected, call the internetVerified function
        // Otherwise, set the hasInternet flag to false
        if (status.connected) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        // If there is an error, set the hasInternet flag to false
        return false;
      }
    }
  }

  watchForKillSwitch() {
    if (this.killWatch) this.killWatch.unsubscribe();

    this.killWatch = onSnapshot(
      doc(this.firestore, 'Admin', 'Controls'),
      (doc) => (this.isDown = doc.data().isDown)
    );
  }
}
