/* eslint-disable @typescript-eslint/dot-notation */
import { Component, Input, OnInit } from '@angular/core';
import { doc, Firestore, getDoc } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ModalHelperService } from '../services/modal-helper.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-follow',
  templateUrl: './follow.page.html',
  styleUrls: ['./follow.page.scss'],
})
export class FollowPage implements OnInit {
  @Input() mySubject: BehaviorSubject<string>;

  type: string;
  followArray = [];
  user: any = [];

  constructor(
    public firestore: Firestore,
    public authService: AuthService,
    public userService: UserService,
    public modalHelper: ModalHelperService
  ) {}

  ngOnInit() {
    this.type = this.mySubject.value['type'];
    this.user = this.mySubject.value['user'];

    if (this.type === 'followers') {
      this.generateFollowersArray();
    } else if (this.type === 'following') {
      this.generateFollowingArray();
    }
  }

  async generateFollowersArray() {
    if (this.user.followers && this.user.followers.length) {
      await this.user.followers.forEach(async (follower) =>
        this.followArray.push(await this.getUser(follower))
      );

      this.followArray.forEach((follower, key) => {
        if (
          this.authService.user.followers.filter((c) => c === follower).length
        ) {
          this.followArray[key].isFollowingLv = true;
        } else {
          this.followArray[key].isFollowingLv = false;
        }
      });
    }
  }

  generateFollowingArray() {
    if (this.user.following && this.user.following.length) {
      this.user.following.forEach((follower) => {
        this.getUser(follower).then((user) => {
          this.followArray.push(user);
        });
      });
    }
  }

  async getUser(id) {
    const userData = (await getDoc(doc(this.firestore, 'Users/' + id))).data();

    if (userData) {
      return userData;
    }

    return this.userService.removeUnknownFollower(id);
  }

  async openProfile(owner) {
    this.modalHelper.triggerUserProfile(owner, this.type);
  }
}
