// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chips {
  display: inline-flex;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-right: 10px;
  width: 100%;
}
.chips .min-chip {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  min-width: 200px;
  max-width: 200px;
  overflow: hidden;
  margin: 10px;
  margin-right: 3px;
  padding: 7px;
  padding-left: 16px;
  border-radius: 4px;
  border-left: 3px solid rgba(180, 147, 130, 0.7294117647);
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.chips .min-chip h4,
.chips .min-chip h5 {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.chips .min-chip h4 {
  font-size: 10px;
  color: #a5a5a5;
}
.chips .min-chip h5 {
  font-size: 14px;
  color: rgb(62, 62, 62);
}

.center_spinner {
  margin-top: 10px;
  margin-bottom: 10px;
}

.center_spinner ion-spinner {
  width: 25px !important;
  height: 25px !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/set-slide/set-slide.component.scss"],"names":[],"mappings":"AAAA;EACE,oBAAA;EACA,kBAAA;EACA,kBAAA;EACA,mBAAA;EACA,WAAA;AACF;AACE;EACE,gBAAA;EACA,+CAAA;EACA,gBAAA;EACA,gBAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EAEA,wDAAA;EACA,4BAAA;EACA,yBAAA;AAAJ;AAEI;;EAEE,SAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;AAAN;AAGI;EACE,eAAA;EACA,cAAA;AADN;AAII;EACE,eAAA;EACA,sBAAA;AAFN;;AAOA;EACE,gBAAA;EACA,mBAAA;AAJF;;AAOA;EACE,sBAAA;EACA,uBAAA;AAJF","sourcesContent":[".chips {\n  display: inline-flex;\n  overflow-x: scroll;\n  overflow-y: hidden;\n  padding-right: 10px;\n  width: 100%;\n\n  .min-chip {\n    background: #fff;\n    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;\n    min-width: 200px;\n    max-width: 200px;\n    overflow: hidden;\n    margin: 10px;\n    margin-right: 3px;\n    padding: 7px;\n    padding-left: 16px;\n    border-radius: 4px;\n\n    border-left: 3px solid #b49382ba;\n    border-bottom-left-radius: 0;\n    border-top-left-radius: 0;\n\n    h4,\n    h5 {\n      margin: 0;\n      white-space: nowrap;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }\n\n    h4 {\n      font-size: 10px;\n      color: #a5a5a5;\n    }\n\n    h5 {\n      font-size: 14px;\n      color: rgb(62, 62, 62);\n    }\n  }\n}\n\n.center_spinner {\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.center_spinner ion-spinner {\n  width: 25px !important;\n  height: 25px !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
