// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-avatar {
  width: 30px;
  height: 30px;
  margin-right: 14px;
  margin-top: 6px;
}

.empty_txt {
  text-align: center;
  margin-top: 40%;
  color: #d4d4d4;
}`, "",{"version":3,"sources":["webpack://./src/app/listing-likes/listing-likes.page.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AAEA;EACI,kBAAA;EACA,eAAA;EACA,cAAA;AACJ","sourcesContent":["ion-avatar {\n    width: 30px;\n    height: 30px;\n    margin-right: 14px;\n    margin-top: 6px;\n}\n\n.empty_txt {\n    text-align: center;\n    margin-top: 40%;\n    color: #d4d4d4;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
