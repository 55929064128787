import { Component, Input } from '@angular/core';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Listing } from '../../../types/index';
import { AuthService } from 'src/app/services/auth.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { ListingManagerService } from 'src/app/services/listing-manager.service';

@Component({
  selector: 'app-set-viewer',
  templateUrl: './set-viewer.component.html',
  styleUrls: ['./set-viewer.component.scss'],
})
export class SetViewerComponent {
  @Input() card: Listing;

  set: any = [];
  pulledFullSet = false;
  setGrabFailed = false;

  constructor(
    public fns: Functions,
    public modalHelper: ModalHelperService,
    public listingManagerService: ListingManagerService,
    public authService: AuthService
  ) {
    const timer = setInterval(() => {
      const groupID = this.card.tcgGroupID
        ? this.card.tcgGroupID
        : this.card.relatedTCGCards
        ? this.card.relatedTCGCards[0].groupId
        : null;

      if (groupID) {
        this.pullSetData(groupID);
        clearInterval(timer);
      }
    });
  }

  async pullSetData(groupID) {
    console.log('Pulling set data...');
    httpsCallable(
      this.fns,
      'listing-getSet'
    )({
      groupID,
    })
      .then((res: any) => {
        console.log(res.data);
        this.set = res.data;
      })
      .catch((e) => console.error(e));
  }

  async getCardsForSet() {
    if (this.pulledFullSet) return;

    console.log('Pulling full set...');
    this.set.cards = [];

    try {
      const res = (await httpsCallable(
        this.fns,
        'listing-getSetCards'
      )({
        groupID: this.set.groupId,
        limit: 10000,
      })) as any;

      console.log(res.data);

      // Try fancy filter
      if (this.card.game == 'Pokémon') {
        this.set.cards = res.data
          .filter((d) => d.extendedData && d.extendedData[1])
          .sort((a, b) => b.extendedData[0].value - a.extendedData[0].value);
      } else if (this.card.game == 'Magic: The Gathering') {
        this.set.cards = res.data.filter(
          (d) =>
            d.name.split(' ').pop() !== 'Box' &&
            d.name.split(' ').pop() !== 'Pack' &&
            d.name.split(' ').pop() !== 'Display' &&
            d.name.split(' ').pop() !== 'Bundle' &&
            d.name.split(' ').pop() !== 'Case'
        );
      } else if (this.card.game == 'YuGiOh') {
        this.set.cards = res.data.filter(
          (d) =>
            !d.name.includes('Booster Pack') && !d.name.includes('Booster Box')
        );
      } else if (this.card.game == 'Flesh & Blood') {
        this.set.cards = res.data.filter((d) => !d.name.includes(' Deck '));
      } else if (this.card.game == 'Final Fantasy') {
        this.set.cards = res.data.filter(
          (d) => d.extendedData && d.extendedData[0].value !== 'None'
        );
      } else {
        this.set.cards = res.data;
      }

      this.pulledFullSet = true;

      // If no fancy filter show everything :(
      setTimeout(
        () => !this.set.cards.length && (this.set.cards = res.data),
        1000
      );
      console.log(this.set);
    } catch (error) {
      console.error(error);
    }
  }

  async listMarketCard(card) {
    this.listingManagerService.filledListingCard = null;
    this.listingManagerService.uploadingListingCard = await card;

    this.modalHelper.triggerListingForm();
  }

  async openMarketCard(card) {
    const buttons = [
      {
        text: 'Open in TCGPlayer',
        handler: () => this.modalHelper.triggerIAB(card.url),
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      },
    ];

    if (this.authService.user && this.authService.user.sellerVerified) {
      buttons.splice(1, 0, {
        text: 'List this product',
        handler: () => this.listMarketCard(card),
      });
    }

    const selectedBtn = await this.modalHelper.createActionSheet({
      buttons,
    });
    buttons[selectedBtn].handler();
  }
}
