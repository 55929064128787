// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile_filter_wrap {
  background: #fff0e5;
}
.profile_filter_wrap form {
  padding: 10px;
  padding-bottom: 0;
  box-sizing: border-box;
}
.profile_filter_wrap ion-searchbar {
  opacity: 0.4;
  padding: 0 10px;
  padding-bottom: 0;
}
.profile_filter_wrap ion-buttons {
  width: 100%;
  margin-bottom: 20px;
  display: inline-flex;
  justify-content: center;
  grid-gap: 10px;
}
.profile_filter_wrap ion-buttons ion-button {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 900;
  background: #f7eadf;
  color: #b8aca2;
  display: inline-flex;
  border-radius: 3px;
}
.profile_filter_wrap ion-buttons ion-button ion-icon {
  margin: 0px 5px;
  margin-right: 10px;
}
.profile_filter_wrap ion-buttons ion-button label {
  margin-right: 5px;
  cursor: pointer;
}
.profile_filter_wrap ion-buttons .selected {
  background: #ffe4cf;
  color: #d9bca5;
}
.profile_filter_wrap .close_line {
  width: 100%;
  text-align: center;
  padding: 3px;
  font-size: 11px;
  color: #fff2e6;
  background: #d2b094;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/profile-filter/profile-filter.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;AACI;EACI,aAAA;EACA,iBAAA;EACA,sBAAA;AACR;AAEI;EACI,YAAA;EACA,eAAA;EACA,iBAAA;AAAR;AAGI;EACI,WAAA;EACA,mBAAA;EACA,oBAAA;EACA,uBAAA;EACA,cAAA;AADR;AAGQ;EACI,iBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;EACA,oBAAA;EACA,kBAAA;AADZ;AAGY;EACI,eAAA;EACA,kBAAA;AADhB;AAIY;EACI,iBAAA;EACA,eAAA;AAFhB;AAMQ;EACI,mBAAA;EACA,cAAA;AAJZ;AAQI;EACI,WAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;EAEA,cAAA;EACA,mBAAA;EACA,eAAA;AAPR","sourcesContent":[".profile_filter_wrap {\n    background: #fff0e5;\n\n    form {\n        padding: 10px;\n        padding-bottom: 0;\n        box-sizing: border-box;\n    }\n\n    ion-searchbar {\n        opacity: 0.4;\n        padding: 0 10px;\n        padding-bottom: 0;\n    }\n\n    ion-buttons {\n        width: 100%;\n        margin-bottom: 20px;\n        display: inline-flex;\n        justify-content: center;\n        grid-gap: 10px;\n\n        ion-button {\n            font-weight: bold;\n            text-transform: uppercase;\n            font-size: 10px;\n            font-weight: 900;\n            background: #f7eadf;\n            color: #b8aca2;\n            display: inline-flex;\n            border-radius: 3px;\n\n            ion-icon {\n                margin: 0px 5px;\n                margin-right: 10px;\n            }\n\n            label {\n                margin-right: 5px;\n                cursor: pointer;\n            }\n        }\n\n        .selected {\n            background: #ffe4cf;\n            color: #d9bca5;\n        }\n    }\n\n    .close_line {\n        width: 100%;\n        text-align: center;\n        padding: 3px;\n        font-size: 11px;\n\n        color: #fff2e6;\n        background: #d2b094;\n        cursor: pointer;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
