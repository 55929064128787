/* eslint-disable max-len */
export const environment = {
  production: true,
  trackingCheatCode: 'EESS1184',
  firebase: {
    apiKey: 'AIzaSyCZxO2VSfmg_RLvR0vNmCkP8WFRsr427I0',
    authDomain: 'my-project-1485151099856.firebaseapp.com',
    projectId: 'my-project-1485151099856',
    storageBucket: 'my-project-1485151099856.appspot.com',
    messagingSenderId: '177202314271',
    appId: '1:177202314271:web:ad0c86ae223a85dbaf692a',
  },
  fcmServerKey:
    'AAAAKUIUvB8:APA91bEMoUOsCT6bmqA7C8eu914NeCxb3w9Ze_-PBF6NdpCFH_OPYu0gXEHTcEdd_CrmzbjOAFbMs1F_hQRjjhewwENzs8RsoBfOAzDGw4wGuzPBMVFKQgqj4QHrAUTwoCnv1kaYr6Kf',
  trackingKey: 'j3hml0gj-cz14-h140-okj3-sjlajyb4s8hq',
  // stripeTestKey: 'pk_test_EUoTYLTfqwUs7x8MfJatwUeN',
  stripeKey: 'pk_live_yyabzM4GqbTUwZLbHzdXfika',
  googleKey: 'AIzaSyCZxO2VSfmg_RLvR0vNmCkP8WFRsr427I0',
  algoliaAppID: 'XU2DX2ZNWO',
  algoliaSecret: 'a721bead950e76938ec695127ffcccc8',
  webGoogleClientID:
    '177202314271-u9hh5g8us26isioki9injng4ramvlv94.apps.googleusercontent.com',
  iosGoogleClientID:
    '177202314271-idq2i6gv8kljbo67s7sp71tqa84gt1af.apps.googleusercontent.com',
  androidGoogleClientID:
    '177202314271-u9hh5g8us26isioki9injng4ramvlv94.apps.googleusercontent.com',
};
