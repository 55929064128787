import { Component, OnInit } from '@angular/core';
import { MessengerService } from '../services/messenger.service';
import { ModalHelperService } from '../services/modal-helper.service';

@Component({
  selector: 'app-howto-delivery',
  templateUrl: './howto-delivery.page.html',
  styleUrls: ['./howto-delivery.page.scss'],
})
export class HowtoDeliveryPage implements OnInit {
  constructor(
    public modalHelper: ModalHelperService,
    public messengerService: MessengerService
  ) {}

  ngOnInit() {}
}
