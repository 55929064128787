// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-toolbar {
  padding-left: 14px;
  padding-right: 14px;
}

ion-searchbar {
  padding: 0;
}

.bagBadge {
  width: 12px;
  height: 12px;
  font-size: 8px;
  line-height: 12px;
  border-radius: 50%;
  position: absolute;
  margin-left: 18px;
  margin-top: -15px;
  z-index: 100;
  background: #e9b3a3;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/components/header/header.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,cAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,YAAA;EAEA,mBAAA;EACA,WAAA;AAAJ","sourcesContent":["ion-toolbar {\n    padding-left: 14px;\n    padding-right: 14px;\n}\n\nion-searchbar {\n    padding: 0;\n}\n\n.bagBadge {\n    width: 12px;\n    height: 12px;\n    font-size: 8px;\n    line-height: 12px;\n    border-radius: 50%;\n    position: absolute;\n    margin-left: 18px;\n    margin-top: -15px;\n    z-index: 100;\n\n    background: #e9b3a3;\n    color: #fff;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
