import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.page.html',
  styleUrls: ['./landing.page.scss'],
})
export class LandingPage implements OnInit {
  hasInternet: boolean;

  constructor(public authService: AuthService) {}

  async ngOnInit() {
    this.hasInternet = await this.authService.checkForInternet();
  }

  openAppleStore() {
    window.open('https://www.apple.com/au/app-store/', '_blank').focus();
  }

  openGooglePlayStore() {
    window.open('https://play.google.com/store', '_blank').focus();
  }
}
