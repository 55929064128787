import { Injectable } from '@angular/core';
import {
  getDoc,
  doc,
  Firestore,
  addDoc,
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { ModalHelperService } from './modal-helper.service';
import { type } from 'os';

@Injectable({
  providedIn: 'root',
})
export class MessengerService {
  refreshMail: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  refreshMailSub: Observable<boolean> = this.refreshMail.asObservable();

  constructor(
    public authService: AuthService,
    public modalHelper: ModalHelperService,
    public route: ActivatedRoute,
    public router: Router,
    public firestore: Firestore
  ) {}

  // Open the Cardboard Ninja Facebook Messenger page in a popover window
  async getHelp() {
    this.modalHelper.triggerIAB('https://m.me/cbnja');
  }

  // Send a text message with a verification code to the given phone number
  async sendText(phNum) {
    // Generate a random verification code
    const verificationNumber = Math.floor(Math.random() * 1000000)
      .toString()
      .padStart(6, '0');

    // Add a document to the "Twilio" collection with the text message details
    const twilioDoc = await addDoc(collection(this.firestore, 'Twilio'), {
      to: phNum,
      from: '+61483903888',
      body: `Your security code to access Cardboard Ninja is ${verificationNumber}`,
    });

    // Return the verification code and the document containing the text message details
    return { verificationNumber, twilioDoc };
  }

  // Open the Messenger page with the given user and card information
  async openMessenger(to, from, id) {
    // Create a BehaviorSubject with the user and card information
    const owner = {
      uid: to === this.authService.user.uid ? from : to,
      to,
      context: id,
    };

    const card = (await getDoc(doc(this.firestore, 'Listings', id))).data();

    // Set the query parameters for the Messenger page
    this.router.navigate([], {
      queryParams: {
        t: to,
        f: from,
        i: id,
      },
      queryParamsHandling: 'merge',
    });

    // If the user is authenticated, open the Messenger page in a modal window
    if (this.authService.user) {
      this.modalHelper.triggerMessenger(owner, card);

      this.router.navigate([], {
        queryParams: {
          t: null,
          f: null,
          i: null,
        },
        queryParamsHandling: 'merge',
      });
    } else {
      this.authService.notAuthedPopup();
    }
  }

  triggerMailRefresh() {
    this.refreshMail.next(true);
  }
}
