/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CartService } from '../services/cart.service';
import { ListingManagerService } from '../services/listing-manager.service';
import { ModalHelperService } from '../services/modal-helper.service';
import { PayService } from '../services/pay.service';

@Component({
  selector: 'app-bag',
  templateUrl: './bag.page.html',
  styleUrls: ['./bag.page.scss'],
})
export class BagPage implements OnInit {
  showEdits: boolean;
  canPurchase: boolean = true;

  constructor(
    public modalHelper: ModalHelperService,
    public listingService: ListingManagerService,
    public authService: AuthService,
    public router: Router,
    public cartService: CartService,
    public payService: PayService
  ) {}

  ngOnInit() {}

  doRefresh(event) {
    this.cartService.pullCardsForCart().then(() => {
      event.target.complete();
    });
  }

  removeSingleBag(owner) {
    this.modalHelper.createAlert({
      title: 'Clear Bag',
      message: 'Clear all cards from @' + owner.username + '?',
      buttonTitle: 'Clear',
      type: 'confirm',
      handler: () => this.cartService.removeOwnerFromBag(owner),
    });
  }

  clearEverythingInBag() {
    this.modalHelper.createAlert({
      title: 'Clear Bag',
      message: 'Are you sure you want to clear all items from all sellers?',
      buttonTitle: 'Clear All',
      type: 'confirm',
      handler: async () => this.cartService.clearWholeBag(),
    });
  }

  checkoutBtn(bag) {
    this.payService.makePayment(bag).then(() => {
      bag = [];
      this.canPurchase = true;
    });
  }

  async buyAll() {
    this.modalHelper.createAlert({
      title: 'Buy All',
      message: 'Buy all feature coming soon!',
    });
  }
}
