import { Injectable, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
  BrowserModule,
  HammerModule,
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

// Google Maps
import { NgMapsCoreModule } from '@ng-maps/core';
import { GOOGLE_MAPS_API_CONFIG, NgMapsGoogleModule } from '@ng-maps/google';

import { ServiceWorkerModule } from '@angular/service-worker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { AgGridModule } from 'ag-grid-angular';

import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  provideAuth,
} from '@angular/fire/auth';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { Capacitor } from '@capacitor/core';
import { DatePipe } from '@angular/common';

import { TcgControllerService } from './services/tcg-controller.service';

import { LandingPage } from './landing/landing.page';
import { BagPage } from './bag/bag.page';
import { ListingLikesPage } from './listing-likes/listing-likes.page';
import { UserCardComponent } from './components/user-card/user-card.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { PricingChartsComponent } from './components/pricing-charts/pricing-charts.component';
import { FollowPage } from './follow/follow.page';
import { ListingFooterComponent } from './components/listing-footer/listing-footer.component';
import { NewsFeedComponent } from './components/news-feed/news-feed.component';
import { ListingHeaderComponent } from './components/listing-header/listing-header.component';
import { ListingPage } from './listing/listing.page';
import { CardUnitComponent } from './components/card-unit/card-unit.component';
import { TypeSlideComponent } from './components/type-slide/type-slide.component';
import { BagIndicatorComponent } from './components/bag-indicator/bag-indicator.component';
import { HomeSectionComponent } from './components/home-section/home-section.component';
import { StatusIndicatorComponent } from './components/card-unit/status-indicator/status-indicator.component';
import { WelcomePage } from './welcome/welcome.page';
import { HomePage } from './home/home.page';
import { InfinityPage } from './infinity/infinity.page';
import { BigHomeSectionComponent } from './components/big-home-section/big-home-section.component';
import { GameFilterPopoverComponent } from './components/header/game-filter-popover/game-filter-popover.component';
import { HeaderComponent } from './components/header/header.component';
import { MicroGridComponent } from './components/micro-grid/micro-grid.component';
import { TcgDataCardUnitComponent } from './components/tcg-data-card-unit/tcg-data-card-unit.component';
import { SearchPage } from './search/search.page';
import { MailCardComponent } from './components/mail-card/mail-card.component';
import { FundsPage } from './funds/funds.page';
import { MailPage } from './mail/mail.page';
import { OrderTrackerPage } from './orders/order-tracker/order-tracker.page';
import { OrdersPage } from './orders/orders.page';
import { RecentActivityPage } from './recent-activity/recent-activity.page';
import { GridControllerComponent } from './components/grid-controller/grid-controller.component';
import { LoginComponent } from './components/login/login.component';
import { ProfileAggridViewComponent } from './components/profile-aggrid-view/profile-aggrid-view.component';
import { ProfileFilterComponent } from './components/profile-filter/profile-filter.component';
import { ProfilePopoverComponent } from './components/profile-popover/profile-popover.component';
import { ProfilePage } from './profile/profile.page';
import { BannersComponent } from './components/banners/banners.component';
import { ProfileDetailChunkComponent } from './components/profile-detail-chunk/profile-detail-chunk.component';
import { ProfileCardViewComponent } from './components/profile-card-view/profile-card-view.component';
import { ProfileGridViewComponent } from './components/profile-grid-view/profile-grid-view.component';
import { AdminPage } from './admin/admin.page';
import { AffiliatesPage } from './affiliates/affiliates.page';
import { ListingFormPage } from './listing-form/listing-form.page';
import { ReceiptsPage } from './receipts/receipts.page';
import { UserProfilePage } from './user-profile/user-profile.page';
import { WriteReviewPage } from './write-review/write-review.page';
import { SellerRegisterComponent } from './components/seller-register/seller-register.component';
import { ListingFormSliderComponent } from './listing-form/listing-form-slider/listing-form-slider.component';
import { SearchCategoryPage } from './search-category/search-category.page';
import { AddListingPage } from './add-listing/add-listing.page';
import { ContactPage } from './contact/contact.page';
import { HowtoPage } from './howto/howto.page';
import { LegalPage } from './legal/legal.page';
import { MessengerPage } from './messenger/messenger.page';
import { MsgHeaderComponent } from './messenger/msg-header/msg-header.component';
import { MsgContextRowComponent } from './messenger/msg-header/msg-context-row/msg-context-row.component';
import { MsgMessageComponent } from './messenger/msg-message/msg-message.component';
import { ReviewCardsComponent } from './components/review-cards/review-cards.component';
import { SetViewerComponent } from './components/set-viewer/set-viewer.component';
import { TagsPage } from './tags/tags.page';
import { SetSlideComponent } from './components/set-slide/set-slide.component';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = {};
}

@NgModule({
  declarations: [
    AppComponent,
    LandingPage,
    BagPage,
    //Pages
    HomePage,
    SearchPage,
    ProfilePage,
    WelcomePage,
    InfinityPage,
    MailPage,
    TagsPage,
    RecentActivityPage,
    OrdersPage,
    OrderTrackerPage,
    FundsPage,
    HowtoPage,
    LegalPage,
    UserProfilePage,
    AdminPage,
    ListingFormPage,
    WriteReviewPage,
    AffiliatesPage,
    ReceiptsPage,
    SearchCategoryPage,
    AddListingPage,
    ContactPage,
    MessengerPage,
    ListingPage,
    FollowPage,
    // Components
    HeaderComponent,
    StarRatingComponent,
    TcgDataCardUnitComponent,
    BigHomeSectionComponent,
    MicroGridComponent,
    NewsFeedComponent,
    GameFilterPopoverComponent,
    CardUnitComponent,
    BannersComponent,
    SetSlideComponent,
    TypeSlideComponent,
    BagIndicatorComponent,
    HomeSectionComponent,
    UserCardComponent,
    StatusIndicatorComponent,
    MailCardComponent,
    LoginComponent,
    GridControllerComponent,
    ProfileFilterComponent,
    ProfilePopoverComponent,
    ProfileCardViewComponent,
    ProfileGridViewComponent,
    ProfileAggridViewComponent,
    ProfileDetailChunkComponent,
    ListingFormSliderComponent,
    SellerRegisterComponent,
    MsgHeaderComponent,
    MsgContextRowComponent,
    MsgMessageComponent,
    PricingChartsComponent,
    SetViewerComponent,
    ReviewCardsComponent,
    ListingHeaderComponent,
    ListingFooterComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HammerModule,
    IonicModule.forRoot({
      mode: 'ios',
    }),
    AppRoutingModule,
    FormsModule,
    AgGridModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions(getApp(), 'australia-southeast1')),
    provideMessaging(() => getMessaging()),
    provideStorage(() => getStorage()),
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
        });
      } else {
        return getAuth();
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgMapsCoreModule,
    NgMapsGoogleModule,
    // AgmCoreModule.forRoot({
    //   apiKey: environment.googleKey,
    //   libraries: ['places'],
    // }),
    // ServerModule,
    // IonicServerModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
    {
      provide: GOOGLE_MAPS_API_CONFIG,
      useValue: {
        apiKey: environment.googleKey,
        libraries: ['places'],
      },
    },
    DatePipe,
    TcgControllerService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
