import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { FilterService } from 'src/app/services/filter.service';
import { ListingManagerService } from 'src/app/services/listing-manager.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() card = {};
  @Input() owner = {};

  showSearch: boolean;

  bagCount = 0;

  constructor(
    public modalHelper: ModalHelperService,
    public filterService: FilterService,
    public listingManager: ListingManagerService,
    public cartService: CartService,
    public router: Router,
    public authService: AuthService
  ) {}

  ngOnInit() {}

  async showHowTo() {
    this.modalHelper.triggerWelcome();
  }

  async openShipping() {
    if (this.authService.user) {
      this.modalHelper.triggerShipping();
    }
  }



}
