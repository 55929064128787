import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import {
  collection,
  Firestore,
  getDocs,
  query,
  where,
} from '@angular/fire/firestore';
import { PayService } from '../services/pay.service';
import { ModalHelperService } from '../services/modal-helper.service';

@Component({
  selector: 'app-affiliates',
  templateUrl: './affiliates.page.html',
  styleUrls: ['./affiliates.page.scss'],
})
export class AffiliatesPage implements OnInit {
  loading = true;

  affiliates = [];

  totalTakings: number;

  constructor(
    public authService: AuthService,
    public payService: PayService,
    public firestore: Firestore,
    public modalHelper: ModalHelperService
  ) {}

  ngOnInit() {
    this.getMyAffilates();
  }

  async copyURL(txt) {
    await this.modalHelper.writeToClipboard(txt);
  }

  async shareURL(url) {
    this.modalHelper.share({
      text: 'Join me in Cardboard Ninja!',
      url,
      dialogTitle: 'Share your affiliate link',
    });
  }

  async getMyAffilates() {
    const affiliates = (
      await getDocs(
        query(
          collection(this.firestore, 'Users'),
          where('affiliate', '==', '@' + this.authService.user.username)
        )
      )
    ).docs.map((listing) => listing.data());

    await this.getStripeAccounts(affiliates);

    this.loading = false;
  }

  async getStripeAccounts(accounts): Promise<void> {
    // Initialize total takings to 0
    this.totalTakings = 0;

    // Map the accounts array to an array of affiliate data
    try {
      await Promise.all(
        accounts.map(async (account) => {
          account.signedUpDate = new Date(account.signedUpDate.seconds * 1000);

          if (account.customerAccountID) {
            const acc: any = await this.payService.getCustomerTransactions(
              account.customerAccountID
            );

            let totalSpend = 0;

            acc.data.forEach((a) => {
              totalSpend += a.amount;
            });

            const refinedValue = Number(+totalSpend / 100);

            account.accountValue = refinedValue.toFixed(2);
            account.affTakings = Number(refinedValue * 0.0005);
          }
        })
      );

      // Update the affiliates array with the calculated account values and affiliate takings
      this.affiliates = accounts;

      // Calculate the total takings by summing the affiliate takings of each account
      this.totalTakings = this.affiliates
        .filter((a) => a.affTakings)
        .reduce((total, affiliate) => total + affiliate.affTakings, 0);
    } catch (e) {
      console.log(e);
    }
  }

  doRefresh(event) {
    this.getMyAffilates();

    setTimeout(() => {
      event.target.complete();
    }, 1000);
  }
}
