// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.outer_loading_wrap {
  display: flex;
  flex-wrap: wrap;
  place-items: center;
  grid-gap: 2px;
  height: 450px;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 8px 20px;
}

.loading_wrap {
  width: 100px;
  height: 33%;
  min-height: 33%;
  border-radius: 4px;
  background: #fcf5ef;
  margin-bottom: 2px;
}

.loading_block {
  height: 100%;
  background: #fff7f0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/micro-grid/micro-grid.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,mBAAA;EACA,aAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,YAAA;EACA,WAAA;EACA,eAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,YAAA;EACA,mBAAA;AACJ","sourcesContent":[".outer_loading_wrap {\n    display: flex;\n    flex-wrap: wrap;\n    place-items: center;\n    grid-gap: 2px;\n    height: 450px;\n    overflow-x: auto;\n    overflow-y: hidden;\n    padding: 0 8px 20px;\n}\n\n.loading_wrap {\n    width: 100px;\n    height: 33%;\n    min-height: 33%;\n    border-radius: 4px;\n    background: #fcf5ef;\n    margin-bottom: 2px;\n}\n\n.loading_block {\n    height: 100%;\n    background: #fff7f0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
