/* eslint-disable @typescript-eslint/no-unused-expressions */
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  Firestore,
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { IonContent, IonSearchbar } from '@ionic/angular';

import { debounceTime, fromEvent, Observable, Subscription } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { CartService } from '../services/cart.service';

import algoliasearch from 'algoliasearch';
import { environment } from 'src/environments/environment';

import { ListingManagerService } from '../services/listing-manager.service';
import { GesturesService } from '../services/gestures.service';
import { ModalHelperService } from '../services/modal-helper.service';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { FilterService } from '../services/filter.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.page.html',
  styleUrls: ['./search.page.scss'],
})
export class SearchPage implements OnInit, OnDestroy {
  @ViewChild('searchWrap', { read: ElementRef })
  searchWrap: ElementRef;

  @ViewChild('searchInput', { static: true }) searchInput: IonSearchbar;
  @ViewChild(IonContent, { static: false }) private content: IonContent;

  trendingSub: Subscription;
  topSub: Subscription;

  inputChangesSubscription: Subscription;
  routeSubscription: Subscription;
  pageScrollSubscription: Subscription;

  searchListings: any = [];
  trendingListings: any = [];
  highestRatedUsers: any = [];
  allUsers: any = [];
  runningPromos: any = [];
  index: any;

  searching: boolean;
  fetching: boolean;
  noCardsFound: boolean;
  loading = true;

  statusFilter: string = 'selling';

  private searchInput$: Observable<Event>;
  private debouncedSearchInput$: Observable<Event>;

  constructor(
    public fns: Functions,
    public firestore: Firestore,
    public router: Router,
    public cartService: CartService,
    public authService: AuthService,

    public gestureService: GesturesService,
    public listingManager: ListingManagerService,
    public modalHelper: ModalHelperService,
    public filterService: FilterService,
    public route: ActivatedRoute
  ) {
    const awaitUser = setInterval(() => {
      if (this.authService.gotAuth) {
        clearInterval(awaitUser);
        this.establish();
      }
    }, 200);
  }

  ngOnInit() {
    this.watchForInputChange();
  }

  ngOnDestroy() {
    this.inputChangesSubscription &&
      this.inputChangesSubscription.unsubscribe();
    this.routeSubscription && this.routeSubscription.unsubscribe();
    this.pageScrollSubscription && this.pageScrollSubscription.unsubscribe();
  }

  ionViewDidLeave() {
    this.modalHelper.hideKeyboard();
  }

  doRefresh(event) {
    this.constructSearchHomePage().then(() => {
      setTimeout(() => {
        event.target.complete();
      }, 500);
    });
  }

  async establish() {
    // initialize the Algolia client with the app ID and secret
    const client = algoliasearch(
      environment.algoliaAppID,
      environment.algoliaSecret
    );
    // create an index for the 'Listings' collection
    this.index = client.initIndex('Listings');

    // get all the users in the 'Users' collection
    // const allUsersResult = await getDocs(
    //   query(collection(this.firestore, 'Users'))
    // );
    // this.allUsers = allUsersResult.docs.map((listing) => listing.data());

    // construct the search home page
    this.constructSearchHomePage();

    // setup the swipe gesture
    this.setupSwipeGesture();
    // watch for tab scrolling
    this.watchForTabScroll();

    // subscribe to query parameters and check for the 'infinity' parameter
    this.routeSubscription = this.route.queryParams.subscribe((params) => {
      if (params.infinity) {
        // if the 'infinity' parameter exists, open the 'infinity' modal
        this.modalHelper.triggerInfinity(params.infinity);
      }

      if (!this.fetching && params.q) {
        // if the 'q' parameter exists, search for the query
        this.searchCardboard({ target: { value: params.q } });
      }
    });
  }

  async constructSearchHomePage() {
    this.loading = true;
    //this.trendingListings = [];
    this.highestRatedUsers = [];
    this.runningPromos = [];

    this.filterService.refreshGameFilter.next(true);

    httpsCallable(
      this.fns,
      'searchPage-pull'
    )({
      limit: 10,
    })
      .then((res: any) => {
        const d = res.data;
        this.highestRatedUsers = d.highestRatedUsers;
        this.runningPromos = d.runningPromos;
        this.loading = false;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  openListing(card) {
    this.modalHelper.triggerListingPage(card);
  }

  async searchCardboard(e) {
    this.searchInput.value = e.target.value;

    this.searching = true;
    this.fetching = true;

    this.router.navigate([], {
      queryParams: {
        q: this.searchInput.value,
      },
      queryParamsHandling: 'merge',
    });

    if (this.searchInput.value.length) {
      try {
        const data = await this.index.search(
          this.searchInput.value.toLowerCase(),
          {
            // Optimize the search query by limiting the number of attributes we retrieve and the number of hits we return
            getRankingInfo: true,
            analytics: false,
            enableABTest: false,
            hitsPerPage: 10,
            attributesToRetrieve: ['objectID'],
            attributesToSnippet: ['*:20'],
            snippetEllipsisText: '…',
            responseFields: ['*'],
            explain: ['*'],
            page: 0,
            facets: ['*'],
          }
        );

        const objectIDs = data.hits.map((hit) => hit.objectID);

        const querySnapshot = await getDocs(
          query(
            collection(this.firestore, 'Listings'),
            where('id', 'in', objectIDs)
          )
        );

        this.searchListings = [];
        const statusFilter = this.statusFilter || '';

        querySnapshot.forEach((doc) => {
          const dbObject = doc.data();
          if (statusFilter === 'selling' && dbObject.status === 'selling') {
            this.getOwner(dbObject);
            this.searchListings.push(dbObject);
          } else if (statusFilter !== 'selling') {
            this.getOwner(dbObject);
            this.searchListings.push(dbObject);
          }
        });
      } catch (error) {
        this.noCardsFound = true;
      }

      setTimeout(() => (this.fetching = false), 1000);
    } else {
      this.clearSearch();
    }
  }

  getOwner(listing) {
    // Return a new promise that will be resolved with the updated listing
    return new Promise(async (resolve) => {
      const listingDoc = doc(this.firestore, 'Users', listing.uploader);
      const userData = (await getDoc(listingDoc)).data();

      // Add the user data to the "owner" property of the listing
      listing.owner = userData;

      // Resolve the promise with the updated listing
      resolve(listing);
    });
  }

  clearSearch() {
    this.searchListings = [];
    this.searching = false;
    this.fetching = false;

    this.router.navigate([], {
      queryParams: {
        q: null,
      },
      queryParamsHandling: 'merge',
    });
  }

  addListing() {
    this.router.navigateByUrl('/add-listing');
  }

  watchForTabScroll() {
    this.pageScrollSubscription = this.authService.scrollPageSub.subscribe(
      (d) => {
        d && this.content.scrollToTop(400);
      }
    );
  }

  async watchForInputChange() {
    if (!this.searchInput) return;

    if (this.inputChangesSubscription) {
      this.inputChangesSubscription.unsubscribe();
    }

    if (!this.searchInput$) {
      this.searchInput$ = fromEvent(
        await this.searchInput.getInputElement(),
        'keyup'
      );
    }

    if (!this.debouncedSearchInput$) {
      this.debouncedSearchInput$ = this.searchInput$.pipe(debounceTime(500));
    }

    this.inputChangesSubscription = this.debouncedSearchInput$.subscribe((e) =>
      this.searchCardboard(e)
    );
  }

  // GESUTRE METHODS
  async setupSwipeGesture() {
    // Check if the element is defined
    if (!this.searchWrap) {
      // If not defined, set up function to check for it using requestAnimationFrame
      const checkForElement = () => {
        // Check if the element is now defined
        if (this.searchWrap) {
          // Create swipe gesture with specified options
          this.gestureService.runBtmTabGestures(
            this.searchWrap.nativeElement,
            'search'
          );
        } else {
          // If not defined, request animation frame again to check for it later
          requestAnimationFrame(checkForElement);
        }
      };

      // Request animation frame to start checking for the element
      requestAnimationFrame(checkForElement);
    } else {
      // Create swipe gesture with specified options
      this.gestureService.runBtmTabGestures(
        this.searchWrap.nativeElement,
        'search'
      );
    }
  }
}
