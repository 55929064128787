import { Component, Input, OnInit } from '@angular/core';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { User } from 'src/types';

@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent implements OnInit {
  @Input() user: User;

  constructor(public modalHelper: ModalHelperService) {}

  ngOnInit() {}

  async openProfile(owner) {
    this.modalHelper.triggerUserProfile(owner, 'shop');
  }
}
