import { Component, Input, OnInit } from '@angular/core';
import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { Review, User } from 'src/types';
import { ModalHelperService } from '../services/modal-helper.service';

@Component({
  selector: 'app-review-editor',
  templateUrl: './review-editor.page.html',
  styleUrls: ['./review-editor.page.scss'],
})
export class ReviewEditorPage implements OnInit {
  @Input() mySubject: BehaviorSubject<User>;

  user: User;

  reviews: Review[];

  constructor(
    public firestore: Firestore,
    public modalHelper: ModalHelperService
  ) {}

  ngOnInit() {
    this.user = this.mySubject.value;
    this.pullReviews(this.user.uid).then(
      (reviews: any) => (this.reviews = reviews)
    );
  }

  pullReviews(uid) {
    return new Promise(async (resolve) => {
      const listings = [];

      (
        await getDocs(
          query(
            collection(this.firestore, 'Users', String(uid), 'Reviews'),
            where('archived', '!=', true),
            orderBy('archived'),
            orderBy('time', 'desc')
          )
        )
      ).forEach(async (review) => {
        const reviewData = review.data();
        const listing = (
          await getDoc(
            doc(this.firestore, 'Listings', String(reviewData.listing))
          )
        ).data();

        reviewData.listing = listing;
        reviewData.user = this.user;
        listings.push(reviewData);
      });

      resolve(listings);
    });
  }
}
