// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  margin-top: 25px;
  margin-bottom: 2vh;
  margin-left: auto;
  margin-right: auto;
  display: block;
  box-sizing: border-box;
  min-height: 300px;
  height: 300px;
  max-height: 300px;
  padding: 20px;
}

.custom-file {
  display: block;
  color: #8e8e8e;
  border: 2px dotted #d1d1d1;
  width: 90%;
  /* height: -webkit-fill-available; */
  margin: auto;
  padding-top: 14vh;
  padding-bottom: 14vh;
  margin-top: 2vh;
  margin-bottom: 11vh;
  text-align: center;
  box-sizing: border-box;
  font-size: 40px;
}

.img_deleter {
  display: block;
  position: absolute;
  font-size: 22px;
  right: 20px;
  margin-top: 10px;
  color: #313131;
  text-shadow: 1px 1px #fff;
}

.edit_order_row {
  display: inline-flex;
  justify-content: center;
  width: 100%;
  grid-gap: 15px;
  font-size: 21px;
  color: #c5927d;
}
.edit_order_row .order {
  padding: 4px 8px;
}
.edit_order_row ion-icon {
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/listing-form/listing-form-slider/listing-form-slider.component.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;EACA,cAAA;EACA,sBAAA;EAEA,iBAAA;EACA,aAAA;EACA,iBAAA;EACA,aAAA;AAAF;;AAGA;EACE,cAAA;EACA,cAAA;EACA,0BAAA;EACA,UAAA;EACA,oCAAA;EACA,YAAA;EACA,iBAAA;EACA,oBAAA;EACA,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;AAAF;;AAGA;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;EACA,gBAAA;EACA,cAAA;EACA,yBAAA;AAAF;;AAGA;EACE,oBAAA;EACA,uBAAA;EACA,WAAA;EACA,cAAA;EACA,eAAA;EACA,cAAA;AAAF;AAEE;EACE,gBAAA;AAAJ;AAGE;EACE,gBAAA;AADJ","sourcesContent":["img {\n  object-fit: contain;\n  width: 100%;\n  height: 100%;\n  margin-top: 25px;\n  margin-bottom: 2vh;\n  margin-left: auto;\n  margin-right: auto;\n  display: block;\n  box-sizing: border-box;\n\n  min-height: 300px;\n  height: 300px;\n  max-height: 300px;\n  padding: 20px;\n}\n\n.custom-file {\n  display: block;\n  color: #8e8e8e;\n  border: 2px dotted #d1d1d1;\n  width: 90%;\n  /* height: -webkit-fill-available; */\n  margin: auto;\n  padding-top: 14vh;\n  padding-bottom: 14vh;\n  margin-top: 2vh;\n  margin-bottom: 11vh;\n  text-align: center;\n  box-sizing: border-box;\n  font-size: 40px;\n}\n\n.img_deleter {\n  display: block;\n  position: absolute;\n  font-size: 22px;\n  right: 20px;\n  margin-top: 10px;\n  color: #313131;\n  text-shadow: 1px 1px #fff;\n}\n\n.edit_order_row {\n  display: inline-flex;\n  justify-content: center;\n  width: 100%;\n  grid-gap: 15px;\n  font-size: 21px;\n  color: #c5927d;\n\n  .order {\n    padding: 4px 8px;\n  }\n\n  ion-icon {\n    margin-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
