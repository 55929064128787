import { Component, Input, ViewChild } from '@angular/core';
import { PhotoViewer, capShowOptions } from '@capacitor-community/photoviewer';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import Swiper from 'swiper';
import { ListingFormService } from '../listing-form-service.service';

@Component({
  selector: 'app-listing-form-slider',
  templateUrl: './listing-form-slider.component.html',
  styleUrls: ['./listing-form-slider.component.scss'],
})
export class ListingFormSliderComponent {
  @ViewChild('swiperRef', { static: false }) swiperRef;

  constructor(
    public modalHelper: ModalHelperService,
    public lfs: ListingFormService
  ) {}

  async uploadPhoto() {
    if (this.modalHelper.isMobile) {
      const buttons = [
        {
          text: 'Take Picture',
          handler: () => this.pickSingle(),
        },
        {
          text: 'Upload From Gallery',
          handler: () => this.pickImages(),
        },
        {
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => {},
        },
      ];

      const selectedBtn = await this.modalHelper.createActionSheet({
        title: 'Select Image Source',
        buttons,
      });

      buttons[selectedBtn].handler();
    } else {
      this.pickSingle();
    }
  }

  async pickSingle() {
    try {
      const image = await Camera.getPhoto({
        source: this.modalHelper.isMobile
          ? CameraSource.Camera
          : CameraSource.Photos,
        resultType: CameraResultType.Base64,
        saveToGallery: false,
        quality: 20,
      });

      this.lfs.uploadingListing.images.push(image.base64String);
      this.updateSlider(this.lfs.uploadingListing.images.length - 1);
    } catch (error) {
      console.log(error);
    }
  }

  async pickImages() {
    try {
      const images = await Camera.pickImages({
        presentationStyle: 'popover',
        quality: 20,
      });

      await this.modalHelper.createLoader({
        message: 'Compressing Images...',
      });

      for (const photo of images.photos) {
        const res = await Filesystem.readFile({ path: photo.path });

        this.lfs.uploadingListing.images.push(res.data);
        this.updateSlider(this.lfs.uploadingListing.images.length - 1);
      }

      this.modalHelper.dismissLoader();
    } catch (error) {
      console.log(error);
    }
  }

  async deletePhoto(id: number) {
    this.modalHelper.createAlert({
      title: 'Remove Image?',
      buttonTitle: 'Remove',
      type: 'confirm',
      handler: () => {
        this.lfs.uploadingListing.images.splice(id, 1);
        this.updateSlider(id);
      },
    });
  }

  openPhoto(url: string, title) {
    PhotoViewer.show({
      images: [{ url, title }],
      mode: 'one',
      options: {
        title: true,
        backgroundcolor: 'ivory',
      },
    } as capShowOptions).catch((e) => alert(e));
  }

  reOrderImages(oldIndex: number, newIndex: number) {
    const arr = this.lfs.uploadingListing.images.slice();
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    this.lfs.uploadingListing.images = arr;
  }

  getImageSource(image: string): string {
    if (!image) return;
    return image.includes('tcgplayer.com') ||
      image.includes('firebasestorage.googleapis.com')
      ? image
      : 'data:image/png;base64,' + image;
  }

  updateSlider(slideIndex: number) {
    const swiper = new Swiper(this.swiperRef.nativeElement);

    swiper.addSlide(0, `<label></label>`);
    swiper.update();
    swiper.slideTo(slideIndex);
  }
}
