// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `swiper-container {
  border-bottom: 3px solid rgba(226, 147, 81, 0.6);
  height: 200px;
}
swiper-container img {
  object-fit: cover;
  object-position: top;
  height: 200px;
  max-height: 200px;
  width: 100%;
  background: black;
  cursor: pointer;
}

swiper:last-child {
  margin-bottom: 0px;
}

.section .card_grid {
  background: none;
}

.fake_banner {
  max-height: 200px;
  height: 200px;
  width: 100%;
  background: #fff;
  object-fit: cover;
  object-position: center;
  border-bottom: 3px solid rgba(226, 147, 81, 0.6);
}`, "",{"version":3,"sources":["webpack://./src/app/components/banners/banners.component.scss"],"names":[],"mappings":"AAAA;EACI,gDAAA;EACA,aAAA;AACJ;AACI;EACI,iBAAA;EACA,oBAAA;EAIA,aAAA;EACA,iBAAA;EACA,WAAA;EACA,iBAAA;EACA,eAAA;AAFR;;AAMA;EACI,kBAAA;AAHJ;;AAOI;EACI,gBAAA;AAJR;;AAQA;EACI,iBAAA;EACA,aAAA;EACA,WAAA;EACA,gBAAA;EAEA,iBAAA;EACA,uBAAA;EACA,gDAAA;AANJ","sourcesContent":["swiper-container {\n    border-bottom: 3px solid rgba(226, 147, 81, 0.6);\n    height: 200px;\n\n    img {\n        object-fit: cover;\n        object-position: top;\n        // object-fit: contain;\n        // object-position: center;\n\n        height: 200px;\n        max-height: 200px;\n        width: 100%;\n        background: black;\n        cursor: pointer;\n    }\n}\n\nswiper:last-child {\n    margin-bottom: 0px;\n}\n\n.section {\n    .card_grid {\n        background: none;\n    }\n}\n\n.fake_banner {\n    max-height: 200px;\n    height: 200px;\n    width: 100%;\n    background: #fff;\n\n    object-fit: cover;\n    object-position: center;\n    border-bottom: 3px solid rgba(226, 147, 81, 0.6);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
