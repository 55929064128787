/* eslint-disable @typescript-eslint/dot-notation */
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Firestore } from '@angular/fire/firestore';
import { Functions, httpsCallable } from '@angular/fire/functions';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Subscription } from 'rxjs';
import { Listing } from 'src/types';
import { AdminService } from '../services/admin.service';
import { AuthService } from '../services/auth.service';
import { GesturesService } from '../services/gestures.service';
import { ModalHelperService } from '../services/modal-helper.service';
import { UserService } from '../services/user.service';
@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.page.html',
  styleUrls: ['./user-profile.page.scss'],
})
export class UserProfilePage implements OnInit, OnDestroy {
  @Input() mySubject: BehaviorSubject<{
    type: string;
    owner: string;
  }>;

  @ViewChild('profileCardWrap', { read: ElementRef })
  profileCardWrap: ElementRef;

  @ViewChild('reviewInput', { static: false }) review: {
    value: '';
  };
  @ViewChild('reviewRange', { static: false }) reviewRange: {
    value: '';
  };

  user: any;

  reviewMode: boolean;
  isMine: boolean;
  triedOnce: boolean;

  ownerID: string;

  loading = true;

  reviewSubscription: Subscription;

  listedCardCache = new Map<string, Listing>();
  ownedCardCache = new Map<string, Listing>();

  constructor(
    public router: Router,
    public firestore: Firestore,
    public modalHelper: ModalHelperService,
    public authService: AuthService,

    public userService: UserService,
    public adminService: AdminService,
    private metaService: Meta,
    public titleService: Title,
    public fns: Functions,
    public gestureService: GesturesService
  ) {}

  ngOnInit() {
    this.waitForAuth();
  }

  ngOnDestroy() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.reviewSubscription && this.reviewSubscription.unsubscribe();
  }

  async waitForAuth() {
    this.loading = true;

    if (!this.authService.gotAuth) {
      this.authService.waitForAuth().then(() => this.organiseUser());
    } else {
      this.organiseUser();
    }
  }

  async organiseUser() {
    if (!this.authService.gotAuth) {
      await this.authService.waitForAuth();
    }

    const { type, owner } = this.mySubject.value;
    this.user = owner;

    if (!this.user) {
      this.modalHelper.createAlert({
        title: 'Error',
        message: 'Something went wrong. Please try again.',
      });
      return;
    }

    try {
      const res = await httpsCallable(
        this.fns,
        'user-pull'
      )({ id: this.user.uid });
      this.buildUserPage(res.data, type);
    } catch (e) {
      console.log(e);
    }
  }

  buildUserPage(user, type) {
    console.log('buildUserPage', user);
    this.user = user;

    this.manageWebElements(this.user);

    this.isMine =
      this.authService.user && this.user.uid === this.authService.user.uid;

    this.loading = false;

    switch (type) {
      case 'shop':
        this.openTab(1);
        break;
      case 'likes':
        this.openTab(2);
        break;
      case 'reviews':
        this.openTab(3);
        break;
      default:
        break;
    }

    this.setupSwipeGesture();
  }

  async openTab(tab: number) {
    await this.modalHelper.buzz();

    // Store the selected tab in the user service
    this.userService.selectedTab = tab;
  }

  doRefresh(event) {
    this.openTab(this.userService.selectedTab).then(() =>
      event.target.complete()
    );
  }

  async closeProfile() {
    this.modalHelper.dismissModal();
  }

  async openFollow(type: any) {
    this.modalHelper.triggerFollow(type, this.user);
  }

  manageWebElements(user) {
    if (this.modalHelper.isMobile) {
      return;
    }

    const title = '@' + user.username + ' on Cardboard Ninja';

    this.metaService.updateTag({
      name: 'title',
      content: title,
    });
    this.metaService.updateTag({
      name: 'image',
      content: user.profilePic,
    });
    this.metaService.updateTag({
      property: 'og:title',
      content: title,
    });
    this.metaService.updateTag({
      property: 'og:image',
      content: user.profilePic,
    });
    this.titleService.setTitle(title);
  }

  // GESUTRE METHODS
  async setupSwipeGesture() {
    // Check if profile card wrap element is defined
    if (!this.profileCardWrap) {
      // If not defined, set up function to check for it using requestAnimationFrame
      const checkForProfileCardWrap = () => {
        // Check if profile card wrap element is now defined
        if (this.profileCardWrap && this.profileCardWrap.nativeElement) {
          // Create swipe gesture with specified options
          this.gestureService.runGestures(
            this.profileCardWrap.nativeElement,
            3
          );
        } else {
          // If not defined, request animation frame again to check for it later
          requestAnimationFrame(checkForProfileCardWrap);
        }
      };

      // Request animation frame to start checking for profile card wrap element
      requestAnimationFrame(checkForProfileCardWrap);
    } else {
      // Create swipe gesture with specified options
      this.gestureService.runGestures(this.profileCardWrap.nativeElement, 3);
    }
  }

  async userOptions() {
    const blocked =
      this.authService.user &&
      this.authService.user.blockList &&
      this.authService.user.blockList.includes(this.user.uid);

    // Vibrate the device to provide haptic feedback
    await this.modalHelper.buzz();

    // Show the action sheet with the "Copy" and "Cancel" options
    const buttons = [
      {
        text: (blocked ? 'Unblock @' : 'Block @') + this.user.username,
        icon: blocked ? 'person-add-outline' : 'person-remove-outline',
        handler: () => this.authService.toggleBlockingUser(this.user, blocked),
      },
      {
        text: 'Cancel',
        icon: 'close',
        role: 'cancel',
        handler: () => {},
      },
    ];

    const selectedBtn = await this.modalHelper.createActionSheet({
      title: 'User Options',
      buttons,
    });
    buttons[selectedBtn].handler();
  }
}

// // Use async/await syntax for asynchronous operations
// async getUsersListings() {
//   try {
//     const newListedCards = this.user.listedCards.filter(
//       (card) => !this.listedCardCache.has(card)
//     );

//     const cardPromises = newListedCards.map(async (card) => {
//       const cardDoc = await getDoc(doc(this.firestore, 'Listings', card));
//       if (cardDoc.exists) {
//         const cardData = cardDoc.data() as Listing;
//         if (cardData.status !== 'removed') {
//           this.populateOwner(cardData);
//           this.listedCardCache.set(card, cardData);
//           return cardData;
//         }
//       }
//     });

//     const newSoldCards = await Promise.all(cardPromises);

//     this.cards = this.cards
//       .concat(newSoldCards.filter((card) => card != null))
//       .sort(
//         (a: Listing, b: Listing) =>
//           (b.postDate ? b.postDate.seconds : 0) -
//           (a.postDate ? a.postDate.seconds : 0)
//       )
//       .sort((a: Listing, b: Listing) =>
//         ('' + a.status).localeCompare(b.status)
//       );

//     this.cards = this.cards;
//   } catch (error) {
//     console.error('Error pulling listed cards:', error);
//   }
// }

// async populateOwner(card) {
//   if (!card) {
//     return;
//   }
//   if (card.uploader === this.authService.user.uid) {
//     card.owner = this.authService.user;
//   } else {
//     if (!card.owner) {
//       card.owner = (
//         await getDoc(doc(this.firestore, 'Users', card.uploader))
//       ).data();
//     }
//   }
// }

// async getLikedCards() {
//   // Early return if user or user's liked property is not defined or empty
//   if (!this.user || !this.user.liked || !this.user.liked.length) {
//     return;
//   }

//   // Use map() instead of forEach() to avoid unnecessary nested async/await
//   const likedListingDocs = this.user.liked.map((like) =>
//     doc(this.firestore, 'Listings', like)
//   );
//   const likedListings = await Promise.all(likedListingDocs.map(getDoc));

//   // Use for-of loop instead of forEach() to avoid unnecessary nested async/await
//   for (const listing of likedListings) {
//     const ownerDoc = doc(
//       this.firestore,
//       'Users',
//       String(listing.data().uploader)
//     );
//     const owner = (await getDoc(ownerDoc)).data();

//     // Add the owner data to the listing object and push it to the cards array
//     this.likedCards.push({ ...listing.data(), owner });
//   }
// }

// async pullUser(owner: string) {
//   return new Promise(async (resolve) => {
//     const listingDoc = doc(this.firestore, 'Users', String(owner));
//     const endOutput = (await getDoc(listingDoc)).data();
//     resolve(endOutput);
//   });
// }

// async pullReviews() {
//   const reviews = (
//     await getDocs(
//       query(
//         collection(this.firestore, 'Users', this.user.uid, 'Reviews'),
//         where('archived', '!=', true),
//         orderBy('archived'),
//         orderBy('time', 'desc')
//       )
//     )
//   ).docs.map((review) => review.data());

//   reviews.forEach(async (review: any) => {
//     review.user = (
//       await getDoc(doc(this.firestore, 'Users', review.by))
//     ).data();
//     review.listing = (
//       await getDoc(doc(this.firestore, 'Listings', review.listing))
//     ).data();

//     this.userReviews.push(review);
//   });
// }
