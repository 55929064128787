// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards_grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 10%;
}
.cards_grid .card {
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/tags/tags.page.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,6BAAA;EACA,eAAA;AACJ;AACI;EACI,eAAA;AACR","sourcesContent":[".cards_grid {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-evenly;\n    margin-top: 10%;\n\n    .card {\n        margin-right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
