import { Component, Input, OnInit } from '@angular/core';
import { Firestore, setDoc, doc } from '@angular/fire/firestore';
import { capShowOptions, PhotoViewer } from '@capacitor-community/photoviewer';
import { AuthService } from 'src/app/services/auth.service';
import { CartService } from 'src/app/services/cart.service';
import { ModalHelperService } from 'src/app/services/modal-helper.service';
import { Message, Listing, User } from 'src/types';
import { GesturesService } from 'src/app/services/gestures.service';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-msg-message',
  templateUrl: './msg-message.component.html',
  styleUrls: ['./msg-message.component.scss'],
})
export class MsgMessageComponent implements OnInit {
  @Input() msg: Message;
  @Input() owner: User;
  @Input() context: Listing;

  constructor(
    public authService: AuthService,
    public cartService: CartService,
    public firestore: Firestore,

    public gestureService: GesturesService,
    public modalHelper: ModalHelperService
  ) {}

  ngOnInit() {
    //if text message includes 'https://' or 'http://' then wrap in a tag with url
    this.msg.message =
      this.msg.type === 'text'
        ? this.msg.message.replace(
            /(https?:\/\/[^\s]+)/g,
            '<u class="openURL">$1</u>'
          )
        : this.msg.message;
  }

  async openProfile(owner: User) {
    this.modalHelper.triggerUserProfile(owner, 'shop');
  }

  // Copy the given text to the clipboard
  async copyText(txt) {
    this.modalHelper.writeToClipboard(txt);
  }

  openPhoto(url, title) {
    PhotoViewer.show({
      images: [{ url, title }],
      mode: 'one',
      options: {
        title: true,
        backgroundcolor: 'ivory',
      },
    } as capShowOptions).catch((e) => alert(e));
  }

  async declineOffer(msg) {
    msg.offerAccepted = false;

    console.log(msg);

    await setDoc(
      doc(this.firestore, 'Messages', msg.to, msg.from, msg.id),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      { offerAccepted: false },
      {
        merge: true,
      }
    );
  }

  acceptOffer(msg) {
    this.modalHelper.createAlert({
      title: 'Accept Offer?',
      message:
        'Are you sure you want to allow this user to purchase this listing for $' +
        msg.message +
        ' AUD? No backsies. ',
      type: 'confirm',
      handler: async () => {
        msg.offerAccepted = true;

        await setDoc(
          doc(this.firestore, 'Messages', msg.to, msg.from, msg.id),
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { offerAccepted: true },
          {
            merge: true,
          }
        );
      },
      buttonTitle: 'Accept',
    });
  }

  // Add the current card to the user's bag, with the specified price
  async addToBag(price) {
    // Check if the user is logged in
    if (!this.authService.user) {
      // Show the "not authenticated" popup if the user is not logged in
      return this.authService.notAuthedPopup();
    }

    // Check if the current card and owner are defined
    if (!this.context || !this.owner) {
      return await this.modalHelper.createAlert({
        title: 'Something went wrong! 😢',
        message: 'please try again.',
      });
    }

    // Set the initial price for the current card
    this.context.definedInitialPrice = price;

    // Add the card to the user's bag
    await this.cartService.addCardToBag(this.context);

    this.modalHelper.triggerBag();
  }

  manageDate(date) {
    return date.seconds ? new Date(date.seconds * 1000) : 'invalid date';
  }

  copyAndReveal(msg) {
    msg.rev = true;

    this.copyText(msg.message);
  }
}
